import { OptActionButton, OptLoading } from '@optsol/react';

interface Props {
  onClick: () => void;
  texto: string;
  width?: number | string;
  botaoDesabilitado?: boolean;
  corTexto?: string;
  corIcone?: string;
  startIcon: string;
}

export const BotaoDeAcaoToolbar = ({
  onClick,
  texto,
  // width,
  botaoDesabilitado = false,
  corTexto,
  corIcone,
  startIcon
}: Props) => {
  return (
    // <Button
    //   size="large"
    //   onClick={onClick}
    //   disabled={botaoDesabilitado ? botaoDesabilitado : false}
    //   sx={{
    //     backgroundColor: Colors.primary,
    //     height: '35px',
    //     width: width ?? 'auto',
    //     color: Colors.white,
    //     marginRight: '10px',
    //     paddingBottom: '5px',
    //     paddingLeft: '19.5px',
    //     paddingRight: '19.5px',
    //     borderRadius: '7px',
    //     textTransform: 'capitalize',
    //     fontFamily: 'Inter, sans-serif',
    //     '&:hover': {
    //       backgroundColor: Colors.primaryTints.tint1
    //     },
    //     '&:disabled': {
    //       backgroundColor: Colors.gray7,
    //       color: Colors.gray3
    //     }
    //   }}
    // >
    //   {!botaoDesabilitado ? texto : <OptLoading size={22} />}
    // </Button>

    <OptActionButton
      onClick={onClick}
      disabled={botaoDesabilitado}
      startIcon={{ path: startIcon, color: corIcone }}
    >
      {!botaoDesabilitado ? (
        <span style={{ color: corTexto }}>{texto}</span>
      ) : (
        <OptLoading size={22} />
      )}
    </OptActionButton>
  );
};
