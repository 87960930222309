import { yupResolver } from '@hookform/resolvers/yup';
import { CircularProgress } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { FieldErrors, FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import {
  CriarVeiculoModel,
  DADOS_CRIAR_VEICULO_DEFAULT,
  dadosCriarVeiculoFormModelSchema
} from '../../../models/Veiculo/Veiculo.model';
import { RoutesObj } from '../../../routes/index.routes';
import { useVeiculoService } from '../../../service/veiculo.service';
import ButtonModal from '../../components/Button/ButtonModal';
import { useAuthenticationContext } from '../../contexts/authentication/authenticationContext';

import FormCriarVeiculo from './FormCriarVeiculo';

const CriarVeiculo = () => {
  const [loadingButtomSave, setLoadingButtomSave] = useState(false);
  const { salvarVeiculo } = useVeiculoService();
  const { enqueueSnackbar } = useSnackbar();
  const navegate = useNavigate();

  const {
    state: { tema }
  } = useAuthenticationContext();

  const form = useForm<CriarVeiculoModel>({
    mode: 'onBlur',
    defaultValues: DADOS_CRIAR_VEICULO_DEFAULT,
    resolver: yupResolver(dadosCriarVeiculoFormModelSchema)
  });

  const onValid = (data: CriarVeiculoModel) => {
    setLoadingButtomSave(true);
    salvarVeiculo(data).then((x) => {
      setLoadingButtomSave(false);
      enqueueSnackbar('Registro salvo com sucesso!', {
        variant: 'success'
      });
      navegate(RoutesObj.Veiculo.Detalhes(x.data?.id));
    });
  };

  const onInvalid = (_errors: FieldErrors<CriarVeiculoModel>) => {};

  return (
    <>
      <FormProvider {...form}>
        <FormCriarVeiculo />
        <ButtonModal
          size="large"
          tipo="confirm"
          $backgroundColor={tema.light?.primary}
          onClick={form.handleSubmit(onValid, onInvalid)}
          loadingIndicator={<CircularProgress color={'primary'} size={16} />}
          loading={loadingButtomSave}
        >
          Salvar
        </ButtonModal>
      </FormProvider>
    </>
  );
};

export default CriarVeiculo;
