import { InteractionType } from '@azure/msal-browser';
import { MsalAuthenticationTemplate, MsalProvider } from '@azure/msal-react';
import { OptTheme } from '@optsol/react';
import React, { createContext, useCallback, useMemo, useReducer } from 'react';
import Cookies from 'universal-cookie';

import { Colors } from '../../../core/colors';
import { useAuthenticationService } from '../../../service/authentication/authentication.service';
import { useMsalService } from '../../../service/authentication/msal.service';
import { MsalConfig, pca } from '../../../shared/msal/config';
import { UserInfo } from '../../../shared/msal/UserInfo';
import { converterHexToRgbA, dataParaBase64 } from '../../../shared/utils/functions';

import { AuthenticationActions } from './authenticationActions';
import {
  AuthenticationDispatch,
  AuthenticationReducer,
  AUTHENTICATION_INITIAL_DISPATCH
} from './authenticationReducer';
import {
  AuthenticationState,
  AUTHENTICATION_INITIAL_STATE,
  DecodedTokenModel
} from './authenticationState';

const AuthenticationStateContext = createContext<AuthenticationState>(AUTHENTICATION_INITIAL_STATE);
const AuthenticationDispatchContext = createContext<AuthenticationDispatch>(
  AUTHENTICATION_INITIAL_DISPATCH
);

type AuthenticationProps = { children: React.ReactNode };

function AuthenticationProvider({ children }: AuthenticationProps) {
  const [state, dispatch] = useReducer(AuthenticationReducer, AUTHENTICATION_INITIAL_STATE);

  return (
    <MsalProvider instance={pca}>
      <MsalAuthenticationTemplate
        interactionType={InteractionType.Redirect}
        authenticationRequest={{ scopes: MsalConfig.scopes }}
      >
        <AuthenticationStateContext.Provider value={state}>
          <AuthenticationDispatchContext.Provider value={dispatch}>
            {children}
          </AuthenticationDispatchContext.Provider>
        </AuthenticationStateContext.Provider>
      </MsalAuthenticationTemplate>
    </MsalProvider>
  );
}

function useAuthenticationContext() {
  const state = React.useContext(AuthenticationStateContext);
  const authenticationService = useAuthenticationService();

  const { getAccessToken } = useMsalService();
  const dispatch = React.useContext(AuthenticationDispatchContext);
  const actions = AuthenticationActions;
  const cookies = useMemo(() => {
    return new Cookies();
  }, []);

  if (state === undefined) {
    throw new Error(
      'useAuthenticationState deve ser utilizando dentro de um AuthenticationProvider'
    );
  }

  if (dispatch === undefined) {
    throw new Error(
      'useAuthenticationDispatch deve ser utilizando dentro de um AuthenticationProvider'
    );
  }

  const hasAccess = useCallback(
    (access: string) => {
      const token = state.token;
      if (!token) return false;
      const objectJWT: DecodedTokenModel = JSON.parse(window.atob(token.split('.')[1]));
      const securityClaims = objectJWT.extension_SecurityClaim?.split(';');

      if (securityClaims) return securityClaims.some((claim: string) => claim === access);
      else return false;
    },
    [state.token]
  );

  async function inicializar() {
    dispatch({ type: actions.LOADING_USER_INFO, payload: true });

    try {
      const token = await getAccessToken();
      if (token) {
        definirToken(token);
        const historyToken = await getAccessToken(MsalConfig.historyScopes);
        definirHistoryToken(historyToken);

        if (!cookies.get('local-id')) {
          const dataAtualBase64 = dataParaBase64();
          cookies.set('local-id', dataAtualBase64, { path: '/' });
          // { httpOnly: true }
          definirLocalId(dataAtualBase64);
          await carregarUserInfo(token, dataAtualBase64);
        } else {
          definirLocalId(cookies.get('local-id'));
          await carregarUserInfo(token, cookies.get('local-id'));
        }
      } else {
        dispatch({ type: actions.LOADING_USER_INFO, payload: false });
        // enqueueSnackbar("Falha na obtenção de token de autenticação", {
        //   variant: "error",
        // });
      }
    } catch (err) {
      dispatch({ type: actions.LOADING_USER_INFO, payload: false });
      throw err;
    }
  }

  async function recarregarUserInfo() {
    const token = await getAccessToken();
    await carregarUserInfo(token, state.localId);
  }

  async function carregarUserInfo(token?: string, localId?: string) {
    const result = await authenticationService.getUserInfo(token, localId);

    dispatch({
      type: actions.DEFINIR_TEMA,
      payload: obterTema(result.cor, result.corSecundaria).theme
    });
    dispatch({
      type: actions.DEFINIR_TEMA_SECUNDARIO,
      payload: obterTema(result.cor, result.corSecundaria).insideTheme
    });
    atualizarUserInfo(result);
    // dispatch({ type: actions.CARREGAR_USER_INFO, payload: result });
  }

  function obterTema(corPrimaria: string, corSecundaria: string) {
    const theme: OptTheme = {
      light: {
        style: 'soft',
        primary: `${converterHexToRgbA(corPrimaria ?? Colors.primary, '1')}`, //aplicar no Ícone e hover backGround
        primaryContrast: `${converterHexToRgbA(corSecundaria ?? Colors.secondary, '1')}`, //aplicar cor no houver do Ícone
        secondary: `${converterHexToRgbA(corSecundaria ?? Colors.secondary, '1')}`, //aplicar cor nas informações do usuário
        secondaryContrast: `${converterHexToRgbA(corPrimaria ?? Colors.primary, '1')}` //aplicar cor na letra da informação do usuário
      }
    };

    const insideTheme: OptTheme = {
      light: {
        style: 'soft',
        primary: `${converterHexToRgbA(corPrimaria ?? Colors.primary, '1')}`,
        primaryContrast: `${converterHexToRgbA(corPrimaria ?? Colors.primary, '0.1')}`,
        secondary: `${converterHexToRgbA(corSecundaria ?? Colors.secondary, '0.1')}`,
        secondaryContrast: `${converterHexToRgbA(corSecundaria ?? Colors.secondary, '0.1')}`
      }
    };

    return { theme, insideTheme };
  }

  function atualizarUserInfo(newuserInfo: UserInfo) {
    dispatch({ type: actions.CARREGAR_USER_INFO, payload: newuserInfo });
  }

  function definirTenant(tenantId?: string) {
    dispatch({ type: actions.DEFINIR_TENANT, payload: tenantId });
  }

  function definirToken(token?: string) {
    if (token !== state.token) dispatch({ type: actions.DEFINIR_TOKEN, payload: token });
  }

  function definirHistoryToken(historyToken?: string) {
    if (historyToken !== state.historyToken)
      dispatch({ type: actions.DEFINIR_HISTORY_TOKEN, payload: historyToken });
  }

  function definirLocalId(localId?: string) {
    if (localId) dispatch({ type: actions.DEFINIR_LOCAL_ID, payload: localId });
  }

  function resetarLocalId() {
    cookies.remove('local-id', { path: '/' });
    dispatch({ type: actions.DEFINIR_LOCAL_ID, payload: undefined });
  }

  return {
    state,
    carregarUserInfo,
    hasAccess,
    definirTenant,
    definirToken,
    definirHistoryToken,
    inicializar,
    atualizarUserInfo,
    definirLocalId,
    resetarLocalId,
    recarregarUserInfo
  };
}

export { AuthenticationProvider, useAuthenticationContext };
