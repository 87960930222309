import { mdiHandWave } from '@mdi/js';
import Icon from '@mdi/react';
import { IconButton, Tooltip } from '@mui/material';
import { OptConfirmationDialog } from '@optsol/react';
import { useState } from 'react';

import { Colors } from '../../../../core/colors';
import {
  FasesAtendimentoEnum,
  FasesAtendimentoEnumLabel
} from '../../../../models/enums/FasesAtendimento';

export interface Props {
  disableForm?: boolean;
  pacienteId: string;
  atendimentoId: string;
  faseAtendimento: FasesAtendimentoEnum | null;
  assumirPaciente: (
    atendimentoId: string,
    pacienteId: string,
    faseAtendimento: FasesAtendimentoEnum | null
  ) => Promise<void>;
}

export const AssumirPacienteButton = ({
  disableForm,
  pacienteId,
  atendimentoId,
  faseAtendimento,
  assumirPaciente
}: Props) => {
  const [confirmandoAssumir, setConfirmandoAssumir] = useState<boolean>(false);
  async function assumirHandler() {
    toggleConfirmacaoAssumir();

    await assumirPaciente(atendimentoId, pacienteId, faseAtendimento);
  }
  const toggleConfirmacaoAssumir = () => {
    setConfirmandoAssumir((prevState) => !prevState);
  };
  return (
    <>
      <Tooltip
        title={`Assumir atendimento ${
          faseAtendimento ? FasesAtendimentoEnumLabel.get(faseAtendimento) : ''
        }`}
        placement="top"
      >
        <IconButton
          disabled={disableForm}
          color="info"
          onClick={(e) => {
            e.stopPropagation();
            toggleConfirmacaoAssumir();
          }}
        >
          <Icon path={mdiHandWave} size={1} color={Colors.blue} />
        </IconButton>
      </Tooltip>
      <OptConfirmationDialog
        open={confirmandoAssumir}
        title="Assumir atendimento"
        icon={{ path: mdiHandWave, color: Colors.blue }}
        onCancel={toggleConfirmacaoAssumir}
        onClose={toggleConfirmacaoAssumir}
        onConfirm={assumirHandler}
      >
        Deseja assumir o atendimento deste paciente?
      </OptConfirmationDialog>
      {/* <OptBackdrop open={assumirAssumirIsLoading} /> */}
    </>
  );
};
