import Typography, { TypographyProps } from '@mui/material/Typography';

import { renderStatusColor } from './RenderColor';

interface Props extends TypographyProps {
  status: number;
  fontSize?: number;
  bgcolor?: string;
}

export function ChipStatusUrgencia({ status, fontSize, bgcolor, ...props }: Props) {
  return (
    <Typography
      fontSize={fontSize ?? 15}
      color={bgcolor ? 'white' : renderStatusColor(status).color}
      bgcolor={bgcolor ? bgcolor : ''}
      {...props}
    >
      {renderStatusColor(status).texto}
    </Typography>
  );
}
