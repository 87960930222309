import { mdiClose } from '@mdi/js';
import Icon from '@mdi/react';
import { Box, Button, Typography } from '@mui/material';
import { PropsWithChildren } from 'react';

import { Colors } from '../../../core/colors';

import * as S from './styles/index';

interface Props {
  open: boolean;
  onClose: () => void;
  title?: string;
  width?: string;
  maxWidth?: string;
  overflow?: string;
}

export const Modal = ({
  open,
  onClose,
  children,
  title,
  width = '100%',
  maxWidth = '100%',
  overflow = 'auto'
}: PropsWithChildren<Props>) => {
  return (
    <S.OptDialogCustom open={open} onClose={onClose} title="" maxWidth={'xl'}>
      <Box
        sx={{
          width: width,
          maxWidth: maxWidth,
          padding: '5px 20px',
          marginBottom: '25px',
          overflow: overflow
        }}
      >
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }} mb={-2} mt={-1}>
          <Typography align="left" fontWeight={600} fontSize={18}>
            {title}
          </Typography>
          <Button onClick={onClose}>
            <Icon path={mdiClose} size={1} color={Colors.gray1} />
          </Button>
        </Box>
        {children}
      </Box>
    </S.OptDialogCustom>
  );
};
