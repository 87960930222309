import { Grid, MenuItem, SelectChangeEvent } from '@mui/material';
import { useCallback, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { EnderecoModel } from '../../../../models/Endereco/Endereco.model';
import { EnderecoGeoLocalizacao } from '../../../../models/Endereco/EnderecoGeoLocalizacao.mode';
import { MenuEmpresaClaims } from '../../../../models/enums/AcessoClaims';
import { obterEnderecoGoole } from '../../../../shared/utils/functions';
import { ErrorMessage, FormSelect, FormTextField } from '../../../components/Form';
import { AutoCompleteMaps } from '../../../components/Google/Autocomplete';
import { FullLocationModel } from '../../../components/Google/model/FullLocation.model';
import { ProtectedContent } from '../../../components/ProtectedContent/ProtectedContent';

export const FormCriarEnderecoGeLocalizacao = () => {
  const {
    setValue,
    control,
    formState: { errors }
  } = useFormContext<EnderecoModel>();
  const adicionarEnderecoOrigem = useCallback(
    (place?: google.maps.places.PlaceResult, fullLocation?: FullLocationModel | undefined) => {
      if (!place) return;
      let cep: string[] | undefined;
      let rua: string[] | undefined;
      let bairro: string[] | undefined;
      let cidade: string[] | undefined;
      let estado: string[] | undefined;
      let numero: string[] | undefined;

      if (place.address_components) {
        cep = obterEnderecoGoole(place, 'postal_code');
        rua = obterEnderecoGoole(place, 'route');
        bairro = obterEnderecoGoole(place, 'sublocality_level_1');
        cidade = obterEnderecoGoole(place, 'administrative_area_level_2');
        numero = obterEnderecoGoole(place, 'street_number');
        estado = place.address_components
          .filter(function (it) {
            return it.types.indexOf('administrative_area_level_1') !== -1;
          })
          .map(function (it) {
            return it.short_name;
          });
      }

      if (numero && numero.length > 0) {
        if (isNaN(+numero[0])) {
          numero = [];
        }
      }

      const enderecoGeoLocalizacao: EnderecoGeoLocalizacao = {
        descricaoLocal: place?.formatted_address ?? '',
        latitude: fullLocation?.lat ?? place.geometry?.location?.lat() ?? 0,
        longitude: fullLocation?.lng ?? place.geometry?.location?.lng() ?? 0,
        placeId: place?.place_id ?? '',
        bairro: bairro ? bairro[0] : '',
        cidade: cidade ? cidade[0] : '',
        estado: estado ? estado[0] : '',
        cep: cep ? cep[0] : '',
        numero: numero && numero.length > 0 ? +numero[0] : null,
        rua: rua ? rua[0] : ''
      };

      setValue('enderecoGeolocalizacao', enderecoGeoLocalizacao);
    },
    [setValue]
  );

  const [listaTipos] = useState<any[]>([
    { id: 1, nome: 'Sede' },
    { id: 2, nome: 'Ponto de apoio' }
  ]);

  return (
    <>
      <Grid container item rowSpacing={1} columnSpacing={1}>
        <Grid item xs={12}>
          <Controller
            name="nome"
            control={control}
            render={() => (
              <>
                <ProtectedContent mode="disable" access={MenuEmpresaClaims.ATUALIZAR}>
                  <FormTextField control={control} name="nome" label="Nome" />
                </ProtectedContent>
              </>
            )}
          />
        </Grid>
      </Grid>
      <Grid container item rowSpacing={2} columnSpacing={1} pt={2.5}>
        <Grid item xs={4}>
          <Controller
            name="tipoLocal"
            control={control}
            render={({ field: { onChange, value } }) => {
              return (
                <>
                  <ProtectedContent mode="disable" access={MenuEmpresaClaims.ATUALIZAR}>
                    <FormSelect
                      onChange={(event: SelectChangeEvent<unknown>) => {
                        onChange(event.target.value);
                      }}
                      value={value}
                      name="tipoLocal"
                      label="Tipo"
                      size="small"
                    >
                      {listaTipos.map((x) => {
                        return (
                          <MenuItem key={x.id} value={x.id} style={{ textAlign: 'left' }}>
                            {x.nome}
                          </MenuItem>
                        );
                      })}
                    </FormSelect>
                  </ProtectedContent>
                </>
              );
            }}
          />
        </Grid>
        <Grid item xs={8}>
          <ProtectedContent mode="disable" access={MenuEmpresaClaims.ATUALIZAR}>
            <AutoCompleteMaps
              name="enderecoGeolocalizacao.descricaoLocal"
              onPlaceChange={(
                place: google.maps.places.PlaceResult,
                placeId?: string | undefined,
                fullLocation?: FullLocationModel | undefined
              ) => {
                adicionarEnderecoOrigem(place, fullLocation);
              }}
              label="Endereço"
            />
          </ProtectedContent>
          <ErrorMessage error={errors.enderecoGeolocalizacao?.descricaoLocal} />
        </Grid>
      </Grid>

      <Grid container item columnSpacing={1} pb={2.5} pt={2.5}>
        <Grid item xs={12}>
          <Controller
            name="complemento"
            control={control}
            render={() => (
              <>
                <ProtectedContent mode="disable" access={MenuEmpresaClaims.ATUALIZAR}>
                  <FormTextField control={control} name="complemento" label="Complemento" />
                </ProtectedContent>
              </>
            )}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default FormCriarEnderecoGeLocalizacao;
