import { OptChip } from '@optsol/react';

import { Colors } from '../../../core/colors';
import {
  StatusAtendimentoEnum,
  StatusAtendimentoEnumLabel
} from '../../../models/enums/StatusAtendimento';

interface StatusProps {
  status?: StatusAtendimentoEnum | null;
  faseAtendimentoSigla?: string;
}

export function StatusPacienteChip({ status, faseAtendimentoSigla }: StatusProps) {
  function retornaTextoStatusPaciente() {
    switch (status) {
      case StatusAtendimentoEnum.EM_ANDAMENTO:
        return `${StatusAtendimentoEnumLabel.get(StatusAtendimentoEnum.EM_ANDAMENTO)} ${
          faseAtendimentoSigla ? ' - ' + faseAtendimentoSigla : ''
        }`;
      case StatusAtendimentoEnum.ENCERRADO:
        return `${StatusAtendimentoEnumLabel.get(StatusAtendimentoEnum.ENCERRADO)}${
          faseAtendimentoSigla ? ' - ' + faseAtendimentoSigla : ''
        }`;
      case StatusAtendimentoEnum.PENDENTE:
        return `${StatusAtendimentoEnumLabel.get(StatusAtendimentoEnum.PENDENTE)}${
          faseAtendimentoSigla ? ' - ' + faseAtendimentoSigla : ''
        }`;
      case StatusAtendimentoEnum.CANCELADO:
        return `${StatusAtendimentoEnumLabel.get(StatusAtendimentoEnum.CANCELADO)}${
          faseAtendimentoSigla ? ' - ' + faseAtendimentoSigla : ''
        }`;
      case StatusAtendimentoEnum.CONCLUIDO:
        return `${StatusAtendimentoEnumLabel.get(StatusAtendimentoEnum.CONCLUIDO)}${
          faseAtendimentoSigla ? ' - ' + faseAtendimentoSigla : ''
        }`;
      case StatusAtendimentoEnum.AGENDADO:
        return `${StatusAtendimentoEnumLabel.get(StatusAtendimentoEnum.AGENDADO)}${
          faseAtendimentoSigla ? ' - ' + faseAtendimentoSigla : ''
        }`;
    }
  }

  function retornaColorFundo() {
    switch (status) {
      case StatusAtendimentoEnum.EM_ANDAMENTO:
        return Colors.blue;
      case StatusAtendimentoEnum.ENCERRADO:
        return Colors.red;
      case StatusAtendimentoEnum.PENDENTE:
        return Colors.yellow1;
      case StatusAtendimentoEnum.CANCELADO:
        return Colors.gray3;
      case StatusAtendimentoEnum.CONCLUIDO:
        return Colors.green;
      case StatusAtendimentoEnum.AGENDADO:
        return Colors.orange1;
    }
  }

  return (
    <>
      {status && retornaTextoStatusPaciente() && (
        <OptChip
          label={retornaTextoStatusPaciente()}
          style={{
            background: retornaColorFundo(),
            color: Colors.white,
            borderRadius: '4px',
            height: 25
          }}
        />
      )}
    </>
  );
}
