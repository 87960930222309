import { Grid } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';

import { CriarPlanoModel } from '../../../models/Plano/Plano.model';
import { ErrorMessage, FormTextField } from '../../components/Form';

export const FormCriarPlano = () => {
  const {
    control,
    formState: { errors }
  } = useFormContext<CriarPlanoModel>();

  return (
    <>
      <Grid container item rowSpacing={3} columnSpacing={3} pt={2.5} pb={2.5}>
        <Grid item xs={12}>
          <Controller
            name="nome"
            control={control}
            render={() => (
              <>
                <FormTextField name="nome" control={control} label="Nome" size="small" fullWidth />
              </>
            )}
          />
          <ErrorMessage error={errors.nome} />
        </Grid>
        <Grid item xs={12}>
          <Controller
            name="descricao"
            control={control}
            render={() => (
              <>
                <FormTextField
                  name="descricao"
                  control={control}
                  label="Descrição"
                  size="small"
                  fullWidth
                />
              </>
            )}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default FormCriarPlano;
