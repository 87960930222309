import { mdiSwapHorizontal } from '@mdi/js';
import Icon from '@mdi/react';
import IconButton from '@mui/material/IconButton/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { OptBackdrop } from '@optsol/react';
import { useState } from 'react';

import { Colors } from '../../../../core/colors';
import { FasesAtendimentoEnum } from '../../../../models/enums/FasesAtendimento';
import { PerfilAcessoEnum } from '../../../../models/enums/PerfilAcesso';
import GridEncaminharUsuario from '../../../components/EncaminharUsuario/GridEncaminharUsuario';
import { Modal } from '../../../components/Modal/Modal';

interface Props {
  pacienteId: string;
  atendimentoId: string;
  faseAtendimento: FasesAtendimentoEnum | null;
  alterarResponsavel: (
    atendimentoId: string,
    pacienteId: string,
    usuarioId: string,
    faseAtendimento: FasesAtendimentoEnum | null
  ) => Promise<void>;
  usuarioResponsavelId?: string;
}

export const AlterarResponsavelButton = ({
  pacienteId,
  atendimentoId,
  faseAtendimento,
  alterarResponsavel,
  usuarioResponsavelId
}: Props) => {
  const [encaminharIsOpen, setEncaminharIsOpen] = useState<boolean>(false);
  const [loadingEncaminhamento, setLoadingEncaminhamento] = useState<boolean>(false);

  const toggleEncaminharModal = () => {
    setEncaminharIsOpen((prevState) => !prevState);
  };
  async function encaminharHandler(usuarioId: string): Promise<void> {
    try {
      setLoadingEncaminhamento(true);
      await alterarResponsavel(atendimentoId, pacienteId, usuarioId, faseAtendimento);
      toggleEncaminharModal();
    } finally {
      setLoadingEncaminhamento(false);
    }
  }
  let tituloToolTip = '';

  switch (faseAtendimento) {
    case FasesAtendimentoEnum.FASEREGULACAO:
      tituloToolTip = 'Alterar fase regulação responsável';
      break;
    case FasesAtendimentoEnum.FASEEMPENHO:
      tituloToolTip = 'Alterar fase empenho responsável';
      break;
    case FasesAtendimentoEnum.FASECRIACAO:
      tituloToolTip = 'Alterar fase criação responsável';
      break;
    default:
      break;
  }

  let perfilAcesso = PerfilAcessoEnum.MedicoRegulador;

  switch (faseAtendimento) {
    case FasesAtendimentoEnum.FASEREGULACAO:
      perfilAcesso = PerfilAcessoEnum.MedicoRegulador;
      break;
    case FasesAtendimentoEnum.FASEEMPENHO:
      perfilAcesso = PerfilAcessoEnum.RadioOperador;
      break;
    case FasesAtendimentoEnum.FASECRIACAO:
      perfilAcesso = PerfilAcessoEnum.AtendenteTARM;
      break;
    default:
      break;
  }

  return (
    <>
      <Tooltip title={tituloToolTip} placement="top">
        <IconButton
          color="info"
          onClick={(e) => {
            e.stopPropagation();
            toggleEncaminharModal();
          }}
        >
          <Icon path={mdiSwapHorizontal} size={1} color={Colors.blue} />
        </IconButton>
      </Tooltip>
      <Modal
        open={encaminharIsOpen}
        onClose={toggleEncaminharModal}
        title="Encaminhamento"
        width="600px"
      >
        <GridEncaminharUsuario
          usuarioResponsavelId={usuarioResponsavelId}
          perfil={perfilAcesso}
          encaminhar={encaminharHandler}
          pacienteId={pacienteId}
          faseAtendimento={faseAtendimento}
          supervisorAlterando
        />
      </Modal>
      <OptBackdrop open={loadingEncaminhamento} />
    </>
  );
};
