import { mdiWeb } from '@mdi/js';
import Icon from '@mdi/react';
import { Button } from '@mui/material';
import {
  OptGrid,
  OptGridColumn,
  OptGridOptions,
  OptGridRequest,
  OptGridResponse
} from '@optsol/react';
import NumberFormat from 'react-number-format';

import { Colors } from '../../../core/colors';
import { EmpresaSearchResponse } from '../../../models/dtos/Empresa/empresaSearchResponse.model';
import { PaginatedSearchRequest } from '../../../shared/utils/searchRequest';

interface GridProps {
  carregar: (
    query: OptGridRequest,
    EmpresaSearchRequest?: PaginatedSearchRequest
  ) => Promise<OptGridResponse<EmpresaSearchResponse>>;
  editarEmpresa: (clientId: string) => void;
}

const options: OptGridOptions = {
  search: true,
  pageSize: 10
};
const GridEmpresas = ({ carregar, editarEmpresa }: GridProps) => {
  const columns: OptGridColumn<EmpresaSearchResponse>[] = [
    {
      title: 'id',
      field: 'id',
      hidden: true
    },
    {
      title: 'Nome',
      field: 'razaoSocial',
      align: 'start'
    },
    {
      title: 'CNPJ',
      field: 'cnpj',
      align: 'start',
      render: (props: EmpresaSearchResponse) => {
        return <span>{props.cnpj}</span>;
      }
    },
    {
      title: 'Telefone fixo',
      field: 'telefoneFixo',
      align: 'start',
      render: (props) => {
        return props?.telefoneFixo ? (
          <NumberFormat format={'(##) ####-####'} value={props?.telefoneFixo} displayType="text" />
        ) : (
          <span> - </span>
        );
      }
    },
    {
      title: 'Celular',
      field: 'telefoneCelular',
      align: 'start',
      render: (props: EmpresaSearchResponse) => {
        return props?.telefoneCelular ? (
          <NumberFormat
            format={'(##) #####-####'}
            value={props?.telefoneCelular}
            displayType="text"
          />
        ) : (
          <span> - </span>
        );
      }
    },
    {
      title: 'E-mail',
      field: 'email',
      align: 'start',
      render: (props: EmpresaSearchResponse) => {
        return <span> {props.email ? props.email : '-'}</span>;
      }
    },
    {
      title: 'Site',
      field: 'site',
      align: 'start',
      render: (props: EmpresaSearchResponse) => {
        return props?.site ? (
          <Button
            onClick={(e) => {
              e.stopPropagation();
              openLinkInNewTab(props.site);
            }}
          >
            <Icon path={mdiWeb} size={1} color={Colors.primary} />
          </Button>
        ) : (
          <span> - </span>
        );
      }
    }
  ];

  const openLinkInNewTab = (url: string) => {
    if (url.search('http') === -1) url = `https://${url}`;
    window.open(url, '_blank', 'noreferrer');
  };
  return (
    <OptGrid
      data={carregar}
      options={options}
      columns={columns}
      onRowClick={(rowData) => editarEmpresa(rowData.id ?? '')}
    />
  );
};

export default GridEmpresas;
