import { Box } from '@mui/system';

interface CategoriaCnhProps {
  tipo: number;
}

export function CategoriaCnhText(props: CategoriaCnhProps) {
  return (
    <>
      {props.tipo === 0 ? (
        <Box sx={{ fontWeight: '600' }}>A</Box>
      ) : props.tipo === 1 ? (
        <Box sx={{ fontWeight: '600' }}>B</Box>
      ) : props.tipo === 2 ? (
        <Box sx={{ fontWeight: '600' }}>AB</Box>
      ) : props.tipo === 3 ? (
        <Box sx={{ fontWeight: '600' }}>C</Box>
      ) : props.tipo === 4 ? (
        <Box sx={{ fontWeight: '600' }}>AC</Box>
      ) : props.tipo === 5 ? (
        <Box sx={{ fontWeight: '600' }}>D</Box>
      ) : props.tipo === 6 ? (
        <Box sx={{ fontWeight: '600' }}>AD</Box>
      ) : props.tipo === 7 ? (
        <Box sx={{ fontWeight: '600' }}>E</Box>
      ) : props.tipo === 8 ? (
        <Box sx={{ fontWeight: '600' }}>AE</Box>
      ) : (
        '-'
      )}
    </>
  );
}
