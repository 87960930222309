import { mdiAlert, mdiCheck, mdiFile } from '@mdi/js';
import Icon from '@mdi/react';
import { Card, CardContent, Grid, Typography } from '@mui/material';
import { OptGridRef } from '@optsol/react';
import { useSnackbar } from 'notistack';
import { useRef, useState } from 'react';
import { ColorPicker, useColor } from 'react-color-palette';
import 'react-color-palette/lib/css/styles.css';
import { Controller, useFormContext } from 'react-hook-form';

import { Colors } from '../../../core/colors';
import { GrupoAcessoResponse } from '../../../models/dtos/Usuario/usuarioSearchResponse.model';
import { EmpresaModel } from '../../../models/Empresa/Empresa.model';
import { EnderecoModel } from '../../../models/Endereco/Endereco.model';
import { MenuEmpresaClaims } from '../../../models/enums/AcessoClaims';
import {
  FasesAtendimentoEnum,
  FasesAtendimentoEnumLabel,
  ListaFasesAtendimentoEnumLabel
} from '../../../models/enums/FasesAtendimento';
import { PerfilAcessoEnum } from '../../../models/enums/PerfilAcesso';
import { useEmpresaService } from '../../../service/empresa.service';
import { validarCNPJ } from '../../../shared/utils/functions';
import { BotaoDeAcaoToolbar } from '../../components/Button/BotaoDeAcaoToolbar';
import { IconButton } from '../../components/Button/IconButton';
import { ErrorMessage, FormTextField } from '../../components/Form';
import { FormNumberFormat } from '../../components/Form/FormNumberFormat';
import { ImagePicker } from '../../components/Image/ImagePicker';
import { Modal } from '../../components/Modal/Modal';
import { ProtectedContent } from '../../components/ProtectedContent/ProtectedContent';
import VinculoFasesAtendimento from '../../components/VinculoFasesAtendimento/VinculoFasesAtendimento';
import { useAuthenticationContext } from '../../contexts/authentication/authenticationContext';
import GridenderecoGeoLocalizacao from '../../pages/empresas/enderecoGeoLocalizacao/GridenderecoGeoLocalizacao';

import CriarEnderecoGeLocalizacao from './endereco/CriarEnderecoGeLocalizacao';
import { ChaveModel } from './ListaChaves/ChaveModel/ChaveModel';
import { ListaChaves } from './ListaChaves/ListaChaves';
import * as S from './styles/index';

interface Props {
  editarEmpresa: () => void;
  salvarEndereco: (enderecoModel: EnderecoModel) => Promise<void>;
  excluirEndereco: (enderecoId: string) => Promise<void>;
  salvarImagem: (file: File) => Promise<void>;
  criarChave: (nome: string) => Promise<ChaveModel>;
  removerChave: (id: string) => Promise<void>;
  validarLogin: (loginP: string, senhaP: string) => Promise<void>;
  isDirty: boolean;
  enderecos: EnderecoModel[];
  gruposAcesso: GrupoAcessoResponse[];
  empresaIsLoading: boolean;
  imagem: string;
}

export const FormEditarEmpresa = ({
  editarEmpresa,
  salvarEndereco,
  excluirEndereco,
  salvarImagem,
  criarChave,
  removerChave,
  validarLogin,
  enderecos,
  gruposAcesso,
  imagem,
  isDirty
}: Props) => {
  const {
    control,
    watch,
    setError,
    reset,
    getValues,
    setValue,
    formState: { errors }
  } = useFormContext<EmpresaModel>();

  const {
    state: { userInfo }
  } = useAuthenticationContext();
  const { enqueueSnackbar } = useSnackbar();
  const monitorarCnpj = watch('cnpj');
  const refGridEndereco = useRef<OptGridRef>();
  const { adicionarFaseAtendimento, removerFaseAtendimento } = useEmpresaService();
  const [endereco, setEndereco] = useState<EnderecoModel>();
  const [enderecoIsOpen, setEnderecoIsOpen] = useState(false);
  const defaultColor = userInfo.data?.cor ? userInfo.data.cor ?? '' : Colors.primary;
  const defaultSecondaryColor = userInfo.data?.corSecundaria
    ? userInfo.data.corSecundaria ?? ''
    : Colors.primary;
  const [color, setColor] = useColor('hex', defaultColor);
  const [secundaryColor, setSecondaryColor] = useColor('hex', defaultSecondaryColor);
  const [codigoOpen, setModalCodigoOpen] = useState<boolean>(false);

  const { hasAccess } = useAuthenticationContext();
  function validarCnpjOnBlur() {
    if (!(monitorarCnpj?.replace(/[^\w\s]/gi, '')?.trim().length >= 12)) {
      if (!validarCNPJ(monitorarCnpj)) {
        setError('cnpj', { message: 'Documento inválido' });
        return;
      } else {
        setError('cnpj', { message: '' });
      }
      const novoCliente = { ...getValues() };
      reset({ ...novoCliente });
    }
  }

  const toggleEnderecoModal = () => {
    setEnderecoIsOpen((prevState) => !prevState);
  };

  function criarEndereco() {
    setEndereco(undefined);
    toggleEnderecoModal();
  }

  async function excluirEnderecoHandler(enderecoId: string) {
    try {
      // setLoadingDelete(true);
      await excluirEndereco(enderecoId);
    } finally {
      // setLoadingDelete(false);
    }
  }

  function editarCorHandler(cor: string) {
    setValue('cor', cor);
    editarEmpresa();
  }

  function editarCorSecundarioHandler(cor: string) {
    setValue('corSecundaria', cor);
    editarEmpresa();
  }

  async function salvarImagemHandler(file: File) {
    await salvarImagem(file);
  }

  async function criarChaveHandler(nome: string) {
    const result = await criarChave(nome);

    setValue('keyChave', result.hashSecretLabel);
    getValues().chaves?.push(result);
    setValue('nomeChave', '');
    reset(getValues());
    toggleCodigoModal();
  }
  function copiarCodigo() {
    navigator.clipboard.writeText(getValues().keyChave ?? '');
    enqueueSnackbar('O código da chave foi copiado com sucesso!', {
      variant: 'success'
    });
  }
  async function removerChaveHandler(chaveId: string) {
    await removerChave(chaveId);
    setValue(
      'chaves',
      getValues().chaves?.filter((x) => x.id !== chaveId)
    );
    reset(getValues());
  }
  const toggleCodigoModal = () => {
    setModalCodigoOpen((prevState) => !prevState);
  };
  async function adicionarFaseAtendimentoHandler(
    perfil: PerfilAcessoEnum,
    faseAtendimento: FasesAtendimentoEnum
  ) {
    await adicionarFaseAtendimento({
      empresaId: getValues().id ?? '',
      faseAtendimento: faseAtendimento,
      perfilAcesso: perfil
    });

    getValues().fasesAtendimento.push({
      faseAtendimento: faseAtendimento,
      grupoAcesso: perfil
    });
    reset(getValues());
  }

  async function removerFaseAtendimentoHandler(
    perfil: PerfilAcessoEnum,
    faseAtendimento: FasesAtendimentoEnum
  ) {
    await removerFaseAtendimento({
      empresaId: getValues().id ?? '',
      faseAtendimento: faseAtendimento,
      perfilAcesso: perfil
    });
    getValues().fasesAtendimento.splice(
      getValues().fasesAtendimento.findIndex(
        (x) => x.grupoAcesso === perfil && x.faseAtendimento === faseAtendimento
      ),
      1
    );
    reset(getValues());
  }

  async function validarSenhaHandler() {
    const { georeferenciamentoSeguranca } = getValues();
    validarLogin(
      georeferenciamentoSeguranca?.login ?? '',
      georeferenciamentoSeguranca?.senha ?? ''
    );
  }
  return (
    <>
      <Modal open={codigoOpen} onClose={toggleCodigoModal} title="" width="600px" overflow="hidden">
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <Icon path={mdiAlert} size={2} color={Colors.green} />
          <strong>
            Seu código foi gerado e só poderá copia-lo uma única vez, certifique-se de copiá-lo e
            salvá-lo em um local seguro
          </strong>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div
              style={{
                color: 'green',
                fontWeight: 'bold',
                border: 'solid 2px',
                borderColor: 'green'
              }}
            >
              <span>{getValues().keyChave}</span>
            </div>
            <BotaoDeAcaoToolbar
              onClick={copiarCodigo}
              texto="Copiar código"
              startIcon={mdiFile}
              corTexto="green"
              corIcone="green"
            />
          </div>
        </div>
      </Modal>
      <Grid container item rowSpacing={1} columnSpacing={1} pt={1}>
        <Grid item xs={8}>
          <Grid container item rowSpacing={1} columnSpacing={1} px={2.5} pt={1}>
            <Grid container item rowSpacing={3} columnSpacing={3} px={2.5} pt={1}>
              <Grid item xs={6}>
                <ProtectedContent mode="disable" access={MenuEmpresaClaims.ATUALIZAR}>
                  <FormNumberFormat
                    control={control}
                    format={'##.###.###/####-##'}
                    name="cnpj"
                    label="CNPJ"
                    size="small"
                    placeholder="CNPJ"
                    onBlur={() => {
                      validarCnpjOnBlur();
                      isDirty && editarEmpresa();
                    }}
                  />
                </ProtectedContent>
              </Grid>
              <Grid item xs={6}>
                <ProtectedContent mode="disable" access={MenuEmpresaClaims.ATUALIZAR}>
                  <FormTextField
                    control={control}
                    name="razaoSocial"
                    label="Nome"
                    fullWidth
                    onBlur={() => {
                      isDirty && editarEmpresa();
                    }}
                  />
                </ProtectedContent>
              </Grid>
            </Grid>
          </Grid>
          <Grid container item rowSpacing={1} columnSpacing={1} px={2.5} pt={2.5}>
            <Grid container item rowSpacing={3} columnSpacing={3} px={2.5} pt={1}>
              <Grid item xs={6}>
                <ProtectedContent mode="disable" access={MenuEmpresaClaims.ATUALIZAR}>
                  <FormNumberFormat
                    control={control}
                    format={'(##) ####-####'}
                    name="telefoneFixo"
                    label="Telefone"
                    placeholder="Telefone"
                    onBlur={() => {
                      isDirty && editarEmpresa();
                    }}
                  />
                </ProtectedContent>
                <ErrorMessage error={errors.telefoneFixo} />
              </Grid>
              <Grid item xs={6}>
                <ProtectedContent mode="disable" access={MenuEmpresaClaims.ATUALIZAR}>
                  <FormNumberFormat
                    control={control}
                    format={'(##) #####-####'}
                    name="telefoneCelular"
                    label="Celular"
                    size="small"
                    placeholder="Celular"
                    onBlur={() => {
                      isDirty && editarEmpresa();
                    }}
                  />
                </ProtectedContent>
                <ErrorMessage error={errors.telefoneCelular} />
              </Grid>
            </Grid>
          </Grid>
          <Grid container item rowSpacing={1} columnSpacing={1} px={2.5} pt={2.5}>
            <Grid container item rowSpacing={3} columnSpacing={3} px={2.5} pt={1}>
              <Grid item xs={6}>
                <ProtectedContent mode="disable" access={MenuEmpresaClaims.ATUALIZAR}>
                  <FormTextField
                    name="email"
                    control={control}
                    label="E-mail"
                    size="small"
                    fullWidth
                    onBlur={() => {
                      isDirty && editarEmpresa();
                    }}
                  />
                </ProtectedContent>
              </Grid>
              <Grid item xs={6}>
                <ProtectedContent mode="disable" access={MenuEmpresaClaims.ATUALIZAR}>
                  <FormTextField
                    name="site"
                    control={control}
                    label="Site"
                    fullWidth
                    onBlur={() => {
                      isDirty && editarEmpresa();
                    }}
                  />
                </ProtectedContent>
              </Grid>
            </Grid>
          </Grid>
          <Grid container item rowSpacing={1} columnSpacing={1} px={2.5} pt={1}>
            <Grid container item rowSpacing={1} columnSpacing={1} px={2.5} pt={1}>
              <Card style={{ width: '100%', margin: '1em 0 1em 0.5em' }}>
                <Grid container item rowSpacing={3} columnSpacing={3} px={1} pt={3}>
                  <Grid item xs={12}>
                    <S.ButtonGridContent>
                      <Modal
                        open={enderecoIsOpen}
                        onClose={toggleEnderecoModal}
                        title="Cadastro de endereço"
                        width="600px"
                        overflow="hidden"
                      >
                        <CriarEnderecoGeLocalizacao
                          salvarEndereco={salvarEndereco}
                          toggleEnderecoModal={toggleEnderecoModal}
                          endereco={endereco}
                        />
                      </Modal>
                      <strong>Endereço</strong>
                      <ProtectedContent access={MenuEmpresaClaims.ATUALIZAR}>
                        <IconButton onClick={criarEndereco} color />
                      </ProtectedContent>
                    </S.ButtonGridContent>
                    <S.GridContent>
                      <GridenderecoGeoLocalizacao
                        excluirEndereco={excluirEnderecoHandler}
                        data={enderecos}
                        hasAccess={hasAccess(MenuEmpresaClaims.ATUALIZAR)}
                        ref={refGridEndereco}
                      />
                    </S.GridContent>
                  </Grid>
                </Grid>
              </Card>
              <Card style={{ width: '100%', margin: '1em 0 1em 0.5em' }}>
                <CardContent>
                  <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                      Integração com rastreamento de veículo
                    </Typography>
                    <BotaoDeAcaoToolbar
                      onClick={() => {
                        validarSenhaHandler();
                      }}
                      texto="Validar login"
                      startIcon={mdiCheck}
                      corIcone={Colors.green}
                      corTexto={Colors.green}
                    />
                  </div>
                  <Grid container item rowSpacing={3} columnSpacing={3} px={1} pt={3}>
                    <Grid item xs={6}>
                      <ProtectedContent mode="disable" access={MenuEmpresaClaims.ATUALIZAR}>
                        <FormTextField
                          control={control}
                          name="georeferenciamentoSeguranca.login"
                          label="Usuário"
                          fullWidth
                          onBlur={() => {
                            isDirty && editarEmpresa();
                          }}
                        />
                      </ProtectedContent>
                    </Grid>
                    <Grid item xs={6}>
                      <ProtectedContent mode="disable" access={MenuEmpresaClaims.ATUALIZAR}>
                        <FormTextField
                          name="georeferenciamentoSeguranca.senha"
                          control={control}
                          type="password"
                          label="Senha"
                          onBlur={() => {
                            isDirty && editarEmpresa();
                          }}
                        />
                      </ProtectedContent>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
              <Card style={{ width: '100%', margin: '1em 0 1em 0.5em' }}>
                <CardContent>
                  <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                      Chaves
                    </Typography>
                  </div>
                  <Grid container item rowSpacing={3} columnSpacing={3} px={1} pt={3}>
                    <Grid item xs={6}>
                      <ProtectedContent mode="disable" access={MenuEmpresaClaims.ATUALIZAR}>
                        <FormTextField
                          control={control}
                          name="nomeChave"
                          label="Nome"
                          fullWidth
                          onBlur={(event) => {
                            criarChaveHandler(event.target.value);
                          }}
                        />
                      </ProtectedContent>
                    </Grid>
                  </Grid>
                  <div style={{ marginLeft: '0.6rem', marginTop: '0.7rem' }}>
                    <ListaChaves
                      lista={getValues().chaves ?? []}
                      onRemove={(id: string) => {
                        removerChaveHandler(id);
                      }}
                    ></ListaChaves>
                  </div>
                </CardContent>
              </Card>
              <Card
                style={{
                  width: '100%',
                  margin: '1em 0 1em 0.5em',
                  display: 'flex',
                  gap: 10,
                  padding: '1rem'
                }}
              >
                {ListaFasesAtendimentoEnumLabel.filter(
                  (x) => x.id !== FasesAtendimentoEnum.ADMIN && x.id !== FasesAtendimentoEnum.Nenhum
                ).map((x) => (
                  <Grid item xs={3} key={x.id + Math.random()}>
                    <Typography sx={{ fontSize: 14 }} color="primary" gutterBottom>
                      {FasesAtendimentoEnumLabel.get(x.id)}
                    </Typography>
                    <VinculoFasesAtendimento
                      key={x.id}
                      gruposAcesso={gruposAcesso}
                      faseAtendimentoId={x.id}
                      removerFaseAtendimento={removerFaseAtendimentoHandler}
                      adicionarFaseAtendimento={adicionarFaseAtendimentoHandler}
                    />
                  </Grid>
                ))}
              </Card>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={4}>
          <S.CustomGridImageContent>
            <Grid item xs={12}>
              <ProtectedContent access={MenuEmpresaClaims.ATUALIZAR}>
                <strong>Cor Primária</strong>
                <S.ColorPickerContainer color={color.hex}>
                  <Controller
                    name="cor"
                    control={control}
                    render={() => (
                      <ColorPicker
                        width={320}
                        height={180}
                        color={color}
                        onChange={setColor}
                        onChangeComplete={(e) => {
                          editarCorHandler(e.hex);
                        }}
                        hideHSV
                        hideRGB
                      />
                    )}
                  />
                </S.ColorPickerContainer>
              </ProtectedContent>
            </Grid>
            <Grid item xs={12}>
              <ProtectedContent access={MenuEmpresaClaims.ATUALIZAR}>
                <strong>Cor Secundária</strong>
                <S.ColorPickerContainer color={secundaryColor.hex}>
                  <Controller
                    name="corSecundaria"
                    control={control}
                    render={() => (
                      <ColorPicker
                        width={320}
                        height={180}
                        color={secundaryColor}
                        onChange={setSecondaryColor}
                        onChangeComplete={(e) => {
                          editarCorSecundarioHandler(e.hex);
                        }}
                        hideHSV
                        hideRGB
                      />
                    )}
                  />
                </S.ColorPickerContainer>
              </ProtectedContent>
            </Grid>
            <ProtectedContent access={MenuEmpresaClaims.ATUALIZAR}>
              <div style={{ width: '200px' }}>
                <ImagePicker
                  labelText="Alterar Imagem"
                  labelColor={Colors.primaryContrast}
                  image={imagem}
                  salvarImagem={salvarImagemHandler}
                />
              </div>
            </ProtectedContent>
          </S.CustomGridImageContent>
        </Grid>
      </Grid>
    </>
  );
};

export default FormEditarEmpresa;
