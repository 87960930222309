import { mdiDelete, mdiDeleteOutline } from '@mdi/js';
import {
  OptBackdrop,
  OptConfirmationDialog,
  OptGrid,
  OptGridColumn,
  OptGridOptions,
  OptGridRef,
  OptSideLayoutContent
} from '@optsol/react';
import { forwardRef, useState } from 'react';

import { Colors } from '../../../../core/colors';
import { EnderecoModel } from '../../../../models/Endereco/Endereco.model';
import { getHeaderTheme } from '../../../../shared/utils/functions';
import { useAuthenticationContext } from '../../../contexts/authentication/authenticationContext';

interface GridProps {
  data: EnderecoModel[];
  hasAccess?: boolean;
  excluirEndereco: (enderecoId: string) => Promise<void>;
}

const options: OptGridOptions = {
  pageSize: 10
};
const GridEnderecos = forwardRef<OptGridRef | undefined, GridProps>(
  ({ data, hasAccess, excluirEndereco }, ref) => {
    const {
      state: { tema }
    } = useAuthenticationContext();
    const columns: OptGridColumn<EnderecoModel>[] = [
      {
        title: 'id',
        field: 'enderecoId',
        hidden: true
      },
      {
        title: 'Nome',
        field: 'nome',
        align: 'left',
        render: (props: EnderecoModel) => {
          return <>{props.nome ? props.nome : '-'}</>;
        }
      },
      {
        title: 'Descrição',
        field: 'enderecoGeolocalizacao',
        align: 'left',
        render: (props: EnderecoModel) => {
          return (
            <>
              {props.enderecoGeolocalizacao.descricaoLocal
                ? props.enderecoGeolocalizacao.descricaoLocal
                : '-'}
            </>
          );
        }
      },
      {
        title: 'Complemento',
        field: 'complemento',
        align: 'left',
        hidden: false,
        render: (props: EnderecoModel) => {
          return <>{props.complemento ? props.complemento : '-'}</>;
        }
      }
    ];
    const [enderecoId, setEnderecoId] = useState('');
    const [confirmandoExclusao, setConfirmandoExclusao] = useState<boolean>(false);
    const [loadingDelete, setLoadingDelete] = useState(false);
    function cancelarExclusao() {
      setConfirmandoExclusao(false);
    }

    function excluirEnderecoConfirmHandler(enderecoId: string) {
      setEnderecoId(enderecoId);
      setConfirmandoExclusao(true);
    }

    async function excluirEnderecoHandler() {
      try {
        setLoadingDelete(true);
        setConfirmandoExclusao(false);
        await excluirEndereco(enderecoId);
      } finally {
        setLoadingDelete(false);
      }
    }
    return (
      <OptSideLayoutContent>
        <OptConfirmationDialog
          open={confirmandoExclusao}
          title="Excluir endereço"
          icon={{ path: mdiDeleteOutline, color: Colors.red }}
          onCancel={cancelarExclusao}
          onClose={cancelarExclusao}
          onConfirm={() => {
            excluirEnderecoHandler();
          }}
        >
          Deseja confirmar a exclusão do endereço?
        </OptConfirmationDialog>
        <OptGrid
          ref={ref as any}
          data={data}
          headerStyle={getHeaderTheme(tema.light?.primary ? tema.light.primary : Colors.gray10)}
          options={options}
          columns={columns}
          actionsPosition={'end'}
          actions={[
            (rowData) => ({
              icon: { path: mdiDelete },
              tooltip: 'Excluir',
              onClick: () => excluirEnderecoConfirmHandler(rowData.enderecoId ?? ''),
              disabled: !hasAccess
            })
          ]}
        />
        <OptBackdrop open={loadingDelete} />
      </OptSideLayoutContent>
    );
  }
);

GridEnderecos.displayName = 'GridEnderecos';
export default GridEnderecos;
