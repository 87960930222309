import { yupResolver } from '@hookform/resolvers/yup';
import { mdiChevronDown, mdiFile } from '@mdi/js';
import Icon from '@mdi/react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Tooltip,
  Typography
} from '@mui/material';
import { FormProvider, useForm } from 'react-hook-form';

import { Colors } from '../../../../core/colors';
import {
  DADOS_RELATORIO_DEFAULT,
  RelatorioFormModel,
  dadosRelatorioFormModelSchema
} from '../../../../models/Relatorio/Relatorio.model';
import { useAtendimentoService } from '../../../../service/atendimento.service';

import { RelatorioForm } from './RelatorioForm';
import { ToolbarRelatorio } from './ToolbarRelatorios';

export const Relatorio = () => {
  const form = useForm<RelatorioFormModel>({
    mode: 'onBlur',
    defaultValues: DADOS_RELATORIO_DEFAULT,
    resolver: yupResolver(dadosRelatorioFormModelSchema)
  });

  const { gerarRelatorioAtendimentoCliente } = useAtendimentoService();

  async function obterRelatorioCliente() {
    await gerarRelatorioAtendimentoCliente();
  }

  return (
    <Box width={1}>
      <ToolbarRelatorio />
      <Box padding={5} display="flex" flexDirection="column" gap={2}>
        <Typography fontSize="20px" fontWeight={700}>
          Gerar relatórios
        </Typography>
        <Accordion>
          <AccordionSummary
            expandIcon={<Icon path={mdiChevronDown} size={1} />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography>Relatório de atendimentos</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <FormProvider {...form}>
              <RelatorioForm />
            </FormProvider>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<Icon path={mdiChevronDown} size={1} />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography>Relatório uso de atendimento por cliente</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <FormProvider {...form}>
              <Tooltip title={'Gerar relatório'} placement="top">
                <Button
                  variant="outlined"
                  onClick={obterRelatorioCliente}
                  disabled={false}
                  sx={{ marginTop: '1em' }}
                >
                  <Icon path={mdiFile} size={1} color={Colors.blue} />
                </Button>
              </Tooltip>
            </FormProvider>
          </AccordionDetails>
        </Accordion>
      </Box>
    </Box>
  );
};
