import * as yup from 'yup';

import { ChaveModel } from '../../app/features/empresa/ListaChaves/ChaveModel/ChaveModel';
import { validarCNPJ, validarCPF } from '../../shared/utils/functions';
import { EnderecoModel } from '../Endereco/Endereco.model';
import {
  DADOS_ENDERECO_GEOLOCALIZACAO_DEFAULT,
  EnderecoGeoLocalizacao
} from '../Endereco/EnderecoGeoLocalizacao.mode';
import { PerfilAcessoEnum } from '../enums/PerfilAcesso';
import { TipoLocalEnum } from '../enums/TipoLocal';

import { FasesAtendimentoEnum } from './../enums/FasesAtendimento';

export interface FasesAtendimentoResponse {
  faseAtendimento: FasesAtendimentoEnum;
  grupoAcesso: PerfilAcessoEnum;
}

export interface EmpresaModel {
  id?: string;
  razaoSocial: string;
  cnpj: string;
  telefoneFixo?: string;
  telefoneCelular?: string;
  email?: string;
  site?: string;
  cor?: string;
  corSecundaria?: string;
  georeferenciamentoSeguranca?: GeoreferenciamentoSegurancaModel;
  localizacoes?: EnderecoModel[];
  nomeChave?: string;
  keyChave?: string;
  chaves?: ChaveModel[];
  fasesAtendimento: FasesAtendimentoResponse[];
}

export interface GeoreferenciamentoSegurancaModel {
  login?: string;
  senha?: string;
}
export const GEOREFERENCIAMENTO_DEFAULT: GeoreferenciamentoSegurancaModel = {
  login: '',
  senha: ''
};

export interface ImagemModel {
  nome?: string;
  nomeArquivoStorage?: string;
}
export interface LocalizacaoModal {
  enderecoEmpresaId?: string;
  tipoLocal: TipoLocalEnum | null;
  complemento: string;
  nome: string;
  enderecoGeolocalizacao: EnderecoGeoLocalizacao;
}
export const IMAGEM_DEFAULT: ImagemModel = {
  nome: '',
  nomeArquivoStorage: ''
};
export const LOCALIZACAO_DEFAULT: LocalizacaoModal = {
  tipoLocal: null,
  complemento: '',
  nome: '',
  enderecoGeolocalizacao: DADOS_ENDERECO_GEOLOCALIZACAO_DEFAULT
};

export const DADOS_EMPRESA_DEFAULT: EmpresaModel = {
  id: '',
  razaoSocial: '',
  cnpj: '',
  telefoneFixo: '',
  telefoneCelular: '',
  email: '',
  site: '',
  cor: '',
  corSecundaria: '',
  georeferenciamentoSeguranca: GEOREFERENCIAMENTO_DEFAULT,
  // imagem: IMAGEM_DEFAULT,
  localizacoes: [],
  nomeChave: '',
  keyChave: '',
  chaves: [],
  fasesAtendimento: []
};

const MSG_REQUIRED = 'Campo obrigatório';

export const dadosGeoreferenciamentoModelSchema: yup.SchemaOf<GeoreferenciamentoSegurancaModel> =
  yup.object().shape({
    login: yup.string().notRequired(),
    senha: yup.string().notRequired()
  });

export const dadosImagemModelSchema: yup.SchemaOf<ImagemModel> = yup.object().shape({
  nome: yup.string().notRequired(),
  nomeArquivoStorage: yup.string().notRequired()
});

export const dadosfaseAtendimento: yup.SchemaOf<FasesAtendimentoResponse> = yup.object().shape({
  faseAtendimento: yup.mixed().notRequired(),
  grupoAcesso: yup.mixed().notRequired()
});

export const dadosEmpresaModelSchema: yup.SchemaOf<EmpresaModel> = yup.object().shape({
  id: yup.string().notRequired(),
  razaoSocial: yup.string().required(MSG_REQUIRED),
  cnpj: yup
    .string()
    .required(MSG_REQUIRED)
    .test('Teste documento', 'Documento inválido', (value) => {
      if (!value) return false;
      return validarCNPJ(value);
    }),
  telefoneFixo: yup.string().notRequired(),
  telefoneCelular: yup.string().notRequired(),
  email: yup.string().notRequired(),
  site: yup.string().notRequired(),
  cor: yup.string().notRequired(),
  corSecundaria: yup.string().notRequired(),
  georeferenciamentoSeguranca: dadosGeoreferenciamentoModelSchema.notRequired(),
  imagem: dadosImagemModelSchema.notRequired(),
  localizacoes: yup.array().notRequired(),
  nomeChave: yup.string().notRequired(),
  keyChave: yup.string().notRequired(),
  chaves: yup.array().notRequired(),
  fasesAtendimento: yup.array().of(dadosfaseAtendimento).notRequired()
});

export interface CriarEmpresaModel {
  nomeEmpresa: string;
  cnpj: string;
  nome: string;
  cpf: string;
  email: string;
}

export const DADOS_CRIAR_EMPRESA_DEFAULT: CriarEmpresaModel = {
  nomeEmpresa: '',
  cnpj: '',
  nome: '',
  cpf: '',
  email: ''
};
const regexEmail = new RegExp('[a-z0-9]+@[a-z]+.[a-z]{2,3}');
export const dadosCriarEmpresaFormModelSchema: yup.SchemaOf<CriarEmpresaModel> = yup
  .object()
  .shape({
    nomeEmpresa: yup.string().required(MSG_REQUIRED),
    nome: yup.string().required(MSG_REQUIRED),
    email: yup
      .string()
      .required(MSG_REQUIRED)
      .test('Teste email', 'E-mail inválido', (value) => {
        if (!value) return true;
        return regexEmail.test(value.trim());
      }),
    cnpj: yup
      .string()
      .required(MSG_REQUIRED)
      .test('Teste cnpj', 'Documento inválido', (value) => {
        if (!value) return false;
        return validarCNPJ(value);
      }),
    cpf: yup
      .string()
      .required(MSG_REQUIRED)
      .test('Teste cpf', 'Documento inválido', (value) => {
        if (!value) return false;
        return validarCPF(value);
      })
  });
