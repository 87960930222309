export const BASE_CONFIG = {
  App: {
    Version: process.env.REACT_APP_VERSION,
    Name: process.env.REACT_APP_NAME
  },
  Google: {
    BaseURL: 'https://maps.googleapis.com/maps/api/js',
    ApiKey: process.env.REACT_APP_GOOGLE_API_KEY ?? ''
  }
} as const;
