import { mdiAmbulance, mdiClipboardTextClockOutline, mdiHistory } from '@mdi/js';
import Icon from '@mdi/react';
import { Box, IconButton, Tooltip, Typography } from '@mui/material';
import {
  OptGrid,
  OptGridColumn,
  OptGridOptions,
  OptGridRequest,
  OptLoading,
  OptSideLayoutContent,
  OptTimeline,
  OptTimelineAction
} from '@optsol/react';
import { format } from 'date-fns';
import { useCallback, useEffect, useState } from 'react';

import { Colors } from '../../../../core/colors';
import { AtendimentoHistoricoResponseModel } from '../../../../models/dtos/Atendimento/atendimentoHistoricoResponse.model';
import { useAtendimentoService } from '../../../../service/atendimento.service';
import {
  PaginatedSearcHistoricoAtendimentohRequest,
  SearchRequest
} from '../../../../shared/utils/searchRequest';
import { ChipStatusUrgencia } from '../../../components/ClassificacaoUrgenciaText/ChipStatusUrgencia';
import { Modal } from '../../../components/Modal/Modal';
import { StatusAtendimentoChip } from '../../../components/StatusAtendimentoText/StatusAtendimentoChip';

interface Props {
  cpf: string;
  atendimentoId: string;
  informacaoImportante: string;
}

export const HistoricoAtendimentoButton = ({ cpf, atendimentoId, informacaoImportante }: Props) => {
  const { buscarHistoricoAtendimentosPaciente, buscarHistoricoProntuarios } =
    useAtendimentoService();
  const [confirmacaoCancelamento, setConfirmacaoCancelamento] = useState<boolean>(false);
  const [historicoProntuarioIsOpen, setHistoricoProntuarioIsOpen] = useState<boolean>(false);
  const [modalPacienteId, setModalPacienteId] = useState<string>('');
  const [timeLineData, setTimeLineData] = useState<OptTimelineAction[]>();

  const bottomInfoElement = (
    <>
      {informacaoImportante && (
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="flex-start"
          alignItems="flex-start"
          width={'100%'}
        >
          <Typography component="div" color={Colors.darkOrange}>
            Informações importantes do paciente:
          </Typography>
          {informacaoImportante
            .split(/\r?\n/)
            .filter((i) => i)
            .map((item) => (
              <>
                <span style={{ fontWeight: 500, fontSize: 15, padding: '2px 0' }}>{item}</span>
              </>
            ))}
        </Box>
      )}
    </>
  );

  const options: OptGridOptions = {
    search: true,
    pageSize: 10,
    bottomElement: bottomInfoElement
  };
  const columns: OptGridColumn<AtendimentoHistoricoResponseModel>[] = [
    {
      title: 'Id',
      field: 'atendimentoId',
      hidden: true
    },
    {
      title: 'PacienteId',
      field: 'pacienteId',
      hidden: true
    },
    {
      title: 'Número chamado',
      field: 'numeroChamado',
      hidden: false
    },
    {
      title: 'Urgência',
      field: 'urgencia',
      hidden: false,
      render: (props: AtendimentoHistoricoResponseModel) => {
        return props.urgencia === null || props.urgencia === undefined ? (
          <span> - </span>
        ) : (
          <ChipStatusUrgencia status={props.urgencia} />
        );
      }
    },
    {
      title: 'Status',
      field: 'statusPaciente',
      render: (props: AtendimentoHistoricoResponseModel) => {
        return !props.statusPaciente ? (
          <span> - </span>
        ) : (
          <StatusAtendimentoChip status={props.statusPaciente} />
        );
      }
    },
    {
      title: 'Data Atendimento',
      field: 'dataAtendimento',
      hidden: false,
      render: (row) => {
        return row.dataAtendimento ? (
          <Box minWidth={'9.5rem'}>
            <span>{format(new Date(row.dataAtendimento), 'dd/MM/yyyy')}</span>
          </Box>
        ) : (
          <span>-</span>
        );
      }
    }
  ];

  const carregar = useCallback(
    async (
      query: OptGridRequest,
      atendimentoSearchRequest: PaginatedSearcHistoricoAtendimentohRequest = {
        cpf: cpf,
        atendimentoId: atendimentoId
      }
    ) => {
      const request: SearchRequest<PaginatedSearcHistoricoAtendimentohRequest> = {
        page: query.page,
        pageSize: query.pageSize,
        search: atendimentoSearchRequest
      };
      return buscarHistoricoAtendimentosPaciente(request);
    },
    []
  );

  const toggleConfirmacaoCancelamento = () => {
    setConfirmacaoCancelamento((prevState) => !prevState);
  };

  function abrirAtendimento(atendimentoId: string) {
    window.open(`${atendimentoId}`, '_blank', 'noreferrer');
  }
  const toggleHistoricoProntuarioModal = (pacienteId: string) => {
    setModalPacienteId(pacienteId);
    setHistoricoProntuarioIsOpen((prevState) => !prevState);
  };

  useEffect(() => {
    if (historicoProntuarioIsOpen && !timeLineData) {
      const obter = async () => {
        const historyData = await buscarHistoricoProntuarios(modalPacienteId);
        setTimeLineData(historyData.data);
      };
      obter();
    } else {
      setTimeout(() => {
        setTimeLineData(undefined);
      }, 300);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [historicoProntuarioIsOpen]);

  return (
    <>
      <Tooltip title={'Histórico atendimento'} placement="top">
        <IconButton
          color="info"
          onClick={(e) => {
            e.stopPropagation();
            toggleConfirmacaoCancelamento();
          }}
        >
          <Icon path={mdiClipboardTextClockOutline} size={1} color={Colors.black} />
        </IconButton>
      </Tooltip>
      <Modal
        open={historicoProntuarioIsOpen}
        onClose={() => {
          toggleHistoricoProntuarioModal('');
        }}
        title="Histórico de prontuários"
        width="750px"
      >
        <div style={{ marginTop: '1em' }}>
          {timeLineData && (
            <OptTimeline
              data={timeLineData ?? []}
              valuesTableOptions={{
                nameHeader: 'Name',
                valueHeader: 'Value'
              }}
            />
          )}

          {!timeLineData && (
            <div
              style={{
                height: '100px',
                overflow: 'none'
              }}
            >
              <OptLoading size={50} />
            </div>
          )}
        </div>
      </Modal>
      <Modal
        open={confirmacaoCancelamento}
        onClose={toggleConfirmacaoCancelamento}
        title="Histórico atendimento"
        width="750px"
      >
        <OptSideLayoutContent>
          <OptGrid
            data={carregar}
            options={options}
            columns={columns}
            actionsPosition={'end'}
            actions={[
              (rowData) => ({
                icon: { path: mdiAmbulance },
                tooltip: 'Atendimento',
                onClick: () => abrirAtendimento(rowData.atendimentoId),
                disabled: false
              }),
              (rowData) => ({
                icon: { path: mdiHistory },
                tooltip: 'Histórico de prontuários',
                onClick: () => toggleHistoricoProntuarioModal(rowData.pacienteId ?? ''),
                disabled: false
              })
            ]}
          />
        </OptSideLayoutContent>
        {/* {informacaoImportante && (
          <Card sx={{ minWidth: 275 }}>
            <CardContent>
              <Typography variant="h5" component="div" color={`orange`}>
                Informação importante
              </Typography>
              <Typography variant="body2" fontWeight={`500`}>
                {informacaoImportante}
              </Typography>
            </CardContent>
          </Card>
        )} */}
      </Modal>
    </>
  );
};
