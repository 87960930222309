import {
  OptGrid,
  OptGridColumn,
  OptGridOptions,
  OptGridRef,
  OptSideLayoutContent
} from '@optsol/react';
import { forwardRef } from 'react';
import NumberFormat from 'react-number-format';

import { Colors } from '../../../../core/colors';
import { ContatoModel } from '../../../../models/Contato/Contato.model';
import { getHeaderTheme } from '../../../../shared/utils/functions';
import { useAuthenticationContext } from '../../../contexts/authentication/authenticationContext';

interface GridProps {
  data: ContatoModel[];
  editarContato: (endereco: ContatoModel) => void;
}

const options: OptGridOptions = {
  search: true,
  pageSize: 10
};
const GridContatos = forwardRef<OptGridRef | undefined, GridProps>(
  ({ data, editarContato }, ref) => {
    const {
      state: { tema }
    } = useAuthenticationContext();

    const columns: OptGridColumn<ContatoModel>[] = [
      {
        title: 'id',
        field: 'contatoId',
        hidden: true
      },
      {
        title: 'Nome',
        field: 'nomeContato',
        align: 'left',
        render: (row) => {
          return <>{row.nomeContato ? row.nomeContato : '-'}</>;
        }
      },
      {
        title: 'CPF',
        field: 'cpf',
        align: 'left',
        render: (row) => {
          return <>{row.cpf ? row.cpf : '-'}</>;
        }
      },
      {
        title: 'E-mail',
        field: 'email',
        align: 'left',
        render: (row) => {
          return <>{row.email ? row.email : '-'}</>;
        }
      },
      {
        title: 'Telefone',
        field: 'telefone',
        align: 'left',
        render: (row) => {
          return row?.telefone ? (
            <NumberFormat
              format={
                row?.telefone?.replace(/[^\w\s]/gi, '').trim().length >= 12
                  ? '(##) #####-####'
                  : '(##) ####-#####'
              }
              value={row?.telefone}
              displayType="text"
            />
          ) : (
            <span> - </span>
          );
        }
      },
      {
        title: 'Telefone Fixo',
        field: 'telefoneFixo',
        align: 'left',
        hidden: true
      },
      {
        title: 'Telefone Celular',
        field: 'telefoneCelular',
        align: 'left',
        hidden: true
      }
    ];
    return (
      <OptSideLayoutContent>
        <OptGrid
          ref={ref as any}
          data={data}
          headerStyle={getHeaderTheme(tema.light?.primary ? tema.light?.primary : Colors.gray10)}
          options={options}
          columns={columns}
          onRowClick={(rowData) => {
            editarContato(rowData);
          }}
        />
      </OptSideLayoutContent>
    );
  }
);

GridContatos.displayName = 'GridContatos';
export default GridContatos;
