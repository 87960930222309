import { Box } from '@mui/material';
import { OptGridRef, OptGridRequest, OptSideLayoutContent } from '@optsol/react';
import { useCallback, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { RoutesObj } from '../../../routes/index.routes';
import { useVeiculoService } from '../../../service/veiculo.service';
import { obterFiltroDaRota, updateFiltroUrl } from '../../../shared/utils/functions';
import { PaginatedSearchRequest, SearchRequest } from '../../../shared/utils/searchRequest';

import GridVeiculos from './GridVeiculos';
import { ToolbarVeiculos } from './ToolbarVeiculos';

const Veiculos = () => {
  const navegate = useNavigate();
  const ref = useRef<OptGridRef>();
  const [veiculoSearch, setVeiculoSearch] = useState(obterFiltroDaRota('termo') ?? '');

  const { buscarGridVeiculoPaginated } = useVeiculoService();

  function ProcurarVeiculo(data?: string) {
    updateFiltroUrl('termo', data ?? '');
    setVeiculoSearch(data ?? '');
  }

  const carregar = useCallback(
    async (
      query: OptGridRequest,
      veiculoSearchRequest: PaginatedSearchRequest = {
        termoBuscado: veiculoSearch,
        apenasAtivo: false
      }
    ) => {
      const request: SearchRequest<PaginatedSearchRequest> = {
        page: query.page,
        pageSize: query.pageSize,
        search: veiculoSearchRequest
      };
      updateFiltroUrl('page', query.page);
      updateFiltroUrl('pageSize', query.pageSize);
      return buscarGridVeiculoPaginated(request);
    },
    [buscarGridVeiculoPaginated, veiculoSearch]
  );

  async function editarVeiculo(veiculoId: string) {
    navegate(RoutesObj.Veiculo.Detalhes(veiculoId), {
      state: { prevUrl: window.location.href }
    });
  }

  return (
    <OptSideLayoutContent>
      <ToolbarVeiculos filtrarGridBySearch={ProcurarVeiculo} />
      <Box px={1} pt={0.5}>
        <GridVeiculos ref={ref} editarVeiculo={editarVeiculo} carregar={carregar}></GridVeiculos>
      </Box>
    </OptSideLayoutContent>
  );
};

export default Veiculos;
