import { Route, Routes as ReactRouterRoutes } from 'react-router-dom';

import { useAuthenticationContext } from '../app/contexts/authentication/authenticationContext';
import EditarVeiculo from '../app/features/veiculo/EditarVeiculo';
import Home from '../app/pages/home/Home';
import Veiculos from '../app/pages/veiculos/Veiculos';
import { MenuVeiculoClaims } from '../models/enums/AcessoClaims';

export const VeiculoRoutes = () => {
  const { hasAccess } = useAuthenticationContext();
  const enableRoute = hasAccess(MenuVeiculoClaims.VISUALIZAR);
  return (
    <ReactRouterRoutes>
      <Route path="/" element={enableRoute ? <Veiculos /> : <Home />} />
      <Route path="/veiculo/:id" element={enableRoute ? <EditarVeiculo /> : <Home />} />
    </ReactRouterRoutes>
  );
};
