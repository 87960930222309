import { mdiClipboardTextClockOutline } from '@mdi/js';
import { Box, Chip } from '@mui/material';
import { OptActionToolbar, OptLoading, OptTimeline, OptTimelineAction } from '@optsol/react';
import { useEffect, useState } from 'react';

import { Colors } from '../../../../core/colors';
import { MenuAtendimentoClaims } from '../../../../models/enums/AcessoClaims';
import { RoutesObj } from '../../../../routes/index.routes';
import { useHistoricoService } from '../../../../service/historico.service';
import { BotaoDeAcaoToolbar } from '../../../components/Button/BotaoDeAcaoToolbar';
import { Modal } from '../../../components/Modal/Modal';
import { ProtectedContent } from '../../../components/ProtectedContent/ProtectedContent';
import { ToolbarContainer } from '../../../components/ToolbarContainer';
import { useAuthenticationContext } from '../../../contexts/authentication/authenticationContext';

interface Props {
  title?: string;
  atendimentoId?: string;
  numeroChamado: string;
}

export const ToolbarTarmAtendimento = ({ title, atendimentoId, numeroChamado }: Props) => {
  const [modalHistorico, setModalHistorico] = useState<boolean>(false);
  const [timeLineData, setTimeLineData] = useState<OptTimelineAction[]>();

  const { obterHistoryEntidade } = useHistoricoService();
  const { state } = useAuthenticationContext();
  const toggleModal = async () => {
    setModalHistorico((prevState) => !prevState);
  };
  useEffect(() => {
    if (modalHistorico && !timeLineData) {
      const obter = async () => {
        try {
          const historyData = await obterHistoryEntidade(
            'Atendimento',
            state.userInfo.data?.tenantId ?? '',
            atendimentoId as string
          );

          const data = historyData.versions.sort((a, b) => (a.order > b.order ? -1 : 1));
          data.map((history: { dateTimeAction: string | number | Date }) => {
            const newDate = new Date(history.dateTimeAction);
            const dataFormatada = new Intl.DateTimeFormat('pt-BR', {
              year: 'numeric',
              month: '2-digit',
              day: '2-digit',
              hour: '2-digit',
              hourCycle: 'h23',
              minute: '2-digit'
            }).format(newDate);
            return (history.dateTimeAction = dataFormatada);
          });
          setTimeLineData(data);
        } catch (error) {
          toggleModal();
        }
      };
      obter();
    } else {
      setTimeout(() => {
        setTimeLineData(undefined);
      }, 300);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalHistorico]);
  return (
    <>
      <ToolbarContainer>
        <OptActionToolbar
          goBackRoute={window.history.state.usr?.prevUrl ?? RoutesObj.Atendimento.Atendimentos}
          title={title}
          color={state.tema.light?.primary}
          clearMargin
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              width: '100%',
              placeContent: 'space-between'
            }}
          >
            {numeroChamado && (
              <Chip
                label={`Nº chamado: ${numeroChamado}`}
                style={{
                  background: Colors.blue,
                  color: Colors.white,
                  borderRadius: '4px',
                  height: 25,
                  marginLeft: '0.5rem'
                }}
              />
            )}

            {atendimentoId && (
              <ProtectedContent access={MenuAtendimentoClaims.VISUALIZAR_HISTORICO_ATENDIMENTO}>
                <BotaoDeAcaoToolbar
                  onClick={toggleModal}
                  texto="Histórico"
                  startIcon={mdiClipboardTextClockOutline}
                />
              </ProtectedContent>
            )}
          </Box>
        </OptActionToolbar>
      </ToolbarContainer>
      <Modal open={modalHistorico} onClose={toggleModal} title="Histórico" width="800px">
        <div style={{ marginTop: '1em' }}>
          {timeLineData && (
            <OptTimeline
              data={timeLineData ?? []}
              valuesTableOptions={{
                nameHeader: 'Name',
                valueHeader: 'Value'
              }}
            />
          )}
          {!timeLineData && (
            <div style={{ height: '100px', overflow: 'none' }}>
              <OptLoading size={50} />
            </div>
          )}
        </div>
      </Modal>
    </>
  );
};
