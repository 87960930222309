import { Box, Tooltip } from '@mui/material';
import { OptChip } from '@optsol/react';

import { Colors } from '../../../core/colors';
import { StatusAtendimentoEnum } from '../../../models/enums/StatusAtendimento';

interface StatusProps {
  status?: number | null;
  statusPerfilDescricao?: string;
  statusPerfilSigla?: string;
  margin?: string;
  borderRadius?: string;
}

export function StatusAtendimentoChip({
  status,
  statusPerfilDescricao,
  statusPerfilSigla,
  margin,
  borderRadius
}: StatusProps) {
  function retornaTextoStatusAtendimento() {
    switch (status) {
      case StatusAtendimentoEnum.EM_ANDAMENTO:
        return `Em andamento ${statusPerfilDescricao ? ' - ' + statusPerfilDescricao : ''}`;
      case StatusAtendimentoEnum.ENCERRADO:
        return `Encerrado ${statusPerfilDescricao ? ' - ' + statusPerfilDescricao : ''}`;
      case StatusAtendimentoEnum.PENDENTE:
        return `Pendente ${statusPerfilDescricao ? ' - ' + statusPerfilDescricao : ''}`;
      case StatusAtendimentoEnum.CANCELADO:
        return `Cancelado ${statusPerfilDescricao ? ' - ' + statusPerfilDescricao : ''}`;
      case StatusAtendimentoEnum.CONCLUIDO:
        return `Concluido ${statusPerfilDescricao ? ' - ' + statusPerfilDescricao : ''}`;
      case StatusAtendimentoEnum.AGENDADO:
        return `Agendado ${statusPerfilDescricao ? ' - ' + statusPerfilDescricao : ''}`;
    }
  }

  function retornaColorFundo() {
    switch (status) {
      case StatusAtendimentoEnum.EM_ANDAMENTO:
        return Colors.blue;
      case StatusAtendimentoEnum.ENCERRADO:
        return Colors.red;
      case StatusAtendimentoEnum.PENDENTE:
        return Colors.yellow1;
      case StatusAtendimentoEnum.CANCELADO:
        return Colors.gray3;
      case StatusAtendimentoEnum.CONCLUIDO:
        return Colors.green;
      case StatusAtendimentoEnum.AGENDADO:
        return Colors.orange1;
    }
  }

  return (
    <Box display="flex" flex={1}>
      {status && retornaTextoStatusAtendimento() && (
        <Tooltip title={statusPerfilSigla} placement="top">
          <OptChip
            label={retornaTextoStatusAtendimento()}
            style={{
              background: retornaColorFundo(),
              color: Colors.white,
              borderRadius: borderRadius ? borderRadius : '4px',
              height: 25,
              margin: margin ?? ''
            }}
          />
        </Tooltip>
      )}
    </Box>
  );
}
