import styled from 'styled-components';

export const ButtonGridContent = styled.div`
  display: flex;
  justify-content: space-between;
  margin-right: 1em;
  margin-left: 1em;
`;

export const DeleteButtonContent = styled.div`
  display: flex;
  margin-top: 0.5em;
`;

export const ChipInfoPlanoContent = styled.div`
  display: flex;
  flex-direction: column;
`;
