import { mdiCarEmergency, mdiClose } from '@mdi/js';
import Icon from '@mdi/react';
import { IconButton } from '@mui/material';
import { OptBackdrop, OptConfirmationDialog } from '@optsol/react';
import { useState } from 'react';

import { Colors } from '../../../../core/colors';

interface Props {
  disabled?: boolean;
  pacienteId: string;
  atendimentoId: string;
  onClick: (atendimentoId: string, pacienteId: string) => Promise<void>;
}

export const CancelarAmbulanciaButton = ({
  disabled,
  pacienteId,
  atendimentoId,
  onClick
}: Props) => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  async function handleConfirm() {
    toggleModal();

    try {
      setLoading(true);
      await onClick(atendimentoId, pacienteId);
    } finally {
      setLoading(false);
    }
  }
  const toggleModal = () => {
    setOpen((prevState) => !prevState);
  };
  return (
    <>
      <IconButton
        title="Cancelar ambulância"
        disabled={disabled}
        color="info"
        onClick={(e) => {
          e.stopPropagation();
          toggleModal();
        }}
      >
        <Icon path={mdiClose} size={1} color={Colors.red} />
      </IconButton>
      <OptConfirmationDialog
        open={open}
        title="Cancelar ambulância"
        icon={{ path: mdiCarEmergency, color: Colors.red }}
        onCancel={toggleModal}
        onClose={toggleModal}
        onConfirm={handleConfirm}
      >
        Deseja cancelar esta ambulância?
      </OptConfirmationDialog>
      <OptBackdrop open={loading} />
    </>
  );
};
