import { Colors } from '../../core/colors';

export interface ChartDataModel {
  id: string;
  label: string;
  value: number;
}

export interface PieChartDataModel {
  id: string;
  label: string;
  value: number;
  color: string;
}

export interface BarChartDataModel {
  id: string;
  label: string;
  value: number;
  valueColor: string;
}

export interface InputsDashboardModel {
  dataInicio: Date | string | null;
  dataFim: Date | string | null;
  periodo: number | null;
  // 1 hoje
  // 2 ultimos 30 dias
  // 3 ultimos 60 dias
  // 4 ultimos 90 dias
  // 5 ultimos 12 meses
}

export const PieChartSemDados = [{ id: null, label: '', value: true, color: Colors.gray7 }];

export const BarChartSemDados = [{ id: null, label: '', value: true, valueColor: Colors.gray7 }];

export interface DashboardsData {
  indicadores: ChartDataModel[];
  chamadosPorPrioridadeData: PieChartDataModel[];
  maiorIncidenciaData: PieChartDataModel[];
  motivoAtendimentoData: BarChartDataModel[];
  atedimentosMrData: BarChartDataModel[];
  statusAmbulanciaData: PieChartDataModel[];
}

export interface IndicadoresDataModel {
  totalChamados: ChartDataModel;
  emAndamento: ChartDataModel;
  agendados: ChartDataModel;
  pendentes: ChartDataModel;
  cancelados: ChartDataModel;
  finalizados: ChartDataModel;
}

export const INIT_INDICADORES = {
  totalChamados: { id: '99', label: 'Total de chamados', value: 0 },
  emAndamento: { id: '1', label: 'Em andamento', value: 0 },
  agendados: { id: '6', label: 'Agendados', value: 0 },
  pendentes: { id: '3', label: 'Pendentes', value: 0 },
  cancelados: { id: '4', label: 'Cancelados', value: 0 },
  finalizados: { id: '2', label: 'Finalizados', value: 0 }
};
