import { Box } from '@mui/system';

interface TipoVeiculoProps {
  tipo: number;
}

export function TipoVeiculoText(props: TipoVeiculoProps) {
  return (
    <>
      {props.tipo === 0 ? (
        <Box sx={{ fontWeight: '600' }}>USB</Box>
      ) : props.tipo === 1 ? (
        <Box sx={{ fontWeight: '600' }}>USA</Box>
      ) : props.tipo === 2 ? (
        <Box sx={{ fontWeight: '600' }}> VR </Box>
      ) : props.tipo === 3 ? (
        <Box sx={{ fontWeight: '600' }}> CA </Box>
      ) : (
        <Box sx={{ fontWeight: '600' }}> - </Box>
      )}
    </>
  );
}
