import { Route, Routes as ReactRouterRoutes } from 'react-router-dom';

import { useAuthenticationContext } from '../app/contexts/authentication/authenticationContext';
import EditarCliente from '../app/features/cliente/EditarCliente';
import Clientes from '../app/pages/Clientes/Clientes';
import Home from '../app/pages/home/Home';
import { MenuClienteClaims } from '../models/enums/AcessoClaims';

export const ClienteRoutes = () => {
  const { hasAccess } = useAuthenticationContext();
  const enableRoute = hasAccess(MenuClienteClaims.VISUALIZAR);
  return (
    <ReactRouterRoutes>
      <Route path="/" element={enableRoute ? <Clientes /> : <Home />} />
      <Route path="/cliente/:id" element={enableRoute ? <EditarCliente /> : <Home />} />
    </ReactRouterRoutes>
  );
};
