import { mdiNotebookPlus } from '@mdi/js';
import Icon from '@mdi/react';
import {
  Autocomplete,
  Button,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  SelectChangeEvent,
  Switch,
  TextField,
  Tooltip,
  Typography,
  debounce
} from '@mui/material';
import { DateTimePicker } from '@mui/x-date-pickers';
import { OptBackdrop } from '@optsol/react';
import { useCallback, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { Colors } from '../../../../core/colors';
import { AtendimentoFormModel } from '../../../../models/Atendimento/Atendimento.model';
import { InformacaoPacienteFormModel } from '../../../../models/Atendimento/Paciente/InformacaoPaciente.model';
import { CidModel } from '../../../../models/Cid/Cid.model';
import { AdicionarProntuarioRequest } from '../../../../models/dtos/Atendimento/adicionarProntuarioRequest';
import { AdicionarTipoVeiculoPacienteRequestModel } from '../../../../models/dtos/Atendimento/adicionarTipoVeiculoPacienteRequest.model';
import { ListaTipoVeiculoEnum, TipoVeiculo } from '../../../../models/enums/TipoVeiculo';
import { useAtendimentoService } from '../../../../service/atendimento.service';
import { useCidService } from '../../../../service/cid.service';
import { PaginatedSearchRequest, SearchRequest } from '../../../../shared/utils/searchRequest';
import { FormSelect, FormTextField } from '../../../components/Form';
import { useAuthenticationContext } from '../../../contexts/authentication/authenticationContext';
import { ListaTipoVeiculos } from '../ListaTipoVeiculos/ListaTipoVeiculos';
import * as S from '../styles/index';

interface Props {
  index: number;
  disableForm?: boolean;
  podeEditarProntuario?: boolean;
  editarPaciente: (value: InformacaoPacienteFormModel) => Promise<void>;
  salvarProntuario: (value: AdicionarProntuarioRequest) => Promise<void>;
}

export const FormMedicoRegulador = ({
  editarPaciente,
  salvarProntuario,
  index,
  disableForm,
  podeEditarProntuario
}: Props) => {
  const { control, getValues, watch, setValue, reset } = useFormContext<AtendimentoFormModel>();
  const {
    state: { tema }
  } = useAuthenticationContext();
  const { adicionarVeiculoPaciente, removerVeiculoPaciente } = useAtendimentoService();
  const { buscarCidPaginated } = useCidService();
  const [listaCid, setListaCid] = useState<CidModel[]>();

  const paciente = getValues().pacientes[index];
  const monitorarFlag = watch(`pacientes.${index}.obito`);

  async function salvarProntuarioHandler() {
    await salvarProntuario({
      atendimentoId: paciente.atendimentoId ?? '',
      informacao: paciente.informacaoProntuario ?? '',
      pacienteId: paciente.pacienteId ?? ''
    });
    setValue(`pacientes.${index}.informacaoProntuario`, '');
  }

  const [tiposVeiculoLoading, setTiposVeiculoLoading] = useState<boolean>(false);

  // useForm<AtendimentoFormModel>({
  //   mode: 'onBlur',
  //   defaultValues: DADOS_ATENDIMENTO_DEFAULT,
  //   resolver: yupResolver(dadosAtendimentoFormModelSchema)
  // });

  async function adicionarVeiculo(tipoVeiculo: TipoVeiculo) {
    const request: AdicionarTipoVeiculoPacienteRequestModel = {
      tipoVeiculo: tipoVeiculo
    };
    try {
      setTiposVeiculoLoading(true);
      const result = await adicionarVeiculoPaciente(
        request,
        paciente.atendimentoId ?? '',
        paciente.pacienteId ?? ''
      );

      paciente.equipes.push({
        id: result.data.id,
        tipoVeiculo: tipoVeiculo
      });
      reset(getValues());
    } finally {
      setTiposVeiculoLoading(false);
    }
  }

  async function removerTipoVeiculoHandler(equipeId: string) {
    try {
      setTiposVeiculoLoading(true);
      await removerVeiculoPaciente(
        paciente.atendimentoId ?? '',
        paciente.pacienteId ?? '',
        equipeId
      );

      paciente.equipes = paciente.equipes.filter((x) => x.id !== equipeId);
      reset(getValues());
    } finally {
      setTiposVeiculoLoading(false);
    }
  }

  function filtrarCidHandler(newValue: string) {
    filtrarCid(newValue);
  }

  const changeHandler = useCallback((value: string) => {
    filtrarCidHandler(value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedCidChangeHandler = useCallback(debounce(changeHandler, 500), []);

  async function filtrarCid(termo: string) {
    if (termo) {
      const request: SearchRequest<PaginatedSearchRequest> = {
        page: 0,
        pageSize: 10,
        search: {
          termoBuscado: termo,
          apenasAtivo: true
        }
      };
      const result = await buscarCidPaginated(request);
      setListaCid(result.data);
    }
  }

  return (
    <div>
      <Grid container item rowSpacing={3} columnSpacing={3} px={1} pt={2.5}>
        <Grid item display="flex" flexDirection="column" gap={2} xs={6}>
          <Controller
            name={`pacientes.${index}.tipoVeiculo`}
            control={control}
            render={({ field: { value } }) => (
              <span>
                <FormSelect
                  onChange={(event: SelectChangeEvent<unknown>) => {
                    adicionarVeiculo(event.target.value as TipoVeiculo);
                  }}
                  value={value ?? ''}
                  name="tipo"
                  size="small"
                  label="Tipo veículo"
                  disabled={disableForm}
                >
                  {ListaTipoVeiculoEnum.map((x) => {
                    return (
                      <MenuItem key={x.id} value={x.id}>
                        {x.descricao}
                      </MenuItem>
                    );
                  })}
                </FormSelect>
                <ListaTipoVeiculos
                  lista={paciente.equipes}
                  onRemove={(tipoVeiculo: TipoVeiculo, equipeId: string) => {
                    removerTipoVeiculoHandler(equipeId);
                  }}
                  disableForm={disableForm}
                />
              </span>
            )}
          />
          <Controller
            name={`pacientes.${index}.cid`}
            control={control}
            render={({ field: { onChange } }) => (
              <span>
                <InputLabel>
                  <Typography color={tema.light?.primary} lineHeight={1}>
                    CID
                  </Typography>
                </InputLabel>
                <Autocomplete
                  fullWidth
                  options={listaCid ? listaCid : []}
                  getOptionLabel={(option) =>
                    typeof option === 'object'
                      ? `${option.cidId ? `${option.codigo} - ${option.nome}` : ''}`
                      : ''
                  }
                  value={watch(`pacientes.${index}.cid`)}
                  onChange={(_event, newValue: any) => {
                    setValue(`pacientes.${index}.cid`, newValue ? newValue : null);
                    onChange(newValue ? newValue : null);
                    editarPaciente(paciente);
                  }}
                  onInputChange={(_e, value) => debouncedCidChangeHandler(value)}
                  disabled={disableForm}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      size="small"
                      variant="outlined"
                      placeholder="Selecione um cid"
                      disabled={disableForm}
                    />
                  )}
                  freeSolo
                />
              </span>
            )}
          />
        </Grid>
        <Grid item xs={6}>
          <Controller
            name={`pacientes.${index}.informacaoProntuario`}
            control={control}
            render={() => (
              <div>
                <FormTextField
                  name={`pacientes.${index}.informacaoProntuario`}
                  control={control}
                  label="Prontuário"
                  fullWidth
                  multiline
                  rows={5}
                  disabled={!podeEditarProntuario}
                />
                <S.ButtonProntuarioContent>
                  {!podeEditarProntuario ||
                    (!paciente.informacaoProntuario && (
                      <Button
                        variant="outlined"
                        onClick={salvarProntuarioHandler}
                        disabled={!podeEditarProntuario || !paciente.informacaoProntuario}
                      >
                        <Icon path={mdiNotebookPlus} size={1} color={Colors.blue} />
                      </Button>
                    ))}
                  {podeEditarProntuario && paciente.informacaoProntuario && (
                    <Tooltip title={'Salvar prontuário'} placement="top">
                      <Button variant="outlined" onClick={salvarProntuarioHandler} disabled={false}>
                        <Icon path={mdiNotebookPlus} size={1} color={Colors.blue} />
                      </Button>
                    </Tooltip>
                  )}
                </S.ButtonProntuarioContent>
              </div>
            )}
          />
        </Grid>

        <Grid item container xs={12}>
          {monitorarFlag && (
            <Grid item xs={2}>
              <Controller
                name={`pacientes.${index}.dataHoraObito`}
                control={control}
                render={({ field: { onChange, value } }) => {
                  return (
                    <DateTimePicker
                      disabled={!monitorarFlag || !podeEditarProntuario}
                      value={value}
                      onChange={(e: any) => {
                        onChange(e);
                      }}
                      onClose={() => {
                        editarPaciente(paciente);
                      }}
                      renderInput={(params: any) => (
                        <>
                          <InputLabel>Data falecimento</InputLabel>
                          <TextField
                            fullWidth
                            size="small"
                            {...params}
                            onBlur={() => {
                              editarPaciente(paciente);
                            }}
                          />
                        </>
                      )}
                    />
                  );
                }}
              />
            </Grid>
          )}
          <Grid item xs={2}>
            <Controller
              name={`pacientes.${index}.obito`}
              control={control}
              render={({ field: { onChange, value } }) => {
                return (
                  <FormControlLabel
                    label={'Óbito'}
                    sx={monitorarFlag ? { paddingTop: 3, marginLeft: 2 } : {}}
                    control={
                      <Switch
                        onChange={(event) => {
                          onChange(event);
                          if (monitorarFlag) {
                            setValue(`pacientes.${index}.dataHoraObito`, null);
                          }
                        }}
                        checked={value ?? false}
                        onBlur={() => {
                          editarPaciente(paciente);
                        }}
                        disabled={!podeEditarProntuario}
                      />
                    }
                  />
                );
              }}
            />
          </Grid>
        </Grid>
      </Grid>

      <OptBackdrop open={tiposVeiculoLoading} />
    </div>
  );
};

export default FormMedicoRegulador;
