import { InformacaoPacienteFormModel } from '../../../../models/Atendimento/Paciente/InformacaoPaciente.model';
import { EncaminharRequest } from '../../../../models/dtos/Atendimento/encaminharRequest.mode';
import { MenuAtendimentoClaims } from '../../../../models/enums/AcessoClaims';
import { FasesAtendimentoEnum } from '../../../../models/enums/FasesAtendimento';
import { ProtectedContent } from '../../../components/ProtectedContent/ProtectedContent';
import { AlterarResponsavelButton } from '../AlterarResponsavel/AlterarResponsavelButton';
import { AssumirPacienteButton } from '../AssumirChamadoButton/AssumirPacienteButton';
import { AssumirPacienteValidadtion } from '../AtendimentoController';
import { CancelarChamadoButton } from '../CancelarChamadoButton/CancelarChamadoButton';
import { EncaminharPacienteButton } from '../EncaminharPacienteButton/EncaminharPacienteButton';
import { EncerrarChamadoButton } from '../EncerrarChamadoButton/EncerrarChamadoButton';
import { HistoricoAtendimentoButton } from '../HistoricoAtendimentoButton/HistoricoAtendimentoButton';
import { HistoricoProntuarioButton } from '../HistoricoProntuarioButton/HistoricoProntuarioButton';
import { ModalRemoverPaciente } from '../ModalRemoverPaciente';

interface Props {
  informacaoImportante: string;
  paciente: InformacaoPacienteFormModel;
  podeAlterarTarm: (paciente: InformacaoPacienteFormModel) => boolean;
  podeAlterarMedico: (paciente: InformacaoPacienteFormModel) => boolean;
  podeAlterarRadio: (paciente: InformacaoPacienteFormModel) => boolean;
  podeRemoverPaciente: (paciente: InformacaoPacienteFormModel) => boolean;
  podeAlterarResponsavel: (paciente: InformacaoPacienteFormModel) => boolean;
  podeEncerrarChamado: (paciente: InformacaoPacienteFormModel) => boolean;
  podeAssumirPaciente: (paciente: InformacaoPacienteFormModel) => AssumirPacienteValidadtion;
  podeCancelarChamado: (paciente: InformacaoPacienteFormModel) => boolean;
  podeEncaminharPaciente: (paciente: InformacaoPacienteFormModel) => boolean;
  removerPacienteHandler: (pacienteId: string, atendimentoId: string) => Promise<void>;
  encerrarChamadoHandler: (
    atendiemntoId: string,
    pacienteId: string,
    justificativa: string
  ) => Promise<void>;
  assumirPacienteHandler: (
    atendimentoId: string,
    pacienteId: string,
    faseAtendimento: FasesAtendimentoEnum | null
  ) => Promise<void>;
  alterarResponsavelHandler: (
    atendimentoId: string,
    pacienteId: string,
    usuarioId: string,
    faseAtendimento: FasesAtendimentoEnum | null
  ) => Promise<void>;
  cancelarChamadoHandler(
    atendimentoId: string,
    pacienteId: string,
    justificativa: string
  ): Promise<void>;
  encaminharPaciente: (
    request: EncaminharRequest,
    faseAtendimento: FasesAtendimentoEnum | null
  ) => Promise<void>;
  atualizarNotificacaoPaciente: (
    pacienteIdP: string | undefined,
    quantidadeNaoLida: number | undefined,
    userId: string | undefined
  ) => void;
}

export const PacienteActionsBar = ({
  informacaoImportante,
  paciente,
  podeRemoverPaciente,
  removerPacienteHandler,
  podeEncerrarChamado,
  podeAlterarTarm,
  podeAlterarMedico,
  podeAlterarRadio,
  podeAssumirPaciente,
  podeCancelarChamado,
  podeEncaminharPaciente,
  alterarResponsavelHandler,
  encerrarChamadoHandler,
  assumirPacienteHandler,
  cancelarChamadoHandler,
  encaminharPaciente,
  atualizarNotificacaoPaciente
}: Props) => {
  // const {
  //   state: { userInfo }
  // } = useAuthenticationContext();

  const {
    podeAssumirCriacao,
    podeAssumirEmpenho,
    podeAssumirRegulacao,
    processoDeAtendimentoEmAndamentoValido
  } = podeAssumirPaciente(paciente);

  return (
    <div>
      <ProtectedContent access={MenuAtendimentoClaims.REMOVER_PACIENTE}>
        {podeRemoverPaciente(paciente) && (
          <ModalRemoverPaciente
            disableForm={false}
            key={paciente.pacienteId}
            atendimentoId={paciente.atendimentoId ?? ''}
            pacienteId={paciente.pacienteId ?? ''}
            removerPaciente={removerPacienteHandler}
          />
        )}
      </ProtectedContent>
      <ProtectedContent access={MenuAtendimentoClaims.VISUALIZAR_HISTORICO_ATEDIMENTOS_PACIENTE}>
        {paciente.cpf && (
          <HistoricoAtendimentoButton
            informacaoImportante={informacaoImportante}
            cpf={paciente.cpf ?? ''}
            atendimentoId={paciente.atendimentoId ?? ''}
          />
        )}
      </ProtectedContent>
      <ProtectedContent access={MenuAtendimentoClaims.VISUALIZAR_HISTORICO_PRONTUARIO}>
        <HistoricoProntuarioButton
          atendimentoId={paciente.atendimentoId ?? ''}
          paciente={paciente}
          notificacoesNaoLidas={
            paciente?.quantidadeNotificacaoNaoLida ? paciente?.quantidadeNotificacaoNaoLida : 0
          }
          atualizarNotificacaoPaciente={atualizarNotificacaoPaciente}
        />
      </ProtectedContent>
      <ProtectedContent access={MenuAtendimentoClaims.ALTERAR_RESPONSAVEL}>
        {podeAlterarTarm(paciente) && (
          <AlterarResponsavelButton
            atendimentoId={paciente.atendimentoId ?? ''}
            pacienteId={paciente.pacienteId ?? ''}
            faseAtendimento={FasesAtendimentoEnum.FASECRIACAO}
            alterarResponsavel={alterarResponsavelHandler}
            usuarioResponsavelId={paciente.faseCriacaoResponsavelId}
          />
        )}
        {podeAlterarMedico(paciente) && (
          <AlterarResponsavelButton
            atendimentoId={paciente.atendimentoId ?? ''}
            pacienteId={paciente.pacienteId ?? ''}
            faseAtendimento={FasesAtendimentoEnum.FASEREGULACAO}
            alterarResponsavel={alterarResponsavelHandler}
            usuarioResponsavelId={paciente.faseRegulacaoResponsavelId}
          />
        )}
        {podeAlterarRadio(paciente) && (
          <AlterarResponsavelButton
            atendimentoId={paciente.atendimentoId ?? ''}
            pacienteId={paciente.pacienteId ?? ''}
            faseAtendimento={FasesAtendimentoEnum.FASEEMPENHO}
            alterarResponsavel={alterarResponsavelHandler}
            usuarioResponsavelId={paciente.faseEmpenhoResponsavelId}
          />
        )}
      </ProtectedContent>
      <ProtectedContent access={MenuAtendimentoClaims.ENCERRAR_CHAMADO}>
        {podeEncerrarChamado(paciente) && (
          <EncerrarChamadoButton
            atendimentoId={paciente.atendimentoId ?? ''}
            pacienteId={paciente.pacienteId ?? ''}
            paciente={paciente}
            encerrarChamado={encerrarChamadoHandler}
          />
        )}
      </ProtectedContent>
      <ProtectedContent access={MenuAtendimentoClaims.ASSUMIR_PACIENTE}>
        {processoDeAtendimentoEmAndamentoValido && (
          <>
            {podeAssumirRegulacao && (
              <AssumirPacienteButton
                key={`${paciente.pacienteId}-FASEREGULACAO`}
                atendimentoId={paciente.atendimentoId ?? ''}
                pacienteId={paciente.pacienteId ?? ''}
                assumirPaciente={assumirPacienteHandler}
                faseAtendimento={FasesAtendimentoEnum.FASEREGULACAO}
              />
            )}
            {podeAssumirCriacao && (
              <AssumirPacienteButton
                key={`${paciente.pacienteId}-FASECRIACAO`}
                atendimentoId={paciente.atendimentoId ?? ''}
                pacienteId={paciente.pacienteId ?? ''}
                assumirPaciente={assumirPacienteHandler}
                faseAtendimento={FasesAtendimentoEnum.FASECRIACAO}
              />
            )}

            {podeAssumirEmpenho && (
              <AssumirPacienteButton
                key={`${paciente.pacienteId}-FASEEMPENHO`}
                atendimentoId={paciente.atendimentoId ?? ''}
                pacienteId={paciente.pacienteId ?? ''}
                assumirPaciente={assumirPacienteHandler}
                faseAtendimento={FasesAtendimentoEnum.FASEEMPENHO}
              />
            )}
          </>
        )}
      </ProtectedContent>
      <ProtectedContent access={MenuAtendimentoClaims.CANCELAR_CHAMADO}>
        {podeCancelarChamado(paciente) && (
          <CancelarChamadoButton
            key={paciente.pacienteId}
            atendimentoId={paciente.atendimentoId ?? ''}
            pacienteId={paciente.pacienteId ?? ''}
            cancelarChamado={cancelarChamadoHandler}
          />
        )}
      </ProtectedContent>

      <ProtectedContent
        access={[
          MenuAtendimentoClaims.ENCAMINHAR_PACIENTE_RADIO_OPERADOR,
          MenuAtendimentoClaims.ENCAMINHAR_ATENDIMENTO_MEDICO_REGULADOR
        ]}
      >
        {podeEncaminharPaciente(paciente) && (
          <EncaminharPacienteButton paciente={paciente} encaminharPaciente={encaminharPaciente} />
        )}
      </ProtectedContent>
    </div>
  );
};
