import * as yup from 'yup';

import { validarCPF } from '../../shared/utils/functions';
import { ClienteSearchResponse } from '../dtos/Cliente/clienteSearchResponse.model';
import { CategoriaCnhEnum } from '../enums/CategoriaCnh';
import { PerfilAcessoEnum } from '../enums/PerfilAcesso';
import { TipoUsuarioEnum } from '../enums/TipoUsuario';
export interface UsuarioModel {
  usuarioId?: string | null;
  nome: string;
  cpf: string;
  email: string;
  perfilAcessoId?: string | null;
  perfilAcesso: PerfilAcessoEnum | null;
  perfisAcesso: PerfilAcessoEnum[] | null;
  documentoOrgaoRegulador: string | null;
  categoriaCnh: CategoriaCnhEnum | null;
  situacao: boolean;
  cnh: string | null;
  telefone?: string;
  ufDocumento: string | null;
  tipo?: TipoUsuarioEnum | null;
  clientesPermitidosCriacaoAtendimento?: ClienteSearchResponse[];
}

export const DADOS_USUARIO_DEFAULT: UsuarioModel = {
  usuarioId: '',
  nome: '',
  cpf: '',
  email: '',
  perfilAcessoId: '',
  perfilAcesso: null,
  documentoOrgaoRegulador: '',
  categoriaCnh: null,
  cnh: '',
  situacao: false,
  telefone: '',
  ufDocumento: '',
  perfisAcesso: [],
  tipo: null,
  clientesPermitidosCriacaoAtendimento: []
};

const MSG_REQUIRED = 'Campo obrigatório';
const regexEmail = new RegExp('[a-z0-9]+@[a-z]+.[a-z]{2,3}');

export const dadosUsuarioFormModelSchema: yup.SchemaOf<UsuarioModel> = yup.object().shape({
  usuarioId: yup.string().notRequired().nullable(),
  nome: yup.string().required(MSG_REQUIRED),
  cpf: yup
    .string()
    .required(MSG_REQUIRED)
    .test('Cpf', 'Cpf inválido', (value) => {
      if (!value) return false;
      return validarCPF(value);
    }),
  email: yup
    .string()
    .required(MSG_REQUIRED)
    .test('Email', 'E-mail inválido', (value) => {
      if (!value) return false;
      return regexEmail.test(value.trim());
    }),

  perfilAcessoId: yup.string().notRequired().nullable(),
  perfilAcesso: yup.mixed().required(MSG_REQUIRED),
  documentoOrgaoRegulador: yup.string().required(MSG_REQUIRED),
  ufDocumento: yup.string().required(MSG_REQUIRED),
  cnh: yup.string().required(MSG_REQUIRED),
  categoriaCnh: yup.number().required(MSG_REQUIRED),
  situacao: yup.boolean().required(MSG_REQUIRED),
  telefone: yup.string().test('telefone', 'Telefone inválido', (value) => {
    if (!value) return false;
    return !(value.trim().length < 14 && value.trim().length < 15);
  }),
  perfisAcesso: yup.array().required(MSG_REQUIRED).min(1, 'Selecione pelo menos um perfil'),
  tipo: yup.mixed().notRequired(),
  clientesPermitidosCriacaoAtendimento: yup.array().notRequired()
});

export interface CriarUsuarioModel {
  nome: string;
  cpf: string;
  email: string;
  perfisAcesso?: PerfilAcessoEnum[] | null;
  perfilAcesso?: string;
  tipo?: number;
}

export const DADOS_CRIAR_USUARIO_DEFAULT: CriarUsuarioModel = {
  nome: '',
  cpf: '',
  email: '',
  perfisAcesso: [],
  perfilAcesso: ''
};

export const dadosCriarUsuarioFormModelSchema: yup.SchemaOf<CriarUsuarioModel> = yup
  .object()
  .shape({
    nome: yup.string().required(MSG_REQUIRED),
    cpf: yup
      .string()
      .required(MSG_REQUIRED)
      .test('Cpf', 'Cpf inválido', (value) => {
        if (!value) return false;
        return validarCPF(value);
      }),
    email: yup
      .string()
      .required(MSG_REQUIRED)
      .test('Email', 'E-mail inválido', (value) => {
        if (!value) return false;
        return regexEmail.test(value.trim());
      }),
    tipo: yup.number().notRequired(),
    perfisAcesso: yup.array().when(['tipo'], {
      is: (tipo: any) => tipo === TipoUsuarioEnum.INTERNO,
      then: yup.array().required(MSG_REQUIRED).min(1, 'Selecione pelo menos um perfil'),
      otherwise: yup.array().notRequired()
    }),
    perfilAcesso: yup.string().notRequired()
  });

export interface UsuarioResponse {
  usuarioId: string;
  cpf: string;
  nome: string;
}

export interface UsuarioStatusResponse {
  usuarioId: string;
  nome: string;
  ultimaAtualizacao: string;
  status: number;
  //1 = online
  //2 = ausente
  //3 = offline
}
