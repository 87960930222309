import { Grid } from '@mui/material';
import { OptTimeline } from '@optsol/react';
import styled from 'styled-components';

import Button from '../../../components/Button/Button';

export const BotaoNovoPacienteContent = styled.div`
  display: flex;
  justify-content: end;
`;
export const BotaoEncaminharContent = styled.div`
  display: flex;
  justify-content: center;
`;

export const ButtonMarginCustom = styled(Button)`
  margin: 0.5em;
  border-radius: 8px;
`;

export const ButtonGridContent = styled.div`
  display: flex;
  justify-content: space-between;
  margin-right: 1em;
  margin-left: 1em;
`;

export const DeleteButtonContent = styled.div`
  display: flex;
  margin-top: 0.5em;
`;

export const NovoPacienteButtonContent = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: 0.5em 0.5em 0.5em 1.5em;
`;

export const GriButtonEncaminharContent = styled(Grid)`
  display: flex;
  justify-content: center;
`;

export const MedicoReguladorButtonsContent = styled.div`
  display: flex;
  justify-content: flex-end;
`;

// export const AccordionContent = styled.div`
//   margin: 0 20px;
// `;

export const VeiculosLoading = styled.div`
  display: flex;
  max-width: 11em;
`;

export const OptTimelineCustom = styled(OptTimeline)`
  margin-top: 1em;
`;

export const ButtonProntuario = styled(Button)`
  margin: 0.5em 0.1em;
  border-radius: 8px;
`;

export const ButtonProntuarioContent = styled.div`
  display: flex;
  margin-top: 10px;
  width: fit-content;
  /* justify-content: end; */
`;

export const AccordionSummaryContent = styled.div`
  width: 100%;
  display: flex;
  place-content: space-between;
`;
