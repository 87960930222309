import { OptSearchResponse } from '@optsol/react';
import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { useAuthenticationContext } from '../../app/contexts/authentication/authenticationContext';
import { APIPaginatedRequest } from '../../models/APIPaginatedRequest';
import { APIPaginatedResponse } from '../../models/APIPaginatedResponse';
import { RoutesObj } from '../../routes/index.routes';
import { useMsalService } from '../../service/authentication/msal.service';
import { IApiError } from '../types/ApiError';
import { ApiResponse } from '../types/ApiResponse';
import { BaseConfig } from '../utils/baseConfig';

import { useErrorSnackbar } from './errorSnackbar';
export type UseApiOptions = {
  semToken?: boolean;
  external?: boolean;
};

export function useApi({ semToken, external }: Partial<UseApiOptions> = {}) {
  const { getAccessToken } = useMsalService();
  const { tratarErro } = useErrorSnackbar();
  const navigate = useNavigate();
  const { state, definirToken } = useAuthenticationContext();

  const api = axios.create({
    baseURL: external ? '' : BaseConfig.baseApiUrl,
    headers: {
      'Access-Control-Allow-Origin': '*',
      'Local-Id': state.localId
    }
  });

  api.interceptors.request.use(
    async (config) => {
      const configValida = config !== undefined && config.url !== undefined;

      if (configValida) {
        try {
          const deveObterToken = !semToken;

          if (deveObterToken) {
            const token = await getAccessToken();
            definirToken(token);

            if (token && config.headers !== undefined) {
              Object.assign(config.headers, { Authorization: `Bearer ${token}` });

              // se for necessário verificar expiração do token, considere o fato dele estar em UNIX DATE
              // const tokenValido = token !== undefined && token.accessToken !== undefined;
              // if (tokenValido) {
              //   config.headers["Authorization"] = `Bearer ${token.accessToken}`;
              // }
            }
          }
        } catch (e) {
          console.error(e);
        }
      }

      return { ...config };
    },
    (error) => {
      if (error.response.status === 403) {
        console.log(error);
      }
      return Promise.reject(error);
    }
  );

  api.interceptors.response.use(
    (axiosResponse) => {
      const responseInvalida = !axiosResponse.data;

      if (responseInvalida) {
        console.info('Controller não retornou IActionResult!');
      }

      return axiosResponse;
    },
    (error: AxiosError<IApiError>) => {
      if (error.code === 'ERR_CANCELED') return;
      if (error.response?.status === 403) {
        const [localId, errorMessage] = error.response.data.messages;
        if (localId === state.localId) {
          navigate(RoutesObj.ErrorLogin.Principal, { state: errorMessage });
        }
        return Promise.reject(error.response?.data);
      } else {
        tratarErro(error.response?.data);
        return Promise.reject(error.response?.data);
      }
    }
  );

  function postBlobFile<T, R = T>(url: string, data?: any, config?: AxiosRequestConfig) {
    return api.post<T, R>(url, data, config).then((response) => response);
  }

  function getBlobFile<T, R = T>(url: string, data?: any) {
    return api.get<T, R>(url, data).then((response) => response);
  }

  const gridSearch = useCallback(
    <T extends object>(
      url: string,
      data: APIPaginatedRequest<any>,
      config?: AxiosRequestConfig
    ) => {
      data.page = data.page + 1;
      const controller = new AbortController();
      const signal = controller.signal;
      return api
        .post<T, AxiosResponse<OptSearchResponse<T>>>(url, data, { signal, ...config })
        .then((response) => response.data)
        .then((response) => {
          const r: APIPaginatedResponse<T> = {
            data: response.data,
            page: response.page - 1,
            total: response.total,
            totalCount: response.total,
            pageSize: response.pageSize,
            pageCount: response.total
          };
          return r;
        });
    },
    [api]
  );

  function search<T, R = OptSearchResponse<T>>(
    url: string,
    data?: any,
    config?: AxiosRequestConfig
  ) {
    return api.post<T, AxiosResponse<R>>(url, data, config).then((response) => response.data);
  }

  function post<T, R = ApiResponse<T>>(url: string, data?: any, config?: AxiosRequestConfig) {
    return api.post<T, AxiosResponse<R>>(url, data, config).then((response) => response.data);
  }

  function put<T, R = ApiResponse<T>>(url: string, data?: any, config?: AxiosRequestConfig) {
    return api.put<T, AxiosResponse<R>>(url, data, config).then((response) => response.data);
  }

  function get<T, R = ApiResponse<T>>(url: string, config?: AxiosRequestConfig) {
    return api.get<T, AxiosResponse<R>>(url, config).then((response) => response.data);
  }

  function remove<T, R = ApiResponse<T>>(url: string, config?: AxiosRequestConfig) {
    return api.delete<T, AxiosResponse<R>>(url, config).then((response) => response.data);
  }

  function removeWithBody<T, R = ApiResponse<T>>(
    url: string,
    data?: any,
    _config?: AxiosRequestConfig
  ) {
    return api.delete<T, R>(url, { data: data });
  }

  function getFile<T, R = T>(url: string, config?: AxiosRequestConfig) {
    return api.get<T, AxiosResponse<R>>(url, config).then((response) => response.data);
  }

  function postFile<T, R = T>(url: string, data?: any, config?: AxiosRequestConfig) {
    return api.post<T, AxiosResponse<R>>(url, { data: data }, config);
  }

  return {
    search,
    gridSearch,
    post,
    put,
    get,
    getFile,
    remove,
    removeWithBody,
    postBlobFile,
    getBlobFile,
    postFile
  };
}

// function enqueueSnackbar(arg0: string, arg1: { variant: string }) {
//   throw new Error("Function not implemented.");
// }
