import { PerfilAcessoEnum } from '../../../models/enums/PerfilAcesso';

import GridEncaminharUsuario from './GridEncaminharUsuario';

interface Props {
  encaminharChamado: (id: string) => Promise<void>;
  perfil?: PerfilAcessoEnum;
  usuarioResponsavelId?: string;
  pacienteId: string;
}

export const FormEncaminharUsuario = ({
  encaminharChamado,
  perfil,
  usuarioResponsavelId,
  pacienteId
}: Props) => {
  async function encaminharHandler(id: string) {
    await encaminharChamado(id);
  }

  return (
    <>
      <GridEncaminharUsuario
        usuarioResponsavelId={usuarioResponsavelId}
        perfil={perfil}
        encaminhar={encaminharHandler}
        pacienteId={pacienteId}
      />
    </>
  );
};

export default FormEncaminharUsuario;
