import { Box, Grid, MenuItem, SelectChangeEvent } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';

import { ListaTipoVeiculoEnum } from '../../../models/enums/TipoVeiculo';
import { CriarVeiculoModel } from '../../../models/Veiculo/Veiculo.model';
import { FormSelect, FormTextField } from '../../components/Form';

export const FormCriarVeiculo = () => {
  const { control } = useFormContext<CriarVeiculoModel>();

  return (
    <>
      <Grid container item rowSpacing={3} columnSpacing={3} pt={2.5} pb={2.5}>
        <Grid item xs={12}>
          <Controller
            name="tipo"
            control={control}
            render={({ field: { onChange, value } }) => (
              <Box display="flex" flexDirection="column" gap={1}>
                <FormSelect
                  style={{ textAlign: 'left' }}
                  onChange={(event: SelectChangeEvent<unknown>) => {
                    onChange(event);
                  }}
                  value={value ?? -1}
                  name="tipo"
                  size="small"
                  label="Tipo veículo"
                >
                  {ListaTipoVeiculoEnum.map((x) => {
                    return (
                      <MenuItem key={x.id} value={x.id}>
                        {x.descricao}
                      </MenuItem>
                    );
                  })}
                </FormSelect>
              </Box>
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            name="placa"
            control={control}
            render={() => (
              <Box display="flex" flexDirection="column" gap={1}>
                <FormTextField
                  name="placa"
                  control={control}
                  label="Placa"
                  size="small"
                  fullWidth
                />
              </Box>
            )}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default FormCriarVeiculo;
