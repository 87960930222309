import { Route, Routes as ReactRouterRoutes } from 'react-router-dom';

import { useAuthenticationContext } from '../app/contexts/authentication/authenticationContext';
import EditarEmpresa from '../app/features/empresa/EditarEmpresa';
import Empresas from '../app/pages/empresas/Empresas';
import Home from '../app/pages/home/Home';
import { MenuEmpresaClaims } from '../models/enums/AcessoClaims';

export const EmpresaAdminRoutes = () => {
  const { hasAccess } = useAuthenticationContext();
  const enableRoute =
    hasAccess(MenuEmpresaClaims.MENUS__MENU_EMPRESA_OPTSOL) ||
    hasAccess(MenuEmpresaClaims.MENUS__MENU_EMPRESA_CLIENTE);
  return (
    <ReactRouterRoutes>
      <Route path="/" element={enableRoute ? <Empresas /> : <Home />} />
      <Route path="/empresa-admin/:id" element={enableRoute ? <EditarEmpresa /> : <Home />} />
    </ReactRouterRoutes>
  );
};
