import { Box } from '@mui/material';

import {
  BarChartDataModel,
  DashboardsData,
  PieChartDataModel
} from '../../../../models/dashboards/DashboardsModel';
import { MenuHomeClaims } from '../../../../models/enums/AcessoClaims';
import { limitarLabel } from '../../../../shared/utils/functions';
import { GraficoBarras } from '../../../components/Graficos/GraficoBarras';
import { GraficoDonuts } from '../../../components/Graficos/GraficoDonuts';
import ListaIndicadores from '../../../components/Graficos/ListaIndicadores';
import { ProtectedContent } from '../../../components/ProtectedContent/ProtectedContent';
import * as S from '../styles';

interface Props {
  data: DashboardsData;
}
export const Dashboards = ({ data }: Props) => {
  return (
    <ProtectedContent access={MenuHomeClaims.VISUALIZAR_GRAFICOS}>
      <Box display="flex" flexWrap="wrap" justifyContent="center" gap={5.5}>
        <S.CardInformativo>
          <S.TituloCard>Status das Ambulâncias</S.TituloCard>
          <S.CardCentro>
            <ListaIndicadores data={data.statusAmbulanciaData} />
          </S.CardCentro>
        </S.CardInformativo>

        <S.CardInformativo>
          <S.TituloCard>Chamados por prioridade</S.TituloCard>
          <GraficoDonuts chartData={data.chamadosPorPrioridadeData as PieChartDataModel[]} />
        </S.CardInformativo>

        <S.CardInformativo>
          <S.TituloCard>Incidência de Chamados</S.TituloCard>
          <S.CardCentro>
            <ListaIndicadores data={data.maiorIncidenciaData} />
          </S.CardCentro>
        </S.CardInformativo>

        <S.CardInformativo>
          <S.TituloCard>Motivo dos atendimentos</S.TituloCard>
          <GraficoBarras
            chartData={limitarLabel(28, data.motivoAtendimentoData) as BarChartDataModel[]}
          />
        </S.CardInformativo>

        {/* PERFIS RESPONSAVEIS */}

        {/* <S.CardInformativo>
          <S.TituloCard>Atendimento por MR</S.TituloCard>
          <GraficoBarras
            chartData={limitarLabel(22, data.atedimentosMrData) as BarChartDataModel[]}
          />
        </S.CardInformativo> */}
        <S.CardInformativo>
          <S.TituloCard>Atendimento por MR</S.TituloCard>
          <GraficoBarras
            chartData={limitarLabel(28, data.atedimentosMrData) as BarChartDataModel[]}
          />
        </S.CardInformativo>
      </Box>
    </ProtectedContent>
  );
};
