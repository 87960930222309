import { mdiDeleteOutline } from '@mdi/js';
import Icon from '@mdi/react';
import {
  Autocomplete,
  Box,
  debounce,
  Divider,
  FormControl,
  Grid,
  IconButton,
  List,
  ListItemButton,
  ListItemText,
  ListSubheader,
  TextField,
  Typography
} from '@mui/material';
import { OptBackdrop, OptConfirmationDialog } from '@optsol/react';
import { useSnackbar } from 'notistack';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';

import { Colors } from '../../../../core/colors';
import { MenuPlanoClaims } from '../../../../models/enums/AcessoClaims';
import { DADOS_ESTADO_PLANO_DEFAULT, EstadoModel } from '../../../../models/Estado/Estado.model';
import { PlanoModel } from '../../../../models/Plano/Plano.model';
import { usePlanoService } from '../../../../service/plano.service';
import { ProtectedContent } from '../../../components/ProtectedContent/ProtectedContent';
import { useAuthenticationContext } from '../../../contexts/authentication/authenticationContext';

interface Props {
  index: number;
  name: `servicosCobertosPlanos.${number}.estados`;
  selecionarEstadoPlanoIndex: (index: number) => void;
  selecionarEstadoPlano: (value: EstadoModel) => void;
}

const EstadoAbrangenciaPlano = ({
  index,
  name,
  selecionarEstadoPlanoIndex,
  selecionarEstadoPlano
}: Props) => {
  const { control, getValues } = useFormContext<PlanoModel>();
  const {
    state: { tema }
  } = useAuthenticationContext();

  const { fields, append, remove, replace } = useFieldArray({
    name,
    control
  });

  const { enqueueSnackbar } = useSnackbar();

  // const [estados, setEstados] = useState<any[]>([]);
  const [confirmandoExclusao, setConfirmandoExclusao] = useState<boolean>(false);

  const [posicaoExclusao, setPosicaoExclusao] = useState<number>(-1);

  function cancelarExclusao() {
    setConfirmandoExclusao(false);
  }

  const [selectedIndex, setSelectedIndex] = React.useState('');
  const [loadingPlano, setLoadingPlano] = useState<boolean>(false);
  const { salvarEstadoPlano, removerEstadoPlano } = usePlanoService();

  function filtrarEstadoHandler(newValue: string) {
    filtrarEstado(newValue);
  }

  const changeHandler = useCallback((value: string) => {
    filtrarEstadoHandler(value);
  }, []);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedChangeHandler = useCallback(debounce(changeHandler, 500), []);
  async function filtrarEstado(_termo: string) {
    // const result = states.filter((x) => x.label == termo || x.value == termo);
    //setEstados(result);
  }

  const handleListItemClick = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    value: EstadoModel,
    index: number
  ) => {
    setSelectedIndex(value.estadoId);
    selecionarEstadoPlanoIndex(index);
    selecionarEstadoPlano(value);
  };

  function adicionarEstado(estado: any) {
    if (estado) {
      setLoadingPlano(true);
      const listaEstadoPlanoOriginal = getValues(`servicosCobertosPlanos.${index}.estados`);

      const jaAdicionado = listaEstadoPlanoOriginal.filter((x) => x.sigla === estado.value);

      if (jaAdicionado?.length === 0) {
        const estadoPlanoSelecionado = DADOS_ESTADO_PLANO_DEFAULT;
        estadoPlanoSelecionado.nome = estado.label;
        estadoPlanoSelecionado.sigla = estado.value;
        estadoPlanoSelecionado.codigoIBGE = estado.codigoIBGE;
        estadoPlanoSelecionado.planoId = getValues('id');
        estadoPlanoSelecionado.servicoCobertoPlanoId = getValues(
          `servicosCobertosPlanos.${index}.servicoCobertoPlanoId`
        );

        salvarEstadoPlano(estadoPlanoSelecionado).then((x) => {
          estadoPlanoSelecionado.estadoId = x.data.estadoId;
          append(estadoPlanoSelecionado);

          setLoadingPlano(false);
        });
      } else {
        setLoadingPlano(false);

        enqueueSnackbar('Estado já adicionado!', {
          variant: 'warning'
        });
      }
    }
  }

  function removerEstado(posicao: number) {
    setLoadingPlano(true);
    const listaEstadoPlanoRemocao = getValues(`servicosCobertosPlanos.${index}.estados`);

    const estadoPlanoRemover = listaEstadoPlanoRemocao[posicao];

    removerEstadoPlano(estadoPlanoRemover).then(() => {
      remove(posicao);
      selecionarEstadoPlanoIndex(-1);
      selecionarEstadoPlano(DADOS_ESTADO_PLANO_DEFAULT);
      setPosicaoExclusao(-1);
      cancelarExclusao();
      setLoadingPlano(false);
    });
  }

  const states = [
    { value: 'AC', label: 'AC - Acre', codigoIBGE: '12' },
    { value: 'AL', label: 'AL - Alagoas', codigoIBGE: '27' },
    { value: 'AP', label: 'AP - Amapá', codigoIBGE: '16' },
    { value: 'AM', label: 'AM - Amazonas', codigoIBGE: '13' },
    { value: 'BA', label: 'BA - Bahia', codigoIBGE: '29' },
    { value: 'CE', label: 'CE - Ceará', codigoIBGE: '23' },
    { value: 'DF', label: 'DF - Distrito Federal', codigoIBGE: '53' },
    { value: 'ES', label: 'ES - Espírito Santo', codigoIBGE: '32' },
    { value: 'GO', label: 'GO - Goías', codigoIBGE: '52' },
    { value: 'MA', label: 'MA - Maranhão', codigoIBGE: '21' },
    { value: 'MT', label: 'MT - Mato Grosso', codigoIBGE: '51' },
    { value: 'MS', label: 'MS - Mato Grosso do Sul', codigoIBGE: '50' },
    { value: 'MG', label: 'MG - Minas Gerais', codigoIBGE: '31' },
    { value: 'PA', label: 'PA - Pará', codigoIBGE: '15' },
    { value: 'PB', label: 'PB - Paraíba', codigoIBGE: '25' },
    { value: 'PR', label: 'PR - Paraná', codigoIBGE: '41' },
    { value: 'PE', label: 'PE - Pernambuco', codigoIBGE: '26' },
    { value: 'PI', label: 'PI - Piauí', codigoIBGE: '22' },
    { value: 'RJ', label: 'RJ - Rio de Janeiro', codigoIBGE: '33' },
    { value: 'RN', label: 'RN - Rio Grande do Norte', codigoIBGE: '24' },
    { value: 'RS', label: 'RS - Rio Grande do Sul', codigoIBGE: '43' },
    { value: 'RO', label: 'RO - Rondônia', codigoIBGE: '11' },
    { value: 'RR', label: 'RR - Roraíma', codigoIBGE: '14' },
    { value: 'SC', label: 'SC - Santa Catarina', codigoIBGE: '42' },
    { value: 'SP', label: 'SP - São Paulo', codigoIBGE: '35' },
    { value: 'SE', label: 'SE - Sergipe', codigoIBGE: '28' },
    { value: 'TO', label: 'TO - Tocantins', codigoIBGE: '17' }
  ];

  function confirmarRemocaoEstado(index: number) {
    setConfirmandoExclusao(true);
    setPosicaoExclusao(index);
  }

  useEffect(() => {
    const atualizarListaField = getValues(name);
    replace(atualizarListaField);
    setSelectedIndex('');
  }, [index]);

  useEffect(() => {
    if (ref.current) {
      ref.current.value = '';
    }
  }, [fields.length]);

  const ref = useRef<HTMLInputElement>(null);
  return (
    <>
      <OptConfirmationDialog
        open={confirmandoExclusao}
        title="Excluir estado"
        icon={{ path: mdiDeleteOutline, color: Colors.red }}
        onCancel={cancelarExclusao}
        onClose={cancelarExclusao}
        onConfirm={() => {
          removerEstado(posicaoExclusao);
        }}
      >
        Deseja confirmar a exclusão do estado de abrangência?
      </OptConfirmationDialog>
      <Grid item xs={12}>
        <Box display="flex" flexDirection="column" gap={1}>
          <Typography textAlign="left" lineHeight={1} color={tema.light?.primary}>
            Estado abrangente
          </Typography>
          <FormControl fullWidth>
            <ProtectedContent mode="disable" access={MenuPlanoClaims.ATUALIZAR}>
              <Autocomplete
                fullWidth
                noOptionsText="Sem dados a exibir"
                options={states ? states : []}
                getOptionLabel={(option) => (typeof option === 'object' ? option.label : '')}
                onChange={(_event, newValue: any) => {
                  adicionarEstado(newValue);
                }}
                onInputChange={(_e, value) => debouncedChangeHandler(value)}
                renderInput={(params) => (
                  <>
                    <TextField
                      {...params}
                      size="small"
                      inputRef={ref}
                      //label="Estado abrangente"
                      placeholder="Selecione o estado"
                      variant="outlined"
                    />
                  </>
                )}
                freeSolo
              />
            </ProtectedContent>
          </FormControl>
        </Box>
      </Grid>
      {fields?.length > 0 && (
        <Grid
          item
          xs={12}
          sx={{
            border: `1px solid ${tema.light?.primary}`,
            borderColor: tema.light?.primary ? tema.light.primary : Colors.primary,
            borderRadius: '3px',
            marginTop: '10px',
            borderWidth: '2px'
          }}
        >
          <Box
            sx={{
              width: '100%',
              bgcolor: 'background.paper',
              maxHeight: '400px',
              overflow: 'auto'
            }}
          >
            <List
              component="nav"
              aria-label="secondary mailbox folder"
              aria-labelledby="nested-list-subheader"
              subheader={
                <ListSubheader
                  sx={{ backgroundColor: tema.light?.primary, color: '#fff' }}
                  component="div"
                  id="nested-list-subheader"
                >
                  Estado
                </ListSubheader>
              }
            >
              <Divider />
              {fields.map((value, index) => (
                <ProtectedContent key={value.id} mode="disable" access={MenuPlanoClaims.ATUALIZAR}>
                  <ListItemButton
                    key={index}
                    selected={selectedIndex === value.estadoId}
                    onClick={(event) => handleListItemClick(event, value, index)}
                  >
                    <ListItemText primary={value.nome} />
                    <IconButton
                      onClick={() => confirmarRemocaoEstado(index)}
                      title="Excluir estado abrangente"
                    >
                      <Icon path={mdiDeleteOutline} size={1} color="red" />
                    </IconButton>
                  </ListItemButton>
                </ProtectedContent>
              ))}
            </List>
          </Box>
          <OptBackdrop open={loadingPlano} />
        </Grid>
      )}
    </>
  );
};

export default EstadoAbrangenciaPlano;
