import { Grid } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';

import { CriarServicoCobertoModel } from '../../../models/ServicoCoberto/ServicoCoberto.model';
import { FormTextField } from '../../components/Form';

export const FormCriarServicoCoberto = () => {
  const { control } = useFormContext<CriarServicoCobertoModel>();

  return (
    <>
      <Grid container item rowSpacing={3} columnSpacing={3} pt={2.5} pb={2.5}>
        <Grid item xs={12}>
          <Controller
            name="nome"
            control={control}
            render={() => (
              <>
                <FormTextField name="nome" control={control} label="Nome" size="small" fullWidth />
              </>
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            name="descricao"
            control={control}
            render={() => (
              <>
                <FormTextField
                  name="descricao"
                  control={control}
                  label="Descrição"
                  size="small"
                  fullWidth
                />
              </>
            )}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default FormCriarServicoCoberto;
