import { yupResolver } from '@hookform/resolvers/yup';
import { CircularProgress } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { Colors } from '../../../core/colors';
import {
  CriarEmpresaModel,
  DADOS_CRIAR_EMPRESA_DEFAULT,
  dadosCriarEmpresaFormModelSchema
} from '../../../models/Empresa/Empresa.model';
import { RoutesObj } from '../../../routes/index.routes';
import { useEmpresaService } from '../../../service/empresa.service';
import ButtonModal from '../../components/Button/ButtonModal';

import FormCriarEmpresa from './FormCriarEmpresa';

const CadastroEmpresa = () => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { salvarEmpresa } = useEmpresaService();

  const form = useForm<CriarEmpresaModel>({
    mode: 'onBlur',
    defaultValues: DADOS_CRIAR_EMPRESA_DEFAULT,
    resolver: yupResolver(dadosCriarEmpresaFormModelSchema)
  });

  const onValid = async (data: CriarEmpresaModel) => {
    try {
      setLoadingButtomSave(true);
      const result = await salvarEmpresa(data);
      enqueueSnackbar('Registro salvo com sucesso!', {
        variant: 'success'
      });
      const { id } = result.data;
      navigate(RoutesObj.EmpresaAdmin.Detalhes(id));
    } finally {
      setLoadingButtomSave(false);
    }
  };
  const [loadingButtomSave, setLoadingButtomSave] = useState(false);

  return (
    <>
      <FormProvider {...form}>
        <FormCriarEmpresa />
        <ButtonModal
          size="large"
          tipo="confirm"
          $backgroundColor={Colors.primary}
          onClick={form.handleSubmit(onValid)}
          loadingIndicator={<CircularProgress color={'primary'} />}
          loading={loadingButtomSave}
        >
          Salvar
        </ButtonModal>
      </FormProvider>
    </>
  );
};

export default CadastroEmpresa;
