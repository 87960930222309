import { mdiCancel, mdiCheck, mdiFile, mdiFileDownload, mdiImport } from '@mdi/js';
import Icon from '@mdi/react';
import { OptBackdrop } from '@optsol/react';
import { useEffect, useState } from 'react';

import { Colors } from '../../../../core/colors';
import { useClienteService } from '../../../../service/cliente.service';
import { BotaoDeAcaoToolbar } from '../../../components/Button/BotaoDeAcaoToolbar';
import { FilePicker } from '../../../components/FilePicker/FilePicker';
import { Modal } from '../../../components/Modal/Modal';

interface Props {
  atualizarGrid: () => void;
}

export const ImportaClienteButton = ({ atualizarGrid }: Props) => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [importadoComSucesso, setImportadoComSucesso] = useState<boolean | undefined>(undefined);
  const toggleModal = () => {
    if (importadoComSucesso == true) atualizarGrid();
    setImportadoComSucesso(undefined);
    setOpen((prevState) => !prevState);
  };
  const { obterArquivoPadrao, importarArquivoClientes } = useClienteService();
  async function salvarArquivoHandler(file: File): Promise<void> {
    try {
      setLoading(true);
      await importarArquivoClientes(file);
      setImportadoComSucesso(true);
    } catch {
      setImportadoComSucesso(false);
    } finally {
      setLoading(false);
    }
  }
  async function baixarArquivoPadrao(): Promise<void> {
    try {
      setLoading(true);
      await obterArquivoPadrao();
    } finally {
      setLoading(false);
    }
  }
  useEffect(() => {
    setImportadoComSucesso(undefined);
  }, []);
  return (
    <>
      <BotaoDeAcaoToolbar
        // style={{ marginRight: '1em' }}
        onClick={toggleModal}
        texto="Importar cliente"
        startIcon={mdiImport}
      />
      <Modal
        open={open}
        onClose={toggleModal}
        title="Importação de clientes"
        width="600px"
        overflow="hidden"
      >
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            marginTop: '1em'
          }}
        >
          <FilePicker
            labelText="Selecionar arquivo"
            labelColor={Colors.primaryContrast}
            salvarArquivo={salvarArquivoHandler}
            acceptedFile={'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'}
          />
          <BotaoDeAcaoToolbar
            onClick={baixarArquivoPadrao}
            texto="Baixar arquivo padrão"
            startIcon={mdiFileDownload}
          />
        </div>
        {importadoComSucesso === undefined && !loading && (
          <p>Selecione um arquivo para importação!</p>
        )}
        {importadoComSucesso === true && (
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <Icon path={mdiCheck} size={1} color={Colors.green} />
            <p style={{ color: Colors.green }}>Importação relizada com sucesso!</p>
          </div>
        )}
        {importadoComSucesso === false && (
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <Icon path={mdiCancel} size={1} color={Colors.red} />
            <p style={{ color: Colors.red }}>Não foi possível realizar a importação!</p>
          </div>
        )}
        {loading && (
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <Icon path={mdiFile} size={1} color={Colors.blue} />
            <p style={{ color: Colors.blue }}>Importando arquivo...</p>
          </div>
        )}
        <OptBackdrop open={loading} />
      </Modal>
    </>
  );
};
