export enum PerfilAcessoEnum {
  AdministradorSistema = 'ADMIN',

  AtendenteTARM = 'TARM',

  MedicoRegulador = 'MEDRE',

  MedicoAmbulancia = 'MEDAM',

  RadioOperador = 'RADIO',

  Supervisor = 'SUPER',

  CondutorAmbulancias = 'CONDU',

  Enfermeiro = 'ENFER',

  TecnicoEnfermagem = 'TECNO',

  AtendenteTARMExterno = 'TAREX'
}
