import { Route, Routes as ReactRouterRoutes } from 'react-router-dom';

import { useAuthenticationContext } from '../app/contexts/authentication/authenticationContext';
import EditarServicoCoberto from '../app/features/servicoCoberto/EditarServicoCoberto';
import Home from '../app/pages/home/Home';
import ServicoCobertos from '../app/pages/servicoCoberto/ServicoCobertos';
import { MenuServicoCobertoClaims } from '../models/enums/AcessoClaims';

export const CoberturaRoutes = () => {
  const { hasAccess } = useAuthenticationContext();
  const enableRoute = hasAccess(MenuServicoCobertoClaims.VISUALIZAR);
  return (
    <ReactRouterRoutes>
      <Route path="/" element={enableRoute ? <ServicoCobertos /> : <Home />} />
      <Route
        path="/servico-coberto/:id"
        element={enableRoute ? <EditarServicoCoberto /> : <Home />}
      />
    </ReactRouterRoutes>
  );
};
