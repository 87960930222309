import { Box, InputLabel, StandardTextFieldProps, TextField, Typography } from '@mui/material';
import {
  Controller,
  ControllerProps,
  FieldError,
  FieldValues,
  get,
  useFormState
} from 'react-hook-form';

import { useAuthenticationContext } from '../../contexts/authentication/authenticationContext';

import { ErrorMessage } from './ErrorMessage';

export interface FormTextFieldProps<T extends FieldValues>
  extends Omit<ControllerProps<T>, 'render'>,
    Omit<StandardTextFieldProps, 'defaultValue' | 'name'> {
  errors?: FieldError | string;
}

export const FormTextField = <T extends FieldValues>(props: FormTextFieldProps<T>) => {
  const { label, control, errors: formErrors, name, ...inputProps } = props;
  const { errors } = useFormState({ control });
  const error = get(errors, name);
  const {
    state: { tema }
  } = useAuthenticationContext();

  return (
    <Box display="flex" flexDirection="column" gap={1}>
      <InputLabel>
        <Typography textAlign="left" color={tema.light?.primary} lineHeight={1}>
          {label}
        </Typography>
      </InputLabel>
      <Controller
        name={name}
        control={control}
        render={({ field: { onChange, value } }) => (
          <TextField
            {...inputProps}
            fullWidth
            size="small"
            value={value ?? ''}
            onChange={onChange}
            color={error ? 'error' : 'primary'}
          />
        )}
      />
      <ErrorMessage error={error ?? formErrors} />
    </Box>
  );
};
