import { yupResolver } from '@hookform/resolvers/yup';
import { CircularProgress } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { FieldErrors, FormProvider, useForm } from 'react-hook-form';

import { Colors } from '../../../../core/colors';
import {
  dadosCriarEnderecoFormModelSchema,
  DADOS_CRIAR_ENDERECO_DEFAULT,
  EnderecoModel
} from '../../../../models/Endereco/Endereco.model';
import { MenuClienteClaims } from '../../../../models/enums/AcessoClaims';
import ButtonModal from '../../../components/Button/ButtonModal';
import { ProtectedContent } from '../../../components/ProtectedContent/ProtectedContent';

import FormCriarEndereco from './FormCriarEndereco';

interface Props {
  salvarEndereco: (enderecoModel: EnderecoModel) => Promise<void>;
  excluirEndereco: (enderecoId: string) => Promise<void>;
  endereco: EnderecoModel | undefined;
  toggleEnderecoModal: () => void;
}

const CriarEndereco = ({ salvarEndereco, toggleEnderecoModal, endereco }: Props) => {
  const { enqueueSnackbar } = useSnackbar();
  const form = useForm<EnderecoModel>({
    mode: 'onBlur',
    defaultValues: endereco ?? DADOS_CRIAR_ENDERECO_DEFAULT,
    resolver: yupResolver(dadosCriarEnderecoFormModelSchema)
  });

  const onValid = async (data: EnderecoModel) => {
    try {
      setLoadingButtomSave(true);
      await salvarEndereco(data);
      enqueueSnackbar('Registro salvo com sucesso!', {
        variant: 'success'
      });
      toggleEnderecoModal();
    } finally {
      setLoadingButtomSave(false);
    }
  };

  const onInvalid = (errors: FieldErrors<EnderecoModel>) => {
    console.log(errors);
  };
  const [loadingButtomSave, setLoadingButtomSave] = useState(false);

  return (
    <>
      <FormProvider {...form}>
        <FormCriarEndereco />
        <ProtectedContent mode="disable" access={MenuClienteClaims.ATUALIZAR}>
          <ButtonModal
            size="large"
            tipo="confirm"
            $backgroundColor={Colors.primary}
            onClick={form.handleSubmit(onValid, onInvalid)}
            loadingIndicator={<CircularProgress color={'primary'} />}
            loading={loadingButtomSave}
          >
            Salvar
          </ButtonModal>
        </ProtectedContent>
      </FormProvider>
    </>
  );
};

export default CriarEndereco;
