import { Routes as ReactRoutes, Route } from 'react-router-dom';

import { FalhaUsuario } from '../app/components/FalhaUsuario';
import { useAuthenticationContext } from '../app/contexts/authentication/authenticationContext';
import Home from '../app/pages/home/Home';
import MapaAmbulancia from '../app/pages/mapa-ambulancia/MapaAmbulancia';
import { Relatorio } from '../app/pages/relatorios/relatorioAtendimento/RelatorioAtendimento';
import { withInternalTheme } from '../core/hoc/withInternalTheme';

import { AtendimentoRoutes } from './atendimento.routes';
import { ClienteRoutes } from './cliente.routes';
import { CoberturaRoutes } from './cobertura.routes';
import { EmpresaAdminRoutes } from './empresaAdmin.routes';
import { MotivoAtendimentoRoutes } from './motivoAtendimento.routes';
import { PlanoRoutes } from './plano.routes';
import { RadioOperadorRoutes } from './radioOperador.routes';
import { UsuarioRoutes } from './usuario.routes';
import { VeiculoRoutes } from './veiculo.routes';

export const RoutesObj = {
  Home: '/',
  Cobertura: {
    Coberturas: '/servicos-cobertos/*',
    CoberturasGrid: '/servicos-cobertos',
    Detalhes: (id = ':id') => `./servico-coberto/${id}`,
    Filtros: (filtro = ':filtro') => ({ Principal: filtro } as const)
  },
  Plano: {
    Principal: '/planos/*',
    Planos: '/planos',
    Detalhes: (id = ':id') => `./plano/${id}`,
    Filtros: (filtro = ':filtro') => ({ Principal: filtro } as const)
  } as const,
  Cliente: {
    Principal: '/clientes/*',
    Clientes: '/clientes',
    Detalhes: (id = ':id') => `./cliente/${id}`,
    Filtros: (filtro = ':filtro') => ({ Principal: filtro } as const)
  } as const,
  Veiculo: {
    Principal: '/veiculos/*',
    veiculos: '/veiculos',
    Detalhes: (id = ':id') => `./veiculo/${id}`,
    Filtros: (filtro = ':filtro') => ({ Principal: filtro } as const)
  },
  MotivoAtendimento: {
    Principal: '/motivo-atendimentos/*',
    MotivoAtendimentos: '/motivo-atendimentos',
    Detalhes: (id = ':id') => `./motivo-atendimento/${id}`,
    Filtros: (filtro = ':filtro') => ({ Principal: filtro } as const)
  } as const,
  Atendimento: {
    Principal: '/atendimentos/*',
    Atendimentos: '/atendimentos',
    AtendimentoTelefone: (telefone = ':telefone') => `./atendimento?telefone=${telefone}`,
    Detalhes: (id = ':id') => `./atendimento/${id}`,
    Filtros: (filtro = ':filtro') => ({ Principal: filtro } as const)
  } as const,
  Usuario: {
    Principal: '/usuarios/*',
    Usuarios: '/usuarios',
    Detalhes: (id = ':id') => `./usuario/${id}`,
    Filtros: (filtro = ':filtro') => ({ Principal: filtro } as const)
  } as const,
  EmpresaAdmin: {
    Principal: '/empresas-admin/*',
    Empresas: '/empresas-admin',
    Detalhes: (id = ':id') => `./empresa-admin/${id}`,
    Filtros: (filtro = ':filtro') => ({ Principal: filtro } as const)
  } as const,

  MedicoRegulador: {
    Principal: '/atendimentos-medico-regulador/*',
    Atendimentos: '/atendimentos-medico-regulador',
    Detalhes: (id = ':id') => `./medico-regulador/${id}`,
    Filtros: (filtro = ':filtro') => ({ Principal: filtro } as const)
  } as const,
  RadioOperador: {
    Principal: '/atendimentos-radio-operador/*',
    Atendimentos: '/atendimentos-radio-operador',
    Detalhes: (id = ':id') => `./radio-operador/${id}`,
    Filtros: (filtro = ':filtro') => ({ Principal: filtro } as const)
  } as const,
  SupervisaoRegulacao: {
    Principal: '/supervisao-regulacoes/*',
    SupervisaoRegulacoes: '/supervisao-regulacoes',
    Detalhes: (id = ':id') => `./supervisao-regulacao/${id}`,
    Filtros: (filtro = ':filtro') => ({ Principal: filtro } as const)
  } as const,
  MapaAmbulancia: {
    Principal: '/mapa-ambulancias/*',
    MapaAmbulancias: '/mapa-ambulancias'
  } as const,
  ErrorLogin: {
    Principal: '/error-login'
  } as const,
  Relatorio: {
    Principal: '/relatorio'
  } as const
} as const;
export const AppRoutes = () => {
  const {
    state: { temaSecundario }
  } = useAuthenticationContext();
  return (
    <>
      <ReactRoutes>
        <Route path={RoutesObj.Home} element={withInternalTheme(Home, temaSecundario)} />
        <Route
          path={RoutesObj.Cobertura.Coberturas}
          element={withInternalTheme(CoberturaRoutes, temaSecundario)}
        />
        <Route
          path={RoutesObj.Cobertura.CoberturasGrid}
          element={withInternalTheme(CoberturaRoutes, temaSecundario)}
        />
        <Route
          path={RoutesObj.Plano.Principal}
          element={withInternalTheme(PlanoRoutes, temaSecundario)}
        />
        <Route
          path={RoutesObj.Plano.Planos}
          element={withInternalTheme(PlanoRoutes, temaSecundario)}
        />
        <Route
          path={RoutesObj.Cliente.Principal}
          element={withInternalTheme(ClienteRoutes, temaSecundario)}
        />
        <Route
          path={RoutesObj.Veiculo.Principal}
          element={withInternalTheme(VeiculoRoutes, temaSecundario)}
        />
        <Route
          path={RoutesObj.MotivoAtendimento.Principal}
          element={withInternalTheme(MotivoAtendimentoRoutes, temaSecundario)}
        />
        <Route
          path={RoutesObj.Usuario.Principal}
          element={withInternalTheme(UsuarioRoutes, temaSecundario)}
        />
        <Route
          path={RoutesObj.EmpresaAdmin.Principal}
          element={withInternalTheme(EmpresaAdminRoutes, temaSecundario)}
        />
        <Route
          path={RoutesObj.Atendimento.Principal}
          element={withInternalTheme(AtendimentoRoutes, temaSecundario)}
        />
        <Route
          path={RoutesObj.RadioOperador.Principal}
          element={withInternalTheme(RadioOperadorRoutes, temaSecundario)}
        />
        <Route
          path={RoutesObj.MapaAmbulancia.Principal}
          element={withInternalTheme(MapaAmbulancia, temaSecundario)}
        />
        <Route path={RoutesObj.ErrorLogin.Principal} element={<FalhaUsuario />} />
        <Route
          path={RoutesObj.Relatorio.Principal}
          element={withInternalTheme(Relatorio, temaSecundario)}
        />
      </ReactRoutes>
    </>
  );
};
