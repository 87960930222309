import { useCallback, useMemo } from 'react';

import { ServicoCobertoSearchResponse } from '../models/dtos/servicoCobertoSearchResponse.model';
import {
  CriarServicoCobertoModel,
  ServicoCobertoModel
} from '../models/ServicoCoberto/ServicoCoberto.model';
import { useApi } from '../shared/hooks/api';
import { SearchRequest } from '../shared/utils/searchRequest';

export const useServicoCobertoService = () => {
  const { gridSearch, post, get, put } = useApi();

  const baseApi = useMemo(() => 'api/servico-coberto', []);

  const buscarGridServicoCobertoPaginated = useCallback(
    async (data: SearchRequest<object>) => {
      return gridSearch<ServicoCobertoSearchResponse>(`${baseApi}/paginated`, data);
    },
    [baseApi, gridSearch]
  );

  const salvarServicoCoberto = async (data: CriarServicoCobertoModel) => {
    return post<ServicoCobertoModel>(`${baseApi}`, data);
  };

  const obterServicoCobertoData = async (id: string) => {
    return get<ServicoCobertoModel>(`${baseApi}/${id}`);
  };

  const editarServicoCoberto = async (id: string, data: ServicoCobertoModel) => {
    return put(`${baseApi}/${id}`, data);
  };

  return {
    buscarGridServicoCobertoPaginated,
    salvarServicoCoberto,
    obterServicoCobertoData,
    editarServicoCoberto
  };
};
