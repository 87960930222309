import { mdiSearchWeb } from '@mdi/js';
import Icon from '@mdi/react';
import { Card, CardContent, Chip, Stack, Typography } from '@mui/material';
import { OptBackdrop } from '@optsol/react';
import { useState } from 'react';

import { Colors } from '../../../core/colors';
import { MotivoAtendimentoModel } from '../../../models/MotivoAtendimento/MotivoAtendimento.model';
import { InformacoesPlanoModel } from '../../../models/Plano/Plano.model';
import {
  DADOS_SERVICO_COBERTO_PLANO_DEFAULT,
  ServicoCobertoPlanoModel
} from '../../../models/Plano/ServicoCobertoPlano.model';
import { usePlanoService } from '../../../service/plano.service';

import * as S from './styles';

interface Props {
  plano: InformacoesPlanoModel;
  motivoAtendimento?: MotivoAtendimentoModel;
}

const InformacoesPlano = ({ plano, motivoAtendimento }: Props) => {
  const [areasAbrangencia, setAreasAbrangencia] = useState<ServicoCobertoPlanoModel>(
    DADOS_SERVICO_COBERTO_PLANO_DEFAULT
  );
  const { obterAreaAbrangenciaServicoCoberto } = usePlanoService();
  const [areaAbrangenciaIsLoading, setAreaAbrangenciaIsLoading] = useState<boolean>(false);

  async function obterAreaAbrangencia(id: string, servicoCobertoId: string) {
    try {
      setAreaAbrangenciaIsLoading(true);
      const result = await obterAreaAbrangenciaServicoCoberto(id, servicoCobertoId);
      setAreasAbrangencia(result.data);
    } finally {
      setAreaAbrangenciaIsLoading(false);
    }
  }
  return (
    <Card sx={{ backgroundColor: Colors.gray40p }}>
      <CardContent>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Typography variant="h5" component="div">
            {plano.nomePlano}
          </Typography>
          <Typography sx={{ mb: 1.5 }} color="text.secondary">
            &nbsp; {plano.numeroContrato && `(${plano.numeroContrato})`}
          </Typography>
        </div>
        {plano.servicosCobertos?.length > 0 && (
          <S.ChipInfoPlanoContent>
            <Stack direction="row" spacing={1} flexWrap={'wrap'}>
              {plano.servicosCobertos.map((servicoCoberto) => {
                return (
                  <Chip
                    icon={
                      <Icon
                        path={mdiSearchWeb}
                        size={1}
                        style={{ display: servicoCoberto.temAreaAbrangencia ? 'block' : 'none' }}
                      />
                    }
                    clickable
                    onClick={() => {
                      obterAreaAbrangencia(plano.id, servicoCoberto.id);
                    }}
                    key={servicoCoberto.id}
                    label={servicoCoberto.nome}
                    style={{
                      background: motivoAtendimento?.servicosCobertos?.some(
                        (x) => x.id === servicoCoberto.id
                      )
                        ? Colors.gray2
                        : Colors.gray4,
                      color: Colors.white,
                      borderRadius: '4px',
                      height: 25,
                      marginBottom: '0.4rem'
                    }}
                  />
                );
              })}
            </Stack>
            {areasAbrangencia.estados.length > 0 && (
              <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                Abrangência
              </Typography>
            )}
            <Stack direction="row" spacing={1} marginTop={1}>
              {areasAbrangencia.estados.map((estado, index) => {
                return (
                  <div key={estado.estadoId + index}>
                    {estado.cidades.length > 0 ? (
                      estado.cidades.map((cidade) => {
                        return (
                          <Chip
                            key={cidade.cidadeId}
                            label={`${estado.sigla} - ${cidade.nome}`}
                            style={{
                              background: Colors.blue1,
                              color: Colors.white
                            }}
                            sx={{
                              margin: '0 0.2em',
                              borderRadius: '4px',
                              height: 25
                            }}
                          />
                        );
                      })
                    ) : (
                      <Chip
                        key={estado.estadoId}
                        label={estado.nome}
                        style={{
                          background: Colors.blue1,
                          color: Colors.white
                        }}
                        sx={{
                          margin: '0 0.2em',
                          borderRadius: '4px',
                          height: 25
                        }}
                      />
                    )}
                  </div>
                );
              })}
            </Stack>
          </S.ChipInfoPlanoContent>
        )}
      </CardContent>
      <OptBackdrop open={areaAbrangenciaIsLoading} />
    </Card>
  );
};

export default InformacoesPlano;
