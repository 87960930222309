import { yupResolver } from '@hookform/resolvers/yup';
import { mdiClipboardTextClockOutline } from '@mdi/js';
import {
  OptActionToolbar,
  OptBackdrop,
  OptLoading,
  OptSideLayoutContent,
  OptTimeline,
  OptTimelineAction
} from '@optsol/react';
import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';

import { MenuVeiculoClaims } from '../../../models/enums/AcessoClaims';
import {
  DADOS_VEICULO_DEFAULT,
  dadosVeiculoFormModelSchema,
  VeiculoModel
} from '../../../models/Veiculo/Veiculo.model';
import { RoutesObj } from '../../../routes/index.routes';
import { useHistoricoService } from '../../../service/historico.service';
import { useVeiculoService } from '../../../service/veiculo.service';
import { BotaoDeAcaoToolbar } from '../../components/Button/BotaoDeAcaoToolbar';
import { Modal } from '../../components/Modal/Modal';
import { ProtectedContent } from '../../components/ProtectedContent/ProtectedContent';
import { ToolbarContainer } from '../../components/ToolbarContainer';
import { useAuthenticationContext } from '../../contexts/authentication/authenticationContext';

import FormEditarVeiculo from './FormEditarVeiculo';

const EditarVeiculo = () => {
  const [loadingVeiculo, setLoadingVeiculo] = useState<boolean>(false);
  const [modalHistorico, setModalHistorico] = useState<boolean>(false);
  const [timeLineData, setTimeLineData] = useState<OptTimelineAction[]>();
  const { id } = useParams<{ id: string }>();
  const { obterVeiculoData, editarVeiculo } = useVeiculoService();
  useState<VeiculoModel>(DADOS_VEICULO_DEFAULT);
  const { obterHistoryEntidade } = useHistoricoService();
  const { state } = useAuthenticationContext();

  const form = useForm<VeiculoModel>({
    mode: 'onBlur',
    defaultValues: DADOS_VEICULO_DEFAULT,
    resolver: yupResolver(dadosVeiculoFormModelSchema)
  });

  const editarVeiculoHandler = async () => {
    try {
      setLoadingVeiculo(true);
      const veiculoEditar = form.getValues();
      await editarVeiculo(id ?? '', veiculoEditar);
    } finally {
      setLoadingVeiculo(false);
    }
  };

  const carregar = async (id: string) => {
    setLoadingVeiculo(true);
    const result = await obterVeiculoData(id);
    form.reset(result.data ?? DADOS_VEICULO_DEFAULT);
    setLoadingVeiculo(false);
  };

  const toggleModal = async () => {
    setModalHistorico((prevState) => !prevState);
  };

  useEffect(() => {
    if (modalHistorico && !timeLineData) {
      const obter = async () => {
        try {
          const historyData = await obterHistoryEntidade(
            'Veiculo',
            state.userInfo.data?.tenantId ?? '', //"712A7D07-A57C-4F78-8F9F-66DDF61A44C0",
            id as string
          );

          const data = historyData.versions.sort((a, b) => (a.order > b.order ? -1 : 1));
          data.forEach((history: { dateTimeAction: string | number | Date }) => {
            const newDate = new Date(history.dateTimeAction);
            const dataFormatada = new Intl.DateTimeFormat('pt-BR', {
              year: 'numeric',
              month: '2-digit',
              day: '2-digit',
              hour: '2-digit',
              hourCycle: 'h23',
              minute: '2-digit'
            }).format(newDate);
            return (history.dateTimeAction = dataFormatada);
          });
          setTimeLineData(data);
        } catch {
          console.log('Erro ao tentar carregar timeline values');
          toggleModal();
        }
      };
      obter();
    } else {
      setTimeout(() => {
        setTimeLineData(undefined);
      }, 300);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalHistorico]);

  useEffect(() => {
    carregar(id ? id : '');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return (
    <>
      <OptSideLayoutContent>
        <ToolbarContainer>
          <OptActionToolbar
            goBackRoute={window.history.state.usr?.prevUrl ?? RoutesObj.Veiculo.veiculos}
            title="Editar veículo"
            color={state.tema.light?.primary}
            clearMargin
          >
            <ProtectedContent access={MenuVeiculoClaims.VISUALIZAR}>
              <BotaoDeAcaoToolbar
                onClick={toggleModal}
                texto="Histórico"
                startIcon={mdiClipboardTextClockOutline}
              />
            </ProtectedContent>
          </OptActionToolbar>
        </ToolbarContainer>
        <Modal open={modalHistorico} onClose={toggleModal} title="Histórico" width="800px">
          <div style={{ marginTop: '1em' }}>
            {timeLineData && (
              <OptTimeline
                data={timeLineData ?? []}
                valuesTableOptions={{
                  nameHeader: 'Name',
                  valueHeader: 'Value'
                }}
              />
            )}
            {!timeLineData && (
              <div style={{ height: '100px', overflow: 'none' }}>
                <OptLoading size={50} />
              </div>
            )}
          </div>
        </Modal>
        <FormProvider {...form}>
          <FormEditarVeiculo editarVeiculo={editarVeiculoHandler} />
        </FormProvider>
        <OptBackdrop open={loadingVeiculo} />
      </OptSideLayoutContent>
    </>
  );
};

export default EditarVeiculo;
