import { Button } from '@mui/material';
import React, { useState } from 'react';

import { Modal } from '../Modal/Modal';

import { Map } from './Map';
import { Marker } from './Marker';
import { FullLocationModel } from './model/FullLocation.model';

type Props = {
  open: boolean;
  position: google.maps.LatLngLiteral;
  onClose: (place?: google.maps.GeocoderResult, fullLocation?: FullLocationModel) => void;
};

export const MapModal = ({ open, position, onClose }: Props) => {
  const [place, setPlace] = useState<google.maps.GeocoderResult>();
  const [fullLocation, setFullLocation] = useState<FullLocationModel>();
  const [zoom, setZoom] = React.useState(15); // initial zoom

  const onIdle = (m: google.maps.Map) => {
    setZoom(m.getZoom() ?? 15);
    // setCenter(m.getCenter()!.toJSON());
  };

  const handleCloseModal = () => {
    onClose(place, fullLocation);
    setPlace(undefined);
  };

  return (
    <Modal open={open} onClose={handleCloseModal} title="Confirme sua localização" width="800px">
      <Map
        center={place?.geometry.location ?? position}
        onIdle={onIdle}
        zoom={zoom}
        style={{ minHeight: '500px' }}
      >
        <Marker
          position={place?.geometry.location ?? position}
          onPlaceChange={(place: google.maps.GeocoderResult, fullLocation: FullLocationModel) => {
            setPlace(place);
            setFullLocation(fullLocation);
          }}
        />
      </Map>
      <div style={{ display: 'flex', justifyContent: 'right' }}>
        <Button variant="contained" onClick={handleCloseModal}>
          Confirmar
        </Button>
      </div>
    </Modal>
  );
};
