import { OptTheme } from '@optsol/react';

import { UserInfo } from '../../../shared/msal/UserInfo';
import { GenericContext } from '../genericContext';

import { AuthenticationActions } from './authenticationActions';
import { AuthenticationState } from './authenticationState';

export type AuthenticationDispatch = (action: AuthenticationAction) => void;
export const AUTHENTICATION_INITIAL_DISPATCH = (action: AuthenticationAction): void => {
  throw Error('Dispatch not implemented. Action: ' + action);
};

export function AuthenticationReducer(
  state: AuthenticationState,
  action: AuthenticationAction
): AuthenticationState {
  // if (!action.payload) throw new Error(`Ação não identificada: ${(action as any).type}`);

  switch (action.type) {
    case AuthenticationActions.LOADING_USER_INFO: {
      return {
        ...state,
        userInfo: { ...state.userInfo, loading: !!action.payload }
      };
    }
    case AuthenticationActions.CARREGAR_USER_INFO: {
      return {
        ...state,
        userInfo: { data: action.payload || undefined, loading: false }
      };
    }
    case AuthenticationActions.DEFINIR_TENANT: {
      return { ...state, tenantId: action.payload };
    }
    case AuthenticationActions.DEFINIR_TOKEN: {
      return { ...state, token: action.payload };
    }
    case AuthenticationActions.DEFINIR_HISTORY_TOKEN: {
      return { ...state, historyToken: action.payload };
    }
    case AuthenticationActions.DEFINIR_LOCAL_ID: {
      return { ...state, localId: action.payload };
    }
    case AuthenticationActions.DEFINIR_TEMA: {
      return { ...state, tema: action.payload! };
    }
    case AuthenticationActions.DEFINIR_TEMA_SECUNDARIO: {
      return { ...state, temaSecundario: action.payload! };
    }
    default: {
      // eslint-disable-next-line
      throw new Error(`Ação não identificada: ${(action as any).type}`);
    }
  }
}

type AuthenticationAction =
  | GenericContext<AuthenticationActions.CARREGAR_USER_INFO, UserInfo | undefined>
  | GenericContext<AuthenticationActions.LOADING_USER_INFO, boolean>
  | GenericContext<AuthenticationActions.DEFINIR_TENANT, string>
  | GenericContext<AuthenticationActions.DEFINIR_TOKEN, string | undefined>
  | GenericContext<AuthenticationActions.DEFINIR_HISTORY_TOKEN, string | undefined>
  | GenericContext<AuthenticationActions.DEFINIR_LOCAL_ID, string | undefined>
  | GenericContext<AuthenticationActions.DEFINIR_TEMA, OptTheme>
  | GenericContext<AuthenticationActions.DEFINIR_TEMA_SECUNDARIO, OptTheme>;
