import { FormControlLabel, Grid, MenuItem, SelectChangeEvent, Switch } from '@mui/material';
import { useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { MenuVeiculoClaims } from '../../../models/enums/AcessoClaims';
import { TipoRastreamentoEnum } from '../../../models/enums/TipoRastreamento';
import { ListaTipoVeiculoEnum } from '../../../models/enums/TipoVeiculo';
import { IdsRastreioViewModel, VeiculoModel } from '../../../models/Veiculo/Veiculo.model';
import { useVeiculoService } from '../../../service/veiculo.service';
import { FormSelect, FormTextField } from '../../components/Form';
import { TituloForm } from '../../components/Form/TituloForm';
import { ProtectedContent } from '../../components/ProtectedContent/ProtectedContent';
interface Props {
  editarVeiculo: () => void;
}

export const FormEditarVeiculo = ({ editarVeiculo }: Props) => {
  const {
    control,
    watch,
    setValue,
    getValues,
    formState: { isDirty }
  } = useFormContext<VeiculoModel>();

  const rastreavel = watch('rastreavel');
  const tipoRastreamento = watch('tipoRastreamento');
  const [listaIdRastreio, setListaIdRastreio] = useState<IdsRastreioViewModel[]>([]);
  const { obterIdsRastreio } = useVeiculoService();

  function editarTipoRastreamento(value: any) {
    editarVeiculo();
    if (value === 0) {
      consultarIdRastreio();
    }
  }

  function editarRastreamento() {
    const value = getValues().rastreavel;
    if (!value) {
      setValue('tipoRastreamento', null);
      setValue('idRastreio', 0);
      setListaIdRastreio([]);
    }

    editarVeiculo();
  }

  async function consultarIdRastreio() {
    const result = await obterIdsRastreio();
    setListaIdRastreio(result.data);
  }

  useEffect(() => {
    if (rastreavel && tipoRastreamento === TipoRastreamentoEnum.Omnicomm) {
      consultarIdRastreio();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rastreavel]);

  return (
    <>
      <Grid container item rowSpacing={3} columnSpacing={3} px={2.5} pt={2.5}>
        <Grid item xs={12}>
          <TituloForm texto="Dados do veículo" />
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <Controller
            name="tipo"
            control={control}
            render={({ field: { onChange, value } }) => (
              <>
                <ProtectedContent mode="disable" access={MenuVeiculoClaims.ATUALIZAR}>
                  <FormSelect
                    onChange={(event: SelectChangeEvent<unknown>) => {
                      onChange(event);
                      editarVeiculo();
                    }}
                    value={value ?? -1}
                    name="tipo"
                    size="small"
                    label="Tipo veículo"
                  >
                    {ListaTipoVeiculoEnum.map((x) => {
                      return (
                        <MenuItem key={x.id} value={x.id}>
                          {x.descricao}
                        </MenuItem>
                      );
                    })}
                  </FormSelect>
                </ProtectedContent>
              </>
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <Controller
            name="prefixo"
            control={control}
            render={({ field: { onBlur } }) => (
              <>
                <ProtectedContent mode="disable" access={MenuVeiculoClaims.ATUALIZAR}>
                  <FormTextField
                    name="prefixo"
                    control={control}
                    label="Prefixo"
                    size="small"
                    fullWidth
                    onBlur={() => {
                      isDirty ? editarVeiculo() : onBlur();
                    }}
                  />
                </ProtectedContent>
              </>
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <Controller
            name="placa"
            control={control}
            render={({ field: { onBlur } }) => (
              <>
                <ProtectedContent mode="disable" access={MenuVeiculoClaims.ATUALIZAR}>
                  <FormTextField
                    name="placa"
                    control={control}
                    label="Placa"
                    size="small"
                    fullWidth
                    onBlur={() => {
                      isDirty ? editarVeiculo() : onBlur();
                    }}
                  />
                </ProtectedContent>
              </>
            )}
          />
        </Grid>

        <Grid item xs={12} sm={6} md={6}>
          <Controller
            name="chassi"
            control={control}
            render={({ field: { onBlur } }) => (
              <>
                <ProtectedContent mode="disable" access={MenuVeiculoClaims.ATUALIZAR}>
                  <FormTextField
                    name="chassi"
                    control={control}
                    label="Chassi"
                    size="small"
                    fullWidth
                    onBlur={() => {
                      isDirty ? editarVeiculo() : onBlur();
                    }}
                  />
                </ProtectedContent>
              </>
            )}
          />
        </Grid>
        <Grid item xs={6} sm={6} md={6}>
          <Controller
            name="renavam"
            control={control}
            render={({ field: { onBlur } }) => (
              <>
                <ProtectedContent mode="disable" access={MenuVeiculoClaims.ATUALIZAR}>
                  <FormTextField
                    name="renavam"
                    control={control}
                    label="Renavam"
                    size="small"
                    fullWidth
                    onBlur={() => {
                      isDirty ? editarVeiculo() : onBlur();
                    }}
                  />
                </ProtectedContent>
              </>
            )}
          />
        </Grid>
        {rastreavel && (
          <Grid item xs={3} sm={3} md={3}>
            <Controller
              name="tipoRastreamento"
              control={control}
              render={({ field: { onChange, value } }) => (
                <>
                  <ProtectedContent mode="disable" access={MenuVeiculoClaims.ATUALIZAR}>
                    <FormSelect
                      onChange={(event: SelectChangeEvent<unknown>) => {
                        onChange(event);
                        editarTipoRastreamento(event.target.value);
                      }}
                      value={value ?? ''}
                      size="small"
                      name="tipoRastreamento"
                      label="Tipo rastreamento"
                    >
                      <MenuItem key={0} value={0}>
                        Omnicomm
                      </MenuItem>
                    </FormSelect>
                  </ProtectedContent>
                </>
              )}
            />
          </Grid>
        )}
        {rastreavel && (
          <Grid item xs={3} sm={3} md={3}>
            <Controller
              name="idRastreio"
              control={control}
              render={({ field: { onChange, value } }) => (
                <>
                  <ProtectedContent mode="disable" access={MenuVeiculoClaims.ATUALIZAR}>
                    <FormSelect
                      onChange={(event: SelectChangeEvent<unknown>) => {
                        onChange(event);
                        editarVeiculo();
                      }}
                      value={value ?? ''}
                      size="small"
                      name="idRastreio"
                      label="Id rastreio"
                      displayEmpty
                      renderValue={() => (!listaIdRastreio.length ? <>Carregando...</> : value)}
                      disabled={listaIdRastreio.length === 0}
                    >
                      {listaIdRastreio.map((x, index) => {
                        return (
                          <MenuItem key={index} value={x.id}>
                            {x.id}
                          </MenuItem>
                        );
                      })}
                    </FormSelect>
                  </ProtectedContent>
                </>
              )}
            />
          </Grid>
        )}
        {!rastreavel ? <Grid item xs={6}></Grid> : null}

        <Grid item xs={4} display="flex" gap={5}>
          <Controller
            name="rastreavel"
            control={control}
            render={({ field: { onChange, value } }) => (
              <FormControlLabel
                label="Rastreável?"
                control={
                  <ProtectedContent mode="disable" access={MenuVeiculoClaims.ATUALIZAR}>
                    <Switch onChange={onChange} checked={value} onBlur={editarRastreamento} />
                  </ProtectedContent>
                }
              />
            )}
          />
          <Controller
            name="situacao"
            control={control}
            render={({ field: { onChange, value } }) => (
              <FormControlLabel
                label={'Ativo'}
                control={
                  <ProtectedContent mode="disable" access={MenuVeiculoClaims.ATUALIZAR}>
                    <Switch onChange={onChange} checked={value} onBlur={editarVeiculo} />
                  </ProtectedContent>
                }
              />
            )}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default FormEditarVeiculo;
