import { Theme } from '@nivo/core';

export const chartTheme: Theme = {
  labels: {
    text: {
      fontSize: '16px',
      fontWeight: 600
    }
  },
  legends: {
    text: {
      fontSize: '13px',
      fontWeight: '530'
    },
    title: {
      text: {
        fontSize: '20px'
      }
    }
  }
  // tooltip: {
  //   container: {
  //     marginRight: '130px',
  //   },
  // },
};

export const barChartTheme: Theme = {
  labels: {
    text: {
      fontSize: '14px',
      fontWeight: 600
    }
  },
  legends: {
    text: {
      fontSize: '12.5px',
      color: '#A3AED0',
      fontWeight: '500',
      lineHeight: '20px'
    }
  },
  tooltip: {
    container: {
      marginBottom: '-40px'
    }
  }
};

export const AtendimentoMrTheme: Theme = {
  labels: {
    text: {
      fontSize: '13px',
      fontWeight: 600
    }
  },
  axis: {
    ticks: {
      text: {
        fontSize: '0.9rem',
        fontWeight: '500',
        lineHeight: '20px',
        letterSpacing: '-0.24px',
        color: '#454854'
      }
    }
  },
  legends: {
    text: {
      fontSize: '12.5px'
    }
  },
  tooltip: {
    container: {
      width: 'fit-content',
      marginBottom: '-80%',
      marginLeft: '-60%'
    }
  }
};
