import {
  mdiAccountArrowRight,
  mdiCarEmergency,
  mdiCarInfo,
  mdiGarage,
  mdiMapMarker,
  mdiOfficeBuildingMarkerOutline
} from '@mdi/js';
import { useEffect, useState } from 'react';

import { Colors } from '../../../core/colors';
import {
  LocalizacoesModel,
  LocalizacoesPorPacienteModel
} from '../../../models/Mapa/Localizacoes.model';
import { Map } from '../Google/Map';
import { MarkerMapa } from '../Google/MarkerMapa';

type Props = {
  position: google.maps.LatLngLiteral;
  posicoesMapa?: LocalizacoesModel;
  posicoesPorPaciente?: LocalizacoesPorPacienteModel;
  $minHeight?: string;
};

export const MapaComponent = ({
  position,
  posicoesMapa,
  posicoesPorPaciente,
  $minHeight
}: Props) => {
  const zoom = 15;
  const [newPosicoesMapa, setNewPosicoesMapa] = useState<LocalizacoesModel | undefined>(
    posicoesMapa
  );

  useEffect(() => {
    if (posicoesMapa) {
      const newValuePosicoes = { ...posicoesMapa };
      setNewPosicoesMapa(newValuePosicoes);
    }
  }, [posicoesMapa]);
  return (
    <Map center={position} zoom={zoom} style={{ minHeight: $minHeight ?? '81vh' }}>
      {newPosicoesMapa?.geolocalizacoesVeiculos?.map((x, index) => (
        <MarkerMapa
          tooltipModelTexto={x.infoTooltip}
          position={x}
          key={index}
          title={x.prefixo}
          icon={{
            path: x.semAtualizarMuitoTempo ? mdiCarInfo : mdiCarEmergency,
            fillColor: x.colorVeiculo,
            fillOpacity: 1,
            rotation: 0,
            scale: 1.5,
            anchor: new google.maps.Point(18, 25)
          }}
          onPlaceChange={() => {}}
        />
      ))}

      {newPosicoesMapa?.geolocalizacoesEmpresa?.map((x, index) => (
        <MarkerMapa
          position={x}
          key={index}
          title={x.nomeLocal}
          icon={{
            path: x.tipo === 1 ? mdiOfficeBuildingMarkerOutline : mdiGarage,
            fillColor: Colors.darkOrange,
            fillOpacity: 1,
            rotation: 0,
            scale: 1.5,
            anchor: new google.maps.Point(18, 25)
          }}
          onPlaceChange={() => {}}
        />
      ))}

      {posicoesPorPaciente?.geolocalizacoesVeiculos?.map((x, index) => (
        <MarkerMapa
          tooltipModelTexto={x.infoTooltip}
          position={x}
          key={index}
          title={x.prefixo}
          icon={{
            path: x.semAtualizarMuitoTempo ? mdiCarInfo : mdiCarEmergency,
            fillColor: x.colorVeiculo,
            fillOpacity: 1,
            rotation: 0,
            scale: 1.5,
            anchor: new google.maps.Point(18, 25)
          }}
          onPlaceChange={() => {}}
        />
      ))}

      {posicoesPorPaciente?.enderecoOrigem ? (
        <MarkerMapa
          position={{
            ...posicoesPorPaciente.enderecoOrigem.enderecoGeolocalizacao,
            lat: posicoesPorPaciente.enderecoOrigem.enderecoGeolocalizacao.latitude,
            lng: posicoesPorPaciente.enderecoOrigem.enderecoGeolocalizacao.longitude
          }}
          title={posicoesPorPaciente.enderecoOrigem.enderecoGeolocalizacao.descricaoLocal}
          icon={{
            path: mdiAccountArrowRight,
            fillColor: Colors.blue2,
            fillOpacity: 1,
            rotation: 0,
            scale: 1.5,
            anchor: new google.maps.Point(18, 25)
          }}
          onPlaceChange={() => {}}
        />
      ) : (
        <></>
      )}

      {posicoesPorPaciente?.enderecoDestino ? (
        <MarkerMapa
          position={{
            ...posicoesPorPaciente.enderecoDestino.enderecoGeolocalizacao,
            lat: posicoesPorPaciente.enderecoDestino.enderecoGeolocalizacao.latitude,
            lng: posicoesPorPaciente.enderecoDestino.enderecoGeolocalizacao.longitude
          }}
          title={posicoesPorPaciente.enderecoDestino.enderecoGeolocalizacao.descricaoLocal}
          icon={{
            path: mdiMapMarker,
            fillColor: Colors.darkOrange,
            fillOpacity: 1,
            rotation: 0,
            scale: 1.5,
            anchor: new google.maps.Point(18, 25)
          }}
          onPlaceChange={() => {}}
        />
      ) : (
        <></>
      )}
    </Map>
  );
};
export default MapaComponent;
