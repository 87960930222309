import {
  OptGrid,
  OptGridColumn,
  OptGridOptions,
  OptGridRef,
  OptGridRequest,
  OptGridResponse,
  OptSideLayoutContent
} from '@optsol/react';
import { forwardRef } from 'react';

import { Colors } from '../../../core/colors';
import { MotivoAtendimentoSearchResponse } from '../../../models/dtos/MotivoAtendimento/MotivoAtendimentoSearchResponse.model';
import { getHeaderTheme, obterFiltroDaRota } from '../../../shared/utils/functions';
import { ChipStatus } from '../../components/StatusText/ChipStatus';
import { useAuthenticationContext } from '../../contexts/authentication/authenticationContext';
interface GridProps {
  carregar: (data: OptGridRequest) => Promise<OptGridResponse<MotivoAtendimentoSearchResponse>>;
  editarMotivoAtendimento(motivoAtendimentoId: string): Promise<void>;
}

const options: OptGridOptions = {
  search: true,
  pageSize: 10
};

const GridMotivoAtendimentos = forwardRef<OptGridRef | undefined, GridProps>(
  ({ carregar, editarMotivoAtendimento }, ref) => {
    const {
      state: { tema }
    } = useAuthenticationContext();

    const defaultPageSize = obterFiltroDaRota('pageSize');
    const defaultPage = obterFiltroDaRota('page');
    const columns: OptGridColumn<MotivoAtendimentoSearchResponse>[] = [
      {
        title: 'Id',
        field: 'motivoAtendimentoId',
        hidden: true
      },

      {
        title: 'Nome',
        field: 'nome',
        align: 'start',
        render: (props: MotivoAtendimentoSearchResponse) => {
          return <>{props.nome ? props.nome : '-'}</>;
        }
      },
      {
        title: 'Descrição',
        field: 'descricao',
        align: 'start',
        render: (props: MotivoAtendimentoSearchResponse) => {
          return <>{props.descricao ? props.descricao : '-'}</>;
        }
      },
      {
        title: 'Situação  ',
        field: 'situacao',
        align: 'start',
        render: (props: MotivoAtendimentoSearchResponse) => {
          return <ChipStatus status={props.situacao} />;
        }
      }
    ];
    return (
      <OptSideLayoutContent>
        <OptGrid
          ref={ref as any}
          headerStyle={getHeaderTheme(tema.light?.primary ? tema.light.primary : Colors.gray10)}
          data={carregar}
          options={{
            ...options,
            pageIndex: defaultPage !== null ? parseInt(defaultPage) : 0,
            pageSize: defaultPageSize !== null ? parseInt(defaultPageSize) : 10
          }}
          columns={columns}
          onRowClick={(rowData) => {
            editarMotivoAtendimento(rowData.motivoAtendimentoId);
          }}
        />
      </OptSideLayoutContent>
    );
  }
);

GridMotivoAtendimentos.displayName = 'GridMotivoAtendimentos';
export default GridMotivoAtendimentos;
