import { AxiosRequestConfig } from 'axios';
import { useCallback, useEffect, useRef, useState } from 'react';

import { APIPaginatedRequest } from '../../models/APIPaginatedRequest';
import { APIPaginatedResponse } from '../../models/APIPaginatedResponse';

export interface InfiniteScrollRequest {
  page: number;
  search: string;
  orderBy: string;
  pageSize: number;
  referenceDate: Date | null;
  orderDirection: 'asc' | 'desc';
}

export interface InfiniteScrollResponse<T> {
  items: T[];
  page: number;
  totalPages: number;
  total: number;
  newItems: number | T[];
  referenceDate: Date | null;
}

export type InifiniteScrollProps<T extends object, R extends object> = AxiosRequestConfig & {
  load: (request: APIPaginatedRequest<R>, signal?: AbortSignal) => Promise<APIPaginatedResponse<T>>;
  search?: R | Record<string, never>;
  orderBy?: keyof T | '';
  pageSize?: number;
  firstPage?: number;
  orderDirection?: 'asc' | 'desc';
};

const ROOT_MARGIN = '100px';

export default function useInfiniteScroll<T extends object, R extends object>({
  load,
  search,
  firstPage = 0,
  pageSize = 50
}: InifiniteScrollProps<T, R>) {
  // States
  const [error, setError] = useState(false);
  const [hasNext, setHasNext] = useState(true);
  const [loading, setLoading] = useState(true);
  const [items, setItems] = useState<Array<T>>([]);
  const [shouldUpdate, setShouldUpdate] = useState(false);
  const [paginaSelecionada, setPaginaSelecionada] = useState(firstPage);

  // Refs
  const loadFunction = useRef(load);
  const observer = useRef<IntersectionObserver>();
  const lastElementRef = (node: HTMLElement | null) => {
    if (loading) return;
    if (observer.current) observer.current.disconnect();

    observer.current = new IntersectionObserver(
      (entries) => {
        const [lastElement] = entries;
        if (lastElement.isIntersecting) setPaginaSelecionada((prev) => prev + 1);
      },
      { rootMargin: ROOT_MARGIN }
    );
    if (node) observer.current.observe(node);
  };

  // Functions
  const buscar = useCallback(
    async (signal: AbortSignal) => {
      setError(false);
      setLoading(true);
      const request = {
        page: paginaSelecionada,
        pageSize: pageSize,
        search: search ? search : {}
      };

      loadFunction.current
        .call({}, request, signal)
        .then((response) => {
          if (response) {
            // console.log('page', response.page);
            // console.log('total', response.total);
            // console.log('pageSize', response.pageSize);
            // console.log('conta', response.page < response.total / response.pageSize);
            setHasNext(response.page + 1 < response.total / response.pageSize);
            setItems((prev) => prev.concat(response.data));
            setLoading(false);
          }
        })
        .catch(() => {
          setError(true);
          setLoading(false);
        });
    },
    [paginaSelecionada, pageSize, search]
  );

  const refresh = useCallback(() => {
    setItems([]);
    setShouldUpdate(true);
    setPaginaSelecionada(firstPage);
  }, [firstPage]);

  // Effects
  useEffect(() => {
    setItems([]);
    setPaginaSelecionada(firstPage);
  }, [firstPage, search]);

  useEffect(() => {
    const controller = new AbortController();
    buscar(controller.signal);

    return () => {
      setShouldUpdate(false);
      controller.abort();
    };
  }, [buscar, shouldUpdate]);

  return {
    error,
    items,
    hasNext,
    loading,
    refresh,
    lastElementRef
  };
}
