import * as yup from 'yup';

import { CidadeModel, dadosCidadeFormModelSchema } from '../Cidade/Cidade.model';

export interface EstadoModel {
  estadoId: string;
  servicoCobertoPlanoId: string;
  planoId: string;
  nome: string;
  sigla: string;
  codigoIBGE: string;
  cidades: CidadeModel[];
}

export const DADOS_ESTADO_PLANO_DEFAULT: EstadoModel = {
  estadoId: '',
  servicoCobertoPlanoId: '',
  planoId: '',
  nome: '',
  sigla: '',
  codigoIBGE: '',
  cidades: []
};

const MSG_REQUIRED = 'Campo obrigatório';

export const dadosEstadoPlanoFormModelSchema: yup.SchemaOf<EstadoModel> = yup.object().shape({
  estadoId: yup.string().required(MSG_REQUIRED),
  servicoCobertoPlanoId: yup.string().required(MSG_REQUIRED),
  planoId: yup.string().required(MSG_REQUIRED),
  nome: yup.string().required(MSG_REQUIRED),
  sigla: yup.string().required(MSG_REQUIRED),
  codigoIBGE: yup.string().required(MSG_REQUIRED),
  cidades: yup.array().of(dadosCidadeFormModelSchema).required()
});
