import { mdiSend } from '@mdi/js';
import Icon from '@mdi/react';
import { IconButton, Tooltip } from '@mui/material';
import { OptConfirmationDialog } from '@optsol/react';
import { useEffect, useState } from 'react';

import { Colors } from '../../../../core/colors';
import { InformacaoPacienteFormModel } from '../../../../models/Atendimento/Paciente/InformacaoPaciente.model';
import { EncaminharRequest } from '../../../../models/dtos/Atendimento/encaminharRequest.mode';
import { MenuAtendimentoClaims } from '../../../../models/enums/AcessoClaims';
import { FasesAtendimentoEnum } from '../../../../models/enums/FasesAtendimento';
import { PerfilAcessoEnum } from '../../../../models/enums/PerfilAcesso';
import FormEncaminharUsuario from '../../../components/EncaminharUsuario/FormEncaminharUsuario';
import { Modal } from '../../../components/Modal/Modal';
import { useAuthenticationContext } from '../../../contexts/authentication/authenticationContext';

interface Props {
  paciente: InformacaoPacienteFormModel;
  encaminharPaciente: (
    request: EncaminharRequest,
    faseAtendimento: FasesAtendimentoEnum | null
  ) => Promise<void>;
}

export const EncaminharPacienteButton = ({ paciente, encaminharPaciente }: Props) => {
  const { hasAccess } = useAuthenticationContext();
  const [label, setLabel] = useState('');
  const [perfilAcessoParaEncaminhar, setPerfilAcessoParaEncaminhar] = useState<PerfilAcessoEnum>();
  const [encaminharIsOpen, setEncaminharIsOpen] = useState<boolean>(false);
  const [confirmeEncaminharIsOpen, setConfirmeEncaminharIsOpen] = useState<boolean>(false);
  const toggleEncaminharModal = () => {
    setEncaminharIsOpen((prevState) => !prevState);
  };

  const toggleConfirmeEncaminharModal = () => {
    setConfirmeEncaminharIsOpen((prevState) => !prevState);
  };

  async function encaminhar(medicoId: string | null) {
    const request: EncaminharRequest = {
      atendimentoId: paciente.atendimentoId ?? '',
      usuarioId: medicoId,
      pacienteId: paciente.pacienteId ?? ''
    };
    await encaminharPaciente(request, paciente.faseAtendimento);
    if (encaminharIsOpen) toggleEncaminharModal();
  }

  useEffect(() => {
    switch (paciente.faseAtendimento) {
      case FasesAtendimentoEnum.FASECRIACAO:
        setLabel('Encaminhar para fase regulação');
        setPerfilAcessoParaEncaminhar(PerfilAcessoEnum.MedicoRegulador);
        break;
      case FasesAtendimentoEnum.FASEREGULACAO:
        setLabel('Encaminhar para fase empenho');
        setPerfilAcessoParaEncaminhar(PerfilAcessoEnum.RadioOperador);
        break;

      default:
        setLabel('Perfil inválido');
        break;
    }
  }, []);

  function encaminharActionHandler() {
    if (hasAccess(MenuAtendimentoClaims.INSERIR_ATENDIMENTO_USUARIO_EXTERNO)) {
      toggleConfirmeEncaminharModal();
    } else {
      toggleEncaminharModal();
    }
  }

  return (
    <>
      <OptConfirmationDialog
        open={confirmeEncaminharIsOpen}
        title="Encaminhar chamado"
        icon={{ path: mdiSend, color: Colors.blue1 }}
        onCancel={toggleConfirmeEncaminharModal}
        onClose={toggleConfirmeEncaminharModal}
        onConfirm={() => {
          toggleConfirmeEncaminharModal();
          encaminhar(null);
        }}
      >
        Deseja encaminhar esse chamado?
      </OptConfirmationDialog>
      <Tooltip title={label} placement="top">
        <IconButton
          color="info"
          onClick={(e) => {
            e.stopPropagation();
            encaminharActionHandler();
          }}
        >
          <Icon path={mdiSend} size={1} color={Colors.blue} />
        </IconButton>
      </Tooltip>

      <Modal
        open={encaminharIsOpen}
        onClose={() => {
          toggleEncaminharModal();
        }}
        title="Encaminhamento"
        width="600px"
      >
        <FormEncaminharUsuario
          perfil={perfilAcessoParaEncaminhar}
          encaminharChamado={encaminhar}
          pacienteId={paciente.pacienteId ?? ''}
        />
      </Modal>
    </>
  );
};
