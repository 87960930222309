import { yupResolver } from '@hookform/resolvers/yup';
import { mdiClipboardTextClockOutline } from '@mdi/js';
import {
  OptActionToolbar,
  OptBackdrop,
  OptLoading,
  OptSideLayoutContent,
  OptTimeline,
  OptTimelineAction
} from '@optsol/react';
import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';

import { EnderecoModel } from '../../../models/Endereco/Endereco.model';
import { MenuMotivoAtendimentoClaims } from '../../../models/enums/AcessoClaims';
import { ExcluirEnderecoDestinoMotivoRequest } from '../../../models/MotivoAtendimento/EnderecoDestinoMotivo.mode';
import {
  DADOS_MOTIVO_ATENDIMENTO_DEFAULT,
  dadosMotivoAtendimentoFormModelSchema,
  MotivoAtendimentoModel
} from '../../../models/MotivoAtendimento/MotivoAtendimento.model';
import { RoutesObj } from '../../../routes/index.routes';
import { useHistoricoService } from '../../../service/historico.service';
import { useMotivoAtendimentoService } from '../../../service/motivoAtendimento.service';
import { BotaoDeAcaoToolbar } from '../../components/Button/BotaoDeAcaoToolbar';
import { Modal } from '../../components/Modal/Modal';
import { ProtectedContent } from '../../components/ProtectedContent/ProtectedContent';
import { ToolbarContainer } from '../../components/ToolbarContainer';
import { useAuthenticationContext } from '../../contexts/authentication/authenticationContext';

import FormEditarMotivoAtendimento from './FormEditarMotivoAtendimento';

const EditarMotivoAtendimento = () => {
  const [modalHistorico, setModalHistorico] = useState<boolean>(false);
  const [timeLineData, setTimeLineData] = useState<OptTimelineAction[]>();
  const [loadingMotivoAtendimento, setLoadingMotivoAtendimento] = useState<boolean>(false);
  const { id } = useParams<{ id: string }>();

  const { obterMotivoAtendimentoData, editarMotivoAtendimento, salvarEndereco, excluirEndereco } =
    useMotivoAtendimentoService();
  useState<MotivoAtendimentoModel>(DADOS_MOTIVO_ATENDIMENTO_DEFAULT);
  const { obterHistoryEntidade } = useHistoricoService();
  const { state } = useAuthenticationContext();

  const form = useForm<MotivoAtendimentoModel>({
    mode: 'onBlur',
    defaultValues: DADOS_MOTIVO_ATENDIMENTO_DEFAULT,
    resolver: yupResolver(dadosMotivoAtendimentoFormModelSchema)
  });

  const editarMotivoAtendimentoHandler = async () => {
    try {
      setLoadingMotivoAtendimento(true);
      const motivoAtendimentoEditar = form.getValues();
      await editarMotivoAtendimento(id ?? '', motivoAtendimentoEditar);
      form.reset(form.getValues());
    } finally {
      setLoadingMotivoAtendimento(false);
    }
  };

  const salvarEnderecoHandler = async (enderecoModel: EnderecoModel) => {
    try {
      setLoadingMotivoAtendimento(true);
      const formValues = form.getValues();
      const view = await salvarEndereco(id ?? '', enderecoModel);
      formValues.enderecosAtendimentos = [...(formValues.enderecosAtendimentos ?? [])];
      formValues.enderecosAtendimentos?.push(view.data);

      form.reset(formValues);
    } finally {
      setLoadingMotivoAtendimento(false);
    }
  };

  const excluirEnderecoHandler = async (enderecoDestinoId: string) => {
    try {
      setLoadingMotivoAtendimento(true);
      const request: ExcluirEnderecoDestinoMotivoRequest = {
        enderecoDestinoId: enderecoDestinoId,
        motivoAtendimentoId: id ?? ''
      };
      const formValues = form.getValues();
      await excluirEndereco(request);
      formValues.enderecosAtendimentos = [
        ...(form
          .getValues()
          .enderecosAtendimentos?.filter((x) => x.enderecoId !== enderecoDestinoId) ?? [])
      ];
      form.reset(formValues);
    } finally {
      setLoadingMotivoAtendimento(false);
    }
  };

  const carregar = async (id: string) => {
    setLoadingMotivoAtendimento(true);
    let data = DADOS_MOTIVO_ATENDIMENTO_DEFAULT;
    const result = await obterMotivoAtendimentoData(id);
    data = result.data;
    form.reset(data);
    setLoadingMotivoAtendimento(false);
  };

  const toggleModal = async () => {
    setModalHistorico((prevState) => !prevState);
  };

  useEffect(() => {
    if (modalHistorico && !timeLineData) {
      const obter = async () => {
        try {
          const historyData = await obterHistoryEntidade(
            'MotivoAtendimento',
            state.userInfo.data?.tenantId ?? '', //"712A7D07-A57C-4F78-8F9F-66DDF61A44C0",
            id as string
          );

          const data = historyData.versions.sort((a, b) => (a.order > b.order ? -1 : 1));
          data.map((history: { dateTimeAction: string | number | Date }) => {
            const newDate = new Date(history.dateTimeAction);
            const dataFormatada = new Intl.DateTimeFormat('pt-BR', {
              year: 'numeric',
              month: '2-digit',
              day: '2-digit',
              hour: '2-digit',
              hourCycle: 'h23',
              minute: '2-digit'
            }).format(newDate);
            return (history.dateTimeAction = dataFormatada);
          });
          setTimeLineData(data);
        } catch {
          console.log('Erro ao tentar carregar timeline values');
          toggleModal();
        }
      };
      obter();
    } else {
      setTimeout(() => {
        setTimeLineData(undefined);
      }, 300);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalHistorico]);

  useEffect(() => {
    carregar(id ? id : '');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return (
    <>
      <OptSideLayoutContent>
        <ToolbarContainer>
          <OptActionToolbar
            goBackRoute={
              window.history.state.usr?.prevUrl ?? RoutesObj.MotivoAtendimento.MotivoAtendimentos
            }
            title="Editar motivo de atendimento"
            color={state.tema.light?.primary}
            clearMargin
          >
            <ProtectedContent access={MenuMotivoAtendimentoClaims.VISUALIZAR}>
              <BotaoDeAcaoToolbar
                onClick={toggleModal}
                texto="Histórico"
                startIcon={mdiClipboardTextClockOutline}
              />
            </ProtectedContent>
          </OptActionToolbar>
        </ToolbarContainer>
        <Modal open={modalHistorico} onClose={toggleModal} title="Histórico" width="800px">
          <div style={{ marginTop: '1em' }}>
            {timeLineData && (
              <OptTimeline
                data={timeLineData ?? []}
                valuesTableOptions={{
                  nameHeader: 'Name',
                  valueHeader: 'Value'
                }}
              />
            )}
            {!timeLineData && (
              <div style={{ height: '100px', overflow: 'none' }}>
                <OptLoading size={50} />
              </div>
            )}
          </div>
        </Modal>
        <FormProvider {...form}>
          <FormEditarMotivoAtendimento
            editarMotivoAtendimento={editarMotivoAtendimentoHandler}
            salvarEndereco={salvarEnderecoHandler}
            excluirEndereco={excluirEnderecoHandler}
            enderecos={form.getValues().enderecosAtendimentos ?? []}
          />
        </FormProvider>
        <OptBackdrop open={loadingMotivoAtendimento} />
      </OptSideLayoutContent>
    </>
  );
};

export default EditarMotivoAtendimento;
