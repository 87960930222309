import Button from '@mui/material/Button';
import styled from 'styled-components';

import { Colors } from '../../../../core/colors';

interface UserListContainerProps {
  userList: boolean;
}
export const UserListContainer = styled.div<UserListContainerProps>`
  height: 100vh;
  min-width: 290px;
  margin-right: -5px;
  margin-left: 25px;
  border-left: 1px solid rgba(91, 91, 91, 0.15);
  border-top: 1px solid rgba(91, 91, 91, 0.15);
  border-radius: 5px;
  overflow-y: scroll;
  margin-top: -18px;

  ::-webkit-scrollbar {
    width: 2px;
  }

  ::-webkit-scrollbar-track {
    background-color: transparent;
  }

  ::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.3);
    border-radius: 10px;
  }

  animation-duration: 0.3s;
  animation-fill-mode: both;

  animation-name: moveFromRight;

  @keyframes moveFromRight {
    from {
      transform: translateX(400px);
    }
  }
`;

export const TituloCard = styled.h3`
  text-align: center;
  font-size: 22px;
`;

export const CardInformativo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 280px;
  width: 364px;
  padding: 15px 0;
  border-radius: 20px;
  margin-bottom: 5px;
  background-color: white;
  /* box-shadow: rgba(0, 0, 0, 0.1) 0px 2px 2px -1px, rgba(0, 0, 0, 0.01) 0px 6px 4px -1px; */
  /* box-shadow: rgba(0, 0, 0, 0.15) 0.5px 1px 4px; */
`;

export const CardCentro = styled(CardInformativo)`
  width: 100%;
  height: 72%;
  /*   box-shadow: rgba(0, 0, 0, 0.1) 0px 5px 15px; */
`;

export const Titulo = styled.div`
  font-size: 18px;
  line-height: 21px;
  font-weight: 400;
  margin-bottom: 25px;
  margin-top: 30px;
`;

interface Props {
  $active: boolean;
  bgcolor: string;
}
export const UserListButton = styled(Button)<Props>`
  &.MuiButtonBase-root {
    width: ${({ $active }) => ($active ? '290px' : '125px')};
    height: 30px;
    border: ${({ $active }) =>
      $active ? `1px solid ${Colors.gray2}` : `1px solid ${Colors.gray6}`};
    text-transform: capitalize;
    font-weight: 400;
    font-size: 15.5px;
    color: ${({ $active }) => ($active ? Colors.gray10 : Colors.white)};
    background: ${({ bgcolor }) => bgcolor ?? Colors.ciano1};
    box-shadow: ${({ $active }) =>
      $active
        ? `inset 2px 2px 10px 0 rgba(0, 0, 0, 0.3), inset -2px -2px 10px 0 rgba(255, 255, 255, 0.25)`
        : ''};

    :hover {
      border-color: ${({ bgcolor }) => bgcolor ?? Colors.ciano1};
      background-color: ${({ bgcolor }) => bgcolor ?? Colors.ciano1};
    }

    span {
      /* border-color: ${Colors.gray4}; */
      color: ${Colors.gray3};
    }
  }
`;
