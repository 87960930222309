import { Box } from '@mui/material';
import { OptDivider } from '@optsol/react';

import { UsuarioStatusResponse } from '../../../models/Usuario/Usuario.model';

import * as S from './styles';

interface Props {
  userList: UsuarioStatusResponse[];
}
export const UsuarioStatusList = ({ userList }: Props) => {
  return (
    <div style={{ width: '100%' }}>
      {userList.map((user) => {
        return (
          <>
            <Box width="100%" display="flex" alignItems="center" py={1} pl={1.5} pr={0.5}>
              <S.CircleDotRed status={user.status} />
              <Box px={1.8} fontSize={14}>
                <div>{user.nome}</div>
                <div>{user.ultimaAtualizacao}</div>
              </Box>
            </Box>
            <OptDivider
              color={'rgba(255, 255, 255, 0)'}
              style={{ borderColor: 'rgba(91, 91, 91, 0.1)' }}
            />
          </>
        );
      })}
    </div>
  );
};
