import {
  Autocomplete,
  Box,
  debounce,
  FormControl,
  FormControlLabel,
  Grid,
  MenuItem,
  SelectChangeEvent,
  Switch,
  TextField,
  Typography
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { OptBackdrop, OptGridRef, OptLoading } from '@optsol/react';
import { useCallback, useEffect, useRef, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { ClienteFormModel } from '../../../models/Cliente/Cliente.model';
import { ContatoModel, CriarContatoModel } from '../../../models/Contato/Contato.model';
import { GridCliente } from '../../../models/dtos/Cliente/clienteSearchResponse.model';
import { PlanoSearchResponse } from '../../../models/dtos/Plano/planoSearchResponse.model';
import { EnderecoModel } from '../../../models/Endereco/Endereco.model';
import { MenuClienteClaims } from '../../../models/enums/AcessoClaims';
import {
  DADOS_INFORMACOES_PLANO_DEFAULT,
  DADOS_PLANO_DEFAULT,
  InformacoesPlanoModel,
  PlanoModel
} from '../../../models/Plano/Plano.model';
import { DADOS_COBERTURA_DEFAULT } from '../../../models/ServicoCoberto/ServicoCoberto.model';
import { usePlanoService } from '../../../service/plano.service';
import { validarCNPJ, validarCPF } from '../../../shared/utils/functions';
import { PaginatedSearchRequest, SearchRequest } from '../../../shared/utils/searchRequest';
import { IconButton } from '../../components/Button/IconButton';
import { FormSelect, FormTextField } from '../../components/Form';
import { FormNumberFormat } from '../../components/Form/FormNumberFormat';
import { TituloForm } from '../../components/Form/TituloForm';
import { Modal } from '../../components/Modal/Modal';
import { ProtectedContent } from '../../components/ProtectedContent/ProtectedContent';
import { useAuthenticationContext } from '../../contexts/authentication/authenticationContext';
import GridContatos from '../../pages/Clientes/Contatos/GridContatos';
import GridEnderecos from '../../pages/Clientes/Enderecos/GridEnderecos';
import GridClientes from '../../pages/Clientes/GridClientes';

import { ClientePai } from './clientePai/ClientePai';
import CriarContato from './contato/CriarContato';
import CriarEndereco from './endereco/CriarEndereco';
import InformacoesPlano from './InformacoesPlano';
import * as S from './styles/index';

interface Props {
  editarCliente: () => void;
  salvarEndereco: (enderecoModel: EnderecoModel) => Promise<void>;
  salvarContato: (contatoModel: CriarContatoModel) => Promise<void>;
  excluirEndereco: (enderecoId: string) => Promise<void>;
  excluirContato: (contatoId: string) => Promise<void>;
  enderecos: EnderecoModel[];
  contatos: ContatoModel[];
  clienteIsLoading: boolean;
  enderecoIsLoading: boolean;
  contatoIsLoading: boolean;
  defaultPlano: PlanoSearchResponse | null;
  defaultInformacoesPlano: InformacoesPlanoModel | null;
}

export const FormEditarCliente = ({
  editarCliente,
  salvarEndereco,
  salvarContato,
  excluirEndereco,
  excluirContato,
  enderecos,
  contatos,
  clienteIsLoading,
  enderecoIsLoading,
  contatoIsLoading,
  defaultPlano,
  defaultInformacoesPlano
}: Props) => {
  const {
    control,
    watch,
    setError,
    reset,
    getValues,
    formState: { isDirty }
  } = useFormContext<ClienteFormModel>();
  const {
    state: { tema },
    hasAccess
  } = useAuthenticationContext();

  // const navigate = useNavigate();

  const monitorarCpfCnpj = watch('cpfCnpj');
  const [mask, setMask] = useState('###.###.###-###');

  const refGridEndereco = useRef<OptGridRef>();
  const refGridContato = useRef<OptGridRef>();

  const [enderecoIsOpen, setEnderecoIsOpen] = useState(false);
  const [contatoIsOpen, setContatoIsOpen] = useState(false);
  const { buscarGridPlanoPaginated } = usePlanoService();
  const [endereco, setEndereco] = useState<EnderecoModel>();
  const [contato, setContato] = useState<CriarContatoModel>();
  const [planos, setPlanos] = useState<PlanoSearchResponse[]>([]);
  const [plano, setPlano] = useState<PlanoSearchResponse | null>(defaultPlano);
  const [informacoesPlano, setInformacoesPlano] = useState<InformacoesPlanoModel | null>(
    defaultInformacoesPlano
  );

  const hasCNPJ = monitorarCpfCnpj?.replace(/[^\w\s]/gi, '')?.trim().length >= 12;

  const toggleEnderecoModal = () => {
    setEnderecoIsOpen((prevState) => !prevState);
  };
  const toggleContatoModal = () => {
    setContatoIsOpen((prevState) => !prevState);
  };

  function setarPlano(value: PlanoSearchResponse) {
    setPlano(value);

    if (value) {
      const infoPlano = { ...DADOS_INFORMACOES_PLANO_DEFAULT };
      infoPlano.id = value.id;
      infoPlano.servicosCobertos = [];
      infoPlano.nomePlano = value.nome;
      infoPlano.numeroContrato = value.numeroContrato;
      value.servicosCobertosPlanos.forEach((servicoPlano) => {
        const servicoCoberto = DADOS_COBERTURA_DEFAULT;
        servicoCoberto.id = servicoPlano.servicoCobertoId;
        servicoCoberto.nome = servicoPlano.servicoCobertoNome;
        infoPlano.servicosCobertos.push({ ...servicoCoberto });
      });
      setInformacoesPlano(infoPlano);
    } else {
      setInformacoesPlano(null);
    }
  }

  function validarCpfCnpjOnBlur() {
    if (!(monitorarCpfCnpj?.replace(/[^\w\s]/gi, '')?.trim().length >= 12)) {
      if (!validarCPF(monitorarCpfCnpj)) {
        setError('cpfCnpj', { message: 'Documento inválido' });
        return;
      } else {
        setError('cpfCnpj', { message: '' });
      }
    } else {
      if (!validarCNPJ(monitorarCpfCnpj)) {
        setError('cpfCnpj', { message: 'Documento inválido' });
        return;
      } else {
        setError('cpfCnpj', { message: '' });
      }
      const novoCliente = { ...getValues() };
      reset({ ...novoCliente, dataNascimento: null, sexo: null });
    }
  }

  function definirListaDeFilhos() {
    return (
      getValues().clientesFilhos?.map((cliente) => {
        let documento = cliente.cpf ? cliente.cpf : '';
        if (cliente.cnpj) documento = cliente.cnpj ? cliente.cnpj : '';

        const gidCliente: GridCliente = {
          ...cliente,
          documento: documento,
          clientesFilhos: [],
          clienteId: cliente.clienteId ?? '',
          plano: cliente.plano ? cliente.plano : DADOS_PLANO_DEFAULT,
          enderecos: cliente.enderecos ?? [],
          contatos: cliente.contatos ?? [],
          situacao: cliente.situacao ?? false
        };
        return gidCliente;
      }) ?? []
    );
  }

  useEffect(() => {
    refreshGridEnderecos();
  }, [enderecos]);

  useEffect(() => {
    refreshGridContatos();
  }, [contatos]);

  useEffect(() => {
    if (
      getValues()
        .cpfCnpj.replace(/[^\w\s]/gi, '')
        .trim().length >= 12
    ) {
      setMask('##.###.###/####-##');
    } else {
      setMask('###.###.###-###');
    }
  }, []);

  async function filtrarPlanos(termo: string) {
    if (termo) {
      const request: SearchRequest<PaginatedSearchRequest> = {
        page: 0,
        pageSize: 10,
        search: {
          termoBuscado: termo
        }
      };
      const result = await buscarGridPlanoPaginated(request);
      setPlanos(result.data);
    }
  }

  function filtrarPlanosHandler(newValue: string) {
    filtrarPlanos(newValue);
  }

  const changeHandler = useCallback((value: string) => {
    filtrarPlanosHandler(value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedChangeHandler = useCallback(debounce(changeHandler, 500), []);

  function refreshGridEnderecos() {
    if (refGridEndereco.current) {
      refGridEndereco.current.refresh();
    }
  }

  function refreshGridContatos() {
    if (refGridContato.current) {
      refGridContato.current.refresh();
    }
  }

  function editarContato(contato: ContatoModel) {
    setContato({
      email: contato.email,
      telefone: contato.telefoneCelular ? contato.telefoneCelular : contato.telefoneFixo,
      contatoId: contato.contatoId,
      nomeContato: contato.nomeContato,
      cpf: contato.cpf
    });
    toggleContatoModal();
  }

  async function excluirEnderecoHandler(enderecoId: string) {
    await excluirEndereco(enderecoId);
  }

  function criarEndereco() {
    setEndereco(undefined);
    toggleEnderecoModal();
  }

  function criarContato() {
    setContato(undefined);
    toggleContatoModal();
  }

  async function excluirContatoHandler(contatoId: string) {
    await excluirContato(contatoId);
    toggleContatoModal();
  }

  return (
    <>
      {watch('clientePai') && (
        <ClientePai
          clienteLabel={`${watch('clientePai')?.cnpj} - ${watch('clientePai')?.nome}` ?? ''}
        />
      )}
      <Grid container item rowSpacing={3} columnSpacing={3} px={2.5} pt={2.5}>
        <Grid item xs={12}>
          <TituloForm texto="Dados do cliente" />
        </Grid>
        <Grid item xs={hasCNPJ ? 6 : 3}>
          <ProtectedContent mode="disable" access={MenuClienteClaims.ATUALIZAR}>
            <FormNumberFormat
              control={control}
              format={mask}
              name="cpfCnpj"
              size="small"
              label="CPF/CNPJ"
              placeholder="CPF/CNPJ"
              onBlur={() => {
                validarCpfCnpjOnBlur();
                isDirty && editarCliente();
              }}
              onPaste={(e: any) => {
                if (e.clipboardData.getData('text/plain').replace(/\D/g, '').trim().length >= 12) {
                  setMask('##.###.###/####-##');
                } else {
                  setMask('###.###.###-###');
                }
              }}
              onChange={(event) => {
                if (event.target.value.replace(/[^\w\s]/gi, '').trim().length >= 12) {
                  setMask('##.###.###/####-##');
                } else {
                  setMask('###.###.###-###');
                }
              }}
            />
          </ProtectedContent>
        </Grid>

        <Grid item xs={hasCNPJ ? 6 : 3}>
          <Controller
            name="nome"
            control={control}
            render={({ field: { onBlur } }) => (
              <>
                <ProtectedContent mode="disable" access={MenuClienteClaims.ATUALIZAR}>
                  <FormTextField
                    name="nome"
                    control={control}
                    label="Nome"
                    onBlur={() => {
                      isDirty ? editarCliente() : onBlur();
                    }}
                  />
                </ProtectedContent>
              </>
            )}
          />
        </Grid>
        {!hasCNPJ && (
          <>
            <Grid item xs={3}>
              <Controller
                name="dataNascimento"
                control={control}
                render={({ field: { onChange, value, onBlur } }) => {
                  return (
                    <ProtectedContent mode="disable" access={MenuClienteClaims.ATUALIZAR}>
                      <DatePicker
                        // label="Data nascimento"
                        inputFormat="dd/MM/yyyy"
                        value={value}
                        onChange={onChange}
                        onClose={() => {
                          isDirty && editarCliente();
                        }}
                        renderInput={(params) => (
                          <Box display="flex" flexDirection="column" gap={1}>
                            <Typography textAlign="left" lineHeight={1} color={tema.light?.primary}>
                              Data nascimento
                            </Typography>
                            <TextField
                              fullWidth
                              size="small"
                              onBlur={() => {
                                if (isDirty) {
                                  onBlur();
                                  editarCliente();
                                }
                                onBlur();
                              }}
                              {...params}
                            />
                          </Box>
                        )}
                      />
                    </ProtectedContent>
                  );
                }}
              />
            </Grid>
            <Grid item xs={3}>
              <Controller
                name="sexo"
                control={control}
                render={({ field: { onChange, value } }) => {
                  return (
                    <>
                      <ProtectedContent mode="disable" access={MenuClienteClaims.ATUALIZAR}>
                        <FormSelect
                          onChange={(event: SelectChangeEvent<unknown>) => {
                            onChange(event);
                            editarCliente();
                          }}
                          value={value ?? ''}
                          size="small"
                          name="sexo"
                          label="Sexo"
                        >
                          <MenuItem key={0} value={0}>
                            Masculino
                          </MenuItem>
                          <MenuItem key={1} value={1}>
                            Feminino
                          </MenuItem>
                        </FormSelect>
                      </ProtectedContent>
                    </>
                  );
                }}
              />
            </Grid>
          </>
        )}

        <Grid item xs={6} display="flex" flexDirection="column" gap={2}>
          <Controller
            name="planoId"
            control={control}
            render={({ field: { onChange } }) => (
              <FormControl fullWidth>
                <ProtectedContent mode="disable" access={MenuClienteClaims.ATUALIZAR}>
                  <Autocomplete
                    fullWidth
                    options={planos ? planos : []}
                    getOptionLabel={(option) => (typeof option === 'object' ? option.nome : '')}
                    value={plano}
                    onChange={(_event, newValue: PlanoModel | any) => {
                      setarPlano(newValue);
                      onChange(newValue ? newValue.id : null);
                      editarCliente();
                    }}
                    onInputChange={(_e, value) => debouncedChangeHandler(value)}
                    freeSolo
                    renderInput={(params) => (
                      <>
                        <Typography textAlign="left" lineHeight={1} color={tema.light?.primary}>
                          Plano
                        </Typography>
                        <FormTextField
                          {...params}
                          name="planoId"
                          control={control}
                          placeholder="Plano"
                          fullWidth
                        />
                      </>
                    )}
                  />
                </ProtectedContent>
              </FormControl>
            )}
          />
          <Controller
            name="codigoCliente"
            control={control}
            render={({ field: { onBlur } }) => (
              <div>
                <ProtectedContent mode="disable" access={MenuClienteClaims.ATUALIZAR}>
                  <FormTextField
                    inputProps={{ maxLength: 20 }}
                    name="codigoCliente"
                    control={control}
                    label="Código do cliente"
                    size="small"
                    fullWidth
                    disabled
                    onBlur={() => {
                      isDirty ? editarCliente() : onBlur();
                    }}
                  />
                </ProtectedContent>
              </div>
            )}
          />
        </Grid>
        <Grid item xs={6}>
          <Controller
            name="codigoCliente"
            control={control}
            render={({ field: { onBlur } }) => (
              <>
                <ProtectedContent mode="disable" access={MenuClienteClaims.ATUALIZAR}>
                  <FormTextField
                    inputProps={{ maxLength: 2000 }}
                    control={control}
                    name="informacaoImportante"
                    label="Informações importantes"
                    multiline
                    rows={5}
                    onBlur={() => {
                      isDirty ? editarCliente() : onBlur();
                    }}
                  />
                </ProtectedContent>
              </>
            )}
          />
          {`${watch('informacaoImportante')?.length ?? 0} / 2000`}
        </Grid>
      </Grid>

      <Grid item xs={6} ml={3} mb={'1em'}>
        <Controller
          name="situacao"
          control={control}
          render={({ field: { onChange, value, onBlur } }) => {
            return (
              <FormControlLabel
                label={'Ativo'}
                control={
                  <ProtectedContent mode="disable" access={MenuClienteClaims.ATUALIZAR}>
                    <Switch
                      onChange={onChange}
                      checked={value}
                      onBlur={() => {
                        isDirty ? editarCliente() : onBlur();
                      }}
                    />
                  </ProtectedContent>
                }
              />
            );
          }}
        />
      </Grid>
      <Grid container item rowSpacing={3} columnSpacing={3} px={1} pt={1}>
        <Grid item xs={12}>
          {informacoesPlano && (
            <InformacoesPlano
              plano={{
                id: informacoesPlano.id,
                abrangencia: '',
                nomePlano: informacoesPlano.nomePlano,
                numeroContrato: informacoesPlano.numeroContrato,
                servicosCobertos: informacoesPlano.servicosCobertos
              }}
            />
          )}
        </Grid>
      </Grid>
      <Grid container item rowSpacing={3} columnSpacing={3} px={1} pt={3}>
        <Grid item xs={6}>
          <S.ButtonGridContent>
            <Modal
              open={enderecoIsOpen}
              onClose={toggleEnderecoModal}
              title="Cadastro de endereço"
              width="600px"
              overflow="hidden"
            >
              <CriarEndereco
                salvarEndereco={salvarEndereco}
                excluirEndereco={excluirEnderecoHandler}
                toggleEnderecoModal={toggleEnderecoModal}
                endereco={endereco}
              />
            </Modal>
            <Typography color={tema.light?.primary} lineHeight={1} fontWeight={700}>
              Endereço
            </Typography>
            <ProtectedContent access={MenuClienteClaims.ATUALIZAR}>
              <IconButton onClick={criarEndereco} color />
            </ProtectedContent>
          </S.ButtonGridContent>

          {!clienteIsLoading && !enderecoIsLoading ? (
            <GridEnderecos
              data={enderecos}
              excluirEndereco={excluirEnderecoHandler}
              hasAccess={hasAccess(MenuClienteClaims.ATUALIZAR)}
              ref={refGridEndereco}
            />
          ) : (
            <OptLoading color="primary" size={50} />
          )}
          <OptBackdrop open={clienteIsLoading} />
        </Grid>
        <Grid item xs={6}>
          <S.ButtonGridContent>
            <Modal
              open={contatoIsOpen}
              onClose={toggleContatoModal}
              title="Cadastro de contato"
              width="600px"
              overflow="hidden"
            >
              <CriarContato
                salvarContato={salvarContato}
                excluirContato={excluirContatoHandler}
                toggleContatoModal={toggleContatoModal}
                contato={contato}
              />
            </Modal>
            <Typography color={tema.light?.primary} lineHeight={1} fontWeight={700}>
              Contato
            </Typography>
            <ProtectedContent access={MenuClienteClaims.ATUALIZAR}>
              <IconButton onClick={criarContato} color />
            </ProtectedContent>
          </S.ButtonGridContent>
          {!clienteIsLoading && !contatoIsLoading ? (
            <GridContatos data={contatos} ref={refGridContato} editarContato={editarContato} />
          ) : (
            <OptLoading color="primary" size={50} />
          )}
        </Grid>
        <Grid item xs={12}>
          <Box px={2.5} pt={2} display="flex" flexDirection="column" gap={2}>
            <Typography color={tema.light?.primary} lineHeight={1} fontWeight={700}>
              Clientes vinculados
            </Typography>
            <GridClientes
              data={definirListaDeFilhos()}
              editarCliente={(clienteId: string) => {
                // navigate(Routes.Cliente.Detalhes(clienteId), { replace: true });
                window.open(clienteId, '_self', 'noreferrer');
              }}
            ></GridClientes>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default FormEditarCliente;
