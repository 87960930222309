import * as yup from 'yup';

import { dadosEstadoPlanoFormModelSchema, EstadoModel } from '../Estado/Estado.model';
export interface ServicoCobertoPlanoModel {
  servicoCobertoPlanoId: string;
  planoId: string;
  servicoCobertoId: string;
  servicoCobertoNome: string;
  estados: EstadoModel[];
  temAreaAbrangencia?: boolean;
}

export const DADOS_SERVICO_COBERTO_PLANO_DEFAULT: ServicoCobertoPlanoModel = {
  servicoCobertoPlanoId: '',
  planoId: '',
  servicoCobertoId: '',
  servicoCobertoNome: '',
  estados: [],
  temAreaAbrangencia: false
};

const MSG_REQUIRED = 'Campo obrigatório';
export const dadosServicoCobertoPlanoFormModelSchema: yup.SchemaOf<ServicoCobertoPlanoModel> = yup
  .object()
  .shape({
    servicoCobertoPlanoId: yup.string().required(MSG_REQUIRED),
    planoId: yup.string().required(MSG_REQUIRED),
    servicoCobertoId: yup.string().required(MSG_REQUIRED),
    servicoCobertoNome: yup.string().required(MSG_REQUIRED),
    estados: yup.array().of(dadosEstadoPlanoFormModelSchema).required(),
    temAreaAbrangencia: yup.boolean().notRequired()
  });
