export enum AuthenticationActions {
  CARREGAR_USER_INFO = 'AUTHENTICATION_CARREGAR_USER_INFO',
  DEFINIR_TEMA = 'DEFINIR_TEMA',
  DEFINIR_TEMA_SECUNDARIO = 'DEFINIR_TEMA_SECUNDARIO',
  LOADING_USER_INFO = 'AUTHENTICATION_LOADING_USER_INFO',
  DEFINIR_TENANT = 'DEFINIR_TENANT',
  DEFINIR_TOKEN = 'DEFINIR_TOKEN',
  DEFINIR_HISTORY_TOKEN = 'DEFINIR_SSOTOKEN',
  DEFINIR_LOCAL_ID = 'DEFINIR_LOCAL_ID'
}
