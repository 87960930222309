import { Grid } from '@mui/material';
import { useFormContext } from 'react-hook-form';

import { CriarEmpresaModel } from '../../../models/Empresa/Empresa.model';
import { FormTextField } from '../../components/Form';
import { FormNumberFormat } from '../../components/Form/FormNumberFormat';

export const FormCriarEmpresa = () => {
  const { control } = useFormContext<CriarEmpresaModel>();

  return (
    <>
      <Grid container item rowSpacing={3} columnSpacing={3} pt={2.5} pb={2.5}>
        <Grid item xs={6}>
          <FormNumberFormat
            control={control}
            format={'##.###.###/####-##'}
            name="cnpj"
            label="CNPJ"
            placeholder="CNPJ"
            sx={{ mt: 0.5 }}
          />
        </Grid>
        <Grid item xs={6}>
          <FormTextField
            control={control}
            sx={{ mt: 0.5 }}
            name="nomeEmpresa"
            label="Nome empresa"
          />
        </Grid>
        <Grid item xs={6}>
          <FormTextField name="nome" control={control} sx={{ mt: 0.5 }} label="Nome usuário" />
        </Grid>
        <Grid item xs={6}>
          <FormNumberFormat
            name="cpf"
            label="CPF"
            placeholder="CPF"
            control={control}
            format="###.###.###-##"
          />
        </Grid>
        <Grid item xs={12}>
          <FormTextField control={control} sx={{ mt: 0.5 }} name="email" label="E-mail" />
        </Grid>
      </Grid>
    </>
  );
};

export default FormCriarEmpresa;
