/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { PropsWithChildren } from 'react';

import { useAuthenticationContext } from '../../contexts/authentication/authenticationContext';

interface Props {
  access: string | string[];
  mode?: 'disable' | 'hide';
}

function recursiveCloneChildren(children: React.ReactElement[], newProps: any) {
  return React.Children.map(children, (child: React.ReactElement) => {
    if (!React.isValidElement(child)) {
      return child;
    }

    // Eg. String has no props
    if (child.props) {
      newProps.children = recursiveCloneChildren((child as any).props.children, newProps);
      return React.cloneElement(child, newProps);
    }
    return child;
  });
}

export const ProtectedContent = ({ access, mode = 'hide', children }: PropsWithChildren<Props>) => {
  const { hasAccess } = useAuthenticationContext();
  let isAllowed = false;
  if (Array.isArray(access)) {
    isAllowed = access.some((x) => hasAccess(x));
  } else {
    isAllowed = hasAccess(access);
  }

  if (isAllowed) return <>{children}</>;

  if (mode === 'disable') {
    return (
      <>
        {recursiveCloneChildren(children as React.ReactElement[], {
          disabled: true
        })}
      </>
    );
  }

  return null;
};
