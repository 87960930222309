import { mdiFileDownload } from '@mdi/js';
import Icon from '@mdi/react';
import { Box, Card, Grid, Skeleton, Tooltip } from '@mui/material';
import { OptBackdrop } from '@optsol/react';
import { format } from 'date-fns';
import { useCallback, useMemo, useState } from 'react';
import NumberFormat from 'react-number-format';
import { useNavigate } from 'react-router-dom';

import { Colors } from '../../../core/colors';
import { ListaAtendimentoSearchResponse } from '../../../models/dtos/Atendimento/atendimentoSearchResponse.model';
import { MenuAtendimentoClaims } from '../../../models/enums/AcessoClaims';
import { StatusAtendimentoEnum } from '../../../models/enums/StatusAtendimento';
import { RoutesObj } from '../../../routes/index.routes';
import { useAtendimentoService } from '../../../service/atendimento.service';
import useInfiniteScroll from '../../../shared/hooks/infiniteScroll';
import { capitalizeCase } from '../../../shared/utils/functions';
import Chave from '../../components/Chave';
import { ChipStatusUrgencia } from '../../components/ClassificacaoUrgenciaText/ChipStatusUrgencia';
import { renderStatusColor } from '../../components/ClassificacaoUrgenciaText/RenderColor';
import { StatusAtendimentoChip } from '../../components/StatusAtendimentoText/StatusAtendimentoChip';
import Valor from '../../components/Valor';
import { useAuthenticationContext } from '../../contexts/authentication/authenticationContext';

import { HistoricoPaciente } from './HistoricoPaciente/HistoricoPaciente';
import { FiltroModel } from './ToolbarListaAtendimento';

interface Props {
  atendimentoSearch: string;
  filtro: FiltroModel;
}

export const GridCardListaAtendimento = ({ atendimentoSearch, filtro }: Props) => {
  const {
    hasAccess,
    state: { userInfo }
  } = useAuthenticationContext();
  const { buscarGridListaAtendimentoPaginated, gerarBoletim } = useAtendimentoService();
  const navigate = useNavigate();
  const [loadingGerarRelatorio, setLoadingGerarRelatorio] = useState<boolean>(false);

  const { hasNext, loading, lastElementRef, items } = useInfiniteScroll<any, any>({
    load: buscarGridListaAtendimentoPaginated,
    pageSize: 10,
    search: useMemo(
      () => ({
        termoBuscado: atendimentoSearch,
        statusAtendimentoBuscado: filtro.statusAtendimentoSearch,
        apenasMeusChamados: filtro.meusAtendimentos,
        mostrarEncerrados: filtro.apenasEncerrados,
        urgencia: filtro.urgencia,
        fasesAtendimento: userInfo.data?.faseAtendimento
      }),
      [
        atendimentoSearch,
        filtro.statusAtendimentoSearch,
        filtro.meusAtendimentos,
        filtro.apenasEncerrados,
        filtro.urgencia,
        userInfo.data?.faseAtendimento
      ]
    )
  });
  const selecionarAtendimentoHandler = useCallback(
    (atendimento: ListaAtendimentoSearchResponse) => {
      navigate(RoutesObj.Atendimento.Detalhes(atendimento.atendimentoId), {
        state: { prevUrl: window.location.href }
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [navigate]
  );
  async function gerarRelatorio(atendimento: ListaAtendimentoSearchResponse): Promise<void> {
    if (!atendimento.atendimentoId) return;
    try {
      setLoadingGerarRelatorio(true);
      const response = await gerarBoletim(atendimento.atendimentoId, atendimento.pacienteId);
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(response.data);
      link.download =
        'boletim-' + atendimento.numeroChamado + '-' + atendimento?.nomePaciente + '.pdf';
      link.click();
    } finally {
      setLoadingGerarRelatorio(false);
    }
  }
  const limitarCaracteres = (texto: string, limite: number) => {
    if (texto.length > limite) {
      return `${texto.substring(0, limite)}...`;
    }
    return texto;
  };
  return (
    <>
      <OptBackdrop open={loadingGerarRelatorio} />
      <Box px={1.8} pt={1} gap={2}>
        {loading &&
          Array(10)
            .fill(1)
            .map((_item, index) => index)
            .map((value) => (
              <Grid key={value} item xs={12} mt={2} px={1}>
                <Skeleton variant="rounded" width="100%" height={140} />
              </Grid>
            ))}

        <Grid container flex={1} gap={2} px={1} pt={1.5}>
          {items.map((item, index, arr) => {
            const lastElement = arr.length === index + 1;
            const ref = lastElement && hasNext ? lastElementRef : null;
            return (
              <Grid<typeof Card>
                container
                sx={{ cursor: 'pointer' }}
                onClick={() => selecionarAtendimentoHandler(item)}
                component={Card}
                ref={ref}
                key={index}
                display="flex"
                // px={2}
                // pt={1}
              >
                <Grid item container xs={12}>
                  {hasAccess(MenuAtendimentoClaims.GRID_ATENDIMENTO__STATUS) &&
                    item.statusPaciente && (
                      <Grid
                        item
                        display="flex"
                        // ml={-2}
                        // mt={-1}
                      >
                        <StatusAtendimentoChip
                          status={item.statusPaciente}
                          statusPerfilDescricao={item.faseAtendimentoDescricao}
                          statusPerfilSigla={item.faseAtendimentoSigla}
                          borderRadius={'8px 0'}
                        />
                      </Grid>
                    )}

                  {hasAccess(MenuAtendimentoClaims.GRID_ATENDIMENTO__CLASSIFICACAO) && (
                    <Grid
                      item
                      display="flex"
                      width="100%"
                      justifyContent="flex-end"
                      mt={-4.1}
                      // mr={-2}
                    >
                      <ChipStatusUrgencia
                        status={item.classificacaoUrgencia}
                        fontSize={14}
                        bgcolor={renderStatusColor(item.classificacaoUrgencia).color}
                        borderRadius={'0 8px'}
                        padding={'3px 9px'}
                        // boxShadow={
                        //   'rgba(109, 109, 109, 0.1) 0px 30px 60px -12px inset, rgba(52, 52, 52, 0.1) 0px 18px 36px -18px inset'
                        // }
                      />
                    </Grid>
                  )}
                </Grid>

                <Grid container px={2} pb={1}>
                  {hasAccess(MenuAtendimentoClaims.GRID_ATENDIMENTO__NUMERO_CHAMADO) && (
                    <Grid item xs={3} display="flex" gap={1}>
                      <Chave>Nº chamado:</Chave>
                      <Valor textAlign="right">{item.numeroChamado ?? ' -'}</Valor>
                    </Grid>
                  )}
                  {hasAccess(MenuAtendimentoClaims.GRID_ATENDIMENTO__SOLICITANTE) && (
                    <Grid item xs={3} display="flex" gap={1}>
                      <Chave>Solicitante:</Chave>
                      <Valor textAlign="right">
                        {item.nomeSolicitante
                          ? limitarCaracteres(capitalizeCase(item.nomeSolicitante), 28)
                          : ' -'}
                      </Valor>
                    </Grid>
                  )}

                  {hasAccess(MenuAtendimentoClaims.GRID_ATENDIMENTO__DATA_CHAMADO) && (
                    <Grid item xs={3} display="flex" gap={1}>
                      <Chave>Data chamado:</Chave>
                      <Valor textAlign="right">
                        {item.dataChamado
                          ? format(new Date(item.dataChamado), 'dd/MM/yyyy HH:mm:ss')
                          : ' -'}
                      </Valor>
                    </Grid>
                  )}
                  {hasAccess(MenuAtendimentoClaims.GRID_ATENDIMENTO__DATA_AGENDAMENTO) && (
                    <Grid item xs={3} display="flex" gap={1}>
                      <Chave>Agendamento:</Chave>
                      <Valor textAlign="right">
                        {item.inicioAgendamento
                          ? format(new Date(item.inicioAgendamento), 'dd/MM/yyyy HH:mm:ss')
                          : ' -'}
                      </Valor>
                    </Grid>
                  )}

                  {hasAccess(MenuAtendimentoClaims.GRID_ATENDIMENTO__INCIDENTE) && (
                    <Grid item xs={3} display="flex" gap={1}>
                      <Chave>Motivo Atendimento:</Chave>
                      <Valor textAlign="right">{item.incidente ?? ' -'}</Valor>
                    </Grid>
                  )}
                  {hasAccess(MenuAtendimentoClaims.GRID_ATENDIMENTO__PACIENTE) && (
                    <Grid item xs={3} display="flex" gap={1}>
                      <Chave>Paciente:</Chave>
                      <Valor textAlign="right">
                        {item.nomePaciente
                          ? limitarCaracteres(capitalizeCase(item.nomePaciente), 28)
                          : ' -'}
                      </Valor>
                    </Grid>
                  )}
                  {hasAccess(MenuAtendimentoClaims.GRID_ATENDIMENTO__TELEFONE) && (
                    <Grid item xs={3} display="flex" gap={1} alignItems="center">
                      <Chave>Telefone:</Chave>
                      <Valor textAlign="right">
                        <NumberFormat
                          displayType="text"
                          value={item.telefone ?? ' -'}
                          format="(##) ##### - ####"
                        />
                      </Valor>
                    </Grid>
                  )}
                  {hasAccess(MenuAtendimentoClaims.GRID_ATENDIMENTO__TIPO_AMBULANCIA) &&
                    item.tipoVeiculo && (
                      <Grid item xs={3} display="flex" gap={1}>
                        <Chave>Tipo veículo:</Chave>
                        <Valor textAlign="right">{item.tipoVeiculo ?? ' -'}</Valor>
                      </Grid>
                    )}
                  {hasAccess(MenuAtendimentoClaims.GRID_ATENDIMENTO__AMBULANCIA) &&
                    item.prefixoVeiculo && (
                      <Grid item xs={3} display="flex" gap={1}>
                        <Chave>Prefixo veiculo:</Chave>
                        <Valor textAlign="right">{item.prefixoVeiculo ?? ' -'}</Valor>
                      </Grid>
                    )}

                  <Grid item container display="flex" flex={1} xs={12}>
                    {hasAccess(MenuAtendimentoClaims.GRID_ATENDIMENTO__TARM) && (
                      <Grid item xs={3} display="flex" gap={1}>
                        <Chave>Fase Criação:</Chave>
                        <Valor textAlign="right">
                          {item.faseCriacaoResponsavel
                            ? limitarCaracteres(capitalizeCase(item.faseCriacaoResponsavel), 28)
                            : ' -'}
                        </Valor>
                      </Grid>
                    )}

                    {hasAccess(MenuAtendimentoClaims.GRID_ATENDIMENTO__MEDICO_REGULADOR) && (
                      <Grid item xs={3} display="flex" gap={1}>
                        <Chave>Fase Regulação:</Chave>
                        <Valor textAlign="right">
                          {item.faseRegulacaoResponsavel
                            ? limitarCaracteres(capitalizeCase(item.faseRegulacaoResponsavel), 28)
                            : ' -'}
                        </Valor>
                      </Grid>
                    )}

                    {hasAccess(MenuAtendimentoClaims.GRID_ATENDIMENTO__RADIO_OPERADOR) && (
                      <Grid item xs={4} display="flex" gap={1}>
                        <Chave>Fase Empenho:</Chave>
                        <Valor textAlign="right">
                          {item.faseEmpenhoResponsavel
                            ? limitarCaracteres(capitalizeCase(item.faseEmpenhoResponsavel), 28)
                            : ' -'}
                        </Valor>
                      </Grid>
                    )}
                  </Grid>

                  {hasAccess(MenuAtendimentoClaims.GRID_ATENDIMENTO__ENDERECO_ORGIEM) &&
                    item.enderecoOrigem && (
                      <Grid item xs={12} display="flex" gap={1}>
                        <Chave>Endereço origem:</Chave>
                        <Valor textAlign="right">{item.enderecoOrigem ?? ' -'}</Valor>
                      </Grid>
                    )}
                  {hasAccess(MenuAtendimentoClaims.GRID_ATENDIMENTO__ENDERECO_DESTINO) &&
                    item.enderecoDestino && (
                      <Grid item xs={12} display="flex" gap={1}>
                        <Chave>Endereço destino:</Chave>
                        <Valor textAlign="right">{item.enderecoDestino ?? ' -'}</Valor>
                      </Grid>
                    )}
                </Grid>
                <Grid item container xs={12} mt={-4} mb={0.8}>
                  <Box
                    width="100%"
                    display="flex"
                    flexDirection="row-reverse"
                    sx={{ zIndex: 99 }}
                    pr={1}
                  >
                    {hasAccess(MenuAtendimentoClaims.VISUALIZAR_HISTORICO_ATENDIMENTO) && (
                      <HistoricoPaciente atendimentoId={item.atendimentoId} />
                    )}
                    {item.statusPaciente === StatusAtendimentoEnum.ENCERRADO &&
                      hasAccess(MenuAtendimentoClaims.GERAR_BOLETIM) && (
                        <Tooltip title="Gerar boletim" placement="top">
                          <Box
                            sx={{ cursor: 'pointer' }}
                            onClick={(e) => {
                              e.stopPropagation();
                              gerarRelatorio(item);
                            }}
                          >
                            <Icon path={mdiFileDownload} color={Colors.primary} size={1} />
                          </Box>
                        </Tooltip>
                      )}
                  </Box>
                </Grid>
              </Grid>
            );
          })}
        </Grid>

        <Grid item xs={12}>
          <Valor mt={3} align="center">
            {!hasNext && !loading && 'Fim dos resultados'}
          </Valor>
        </Grid>
      </Box>
    </>
  );
};
