import { PropsWithChildren } from 'react';
import styled from 'styled-components';

import { Colors } from '../../../core/colors';
import { useAuthenticationContext } from '../../contexts/authentication/authenticationContext';

interface Props {
  temaSecondary: string;
}

const ColorContainer = styled.div<Props>`
  & > div > div:first-child {
    background-color: ${({ temaSecondary }) => temaSecondary ?? Colors.ciano};
  }

  & div > div > ul.MuiList-root a div.MuiButtonBase-root {
    margin: 13px 0;
  }
  & div > div:first-child > a:first-child > div.MuiButtonBase-root {
    background-color: transparent;
  }
  overflow: auto;
`;

const SideLayoutColorContainer = ({ children }: PropsWithChildren<object>) => {
  const {
    state: { tema }
  } = useAuthenticationContext();

  return (
    <ColorContainer temaSecondary={tema.light?.secondary ?? Colors.ciano}>
      {children}
    </ColorContainer>
  );
};

export default SideLayoutColorContainer;
