import { yupResolver } from '@hookform/resolvers/yup';
import { CircularProgress } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { FieldErrors, FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { Colors } from '../../../core/colors';
import {
  CriarMotivoAtendimentoModel,
  DADOS_CRIAR_MOTIVO_ATENDIMENTO_DEFAULT,
  dadosCriarMotivoAtendimentoFormModelSchema
} from '../../../models/MotivoAtendimento/MotivoAtendimento.model';
import { RoutesObj } from '../../../routes/index.routes';
import { useMotivoAtendimentoService } from '../../../service/motivoAtendimento.service';
import ButtonModal from '../../components/Button/ButtonModal';

import FormCriarMotivoAtendimento from './FormCriarMotivoAtendimento';

const CriarMotivoAtendimento = () => {
  const [loadingButtomSave, setLoadingButtomSave] = useState(false);
  const { salvarMotivoAtendimento } = useMotivoAtendimentoService();
  const { enqueueSnackbar } = useSnackbar();
  const navegate = useNavigate();

  const form = useForm<CriarMotivoAtendimentoModel>({
    mode: 'onBlur',
    defaultValues: DADOS_CRIAR_MOTIVO_ATENDIMENTO_DEFAULT,
    resolver: yupResolver(dadosCriarMotivoAtendimentoFormModelSchema)
  });

  const onValid = (data: CriarMotivoAtendimentoModel) => {
    setLoadingButtomSave(true);
    salvarMotivoAtendimento(data).then((x) => {
      setLoadingButtomSave(false);
      enqueueSnackbar('Registro salvo com sucesso!', {
        variant: 'success'
      });
      navegate(RoutesObj.MotivoAtendimento.Detalhes(x.data?.motivoAtendimentoId));
    });
  };

  const onInvalid = (_errors: FieldErrors<CriarMotivoAtendimentoModel>) => {};

  return (
    <>
      <FormProvider {...form}>
        <FormCriarMotivoAtendimento />
        <ButtonModal
          size="large"
          tipo="confirm"
          $backgroundColor={Colors.primary}
          onClick={form.handleSubmit(onValid, onInvalid)}
          loadingIndicator={<CircularProgress color={'primary'} size={16} />}
          loading={loadingButtomSave}
        >
          Salvar
        </ButtonModal>
      </FormProvider>
    </>
  );
};

export default CriarMotivoAtendimento;
