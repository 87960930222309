export enum ClassificacaoUrgenciaEnum {
  Emergencia = 0,
  MuitoUrgente = 1,
  Urgente = 2,
  PoucoUrgente = 3,
  NaoUrgente = 4,
  SemClassificacao = 5
}

export const ClassificacaoUrgenciaEnumLabel = new Map<number, string>([
  [ClassificacaoUrgenciaEnum.Emergencia, 'Emergência'],
  [ClassificacaoUrgenciaEnum.MuitoUrgente, 'Muito Urgente'],
  [ClassificacaoUrgenciaEnum.Urgente, 'Urgente'],
  [ClassificacaoUrgenciaEnum.PoucoUrgente, 'Pouco Urgente'],
  [ClassificacaoUrgenciaEnum.NaoUrgente, 'Não Urgente'],
  [ClassificacaoUrgenciaEnum.SemClassificacao, 'Sem Classificação']
]);

export const ListaClassificacaoUrgenciaEnum = [
  {
    id: ClassificacaoUrgenciaEnum.Emergencia,
    descricao: ClassificacaoUrgenciaEnumLabel.get(ClassificacaoUrgenciaEnum.Emergencia)
  },
  {
    id: ClassificacaoUrgenciaEnum.MuitoUrgente,
    descricao: ClassificacaoUrgenciaEnumLabel.get(ClassificacaoUrgenciaEnum.MuitoUrgente)
  },
  {
    id: ClassificacaoUrgenciaEnum.Urgente,
    descricao: ClassificacaoUrgenciaEnumLabel.get(ClassificacaoUrgenciaEnum.Urgente)
  },
  {
    id: ClassificacaoUrgenciaEnum.PoucoUrgente,
    descricao: ClassificacaoUrgenciaEnumLabel.get(ClassificacaoUrgenciaEnum.PoucoUrgente)
  },
  {
    id: ClassificacaoUrgenciaEnum.NaoUrgente,
    descricao: ClassificacaoUrgenciaEnumLabel.get(ClassificacaoUrgenciaEnum.NaoUrgente)
  },
  {
    id: ClassificacaoUrgenciaEnum.SemClassificacao,
    descricao: ClassificacaoUrgenciaEnumLabel.get(ClassificacaoUrgenciaEnum.SemClassificacao)
  }
];
