import { Typography } from '@mui/material';

interface Props {
  texto: string;
  sx?: any;
}
export const TituloForm = ({ texto, sx }: Props) => {
  return (
    <div style={{ marginBottom: -12 }}>
      <Typography fontWeight={500} fontSize={17} color="#757985" style={sx}>
        {texto}
      </Typography>
    </div>
  );
};
