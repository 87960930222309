import { yupResolver } from '@hookform/resolvers/yup';
import { mdiClipboardTextClockOutline } from '@mdi/js';
import {
  OptActionToolbar,
  OptBackdrop,
  OptLoading,
  OptSideLayoutContent,
  OptTimeline,
  OptTimelineAction
} from '@optsol/react';
import { OptTimelineField } from '@optsol/react/lib/esm/components/OptTimeline/OptTimelineTableValue';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';

import { ExcluirEnderecoEmpresaRequest } from '../../../models/dtos/Empresa/Endereco/excluirEnderecoEmpresaRequest.model';
import { GrupoAcessoResponse } from '../../../models/dtos/Usuario/usuarioSearchResponse.model';
import {
  DADOS_EMPRESA_DEFAULT,
  dadosEmpresaModelSchema,
  EmpresaModel
} from '../../../models/Empresa/Empresa.model';
import { EnderecoModel } from '../../../models/Endereco/Endereco.model';
import { MenuEmpresaClaims } from '../../../models/enums/AcessoClaims';
import { RoutesObj } from '../../../routes/index.routes';
import { useEmpresaService } from '../../../service/empresa.service';
import { useHistoricoService } from '../../../service/historico.service';
import { useUsuarioService } from '../../../service/usuario.service';
import { validarCNPJ } from '../../../shared/utils/functions';
import { BackdropContainer } from '../../components/BackdropContainer/BackdropContainer';
import { BotaoDeAcaoToolbar } from '../../components/Button/BotaoDeAcaoToolbar';
import { ImageLoadingContainer } from '../../components/ImageLoadingContainer/ImageLoadingContainer';
import { Modal } from '../../components/Modal/Modal';
import { ToolbarContainer } from '../../components/ToolbarContainer';
import { useAuthenticationContext } from '../../contexts/authentication/authenticationContext';

import FormEditarEmpresa from './FormEditarEmpresa';
import { ChaveModel } from './ListaChaves/ChaveModel/ChaveModel';

const EditarEmpresa = () => {
  const form = useForm<EmpresaModel>({
    mode: 'onBlur',
    defaultValues: DADOS_EMPRESA_DEFAULT,
    resolver: yupResolver(dadosEmpresaModelSchema)
  });
  const { obterGrupoAcesso } = useUsuarioService();

  const [gruposAcesso, setGruposAcesso] = useState<GrupoAcessoResponse[]>([]);
  async function obterPerfisAcesso() {
    const result = await obterGrupoAcesso();
    setGruposAcesso(result.data);
  }
  const {
    state: { userInfo, tema },
    hasAccess,
    recarregarUserInfo
  } = useAuthenticationContext();

  const {
    obterEmpresaById,
    atualizarEmpresa,
    salvarEndereco,
    excluirEndereco,
    adicionarArquivo,
    obterArquivo,
    alterarArquivo,
    obterEmpresaByIdIqf,
    obterArquivoImagemHistorico,
    adicionarChaveIntegracao,
    removerChaveIntegracao,
    validarLoginSenhaIntegracao
  } = useEmpresaService();
  const params = useParams();
  const [empresaIsLoading, setEmpresaIsLoading] = useState<boolean>(false);
  const [salvarEmpresaIsLoading, setSalvarEmpresaIsLoading] = useState<boolean>(false);
  const [imagemURL, setImagemURL] = useState<string>('');
  const [filenName, setFilenName] = useState<string>('');
  const [modalHistorico, setModalHistorico] = useState<boolean>(false);
  const [openBackDropContainer, setOpenBackDropContainer] = useState<boolean>(false);
  const [timeLineData, setTimeLineData] = useState<OptTimelineAction[]>();
  const { obterHistoryEntidade, baixarArquivoDaTimeline } = useHistoricoService();
  const toggleModal = () => {
    setModalHistorico((prevState) => !prevState);
  };
  const toggleModalBackDropContainer = () => {
    setOpenBackDropContainer((prevState) => !prevState);
  };
  const { enqueueSnackbar } = useSnackbar();
  const { id } = params;
  async function obterEmpresa() {
    try {
      setEmpresaIsLoading(true);
      let result: any = {};
      if (hasAccess(MenuEmpresaClaims.MENUS__MENU_EMPRESA_CLIENTE) && userInfo.data) {
        //todo se for perfil de empresa cliente
        result = await obterEmpresaById(id ?? '');
      } else {
        //todo se for perfil optsol
        result = await obterEmpresaByIdIqf(id ?? '');
      }

      form.reset({
        ...result.data
      });
    } finally {
      setEmpresaIsLoading(false);
    }
  }

  const toggleAlterarDadosEmpresaModal = () => {
    setSalvarEmpresaIsLoading((prevState) => !prevState);
  };

  const editarEmpresa = async () => {
    try {
      toggleAlterarDadosEmpresaModal();
      if (!params.id) return;

      const empresaModel = form.getValues();
      const documento = empresaModel.cnpj.trim();

      if (!validarCNPJ(documento)) return;

      await atualizarEmpresa(id ?? '', empresaModel);

      if (
        userInfo.data?.cor !== empresaModel.cor ||
        userInfo.data?.corSecundaria !== empresaModel.corSecundaria
      ) {
        recarregarUserInfo();
      }
    } finally {
      toggleAlterarDadosEmpresaModal();
    }
  };

  async function validarSenhaIntegracaoHandler(loginP: string, senhaP: string) {
    try {
      setSalvarEmpresaIsLoading(true);
      const result = await validarLoginSenhaIntegracao({ login: loginP, senha: senhaP });
      if (result.data.bool) {
        enqueueSnackbar('Credenciais para rastreamento válidas!', {
          variant: 'success'
        });
      } else {
        enqueueSnackbar('Credenciais para rastreamento inválidas!', {
          variant: 'error'
        });
      }
    } finally {
      setSalvarEmpresaIsLoading(false);
    }
  }
  async function criarCahve(nome: string): Promise<ChaveModel> {
    try {
      setSalvarEmpresaIsLoading(true);
      return (await adicionarChaveIntegracao(id ?? '', nome)).data;
    } finally {
      setSalvarEmpresaIsLoading(false);
    }
  }

  async function removerChave(chaveId: string): Promise<void> {
    try {
      setSalvarEmpresaIsLoading(true);
      await removerChaveIntegracao(id ?? '', chaveId);
    } finally {
      setSalvarEmpresaIsLoading(false);
    }
  }

  const salvarImagem = async (file: File) => {
    if (!userInfo.data?.imagem) {
      await adicionarArquivo(id ?? '', file);
    } else {
      await alterarArquivo(id ?? '', file);
    }
    recarregarUserInfo();
  };

  const salvarEnderecoHandler = async (enderecoModel: EnderecoModel) => {
    if (!params.id) return;
    // enderecoModel.empresaId = id;
    const formValues = form.getValues();
    const view = await salvarEndereco(id ?? '', enderecoModel);
    formValues.localizacoes?.push(view.data);
    formValues.localizacoes = [...(formValues.localizacoes ?? [])];
    form.reset(formValues);
  };

  const excluirEnderecoHandler = async (enderecoId: string) => {
    try {
      if (!params.id) return;
      setSalvarEmpresaIsLoading(true);
      const request: ExcluirEnderecoEmpresaRequest = {
        empresaId: id ?? '',
        id: enderecoId
      };
      const formValues = form.getValues();
      await excluirEndereco(request);
      formValues.localizacoes = [
        ...(form.getValues().localizacoes?.filter((x) => x.enderecoId !== enderecoId) ?? [])
      ];
      form.reset(formValues);
    } finally {
      setSalvarEmpresaIsLoading(false);
    }
  };

  async function HandleLogo() {
    if (userInfo.data && userInfo.data.imagem) {
      const logoAPI = await obterArquivo(userInfo.data.tenantId);
      const myImage = URL.createObjectURL(logoAPI);
      setImagemURL(myImage);
    }
  }

  useEffect(() => {
    HandleLogo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    obterEmpresa();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    obterPerfisAcesso();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onClickTimelineValue = async (data: OptTimelineField) => {
    const extension = data.value.split('.');
    const fileName = data.name + '.' + extension[extension.length - 1];
    // Porcesso de download precisa ser testado caso o sistema implemente essa funcionalidade
    if (data.type === 'file') {
      try {
        await baixarArquivoDaTimeline(id ?? '', data.value, fileName);
      } catch (error) {
        console.log(error);
      }
    } else if (data.type === 'image') {
      setFilenName(data.value);
      toggleModalBackDropContainer();
    }
  };

  async function obterImageBlobURL() {
    try {
      const result = await obterArquivoImagemHistorico(filenName);
      return result;
    } catch {
      toggleModalBackDropContainer();
    }
  }

  useEffect(() => {
    if (modalHistorico && !timeLineData) {
      const obter = async () => {
        try {
          const historyData = await obterHistoryEntidade(
            'Empresa',
            userInfo.data?.tenantId ?? '',
            id as string
          );

          const data = historyData.versions.sort((a, b) => (a.order > b.order ? -1 : 1));
          data.map((history: { dateTimeAction: string | number | Date }) => {
            const newDate = new Date(history.dateTimeAction);
            const dataFormatada = new Intl.DateTimeFormat('pt-BR', {
              year: 'numeric',
              month: '2-digit',
              day: '2-digit',
              hour: '2-digit',
              hourCycle: 'h23',
              minute: '2-digit'
            }).format(newDate);
            return (history.dateTimeAction = dataFormatada);
          });
          setTimeLineData(data);
        } catch {
          console.log('Erro ao tentar carregar timeline values');
          toggleModal();
        }
      };
      obter();
    } else {
      setTimeout(() => {
        setTimeLineData(undefined);
      }, 300);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalHistorico]);

  return (
    <>
      <OptSideLayoutContent>
        <ToolbarContainer>
          <OptActionToolbar
            goBackRoute={
              hasAccess(MenuEmpresaClaims.MENUS__MENU_EMPRESA_OPTSOL)
                ? RoutesObj.EmpresaAdmin.Empresas
                : RoutesObj.Home
            }
            title="Editar empresa"
            color={tema.light?.primary}
            clearMargin
          >
            <BotaoDeAcaoToolbar
              onClick={toggleModal}
              texto="Histórico"
              startIcon={mdiClipboardTextClockOutline}
            />
          </OptActionToolbar>
        </ToolbarContainer>
        <Modal open={modalHistorico} onClose={toggleModal} title="Histórico" width="800px">
          <div style={{ marginTop: '1em' }}>
            {timeLineData && (
              <OptTimeline
                data={timeLineData ?? []}
                valuesTableOptions={{
                  nameHeader: 'Name',
                  valueHeader: 'Value',
                  onValueClick: (data: OptTimelineField) => {
                    onClickTimelineValue && onClickTimelineValue(data);
                  }
                }}
              />
            )}
            {!timeLineData && (
              <div style={{ height: '100px', overflow: 'none' }}>
                <OptLoading size={50} />
              </div>
            )}
          </div>
          {openBackDropContainer && (
            <BackdropContainer
              open={openBackDropContainer}
              handleClose={toggleModalBackDropContainer}
            >
              <ImageLoadingContainer obterImageBlobURL={obterImageBlobURL} />
            </BackdropContainer>
          )}
        </Modal>

        {!empresaIsLoading && (
          <FormProvider {...form}>
            <FormEditarEmpresa
              editarEmpresa={editarEmpresa}
              criarChave={criarCahve}
              removerChave={removerChave}
              isDirty={!!Object.keys(form.formState.dirtyFields).length}
              enderecos={form.getValues().localizacoes ?? []}
              salvarEndereco={salvarEnderecoHandler}
              excluirEndereco={excluirEnderecoHandler}
              salvarImagem={salvarImagem}
              empresaIsLoading={empresaIsLoading}
              imagem={imagemURL}
              gruposAcesso={gruposAcesso}
              validarLogin={validarSenhaIntegracaoHandler}
            />
          </FormProvider>
        )}
        <OptBackdrop open={empresaIsLoading} />
        <OptBackdrop open={salvarEmpresaIsLoading} />
      </OptSideLayoutContent>
    </>
  );
};

export default EditarEmpresa;
