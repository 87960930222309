import { OptChip } from '@optsol/react';

import { GrupoAcessoResponse } from '../../../../models/dtos/Usuario/usuarioSearchResponse.model';
import { PerfilAcessoEnum } from '../../../../models/enums/PerfilAcesso';

interface Props {
  lista: PerfilAcessoEnum[];
  listaGrupoAcesso: GrupoAcessoResponse[];
  disableForm?: boolean;
  onRemove: (id: PerfilAcessoEnum) => void;
}

export const ListaPerfisAcesso = ({ lista, listaGrupoAcesso, disableForm, onRemove }: Props) => {
  return (
    <>
      {lista.map((x) => (
        <OptChip
          key={`${x}`}
          label={listaGrupoAcesso.find((y) => y.codigo === x)?.nome}
          style={{
            borderRadius: '8px',
            height: 25,
            margin: '2px 2px 2px 0px'
          }}
          disabled={disableForm}
          onDelete={() => {
            onRemove(x ?? '');
          }}
        />
      ))}
    </>
  );
};
