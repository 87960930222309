import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { ptBR } from 'date-fns/locale';
import { SnackbarProvider } from 'notistack';
import { PropsWithChildren } from 'react';
import { BrowserRouter } from 'react-router-dom';
import styled from 'styled-components';

import { AuthenticationProvider } from '../contexts/authentication/authenticationContext';
import { SignalRProvider } from '../contexts/SignalRContext/SignalRContext';

export const CustomSnackBarProvider = styled(SnackbarProvider)`
  &.success {
    background-color: 'purple';
  }
  &.error {
    background-color: 'blue';
  }
  &.warning {
    background-color: 'green';
  }
  &.info {
    background-color: 'yellow';
  }
`;

const AppProviders = ({ children }: PropsWithChildren) => {
  return (
    <CustomSnackBarProvider
      maxSnack={4}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      autoHideDuration={4000}
      preventDuplicate
    >
      <BrowserRouter>
        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ptBR}>
          <AuthenticationProvider>
            <SignalRProvider>{children}</SignalRProvider>
          </AuthenticationProvider>
        </LocalizationProvider>
      </BrowserRouter>
    </CustomSnackBarProvider>
  );
};

export default AppProviders;
