import AccessibilityIcon from '@mui/icons-material/Accessibility';
import CancelIcon from '@mui/icons-material/Cancel';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import EmojiTransportationIcon from '@mui/icons-material/EmojiTransportation';
import GarageIcon from '@mui/icons-material/Garage';
import HealingIcon from '@mui/icons-material/Healing';
import LocalHospitalIcon from '@mui/icons-material/LocalHospital';
import NotificationAddIcon from '@mui/icons-material/NotificationAdd';
import NotificationsIcon from '@mui/icons-material/Notifications';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import TimeToLeave from '@mui/icons-material/TimeToLeave';
import { StepButton, StepLabel, Tooltip } from '@mui/material';
import Stack from '@mui/material/Stack';
import Step from '@mui/material/Step';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import { StepIconProps } from '@mui/material/StepIcon';
import Stepper from '@mui/material/Stepper';
import { styled } from '@mui/material/styles';
import { OptTheme } from '@optsol/react';

import { Colors } from '../../../core/colors';
import { TimelineModel } from '../../../models/Atendimento/Equipe/TimeLine.model';
import { MenuAtendimentoClaims } from '../../../models/enums/AcessoClaims';
import { useAuthenticationContext } from '../../contexts/authentication/authenticationContext';

const ColorlibConnector = styled(StepConnector)<{
  tema: { tema?: OptTheme };
  // }>(({ theme, tema }) => ({
}>(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 22
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      // backgroundImage: `linear-gradient( 95deg, ${tema.tema?.light?.primary} 0%, ${tema.tema?.light?.secondary} 50%, ${tema.tema?.light?.primaryContrast} 100%)`
      // backgroundImage: `linear-gradient( 95deg, ${Colors.timeline.primary} 0%, ${Colors.timeline.secondary} 50%, ${Colors.timeline.secondary} 100%)`
      background: Colors.timeline.secondary
    }
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      // backgroundImage: `linear-gradient( 95deg, ${tema.tema?.light?.primary} 0%, ${tema.tema?.light?.secondary} 50%, ${tema.tema?.light?.primaryContrast} 100%)`
      // backgroundImage: `linear-gradient( 95deg, ${Colors.timeline.primary} 0%, ${Colors.timeline.secondary} 50%, ${Colors.timeline.secondary} 100%)`
      background: Colors.timeline.secondary
    }
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 3,
    border: 0,
    backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
    borderRadius: 1
  }
}));

const ColorlibStepIconRoot = styled('div')<{
  ownerState: { completed?: boolean; active?: boolean; tema?: OptTheme };
}>(({ theme, ownerState }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#ccc',
  zIndex: 1,
  color: '#fff',
  width: 50,
  height: 50,
  display: 'flex',
  borderRadius: '50%',
  justifyContent: 'center',
  alignItems: 'center',
  ...(ownerState.active && {
    // backgroundImage: `linear-gradient( 136deg, ${ownerState.tema?.light?.primary} 0%, ${ownerState.tema?.light?.secondary} 50%, ${ownerState.tema?.light?.primary} 100%)`,
    backgroundImage: `linear-gradient( 136deg, ${Colors.timeline.primary} 0%, ${Colors.timeline.secondary} 50%, ${Colors.timeline.primary} 100%)`,
    boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)'
  }),
  ...(ownerState.completed && {
    // backgroundImage: `linear-gradient( 136deg, ${ownerState.tema?.light?.primary} 0%, ${ownerState.tema?.light?.secondary} 50%, ${ownerState.tema?.light?.primary} 100%)`
    backgroundImage: `linear-gradient( 136deg, ${Colors.timeline.primary} 0%, ${Colors.timeline.secondary} 50%, ${Colors.timeline.primary} 100%)`
  })
}));

interface IconProps extends StepIconProps {
  iconLabel: string;
}

function ColorlibStepIcon(props: IconProps) {
  const { active, completed, className } = props;

  const icons: { [index: string]: React.ReactElement } = {
    NotificacaoEnviada: <NotificationAddIcon />,
    NotificacaoRecebida: <NotificationsActiveIcon />,
    NotificacaoLida: <NotificationsIcon />,
    EquipeRecusouAtendimento: <CancelIcon />,
    EquipeAceitouAtendimento: <DoneAllIcon />,
    EquipeSaiuParaAtendimento: <GarageIcon />,
    EquipeChegouOrigem: <HealingIcon />,
    EquipeSaiuParaDestino: <EmojiTransportationIcon />,
    EquipeChegouDestino: <LocalHospitalIcon />,
    EquipeSaiuParaRetornoPaciente: <TimeToLeave />,
    EquipeChegouRetornoPaciente: <TimeToLeave />,
    AtendimentoConcluido: <GarageIcon />
  };
  const {
    state: { tema }
  } = useAuthenticationContext();

  return (
    <ColorlibStepIconRoot ownerState={{ completed, active, tema }} className={className}>
      {icons[String(props.iconLabel)]}
    </ColorlibStepIconRoot>
  );
}

interface Props {
  timeLine: TimelineModel[] | null;
  equipeId: string;
  pacienteId: string;
  notificacaoEnviada: boolean;
  marcarStep: (
    step: string,
    equipeId: string,
    pacienteId: string,
    timeLineId: string
  ) => Promise<void>;
  disabled?: boolean;
}

export default function StepperCustomizado({
  timeLine,
  equipeId,
  pacienteId,
  notificacaoEnviada,
  marcarStep,
  disabled
}: Props) {
  const {
    state: { tema },
    hasAccess
  } = useAuthenticationContext();

  function clickSlep(stepLabel: string, timeLineId: string) {
    marcarStep(stepLabel, equipeId, pacienteId, timeLineId);
  }

  function complete(timeLineVeiculo: TimelineModel) {
    if (!timeLineVeiculo.confirmado) {
      clickSlep(timeLineVeiculo.descricao ?? '', timeLineVeiculo.id ?? '');
    }
  }

  if (!timeLine) {
    return <></>;
  }

  return (
    <Stack sx={{ width: '100%' }} spacing={0}>
      <Stepper
        connector={<ColorlibConnector tema={{ tema }} />}
        alternativeLabel
        nonLinear
        activeStep={-1}
      >
        {timeLine.map((time) => (
          <Step
            key={time.id + Math.random()}
            completed={time.confirmado ?? false}
            disabled={!notificacaoEnviada}
          >
            <Tooltip
              placement="top"
              title={
                time.dataHoraConfirmado &&
                `Confirmação: ${new Date(time.dataHoraConfirmado).toLocaleString('pt-BR')}`
              }
            >
              <StepButton
                disabled={!hasAccess(MenuAtendimentoClaims.ATUALIZAR_STEP_TIMELINE) || disabled}
                color="inherit"
                onClick={() => {
                  complete(time);
                }}
              >
                <StepLabel
                  StepIconComponent={(props) => (
                    <ColorlibStepIcon iconLabel={time.nome ?? ''} {...props}></ColorlibStepIcon>
                  )}
                  icon={<AccessibilityIcon />}
                >
                  {time.descricao}
                </StepLabel>
              </StepButton>
            </Tooltip>
          </Step>
        ))}
      </Stepper>
    </Stack>
  );
}
