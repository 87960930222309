import * as yup from 'yup';

export interface CidadeModel {
  cidadeId: string;
  planoId: string;
  servicoCobertoPlanoId: string;
  estadoId: string;
  nome: string;
  codigoIBGE: string;
}

export interface CidadeIbgeResponseModel {
  id: string;
  nome: string;
}

export const DADOS_CIDADE_PLANO_DEFAULT: CidadeModel = {
  cidadeId: '',
  estadoId: '',
  servicoCobertoPlanoId: '',
  planoId: '',
  nome: '',
  codigoIBGE: ''
};

const MSG_REQUIRED = 'Campo obrigatório';
export const dadosCidadeFormModelSchema: yup.SchemaOf<CidadeModel> = yup.object().shape({
  cidadeId: yup.string().required(MSG_REQUIRED),
  planoId: yup.string().required(MSG_REQUIRED),
  servicoCobertoPlanoId: yup.string().required(MSG_REQUIRED),
  estadoId: yup.string().required(MSG_REQUIRED),
  nome: yup.string().required(MSG_REQUIRED),
  codigoIBGE: yup.string().required(MSG_REQUIRED)
});
