import {
  Autocomplete,
  debounce,
  Grid,
  InputLabel,
  MenuItem,
  TextField,
  Typography
} from '@mui/material';
import { OptBackdrop } from '@optsol/react';
import { useCallback, useEffect, useRef, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useLocation, useParams } from 'react-router-dom';

import { AtendimentoFormModel } from '../../../models/Atendimento/Atendimento.model';
import {
  ClienteModel,
  DADOS_CLLIENTE_RESPONSE_DEFAULT
} from '../../../models/Cliente/Cliente.model';
import { ClienteSearchResponse } from '../../../models/dtos/Cliente/clienteSearchResponse.model';
import { MenuAtendimentoClaims } from '../../../models/enums/AcessoClaims';
import { MotivoAtendimentoModel } from '../../../models/MotivoAtendimento/MotivoAtendimento.model';
import { useClienteService } from '../../../service/cliente.service';
import { useMotivoAtendimentoService } from '../../../service/motivoAtendimento.service';
import { validarCPF } from '../../../shared/utils/functions';
import { PaginatedSearchRequest, SearchRequest } from '../../../shared/utils/searchRequest';
import { ErrorMessage, FormSelect, FormTextField } from '../../components/Form';
import { FormNumberFormat } from '../../components/Form/FormNumberFormat';
import { TituloForm } from '../../components/Form/TituloForm';
import { ProtectedContent } from '../../components/ProtectedContent/ProtectedContent';
import { useAuthenticationContext } from '../../contexts/authentication/authenticationContext';
import { ClientePai } from '../cliente/clientePai/ClientePai';

import { FormAgendamento } from './tarm/FormAgendamento/FormAgendamento';
import { ModalSelecaoClientes } from './tarm/ModalSelecaoCliente/ModalSelecaoClientes';

interface Props {
  editarAtendimento: () => Promise<void>;
  obterAtendimentoById: () => Promise<void>;
  obterMotivos: () => Promise<MotivoAtendimentoModel[]>;
  defaultCliente: ClienteSearchResponse | null;
  disableForm?: boolean;
  disableFormAgendamento?: boolean;
  possuiId?: (id: string) => void;
}

export const FormAtendimento = ({
  editarAtendimento,
  obterAtendimentoById,
  obterMotivos,
  defaultCliente,
  disableForm,
  disableFormAgendamento,
  possuiId
}: Props) => {
  const {
    control,
    watch,
    setError,
    getValues,
    reset,
    setValue,
    formState: { errors, isDirty }
  } = useFormContext<AtendimentoFormModel>();
  const params = useParams();
  const { obterClientePorTelefone } = useClienteService();
  const { hasAccess } = useAuthenticationContext();
  const { buscarGridClientesPaginated } = useClienteService();
  const { buscarGridMotivoAtendimentoPaginated } = useMotivoAtendimentoService();
  const [cliente, setCliente] = useState<ClienteSearchResponse | null>(defaultCliente);
  const [clientes, setClientes] = useState<ClienteSearchResponse[] | null>();
  const [listaPerfis] = useState<any[]>([
    { id: 0, nome: 'Primário' },
    { id: 1, nome: 'Secundário' },
    { id: 2, nome: 'Terciário' }
  ]);
  const [listaMotivos, setListaMotivos] = useState<MotivoAtendimentoModel[]>([]);

  const [modalSelecaoClientes, setModalSelecaoClientes] = useState(false);
  const [clientesParaSelecao, setClientesParaSelecao] = useState<ClienteModel[]>([]);
  const [obterClientesLoading, setObterClientesLoading] = useState(false);

  const toggleModalSelecaoClientes = () => {
    setModalSelecaoClientes((prevState) => !prevState);
  };
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const telefone = searchParams.get('telefone');

  const monitorarTelefone = watch('telefoneSolicitante');
  const monitorarCpfCnpj = watch('cpfSolicitante');
  const atendimentoFormValue = getValues();

  const inputRef = useRef<HTMLInputElement>();
  const {
    state: { tema }
  } = useAuthenticationContext();

  async function obterMotivosHandler() {
    const result = await obterMotivos();
    setListaMotivos(result);
  }

  function filtrarClientesHandler(newValue: string) {
    filtrarClientes(newValue);
  }

  const changeHandler = useCallback((value: string) => {
    filtrarClientesHandler(value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedClienteChangeHandler = useCallback(debounce(changeHandler, 500), []);

  async function filtrarClientes(termo: string) {
    if (termo) {
      const request: SearchRequest<PaginatedSearchRequest> = {
        page: 0,
        pageSize: 10,
        search: {
          termoBuscado: termo,
          apenasAtivo: true
        }
      };
      const result = await buscarGridClientesPaginated(request);
      setClientes(result.data);
    }
  }

  useEffect(() => {
    if (atendimentoFormValue.motivoAtendimentoId && listaMotivos.length === 0) {
      obterMotivosHandler();
    }
    if (hasAccess(MenuAtendimentoClaims.INSERIR_ATENDIMENTO_USUARIO_EXTERNO) && !params.id) {
      obterClientePorTelefoneHandler(null);
    }
  }, []);

  function validarCpfCnpjOnBlur() {
    if (monitorarCpfCnpj) {
      if (!validarCPF(monitorarCpfCnpj)) {
        setError('cpfSolicitante', { message: 'Documento inválido' });
        return;
      } else {
        setError('cpfSolicitante', { message: '' });
      }

      const novoAtendimento = { ...atendimentoFormValue };
      reset({ ...novoAtendimento });
    }
  }

  async function filtrarMotivos(termo: string) {
    if (termo) {
      const request: SearchRequest<PaginatedSearchRequest> = {
        page: 0,
        pageSize: 10,
        search: {
          termoBuscado: termo,
          apenasAtivo: true
        }
      };
      const result = await buscarGridMotivoAtendimentoPaginated(request);
      setListaMotivos(result.data);
    }
  }

  function filtrarMotivosHandler(newValue: string) {
    filtrarMotivos(newValue);
  }

  const changeMotivoHandler = useCallback((value: string) => {
    filtrarMotivosHandler(value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedMotivoChangeHandler = useCallback(debounce(changeMotivoHandler, 500), []);

  async function editarAtendimentoHandler() {
    const { motivoAtendimentoId } = getValues();
    const motivosFiltrados = listaMotivos.find(
      (x) => x.motivoAtendimentoId === motivoAtendimentoId
    );

    if (motivosFiltrados) {
      setValue('motivoAtendimento', motivosFiltrados);
      reset({ ...getValues() });
    }
    await editarAtendimento();
  }

  async function obterClientePorTelefoneHandler(telefone: string | null) {
    try {
      setObterClientesLoading(true);
      const result = await obterClientePorTelefone(telefone);

      if (result.data.length > 0) {
        setClientesParaSelecao(result.data);
        toggleModalSelecaoClientes();
      } else {
        if (!hasAccess(MenuAtendimentoClaims.INSERIR_ATENDIMENTO_USUARIO_EXTERNO))
          editarAtendimentoHandler();
      }
    } finally {
      setObterClientesLoading(false);
    }
  }

  async function editarClienteHandler() {
    await editarAtendimento();
    obterAtendimentoById();
  }

  useEffect(() => {
    if (atendimentoFormValue.atendimentoId && possuiId) {
      possuiId(atendimentoFormValue.atendimentoId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [atendimentoFormValue.atendimentoId]);

  useEffect(() => {
    setCliente(defaultCliente);
  }, [defaultCliente]);

  useEffect(() => {
    if (!atendimentoFormValue.atendimentoId && inputRef.current) {
      inputRef.current.focus();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function selecionarCliente(cliente: ClienteModel | null) {
    if (cliente) {
      toggleModalSelecaoClientes();
      setValue('cliente', cliente);
      editarAtendimentoHandler();
    } else {
      setValue('cliente', DADOS_CLLIENTE_RESPONSE_DEFAULT);
      toggleModalSelecaoClientes();
      editarAtendimentoHandler();
    }
  }

  useEffect(() => {
    if (telefone) {
      setValue('telefoneSolicitante', telefone);

      if (!hasAccess(MenuAtendimentoClaims.INSERIR_ATENDIMENTO_USUARIO_EXTERNO)) {
        obterClientePorTelefoneHandler(telefone);
      }
    }
  }, []);
  return (
    <>
      <OptBackdrop open={obterClientesLoading} />
      <ModalSelecaoClientes
        clientes={clientesParaSelecao}
        selecionarCliente={selecionarCliente}
        isOpen={modalSelecaoClientes}
      />

      <Grid container gap={2} p={2}>
        <Grid item container spacing={2} p={2}>
          <Grid item xs={12}>
            <TituloForm texto="Dados do solicitante" />
          </Grid>
          {atendimentoFormValue.atendimentoId && (
            <Grid item xs={6}>
              <ProtectedContent mode="disable" access={MenuAtendimentoClaims.ATUALIZAR}>
                <FormTextField
                  label="Nome"
                  name="nomeSolicitante"
                  control={control}
                  onBlur={() => {
                    isDirty && editarAtendimentoHandler();
                  }}
                  disabled={disableForm}
                />
              </ProtectedContent>
            </Grid>
          )}
          <Grid item xs={6}>
            <ProtectedContent mode="disable" access={MenuAtendimentoClaims.ATUALIZAR}>
              <FormNumberFormat
                inputRef={inputRef}
                control={control}
                format={
                  monitorarTelefone
                    ?.replace(/[^\w\s]/gi, '')
                    .replace(' ', '')
                    ?.trim().length >= 11
                    ? '(##) #####-####'
                    : '(##) ####-#####'
                }
                onBlur={() => {
                  if (!hasAccess(MenuAtendimentoClaims.INSERIR_ATENDIMENTO_USUARIO_EXTERNO)) {
                    isDirty && !params.id
                      ? obterClientePorTelefoneHandler(getValues().telefoneSolicitante)
                      : modalSelecaoClientes
                      ? null
                      : editarAtendimentoHandler();
                  }
                }}
                name="telefoneSolicitante"
                label="Telefone"
                placeholder="Telefone"
                disabled={disableForm}
              />
            </ProtectedContent>
          </Grid>
          {atendimentoFormValue.atendimentoId && (
            <>
              <Grid item xs={6}>
                <ProtectedContent mode="disable" access={MenuAtendimentoClaims.ATUALIZAR}>
                  <FormNumberFormat
                    control={control}
                    format={'###.###.###-##'}
                    name="cpfSolicitante"
                    label="CPF"
                    placeholder="CPF"
                    onBlur={() => {
                      validarCpfCnpjOnBlur();
                      isDirty && editarAtendimentoHandler();
                    }}
                    disabled={disableForm}
                  />
                </ProtectedContent>
              </Grid>
              <Grid item xs={6}>
                <ProtectedContent mode="disable" access={MenuAtendimentoClaims.ATUALIZAR}>
                  <FormSelect
                    control={control}
                    onBlur={() => {
                      editarAtendimentoHandler();
                    }}
                    name="perfilSolicitante"
                    label="Perfil solicitação"
                    disabled={disableForm}
                    defaultValue={getValues('perfilSolicitante') ?? ''}
                  >
                    {listaPerfis.map((x) => {
                      return (
                        <MenuItem key={x.id} value={x.id}>
                          {x.nome}
                        </MenuItem>
                      );
                    })}
                  </FormSelect>
                </ProtectedContent>

                <ErrorMessage error={errors.perfilSolicitante} />
              </Grid>
            </>
          )}
        </Grid>
        {atendimentoFormValue.atendimentoId && (
          <Grid item xs={12} pl={2}>
            <TituloForm texto="Informações do cliente" />
          </Grid>
        )}
        <Grid container spacing={2} p={2}>
          {atendimentoFormValue.atendimentoId && (
            <>
              <Grid container item xs={6} spacing={1}>
                {watch('cliente')?.clientePai && (
                  <ClientePai
                    clienteLabel={
                      `${watch('cliente')?.clientePai?.cnpj} - ${
                        watch('cliente')?.clientePai?.nome
                      }` ?? ''
                    }
                  />
                )}
                <Grid item xs={12}>
                  <Controller
                    name="cliente"
                    control={control}
                    render={({ field: { onChange } }) => (
                      <ProtectedContent mode="disable" access={MenuAtendimentoClaims.ATUALIZAR}>
                        <InputLabel>
                          <Typography color={tema.light?.primary} lineHeight={1}>
                            Cliente
                          </Typography>
                        </InputLabel>
                        <Autocomplete
                          fullWidth
                          options={clientes ? clientes : []}
                          getOptionLabel={(option) =>
                            typeof option === 'object'
                              ? `${option.cpf ? option.cpf : option.cnpj} - ${option.nome}`
                              : ''
                          }
                          value={cliente}
                          onChange={(_event, newValue: any) => {
                            setCliente(newValue);
                            onChange(newValue ? newValue : null);
                            editarClienteHandler();
                          }}
                          onInputChange={(_e, value) => debouncedClienteChangeHandler(value)}
                          disabled={disableForm}
                          renderInput={(params) => (
                            <ProtectedContent
                              mode="disable"
                              access={MenuAtendimentoClaims.ATUALIZAR}
                            >
                              <TextField
                                {...params}
                                size="small"
                                variant="outlined"
                                placeholder="Selecione um cliente"
                                disabled={disableForm}
                              />
                            </ProtectedContent>
                          )}
                          freeSolo
                        />
                      </ProtectedContent>
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <ProtectedContent mode="disable" access={MenuAtendimentoClaims.ATUALIZAR}>
                    <Controller
                      name="motivoAtendimento"
                      control={control}
                      render={() => (
                        <ProtectedContent mode="disable" access={MenuAtendimentoClaims.ATUALIZAR}>
                          <InputLabel>
                            <Typography color={tema.light?.primary} lineHeight={1}>
                              Motivo atendimento
                            </Typography>
                          </InputLabel>
                          <Autocomplete
                            fullWidth
                            options={listaMotivos ? listaMotivos : []}
                            getOptionLabel={(option) =>
                              typeof option === 'object' ? `${option.descricao}` : ''
                            }
                            value={watch('motivoAtendimento')}
                            onChange={(_event, newValue: any) => {
                              setValue('motivoAtendimento', newValue ? newValue : null);
                              setValue(
                                'motivoAtendimentoId',
                                newValue ? newValue.motivoAtendimentoId : null
                              );
                              editarAtendimentoHandler();
                            }}
                            onInputChange={(_e, value) => debouncedMotivoChangeHandler(value)}
                            disabled={disableForm}
                            disableClearable
                            renderInput={(params) => (
                              <ProtectedContent
                                mode="disable"
                                access={MenuAtendimentoClaims.ATUALIZAR}
                              >
                                <TextField
                                  {...params}
                                  size="small"
                                  variant="outlined"
                                  placeholder="Selecione um cliente"
                                  disabled={disableForm}
                                />
                              </ProtectedContent>
                            )}
                            freeSolo
                          />
                        </ProtectedContent>
                      )}
                    />
                  </ProtectedContent>
                  <ErrorMessage error={errors.motivoAtendimentoId} />
                </Grid>
              </Grid>
              <Grid container item xs={6}>
                <Grid item xs={12}>
                  <ProtectedContent mode="disable" access={MenuAtendimentoClaims.ATUALIZAR}>
                    <FormTextField
                      inputProps={{ maxLength: 4000 }}
                      control={control}
                      name="informacoesRelevantes"
                      label="Informações importantes"
                      multiline
                      rows={5}
                      onBlur={() => {
                        isDirty && editarAtendimentoHandler();
                      }}
                      disabled={disableForm}
                    />
                    {`${watch('informacoesRelevantes')?.length ?? 0} / 4000`}
                  </ProtectedContent>
                </Grid>
              </Grid>
              {hasAccess(MenuAtendimentoClaims.AGENDAR_ATENDIMENTO) && (
                <Grid xs={12}>
                  <FormAgendamento
                    editarAtendimento={editarAtendimento}
                    disableForm={disableFormAgendamento}
                  />
                </Grid>
              )}
            </>
          )}
        </Grid>
        {atendimentoFormValue.atendimentoId && (
          <Grid item xs={12} pl={4}>
            <TituloForm texto="Envolvidos" />
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default FormAtendimento;
