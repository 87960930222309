import { mdiPlus } from '@mdi/js';
import { Box } from '@mui/material';
import { OptActionToolbar, OptSearchField } from '@optsol/react';
import { useState } from 'react';

import { Colors } from '../../../core/colors';
import { MenuEmpresaClaims } from '../../../models/enums/AcessoClaims';
import { RoutesObj } from '../../../routes/index.routes';
import { BotaoDeAcaoToolbar } from '../../components/Button/BotaoDeAcaoToolbar';
import { Modal } from '../../components/Modal/Modal';
import { ProtectedContent } from '../../components/ProtectedContent/ProtectedContent';
import { ToolbarContainer } from '../../components/ToolbarContainer';
import { useAuthenticationContext } from '../../contexts/authentication/authenticationContext';
import CadastroEmpresa from '../../features/empresa/CadastroEmpresa';
import * as S from '../../style/toolbar-index';

interface Props {
  filtrarGridBySearch: (data?: string) => void;
}

export const ToolbarEmpresa = ({ filtrarGridBySearch }: Props) => {
  const { state } = useAuthenticationContext();
  const [isOpen, setIsOpen] = useState(false);
  const toggleModal = () => {
    setIsOpen((prevState) => !prevState);
  };
  return (
    <ToolbarContainer>
      <OptActionToolbar
        goBackRoute={RoutesObj.Home}
        title="Lista de empresas"
        background={Colors.white}
        color={state.tema.light?.primary}
        clearMargin
      >
        <Box
          sx={{
            display: 'flex',
            flex: 1,
            marginLeft: '30px',
            marginBottom: '-11px'
          }}
        >
          <S.SearchContainer>
            <OptSearchField onSearch={filtrarGridBySearch} placeholder="Search" />
          </S.SearchContainer>
        </Box>
        <ProtectedContent access={MenuEmpresaClaims.INSERIR}>
          <BotaoDeAcaoToolbar onClick={toggleModal} texto="Novo" startIcon={mdiPlus} />
        </ProtectedContent>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end'
          }}
        >
          <Modal
            open={isOpen}
            onClose={toggleModal}
            title="Cadastro de empresa"
            width="500px"
            overflow="hidden"
          >
            <CadastroEmpresa />
          </Modal>
        </Box>
      </OptActionToolbar>
    </ToolbarContainer>
  );
};
