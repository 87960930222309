import { OptActionToolbar } from '@optsol/react';

import { Colors } from '../../../../core/colors';
import { RoutesObj } from '../../../../routes/index.routes';
import { ToolbarContainer } from '../../../components/ToolbarContainer';
import { useAuthenticationContext } from '../../../contexts/authentication/authenticationContext';

export const ToolbarRelatorio = () => {
  const { state } = useAuthenticationContext();

  return (
    <ToolbarContainer>
      <OptActionToolbar
        goBackRoute={RoutesObj.Home}
        title="Relatórios"
        background={Colors.white}
        color={state.tema.light?.primary}
        clearMargin
      ></OptActionToolbar>
    </ToolbarContainer>
  );
};
