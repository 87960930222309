import { useCallback } from 'react';

import { ClienteModel } from '../models/Cliente/Cliente.model';
import { AtualizarClienteRequest } from '../models/dtos/Cliente/atualizarClienteRequest.model';
import { ClienteSearchResponse } from '../models/dtos/Cliente/clienteSearchResponse.model';
import { ContatoResponse } from '../models/dtos/Cliente/Contato/contatoResponse.model';
import { CriarContatoRequest } from '../models/dtos/Cliente/Contato/criarContatoRequest.model';
import { ExcluirContatoRequest } from '../models/dtos/Cliente/Contato/excluirContatoRequest.model';
import { CriarClienteRequest } from '../models/dtos/Cliente/criarClienteRequest.model';
import { CriarClienteResponse } from '../models/dtos/Cliente/criarClienteResponse.model';
import { EnderecoResponse } from '../models/dtos/Cliente/Endereco/enderecoResponse.model';
import { ExcluirEnderecoRequest } from '../models/dtos/Cliente/Endereco/excluirEnderecoRequest.model';
import { ViaCepResponse } from '../models/dtos/viaCepResponse.model';
import { EnderecoModel } from '../models/Endereco/Endereco.model';
import { useApi } from '../shared/hooks/api';
import { downloadBlob } from '../shared/utils/functions';
import { SearchRequest } from '../shared/utils/searchRequest';

export const useClienteService = () => {
  const { gridSearch, post, get, put, removeWithBody, remove, getFile } = useApi();

  const baseApi = `api/cliente`;

  const obterDadosEndereco = async (termo: string): Promise<ViaCepResponse> => {
    return await fetch(`https://viacep.com.br/ws/${termo}/json/`)
      .then((response) => {
        return response.json();
      })
      .catch((_error) => {
        return null;
      });
  };

  const salvarCliente = async (data: CriarClienteRequest) => {
    return post<CriarClienteResponse>(`${baseApi}`, data);
  };

  const buscarGridClientesPaginated = useCallback(
    async (data: SearchRequest<object>, signal?: AbortSignal) => {
      return gridSearch<ClienteSearchResponse>(`${baseApi}/paginated`, data, {
        signal
      });
    },
    [baseApi, gridSearch]
  );

  const obterClienteById = async (clienteId: string) => {
    return get<ClienteSearchResponse>(`${baseApi}/${clienteId}`);
  };

  const atualizarCliente = async (clienteId: string, data: AtualizarClienteRequest) => {
    return put<CriarClienteResponse>(`${baseApi}/${clienteId}`, data);
  };

  const salvarEndereco = async (id: string, data: EnderecoModel) => {
    return post<EnderecoResponse>(`${baseApi}/${id}/adicionar-endereco`, data);
  };

  const alterarEndereco = async (id: string, data: EnderecoModel) => {
    return put<EnderecoResponse>(`${baseApi}/${id}/alterar-endereco`, data);
  };

  const excluirEndereco = async (data: ExcluirEnderecoRequest) => {
    return remove(`${baseApi}/${data.clienteId}/endereco/${data.enderecoId}`);
  };

  const salvarContato = async (data: CriarContatoRequest) => {
    return post<ContatoResponse>(`${baseApi}/adicionar-contato`, data);
  };

  const alterarContato = async (data: CriarContatoRequest) => {
    return put<ContatoResponse>(`${baseApi}/alterar-contato`, data);
  };

  const excluirContato = async (data: ExcluirContatoRequest) => {
    return removeWithBody(`${baseApi}/remover-contato`, data);
  };

  const obterClientePorTelefone = async (telefone: string | null) => {
    return get<ClienteModel[]>(`${baseApi}/obter-por-telefone-contato/${telefone}`);
  };

  const obterArquivoPadrao = async () => {
    const result = await getFile<Blob>(`${baseApi}/importacao-default-file`, {
      responseType: 'blob'
    });

    downloadBlob(result, 'arquivo_importacao_padrao.xlsx');
  };

  const importarArquivoClientes = async (file: File) => {
    const formData = new FormData();
    formData.append('formFile', file);
    const config = {
      headers: {
        'content-type': 'multipart/form-data'
      }
    };
    return post<any>(`${baseApi}/importar-xlsx`, formData, config);
  };

  const obterClientesVinculado = useCallback(
    async (data: SearchRequest<object>, signal?: AbortSignal) => {
      return gridSearch<ClienteModel>(`${baseApi}/obter-clientes-vinculado`, data, {
        signal
      });
    },
    [baseApi, gridSearch]
  );

  return {
    obterDadosEndereco,
    salvarCliente,
    buscarGridClientesPaginated,
    obterClienteById,
    atualizarCliente,
    salvarEndereco,
    alterarEndereco,
    excluirEndereco,
    salvarContato,
    alterarContato,
    excluirContato,
    obterClientePorTelefone,
    obterArquivoPadrao,
    importarArquivoClientes,
    obterClientesVinculado
  };
};
