import { OptSideLayoutContent } from '@optsol/react';
import { useCallback, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { MenuAtendimentoClaims } from '../../../models/enums/AcessoClaims';
import { RoutesObj } from '../../../routes/index.routes';
import { useAuthenticationContext } from '../../contexts/authentication/authenticationContext';

import { GridCardListaAtendimento } from './GridCardListaAtendimento';
import { FiltroModel, ToolbarListaAtendimento } from './ToolbarListaAtendimento';

const ListarAtendimentos = () => {
  const location = useLocation();
  const search = new URLSearchParams(location.search);
  const { hasAccess } = useAuthenticationContext();
  const meusAtendimentos = obterFiltroMeusAtendimentosDaRota();
  const [atendimentoSearch, setAtendimentoSearch] = useState(obterFiltroTermoDaRota() ?? '');
  const [filtro, setFiltro] = useState<FiltroModel>({
    meusAtendimentos:
      meusAtendimentos !== undefined
        ? meusAtendimentos
        : hasAccess(MenuAtendimentoClaims.FILTRO_MEUS_ATENDIMENTOS) ||
          hasAccess(MenuAtendimentoClaims.INSERIR_ATENDIMENTO_USUARIO_EXTERNO),
    apenasEncerrados: obterFiltrMostrarEncerradosDaRota(),
    urgencia: obterFiltroUrgenciaDaRota() ?? undefined,
    statusAtendimentoSearch: obterFiltroStatusDaRota() ?? undefined
  });

  function obterFiltroMeusAtendimentosDaRota() {
    switch (search.get('meusAtendimentos')) {
      case 'true':
        return true;
      case 'false':
        return false;
      default:
        return undefined;
    }
  }

  function obterFiltrMostrarEncerradosDaRota() {
    const search = new URLSearchParams(location.search);
    switch (search.get('mostrarEncerrados')) {
      case 'true':
        return true;
      case 'false':
        return false;
      default:
        return undefined;
    }
  }

  function obterFiltroStatusDaRota() {
    const search = new URLSearchParams(location.search);
    return search.get('status') === '-1' ? '' : search.get('status');
  }

  function obterFiltroUrgenciaDaRota() {
    const search = new URLSearchParams(location.search);
    return search.get('urgencia') === '-1' ? '' : search.get('urgencia');
  }

  function obterFiltroTermoDaRota() {
    const search = new URLSearchParams(location.search);
    return search.get('termo');
  }

  function updateFiltroUrl(fieldName: string, value: any) {
    const query = window.location.href.split('?');
    const search = new URLSearchParams(query.length > 0 ? query[1] : location.search);
    search.set(fieldName, `${value}`);
    history.pushState(null, '', `?${search.toString()}`);
  }

  const navigate = useNavigate();

  const procurarAtendimentoByFiltro = useCallback((data?: FiltroModel) => {
    setFiltro(data ?? {});
  }, []);

  const procurarAtendimento = useCallback((data?: string) => {
    updateFiltroUrl('termo', data ?? '');
    setAtendimentoSearch(data ?? '');
  }, []);

  const cadastrarAtendimento = useCallback(() => {
    navigate(RoutesObj.Atendimento.Detalhes(''));
  }, [navigate]);
  return (
    <OptSideLayoutContent>
      <ToolbarListaAtendimento
        filtrarGridBySearch={procurarAtendimento}
        filtrar={procurarAtendimentoByFiltro}
        cadastrarAtendimento={cadastrarAtendimento}
        obterFiltroMeusAtendimentosDaRota={obterFiltroMeusAtendimentosDaRota}
        obterFiltrMostrarEncerradosDaRota={obterFiltrMostrarEncerradosDaRota}
        obterFiltroStatusDaRota={obterFiltroStatusDaRota}
        obterFiltroUrgenciaDaRota={obterFiltroUrgenciaDaRota}
        obterFiltroTermoDaRota={obterFiltroTermoDaRota}
        updateFiltroUrl={updateFiltroUrl}
      />
      <GridCardListaAtendimento atendimentoSearch={atendimentoSearch} filtro={filtro} />
    </OptSideLayoutContent>
  );
};

export default ListarAtendimentos;
