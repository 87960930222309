import { mdiDelete } from '@mdi/js';
import {
  OptGrid,
  OptGridColumn,
  OptGridOptions,
  OptGridRef,
  OptGridRequest,
  OptGridResponse
} from '@optsol/react';
import { forwardRef } from 'react';

import { Colors } from '../../../core/colors';
import { GridCliente } from '../../../models/dtos/Cliente/clienteSearchResponse.model';
import { getHeaderTheme, obterFiltroDaRota } from '../../../shared/utils/functions';
import { PaginatedSearchRequest } from '../../../shared/utils/searchRequest';
import { ChipStatus } from '../../components/StatusText/ChipStatus';
import { useAuthenticationContext } from '../../contexts/authentication/authenticationContext';
interface GridProps {
  carregar?: (
    query: OptGridRequest,
    ClientSearchRequest?: PaginatedSearchRequest
  ) => Promise<OptGridResponse<GridCliente>>;
  data: GridCliente[];
  editarCliente: (clientId: string) => void;
  onRemove?: (idn: string) => Promise<void>;
}

const options: OptGridOptions = {
  search: true,
  pageSize: 10
};
const GridClientes = forwardRef<OptGridRef, GridProps>(
  ({ carregar, editarCliente, data, onRemove }, ref) => {
    const {
      state: { tema }
    } = useAuthenticationContext();
    const defaultPageSize = obterFiltroDaRota('pageSize');
    const defaultPage = obterFiltroDaRota('page');
    const actions = onRemove
      ? [
          (rowData: any) => ({
            icon: { path: mdiDelete },
            tooltip: 'Excluir',
            onClick: () => onRemove(rowData.clienteId ?? '')
          })
        ]
      : [];
    const columns: OptGridColumn<GridCliente>[] = [
      {
        title: 'id',
        field: 'clienteId',
        hidden: true
      },
      {
        title: 'CPF/CNPJ',
        field: 'documento',
        align: 'start',
        render: (props: GridCliente) => {
          return <>{props.documento ? props.documento : '-'}</>;
        }
      },
      {
        title: 'Nome',
        field: 'nome',
        align: 'start',
        render: (props: GridCliente) => {
          return <>{props.nome ? props.nome : '-'}</>;
        }
      },
      {
        title: 'Plano',
        field: 'plano',
        align: 'start',
        render: (rowData) => {
          return <span> {rowData.plano?.nome ? rowData.plano?.nome : '-'}</span>;
        }
      },
      {
        title: 'Situação  ',
        field: 'situacao',
        align: 'start',
        render: (props: GridCliente) => {
          return <ChipStatus status={props.situacao ?? ''} />;
        }
      }
    ];
    return (
      <OptGrid
        ref={ref}
        data={carregar ? carregar : data}
        headerStyle={getHeaderTheme(tema.light?.primary ? tema.light.primary : Colors.gray10)}
        options={{
          ...options,
          pageIndex: defaultPage !== null ? parseInt(defaultPage) : 0,
          pageSize: defaultPageSize !== null ? parseInt(defaultPageSize) : 10
        }}
        actionsPosition={actions.length > 0 ? 'end' : undefined}
        actions={actions}
        columns={columns}
        onRowClick={(rowData) => editarCliente(rowData.clienteId ?? '')}
      />
    );
  }
);

GridClientes.displayName = 'GridClientes';
export default GridClientes;
