import { mdiPlus } from '@mdi/js';
import {
  Box,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Switch
} from '@mui/material';
import { OptActionToolbar, OptSearchField } from '@optsol/react';
import { useState } from 'react';

import { Colors } from '../../../core/colors';
import { MenuAtendimentoClaims } from '../../../models/enums/AcessoClaims';
import {
  ClassificacaoUrgenciaEnumLabel,
  ListaClassificacaoUrgenciaEnum
} from '../../../models/enums/ClassificacaoUrgencia';
import {
  ListaStatusAtendimentoEnum,
  StatusAtendimentoEnumLabel
} from '../../../models/enums/StatusAtendimento';
import { RoutesObj } from '../../../routes/index.routes';
import { BotaoDeAcaoToolbar } from '../../components/Button/BotaoDeAcaoToolbar';
import { ProtectedContent } from '../../components/ProtectedContent/ProtectedContent';
import { ToolbarContainer } from '../../components/ToolbarContainer';
import { useAuthenticationContext } from '../../contexts/authentication/authenticationContext';
import * as S from '../../style/toolbar-index';

interface Props {
  filtrarGridBySearch: (data?: string) => void;
  cadastrarAtendimento: () => void;
  filtrar: (filtro: FiltroModel) => void;
  obterFiltroMeusAtendimentosDaRota: () => boolean | undefined;
  obterFiltrMostrarEncerradosDaRota: () => boolean | undefined;
  obterFiltroStatusDaRota: () => string | null;
  obterFiltroUrgenciaDaRota: () => string | null;
  obterFiltroTermoDaRota: () => string | null;
  updateFiltroUrl: (filedName: string, value: any) => void;
}

export interface FiltroModel {
  atendimentoSearch?: string;
  meusAtendimentos?: boolean;
  statusAtendimentoSearch?: string;
  urgencia?: string;
  apenasEncerrados?: boolean;
}

export const ToolbarListaAtendimento = ({
  filtrarGridBySearch,
  cadastrarAtendimento,
  filtrar,
  obterFiltroMeusAtendimentosDaRota,
  obterFiltrMostrarEncerradosDaRota,
  obterFiltroStatusDaRota,
  obterFiltroUrgenciaDaRota,
  obterFiltroTermoDaRota,
  updateFiltroUrl
}: Props) => {
  const { state, hasAccess } = useAuthenticationContext();
  const meusAtendimentos = obterFiltroMeusAtendimentosDaRota();
  const [filtro, setFiltro] = useState<FiltroModel>({
    meusAtendimentos:
      meusAtendimentos !== undefined
        ? meusAtendimentos
        : hasAccess(MenuAtendimentoClaims.FILTRO_MEUS_ATENDIMENTOS) ||
          hasAccess(MenuAtendimentoClaims.INSERIR_ATENDIMENTO_USUARIO_EXTERNO)
  });

  function procurarApenasEncerrados(data?: boolean) {
    setFiltro({ ...filtro, apenasEncerrados: data ?? false });
    filtrar({ ...filtro, apenasEncerrados: data ?? false });
  }

  function procurarMeusAtendimentos(data?: boolean) {
    setFiltro({ ...filtro, meusAtendimentos: data ?? false });
    filtrar({ ...filtro, meusAtendimentos: data ?? false });
  }

  const handleChange = (event: SelectChangeEvent) => {
    updateFiltroUrl('status', event.target.value);
    if (event.target.value == '-1') {
      setFiltro({ ...filtro, statusAtendimentoSearch: '' });
      filtrar({ ...filtro, statusAtendimentoSearch: '' });
    } else {
      setFiltro({ ...filtro, statusAtendimentoSearch: event.target.value });
      filtrar({ ...filtro, statusAtendimentoSearch: event.target.value });
    }
  };

  const handleUrgenciaChange = (event: SelectChangeEvent) => {
    updateFiltroUrl('urgencia', event.target.value);
    if (event.target.value == '-1') {
      setFiltro({ ...filtro, urgencia: '' });
      filtrar({ ...filtro, urgencia: '' });
    } else {
      setFiltro({ ...filtro, urgencia: event.target.value });
      filtrar({ ...filtro, urgencia: event.target.value });
    }
  };
  return (
    <ToolbarContainer>
      <OptActionToolbar
        goBackRoute={RoutesObj.Home}
        title="Lista de atendimentos"
        background={Colors.white}
        color={state.tema.light?.primary}
        clearMargin
      >
        <Box
          sx={{
            display: 'flex',
            flex: 1,
            marginLeft: '30px',
            marginBottom: '-11px',
            flexWrap: 'wrap'
          }}
        >
          <S.SearchContainer>
            <OptSearchField
              onSearch={filtrarGridBySearch}
              placeholder="Search"
              defaultValue={obterFiltroTermoDaRota() ?? ''}
            />
          </S.SearchContainer>
          <FormControl sx={{ margin: 0, minWidth: 170, marginLeft: 5 }} size="small">
            <InputLabel id="demo-select-small">Status</InputLabel>
            <Select
              labelId="demo-select-small"
              id="demo-select-small"
              onChange={handleChange}
              defaultValue={obterFiltroStatusDaRota() ?? ''}
              // value={statusAtendimentoSearch}
              label="Status"
            >
              <MenuItem key={0} value={0}>
                Todos
              </MenuItem>
              {ListaStatusAtendimentoEnum.map((x) => {
                return (
                  <MenuItem key={x.id} value={x.id}>
                    {StatusAtendimentoEnumLabel.get(x.id)}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
          <FormControl sx={{ margin: 0, minWidth: 190, marginLeft: 5 }} size="small">
            <InputLabel id="demo-select-small">Urgência</InputLabel>
            <Select
              labelId="urgenciaSelect"
              id="urgenciaSelect"
              onChange={handleUrgenciaChange}
              defaultValue={obterFiltroUrgenciaDaRota() ?? ''}
              label="Urgência"
            >
              <MenuItem key={-1} value={-1}>
                Todos
              </MenuItem>
              {ListaClassificacaoUrgenciaEnum.map((x: any) => {
                return (
                  <MenuItem key={x.id} value={x.id}>
                    {ClassificacaoUrgenciaEnumLabel.get(x.id)}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
          <ProtectedContent access={MenuAtendimentoClaims.FILTRO_MEUS_ATENDIMENTOS}>
            <FormControlLabel
              sx={{ margin: 0, minWidth: 250, marginLeft: 5 }}
              label={'Meus atendimentos'}
              control={
                <Switch
                  onChange={(event) => {
                    updateFiltroUrl('meusAtendimentos', event.target.checked);
                    procurarMeusAtendimentos(event.target.checked);
                  }}
                  defaultChecked={obterFiltroMeusAtendimentosDaRota() ?? true}
                />
              }
            />
          </ProtectedContent>
          <ProtectedContent access={MenuAtendimentoClaims.FILTRO_APENAS_ENCERRADOS}>
            <FormControlLabel
              sx={{ margin: 0, minWidth: 250, marginLeft: 1 }}
              label={'Mostrar encerrados'}
              control={
                <Switch
                  onChange={(event) => {
                    updateFiltroUrl('mostrarEncerrados', event.target.checked);
                    procurarApenasEncerrados(event.target.checked);
                  }}
                  defaultChecked={obterFiltrMostrarEncerradosDaRota()}
                />
              }
            />
          </ProtectedContent>
        </Box>
        {
          <ProtectedContent access={MenuAtendimentoClaims.INSERIR}>
            <BotaoDeAcaoToolbar onClick={cadastrarAtendimento} texto="Novo" startIcon={mdiPlus} />
          </ProtectedContent>
        }
      </OptActionToolbar>
    </ToolbarContainer>
  );
};
