import * as yup from 'yup';

import { validarCNPJ, validarCPF } from '../../shared/utils/functions';
import {
  ClienteModel,
  clienteModelSchema,
  DADOS_CLLIENTE_RESPONSE_DEFAULT
} from '../Cliente/Cliente.model';
import { ClienteSearchResponse } from '../dtos/Cliente/clienteSearchResponse.model';
import { PerfilSolicitante } from '../enums/PerfilSolicitante';
import {
  dadosMotivoAtendimentoFormModelSchema,
  DADOS_MOTIVO_ATENDIMENTO_DEFAULT,
  MotivoAtendimentoModel
} from '../MotivoAtendimento/MotivoAtendimento.model';
import {
  dadosPlanoFormModelSchema,
  DADOS_INFORMACOES_PLANO_DEFAULT,
  InformacoesPlanoModel
} from '../Plano/Plano.model';

import {
  dadosInformacaoPacienteFormModelSchema,
  InformacaoPacienteFormModel,
  LISTA_PACIENTE_DEFAULT
} from './Paciente/InformacaoPaciente.model';

export interface AtendimentoFormModel {
  atendimentoId?: string | null;
  telefoneSolicitante: string;
  nomeSolicitante?: string;
  cpfSolicitante?: string;
  perfilSolicitante?: PerfilSolicitante;
  informacoesRelevantes?: string;
  cliente?: ClienteModel;
  pacientes: InformacaoPacienteFormModel[];
  informacoesPlano: InformacoesPlanoModel;
  motivoAtendimento: MotivoAtendimentoModel;
  motivoAtendimentoId?: string | null;
  agendamento?: boolean | null;
  inicioAgendamento?: string | null;
  fimAgendamento?: string | null;
  numeroChamado: string;
}
export interface AtendimentoModel {
  atendimentoId?: string | null;
  telefoneSolicitante: string;
  nomeSolicitante?: string;
  cpfSolicitante?: string;
  perfilSolicitante?: PerfilSolicitante;
  informacoesRelevantes?: string;
  cliente: ClienteSearchResponse;
  pacientes: InformacaoPacienteFormModel[];
  informacoesPlano?: InformacoesPlanoModel;
  motivoAtendimento?: MotivoAtendimentoModel;
  motivoAtendimentoId?: string;
  agendamento?: boolean | null;
  inicioAgendamento?: string | null;
  fimAgendamento?: string | null;
}
export const DADOS_ATENDIMENTO_DEFAULT: AtendimentoFormModel = {
  atendimentoId: '',
  nomeSolicitante: '',
  cpfSolicitante: '',
  telefoneSolicitante: '',
  perfilSolicitante: -1,
  informacoesRelevantes: '',
  cliente: DADOS_CLLIENTE_RESPONSE_DEFAULT,
  pacientes: LISTA_PACIENTE_DEFAULT,
  informacoesPlano: DADOS_INFORMACOES_PLANO_DEFAULT,
  motivoAtendimento: DADOS_MOTIVO_ATENDIMENTO_DEFAULT,
  motivoAtendimentoId: '',
  agendamento: false,
  inicioAgendamento: '',
  fimAgendamento: '',
  numeroChamado: ''
};
const MSG_REQUIRED = 'Campo obrigatório';
export const dadosAtendimentoFormModelSchema: yup.SchemaOf<AtendimentoFormModel> = yup
  .object()
  .shape({
    atendimentoId: yup.string().notRequired().nullable(),
    nomeSolicitante: yup.string().required(MSG_REQUIRED),
    cpfSolicitante: yup
      .string()
      .notRequired()
      .test('Teste documento', 'Documento inválido', (value) => {
        if (!value) return false;
        if (value.trim().length <= 14) return validarCPF(value);

        return validarCNPJ(value);
      }),
    telefoneSolicitante: yup
      .string()
      .required(MSG_REQUIRED)
      .test('Teste telefone', 'Telefone inválido', (value) => {
        if (!value) return false;
        return !(value.trim().length < 14 && value.trim().length < 15);
      }),
    perfilSolicitante: yup.number().notRequired(),
    informacoesRelevantes: yup.string().notRequired(),
    cliente: clienteModelSchema.notRequired(),
    pacientes: yup.array().of(dadosInformacaoPacienteFormModelSchema).required('Campo obrigatório'),
    informacoesPlano: dadosPlanoFormModelSchema.notRequired(),
    motivoAtendimento: dadosMotivoAtendimentoFormModelSchema.notRequired(),
    motivoAtendimentoId: yup.string().notRequired().nullable(),
    agendamento: yup.boolean().notRequired().nullable(),
    inicioAgendamento: yup.string().notRequired().nullable(),
    fimAgendamento: yup.string().notRequired().nullable(),
    numeroChamado: yup.string().required()
  });
