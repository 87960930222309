import { mdiSend } from '@mdi/js';
import {
  OptGrid,
  OptGridColumn,
  OptGridOptions,
  OptGridRef,
  OptGridRequest,
  OptGridResponse,
  OptSideLayoutContent
} from '@optsol/react';
import { format } from 'date-fns';
import { forwardRef } from 'react';

import { Colors } from '../../../../../core/colors';
import { ClienteModel } from '../../../../../models/Cliente/Cliente.model';
import { PaginatedSearcClientesVinculadoshRequest } from '../../../../../shared/utils/searchRequest';

interface GridProps {
  carregar?: (
    query: OptGridRequest,
    ClientSearchRequest?: PaginatedSearcClientesVinculadoshRequest
  ) => Promise<OptGridResponse<ClienteModel>>;
  data: ClienteModel[];
  selecionarCliente: (cliente: ClienteModel) => Promise<void>;
  selecionarLinhaCliente?: (cliente: ClienteModel) => Promise<void>;
  titulo?: string;
}

const options: OptGridOptions = {
  pageSize: 10
};
const GridSelecaoClientes = forwardRef<OptGridRef | undefined, GridProps>(
  ({ data, carregar, selecionarCliente, selecionarLinhaCliente }, ref) => {
    const columns: OptGridColumn<ClienteModel>[] = [
      {
        title: 'id',
        field: 'clienteId',
        hidden: true
      },
      {
        title: 'Nome',
        field: 'nome',
        align: 'left',
        render: (props: ClienteModel) => {
          return <>{props.nome ? props.nome : '-'}</>;
        }
      },
      {
        title: 'Data de nascimento',
        field: 'dataNascimento',
        align: 'left',
        render: (props: ClienteModel) => {
          return <span>{format(new Date(props.dataNascimento), 'dd/MM/yyyy')}</span>;
        }
      }
    ];
    return (
      <OptSideLayoutContent>
        <OptGrid
          ref={ref as any}
          data={carregar ? carregar : data}
          options={options}
          columns={columns}
          actionsPosition={'end'}
          actions={[
            (rowData) => ({
              icon: { path: mdiSend, color: Colors.blue },
              tooltip: 'Selecionar',
              onClick: () => selecionarCliente(rowData)
            })
          ]}
          onRowClick={(row: ClienteModel) => {
            selecionarLinhaCliente ? selecionarLinhaCliente(row) : () => {};
          }}
        />
      </OptSideLayoutContent>
    );
  }
);

GridSelecaoClientes.displayName = 'GridSelecaoClientes';
export default GridSelecaoClientes;
