import { mdiDeleteOutline } from '@mdi/js';
import Icon from '@mdi/react';
import {
  Box,
  Divider,
  Grid,
  IconButton,
  List,
  ListItemButton,
  ListItemText,
  ListSubheader,
  MenuItem,
  SelectChangeEvent
} from '@mui/material';
import { OptBackdrop, OptConfirmationDialog } from '@optsol/react';
import { useState } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';

import { Colors } from '../../../core/colors';
import { GrupoAcessoResponse } from '../../../models/dtos/Usuario/usuarioSearchResponse.model';
import { EmpresaModel } from '../../../models/Empresa/Empresa.model';
import { MenuEmpresaClaims } from '../../../models/enums/AcessoClaims';
import { FasesAtendimentoEnum } from '../../../models/enums/FasesAtendimento';
import { PerfilAcessoEnum } from '../../../models/enums/PerfilAcesso';
import { useAuthenticationContext } from '../../contexts/authentication/authenticationContext';
import { FormSelect } from '../Form';
import { ProtectedContent } from '../ProtectedContent/ProtectedContent';

interface Props {
  faseAtendimentoId: FasesAtendimentoEnum;
  gruposAcesso: GrupoAcessoResponse[];
  adicionarFaseAtendimento: (
    perfil: PerfilAcessoEnum,
    faseAtendimento: FasesAtendimentoEnum
  ) => Promise<void>;
  removerFaseAtendimento: (
    perfil: PerfilAcessoEnum,
    faseAtendimento: FasesAtendimentoEnum
  ) => Promise<void>;
}

const VinculoFasesAtendimento = ({
  faseAtendimentoId,
  gruposAcesso,
  adicionarFaseAtendimento,
  removerFaseAtendimento
}: Props) => {
  const { control } = useFormContext<EmpresaModel>();
  const { fields } = useFieldArray({
    control,
    name: 'fasesAtendimento'
  });

  const {
    state: { tema }
  } = useAuthenticationContext();

  const [loading, setLoading] = useState<boolean>(false);

  const [confirmandoExclusao, setConfirmandoExclusao] = useState<boolean>(false);

  const [perfilExclusao, setPerfilExclusao] = useState<PerfilAcessoEnum | null>(null);

  function cancelarExclusao() {
    setConfirmandoExclusao(false);
  }

  async function removerPerfilHandler(perfil: PerfilAcessoEnum | null) {
    setConfirmandoExclusao(false);
    try {
      setLoading(true);

      if (perfil) {
        await removerFaseAtendimento(perfil, faseAtendimentoId);
      }
    } finally {
      setPerfilExclusao(null);
      setLoading(false);
    }
  }

  function confirmarRemocaoPerfil(perfil: PerfilAcessoEnum) {
    setConfirmandoExclusao(true);
    setPerfilExclusao(perfil);
  }

  async function adicionarPerfilHandler(perfil: PerfilAcessoEnum) {
    try {
      setLoading(true);
      await adicionarFaseAtendimento(perfil, faseAtendimentoId);
    } finally {
      setLoading(false);
    }
  }

  return (
    <>
      <OptConfirmationDialog
        open={confirmandoExclusao}
        title="Excluir perfil"
        icon={{ path: mdiDeleteOutline, color: Colors.red }}
        onCancel={cancelarExclusao}
        onClose={cancelarExclusao}
        onConfirm={() => {
          removerPerfilHandler(perfilExclusao);
        }}
      >
        Deseja confirmar a exclusão do perfil?
      </OptConfirmationDialog>
      <Grid item xs={12}>
        <ProtectedContent mode="disable" access={MenuEmpresaClaims.ATUALIZAR}>
          <Grid item xs={12}>
            <>
              <FormSelect
                onChange={(event: SelectChangeEvent<unknown>) => {
                  adicionarPerfilHandler(event.target.value as PerfilAcessoEnum);
                }}
                value={''}
                name="perfisAcesso"
                size="small"
                label="Perfil"
                fullWidth
              >
                {gruposAcesso.map((x) => (
                  <MenuItem key={x.codigo} value={x.codigo}>
                    <div style={{ display: 'flex' }}>
                      <span>{x.nome}</span>
                    </div>
                  </MenuItem>
                ))}
              </FormSelect>
            </>
          </Grid>
        </ProtectedContent>
      </Grid>

      {fields.filter((x) => x.faseAtendimento === faseAtendimentoId).map((y) => y.grupoAcesso)
        .length > 0 && (
        <Grid
          item
          xs={12}
          sx={{
            border: `1px solid ${tema.light?.primary}`,
            borderColor: tema.light?.primary ? tema.light.primary : Colors.primary,
            borderRadius: '3px',
            marginTop: '10px',
            borderWidth: '2px'
          }}
        >
          <Box
            sx={{
              width: '100%',
              bgcolor: 'background.paper',
              maxHeight: '400px',
              overflow: 'auto'
            }}
          >
            <List
              component="nav"
              aria-label="secondary mailbox folder"
              aria-labelledby="nested-list-subheader"
              subheader={
                <ListSubheader
                  sx={{ backgroundColor: tema.light?.primary, color: '#fff' }}
                  component="div"
                  id="nested-list-subheader"
                >
                  Perfis
                </ListSubheader>
              }
            >
              <Divider />
              {fields
                .filter((x) => x.faseAtendimento === faseAtendimentoId)
                .map((y) => y.grupoAcesso)
                ?.map((value) => (
                  <ProtectedContent key={value} mode="disable" access={MenuEmpresaClaims.ATUALIZAR}>
                    <ListItemButton key={value}>
                      <ListItemText primary={value} />

                      <IconButton
                        onClick={() => confirmarRemocaoPerfil(value)}
                        title="Excluir perfil"
                      >
                        <Icon path={mdiDeleteOutline} size={1} color="red" />
                      </IconButton>
                    </ListItemButton>
                  </ProtectedContent>
                ))}
            </List>
          </Box>
        </Grid>
      )}
      <OptBackdrop open={loading} />
    </>
  );
};

export default VinculoFasesAtendimento;
