import { Box } from '@mui/material';
import { OptSideLayoutContent } from '@optsol/react';
import { OptGridRef, OptGridRequest } from '@optsol/react/lib/esm/components/OptGrid/@types';
import { useCallback, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { RoutesObj } from '../../../routes/index.routes';
import { useUsuarioService } from '../../../service/usuario.service';
import { obterFiltroDaRota, updateFiltroUrl } from '../../../shared/utils/functions';
import { PaginatedSearchRequest, SearchRequest } from '../../../shared/utils/searchRequest';

import GridUsuarios from './GridUsuarios';
import { ToolbarUsuarios } from './ToolbarUsuarios';

const Usuarios = () => {
  const navegate = useNavigate();
  const ref = useRef<OptGridRef>();
  const [usuarioSearch, setUsuarioSearch] = useState(obterFiltroDaRota('termo') ?? '');

  const { buscarGridUsuarioPaginated } = useUsuarioService();

  function ProcurarUsuario(data?: string) {
    updateFiltroUrl('termo', data ?? '');
    setUsuarioSearch(data ?? '');
  }
  const carregar = useCallback(
    async (
      query: OptGridRequest,
      usuarioSearchRequest: PaginatedSearchRequest = {
        termoBuscado: usuarioSearch,
        apenasAtivo: false
      }
    ) => {
      const request: SearchRequest<PaginatedSearchRequest> = {
        page: query.page,
        pageSize: query.pageSize,
        search: usuarioSearchRequest
      };
      updateFiltroUrl('page', query.page);
      updateFiltroUrl('pageSize', query.pageSize);
      return buscarGridUsuarioPaginated(request);
    },
    [buscarGridUsuarioPaginated, usuarioSearch]
  );

  async function editarUsuario(usuarioId: string) {
    navegate(RoutesObj.Usuario.Detalhes(usuarioId), {
      state: { prevUrl: window.location.href }
    });
  }

  return (
    <OptSideLayoutContent>
      <ToolbarUsuarios filtrarGridBySearch={ProcurarUsuario} />
      <Box px={1} pt={0.5}>
        <GridUsuarios ref={ref} editarUsuario={editarUsuario} carregar={carregar}></GridUsuarios>
      </Box>
    </OptSideLayoutContent>
  );
};

export default Usuarios;
