import { Box } from '@mui/material';
import { OptActionToolbar, OptLoading, OptSideLayoutContent } from '@optsol/react';
import { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

import { Colors } from '../../../core/colors';
import { DashboardsData, InputsDashboardModel } from '../../../models/dashboards/DashboardsModel';
import { MenuHomeClaims } from '../../../models/enums/AcessoClaims';
import { UsuarioStatusResponse } from '../../../models/Usuario/Usuario.model';
import { useDashboardService } from '../../../service/dashboard.service';
import { useUsuarioService } from '../../../service/usuario.service';
import { injetarCoresDashboardData } from '../../../shared/utils/functions';
import { DashboardSearchRequest, SearchRequest } from '../../../shared/utils/searchRequest';
import { ProtectedContent } from '../../components/ProtectedContent/ProtectedContent';
import { ToolbarContainer } from '../../components/ToolbarContainer';
import { UsuarioStatusList } from '../../components/UsuarioStatusList/UsuarioStatusList';
import { useAuthenticationContext } from '../../contexts/authentication/authenticationContext';

import { Dashboards } from './features/Dashboards';
import Indicadores from './features/Indicadores';
import InputsDashboard from './features/InputsDashboard';
import * as S from './styles';

const Home = () => {
  const [usuarioStatusList, setUsuarioStatusList] = useState<UsuarioStatusResponse[]>();
  const [dashboardsData, setDashboardsData] = useState<DashboardsData>();
  const [userListActive, setUserListActive] = useState(false);

  const {
    state: { tema }
  } = useAuthenticationContext();

  const {
    buscarPaginatedChamadosPorPrioridade,
    buscarPaginatedIncidenciaChamados,
    buscarPaginatedStatusVeiculos,
    buscarPaginatedMotivosAtendimento,
    buscarPaginatedAtendimentosPormedicoRegulador,
    buscarPaginatedIndicadores
  } = useDashboardService();
  const { buscarListaUsuariosStatus } = useUsuarioService();
  const { hasAccess } = useAuthenticationContext();
  const { control, watch, setValue } = useForm<InputsDashboardModel>();
  const formWatch = watch();

  const carregar = useCallback(
    async (atendimentoSearchRequest: DashboardSearchRequest) => {
      if (hasAccess(MenuHomeClaims.VISUALIZAR_GRAFICOS)) {
        const request: SearchRequest<DashboardSearchRequest> = {
          page: 0,
          pageSize: 1000,
          search: atendimentoSearchRequest
        };

        const [
          atendimentosPorMedicoregulador,
          chamadosPorPrioridade,
          maiorIncidenciaChamados,
          motivosAtendimento,
          statusChamados,
          indicadores
        ] = await Promise.all([
          buscarPaginatedAtendimentosPormedicoRegulador(request),
          buscarPaginatedChamadosPorPrioridade(request),
          buscarPaginatedIncidenciaChamados(request),
          buscarPaginatedMotivosAtendimento(request),
          buscarPaginatedStatusVeiculos(request),
          buscarPaginatedIndicadores(request)
        ]);

        const data = injetarCoresDashboardData({
          atedimentosMrData: atendimentosPorMedicoregulador.data,
          chamadosPorPrioridadeData: chamadosPorPrioridade.data,
          maiorIncidenciaData: maiorIncidenciaChamados.data,
          motivoAtendimentoData: motivosAtendimento.data,
          statusAmbulanciaData: statusChamados.data,
          indicadores: indicadores.data
        });
        setDashboardsData(data);
      }
    },
    [
      hasAccess,
      buscarPaginatedAtendimentosPormedicoRegulador,
      buscarPaginatedChamadosPorPrioridade,
      buscarPaginatedIncidenciaChamados,
      buscarPaginatedMotivosAtendimento,
      buscarPaginatedStatusVeiculos,
      buscarPaginatedIndicadores
    ]
  );

  useEffect(() => {
    const obterUsuarios = async () => {
      const obterListaUsuarios = await buscarListaUsuariosStatus();
      setUsuarioStatusList(obterListaUsuarios.data);
    };

    if (hasAccess(MenuHomeClaims.VISUALIZAR_STATUS_USUARIOS)) {
      obterUsuarios();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const abrirUserList = () => {
    setUserListActive((prevState) => !prevState);
  };

  useEffect(() => {
    if (formWatch.periodo) {
      setValue('dataInicio', null);
      setValue('dataFim', null);
    }

    carregar({
      dataInicio: '',
      dataFim: '',
      periodo: formWatch.periodo ? formWatch.periodo : 0
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formWatch.periodo]);

  useEffect(() => {
    if ((formWatch.dataFim || formWatch.dataInicio) && formWatch.periodo) {
      setValue('periodo', null);
    }

    if (formWatch.dataFim && formWatch.dataInicio) {
      carregar({
        dataInicio: new Date(formWatch.dataInicio),
        dataFim: new Date(formWatch.dataFim),
        periodo: 0
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formWatch.dataFim, formWatch.dataInicio]);

  return (
    <OptSideLayoutContent noPadding>
      <ProtectedContent access={MenuHomeClaims.VISUALIZAR_GRAFICOS}>
        <Box
          height={'100%'}
          width={'100%'}
          bgcolor={Colors.gray11}
          overflow="auto"
          sx={{ padding: '0' }}
        >
          <ToolbarContainer>
            <OptActionToolbar title="Visão Geral" color={tema.light?.primary}></OptActionToolbar>
          </ToolbarContainer>
          <InputsDashboard
            control={control}
            formWatch={formWatch}
            userListActive={userListActive}
            abrirUserList={abrirUserList}
          />
          {dashboardsData ? (
            <Box display="flex" px={userListActive ? 3 : 5.5} ml={!userListActive ? 5 : 0}>
              <Box
                display="flex"
                justifyContent="center"
                flexDirection="column"
                width={1}
                padding={2}
              >
                <Indicadores data={dashboardsData.indicadores} />
                <Dashboards data={dashboardsData} />
              </Box>
              {usuarioStatusList && userListActive && (
                <S.UserListContainer userList={userListActive}>
                  <UsuarioStatusList userList={usuarioStatusList} />
                </S.UserListContainer>
              )}
            </Box>
          ) : (
            <OptLoading size={50} color="primary" />
          )}
        </Box>
      </ProtectedContent>
    </OptSideLayoutContent>
  );
};

export default Home;
