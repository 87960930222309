import { CircularProgress, Grid, InputLabel, MenuItem, Select } from '@mui/material';
import { useEffect, useState } from 'react';

import { Colors } from '../../../core/colors';
import {
  DADOS_USUARIO_RESPONSE_DEFAULT,
  UsuarioResponseModel
} from '../../../models/dtos/Usuario/UsuarioResponse.model';
import { useUsuarioService } from '../../../service/usuario.service';
import ButtonModal from '../../components/Button/ButtonModal';

import * as S from './styles/index';

interface Props {
  loadingButtomSave: boolean;
  encaminharAtendimento: (id: string) => Promise<void>;
}

export const FormEncaminhar = ({ loadingButtomSave, encaminharAtendimento }: Props) => {
  const { obterMedicosReguladores } = useUsuarioService();

  const [usuario, setusuario] = useState<UsuarioResponseModel>(DADOS_USUARIO_RESPONSE_DEFAULT);
  const [usuarios, setUsuarios] = useState<UsuarioResponseModel[]>();
  function encaminharHandler() {
    encaminharAtendimento(usuario?.usuarioId);
  }

  /*   async function filtrarUsuarios(termo: string) {
    if (termo) {
      const result = await obterMedicosReguladores();
      setUsuarios(result.data);
    }
  }

  function filtrarUsuariosHandler(newValue: string) {
    filtrarUsuarios(newValue);
  }

  const changeHandler = useCallback((value: string) => {
    filtrarUsuariosHandler(value);
  }, []);

  const debouncedUsuarioChangeHandler = useCallback(
    debounce(changeHandler, 500),
    []
  ); */

  async function obterMedicoReguladorHandler() {
    const result = await obterMedicosReguladores();
    setUsuarios(result.data);
  }
  useEffect(() => {
    obterMedicoReguladorHandler();
  }, []);

  return (
    <>
      <Grid container item rowSpacing={3} columnSpacing={3} px={2.5} pt={2.5}>
        <Grid item xs={12}>
          <InputLabel style={{ float: 'left' }} id={`select-user`}>
            Usuário
          </InputLabel>
          <Select
            fullWidth
            onChange={(newValue: any) => {
              const newUser = usuarios?.find((x) => x.usuarioId === newValue.target.value);
              setusuario(newUser ?? DADOS_USUARIO_RESPONSE_DEFAULT);
            }}
            value={usuario.usuarioId}
            name="usuarioId"
            label="Usuário"
            labelId={`select-user`}
          >
            {usuarios?.map((x) => {
              return (
                <MenuItem key={x.usuarioId} value={x.usuarioId} style={{ textAlign: 'left' }}>
                  <div style={{ display: 'flex' }}>
                    <span>{x.nome}</span>
                  </div>
                </MenuItem>
              );
            })}
          </Select>
        </Grid>
      </Grid>
      <S.GriButtonEncaminharContent
        container
        item
        rowSpacing={3}
        columnSpacing={3}
        px={2.5}
        pt={2.5}
      >
        <ButtonModal
          size="large"
          tipo="confirm"
          $backgroundColor={Colors.primary}
          onClick={encaminharHandler}
          loadingIndicator={<CircularProgress color={'primary'} />}
          loading={loadingButtomSave}
          disabled={!usuario?.usuarioId}
          style={{ marginTop: '1em', borderRadius: '8px' }}
        >
          Encaminhar
        </ButtonModal>
      </S.GriButtonEncaminharContent>
    </>
  );
};

export default FormEncaminhar;
