import { yupResolver } from '@hookform/resolvers/yup';
import { CircularProgress } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { FieldErrors, FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { Colors } from '../../../core/colors';
import {
  CriarClienteModel,
  DADOS_CRIAR_CLLIENTE_DEFAULT,
  dadosCriarClienteFormModelSchema
} from '../../../models/Cliente/Cliente.model';
import { CriarClienteRequest } from '../../../models/dtos/Cliente/criarClienteRequest.model';
import { RoutesObj } from '../../../routes/index.routes';
import { useClienteService } from '../../../service/cliente.service';
import ButtonModal from '../../components/Button/ButtonModal';

import FormCriarCliente from './FormCriarCliente';

const CadastroCliente = () => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { salvarCliente } = useClienteService();

  const form = useForm<CriarClienteModel>({
    mode: 'onBlur',
    defaultValues: DADOS_CRIAR_CLLIENTE_DEFAULT,
    resolver: yupResolver(dadosCriarClienteFormModelSchema)
  });

  const onValid = async (data: CriarClienteModel) => {
    try {
      setLoadingButtomSave(true);
      const documento = data.cpfCnpj.trim();
      const request: CriarClienteRequest = {
        cpf: documento.length === 14 ? documento : null,
        cnpj: documento.length === 18 ? documento : null,
        nome: data.nome
      };
      const result = await salvarCliente(request);
      enqueueSnackbar('Registro salvo com sucesso!', {
        variant: 'success'
      });
      const { clienteId } = result.data;
      navigate(RoutesObj.Cliente.Detalhes(clienteId));
    } finally {
      setLoadingButtomSave(false);
    }
  };

  const onInvalid = (_errors: FieldErrors<CriarClienteModel>) => {};
  const [loadingButtomSave, setLoadingButtomSave] = useState(false);

  return (
    <>
      <FormProvider {...form}>
        <FormCriarCliente />
        <ButtonModal
          size="large"
          tipo="confirm"
          $backgroundColor={Colors.primary}
          onClick={form.handleSubmit(onValid, onInvalid)}
          loadingIndicator={<CircularProgress color={'primary'} />}
          loading={loadingButtomSave}
        >
          Salvar
        </ButtonModal>
      </FormProvider>
    </>
  );
};

export default CadastroCliente;
