import { Box, Icon, Select } from '@mui/material';
import MenuItem from '@mui/material/MenuItem/MenuItem';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { OptLoading } from '@optsol/react';
import { useEffect, useState } from 'react';
import { Controller, get, useFormContext } from 'react-hook-form';

import { AtendimentoFormModel } from '../../../models/Atendimento/Atendimento.model';
import { TipoVeiculo } from '../../../models/enums/TipoVeiculo';
import { VeiculoModel } from '../../../models/Veiculo/Veiculo.model';
import { useVeiculoService } from '../../../service/veiculo.service';
import { useAuthenticationContext } from '../../contexts/authentication/authenticationContext';
import { ErrorMessage } from '../Form';
import { CircleDotRed } from '../UsuarioStatusList/styles';

import * as S from './../../features/atendimento/styles';

interface SelectVeiculoProps {
  tipoVeiculo: TipoVeiculo;
  indexPaciente: number;
  indexEquipe: number;
  disabledForm?: boolean;
  editarVeiculo: (indexPaciente: number, indexEquipe: number) => Promise<void>;
}

export const SelectVeiculo = ({
  tipoVeiculo,
  indexPaciente,
  indexEquipe,
  disabledForm,
  editarVeiculo
}: SelectVeiculoProps) => {
  const {
    control,
    formState: { errors }
  } = useFormContext<AtendimentoFormModel>();

  const { obterVeiculosByTipo, obterVeiculos } = useVeiculoService();

  const [veiculos, setVeiculos] = useState<VeiculoModel[]>();
  const [veiculosLoading, setVeiculosLoading] = useState<boolean>(false);

  const {
    state: { tema }
  } = useAuthenticationContext();

  async function obterVeiculosHandler() {
    setVeiculosLoading(true);
    if (tipoVeiculo !== undefined && tipoVeiculo !== null) {
      const result = await obterVeiculosByTipo(tipoVeiculo);
      setVeiculos(result.data);
    } else {
      const result = await obterVeiculos();
      setVeiculos(result.data);
    }
    setVeiculosLoading(false);
  }

  useEffect(() => {
    obterVeiculosHandler();
  }, [tipoVeiculo]);

  return (
    <Box display="flex" flexDirection="column" flex={1}>
      {veiculosLoading ? (
        <S.VeiculosLoading>
          <OptLoading color="primary" size={20} />
          <Typography fontWeight="bold">Buscando Veículos...</Typography>
        </S.VeiculosLoading>
      ) : (
        <>
          <Typography textAlign="left" color={tema.light?.primary} lineHeight={1}>
            Veículo
          </Typography>
          <Controller
            control={control}
            name={`pacientes.${indexPaciente}.equipes.${indexEquipe}.veiculoId`}
            render={({ field }) => (
              <Select
                fullWidth
                onChange={(e) => {
                  field.onChange(e.target.value);
                  editarVeiculo(indexPaciente, indexEquipe);
                }}
                value={field.value ?? ''}
                disabled={disabledForm}
                variant="outlined"
                MenuProps={{
                  PaperProps: {
                    style: { maxHeight: 48 * 4.5 + 8 }
                  }
                }}
              >
                {veiculos?.map((x) => {
                  return (
                    <MenuItem key={x.id} value={x.id}>
                      <Tooltip
                        placement="right"
                        title={
                          <Box display="flex" flexDirection="column">
                            {x.condutorAtual ? (
                              <Box display="flex" justifyContent="center" gap={1} mt={1}>
                                <CircleDotRed status={x.condutorAtual.status} />
                                {x.condutorAtual.ultimaAtualizacao}
                              </Box>
                            ) : (
                              <span>Sem atualização.</span>
                            )}
                          </Box>
                        }
                      >
                        <span>
                          {x.prefixo ? (
                            <>
                              {x.prefixo +
                                ' - ' +
                                x.placa +
                                ' - ' +
                                (x.condutorAtual ? x.condutorAtual.nome : 'Sem condutor')}
                              <Icon sx={{ fontSize: 15, paddingBottom: 2, marginLeft: 1 }}>
                                &#128712;
                              </Icon>
                            </>
                          ) : (
                            <>
                              {x.prefixo}
                              <Icon sx={{ fontSize: 15, paddingBottom: 2, marginLeft: 1 }}>
                                &#128712;
                              </Icon>
                            </>
                          )}
                        </span>
                      </Tooltip>
                    </MenuItem>
                  );
                })}
              </Select>
            )}
          />
          <ErrorMessage
            error={get(errors, `pacientes.${indexPaciente}.equipes.${indexEquipe}.veiculoId`)}
          />
        </>
      )}
    </Box>
  );
};
