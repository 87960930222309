import { OptTimelineAction } from '@optsol/react';
import { AxiosRequestConfig, AxiosResponse, GenericAbortSignal } from 'axios';
import { useCallback } from 'react';

import { TimelineModel } from '../models/Atendimento/Equipe/TimeLine.model';
import { InformacaoPacienteFormModel } from '../models/Atendimento/Paciente/InformacaoPaciente.model';
import { AdicionarProntuarioRequest } from '../models/dtos/Atendimento/adicionarProntuarioRequest';
import { AdicionarTipoVeiculoPacienteRequestModel } from '../models/dtos/Atendimento/adicionarTipoVeiculoPacienteRequest.model';
import { AtendimentoHistoricoResponseModel } from '../models/dtos/Atendimento/atendimentoHistoricoResponse.model';
import {
  AtendimentoSearchResponse,
  ListaAtendimentoSearchResponse
} from '../models/dtos/Atendimento/atendimentoSearchResponse.model';
import { CriarAtendimentoRequest } from '../models/dtos/Atendimento/criarAtendimentoRequest.model';
import { CriarAtendimentoResponse } from '../models/dtos/Atendimento/criarAtendimentoResponse.model';
import { CriarPacienteRequest } from '../models/dtos/Atendimento/criarPacienteRequest.model';
import {
  EncaminharRequest,
  EncaminharVeiculoRequest,
  EncerrarPlantaoRequest
} from '../models/dtos/Atendimento/encaminharRequest.mode';
import { IdResponse } from '../models/dtos/Atendimento/idResponse.model';
import { useApi } from '../shared/hooks/api';
import { downloadBlob } from '../shared/utils/functions';
import { SearchRequest } from '../shared/utils/searchRequest';

import { AtendimentoModel } from './../models/Atendimento/Atendimento.model';

export const useAtendimentoService = () => {
  const { gridSearch, post, get, put, postBlobFile, getBlobFile, removeWithBody, remove } =
    useApi();

  const baseApi = `api/atendimento`;

  const buscarGridAtendimentoPaginated = useCallback(
    async (data: SearchRequest<object>) => {
      return gridSearch<AtendimentoSearchResponse>(`${baseApi}/paginated`, data);
    },
    [baseApi, gridSearch]
  );

  const salvarAtendimento = async (data: CriarAtendimentoRequest) => {
    return post<CriarAtendimentoResponse>(`${baseApi}`, data);
  };

  const alterarAtendimento = async (data: CriarAtendimentoRequest, atendimentoId: string) => {
    return put<CriarAtendimentoResponse>(`${baseApi}/${atendimentoId}`, data);
  };

  const adicionarPaciente = async (data: CriarPacienteRequest) => {
    return post<InformacaoPacienteFormModel[]>(`${baseApi}/adicionar-paciente`, data);
  };

  const alterarPaciente = async (data: CriarPacienteRequest) => {
    return put<InformacaoPacienteFormModel[]>(`${baseApi}/alterar-paciente`, data);
  };

  const obterAtendimentoById = async (atendimentoId: string) => {
    return get<AtendimentoModel>(`${baseApi}/${atendimentoId}`);
  };

  const encaminhar = async (data: EncaminharRequest) => {
    return put<InformacaoPacienteFormModel>(`${baseApi}/encaminhar-medico-regulador`, data);
  };

  const cancelarChamado = async (
    atendimentoId: string,
    pacienteId: string,
    justificativa: string
  ) => {
    return put<AtendimentoModel>(`${baseApi}/cancelar-chamado`, {
      atendimentoId: atendimentoId,
      pacienteId: pacienteId,
      justificativa: justificativa
    });
  };

  const encaminharParaMedicoRegulador = async (request: EncaminharRequest) => {
    return put(`${baseApi}/encaminhar-medico-regulador`, {
      atendimentoId: request.atendimentoId,
      usuarioId: request.usuarioId
    });
  };

  const encaminharParaRadioOperador = async (request: EncaminharRequest) => {
    return put(`${baseApi}/encaminhar-radio-operador`, {
      atendimentoId: request.atendimentoId,
      usuarioId: request.usuarioId,
      pacienteId: request?.pacienteId
    });
  };

  const encerrarPlantaoAtendimento = async (request: EncerrarPlantaoRequest) => {
    return put(`${baseApi}/encerrar-plantao`, request);
  };

  const encerrarChamado = async (
    atendimentoIdP: string,
    pacienteIdP: string,
    justificativa: string
  ) => {
    return put<AtendimentoModel>(`${baseApi}/encerrar-chamado`, {
      atendimentoId: atendimentoIdP,
      pacienteId: pacienteIdP,
      justificativa: justificativa
    });
  };

  const buscarGridListaAtendimentoPaginated = useCallback(
    async (data: SearchRequest<object>, signal?: GenericAbortSignal) => {
      const config: AxiosRequestConfig = {};
      if (signal) config.signal = signal;
      return gridSearch<ListaAtendimentoSearchResponse>(
        `${baseApi}/listagem-atendimentos`,
        data,
        config
      );
    },
    [baseApi, gridSearch]
  );
  const concluirRemocao = async (atendimentoId: string, pacienteId: string) => {
    return put<InformacaoPacienteFormModel[]>(`${baseApi}/concluir-remocao`, {
      atendimentoId: atendimentoId,
      pacienteId: pacienteId
    });
  };

  const buscarHistoricoProntuarios = async (pacienteIdP: string) => {
    return post<OptTimelineAction[]>(`${baseApi}/buscar-historico-prontuarios`, {
      pacienteId: pacienteIdP
    });
  };

  const encaminharVeiculo = async (request: EncaminharVeiculoRequest) => {
    return put(`${baseApi}/encaminhar-ambulancia`, request);
  };

  const gerarBoletim = async (atendimentoId: string, pacienteId: string) => {
    return postBlobFile<AxiosResponse>(
      `${baseApi}/boletim-atendimento`,
      {
        atendimentoId: atendimentoId,
        pacienteId: pacienteId
      },
      {
        responseType: 'blob'
      }
    );
  };

  const adicionarProntuario = async (request: AdicionarProntuarioRequest) => {
    return post(`${baseApi}/adicionar-prontuario`, request);
  };

  const removerPaciente = async (atendimentoId: string, pacienteId: string) => {
    return removeWithBody<AtendimentoModel>(`${baseApi}/remover-paciente`, {
      atendimentoId: atendimentoId,
      pacienteId: pacienteId
    });
  };

  const atualizarTimeLine = async (
    atendimentoId: string,
    pacienteId: string,
    equipeId: string,
    step: string
  ) => {
    return put(
      `${baseApi}/${atendimentoId}/atualizar-timeline/${pacienteId}/equipe/${equipeId}/step/${step}`
    );
  };

  const listarAtendimentosPendentesMedicoRegulador = async (perfisAcesso: string[]) => {
    return post<AtendimentoModel[]>(`${baseApi}/atendimentos-pendentes/`, {
      perfisAcesso: perfisAcesso
    });
  };

  const adicionarVeiculoPaciente = async (
    request: AdicionarTipoVeiculoPacienteRequestModel,
    atendimentoId: string,
    pacienteId: string
  ) => {
    return post<IdResponse>(
      `${baseApi}/${atendimentoId}/adicionar-tipo-veiculo-paciente/${pacienteId}`,
      request
    );
  };

  const removerVeiculoPaciente = async (
    atendimentoId: string,
    pacienteId: string,
    equipeId: string
  ) => {
    return remove<AtendimentoModel>(
      `${baseApi}/${atendimentoId}/remover-tipo-veiculo-paciente/${pacienteId}/equipe/${equipeId}`
    );
  };

  const atualizarVeiculoPaciente = async (
    atendimentoId: string,
    pacienteId: string,
    equipeId: string,
    veiculoId: string
  ) => {
    return put<TimelineModel[]>(
      `${baseApi}/${atendimentoId}/atualizar-veiculo-paciente/${pacienteId}/equipe/${equipeId}/veiculo/${veiculoId}`
    );
  };

  const cancelarAmbulancia = async (
    atendimentoId: string,
    pacienteId: string,
    equipeId: string,
    veiculoId: string
  ) => {
    return put<TimelineModel[]>(
      `${baseApi}/${atendimentoId}/cancelar-ambulancia/${pacienteId}/equipe/${equipeId}/veiculo/${veiculoId}`
    );
  };

  const assumirPacienteMedicoRegulador = async (atendimentoId: string, pacienteId: string) => {
    return put<AtendimentoModel>(
      `${baseApi}/${atendimentoId}/paciente/${pacienteId}/assumir-paciente-medico`,
      null
    );
  };

  const assumirPacienteRadioOperador = async (atendimentoId: string, pacienteId: string) => {
    return put<AtendimentoModel>(
      `${baseApi}/${atendimentoId}/paciente/${pacienteId}/assumir-paciente-radio`,
      null
    );
  };

  const assumirPacienteRadioTarm = async (atendimentoId: string, pacienteId: string) => {
    return put<AtendimentoModel>(
      `${baseApi}/${atendimentoId}/paciente/${pacienteId}/assumir-paciente-tarm`,
      null
    );
  };

  const alterarReponsavelRadioOperador = async (
    atendimentoId: string,
    pacienteId: string,
    usuarioId: string
  ) => {
    return put<AtendimentoModel>(
      `${baseApi}/${atendimentoId}/paciente/${pacienteId}/radio-operador/${usuarioId}/alterar-reponsavel`,
      null
    );
  };

  const alterarReponsavelMedicoRegulador = async (
    atendimentoId: string,
    pacienteId: string,
    usuarioId: string
  ) => {
    return put<AtendimentoModel>(
      `${baseApi}/${atendimentoId}/paciente/${pacienteId}/medico-regulador/${usuarioId}/alterar-reponsavel`,
      null
    );
  };

  const alterarReponsavelTarm = async (
    atendimentoId: string,
    pacienteId: string,
    usuarioId: string
  ) => {
    return put<AtendimentoModel>(
      `${baseApi}/${atendimentoId}/paciente/${pacienteId}/tarm/${usuarioId}/alterar-reponsavel`,
      null
    );
  };

  const buscarHistoricoAtendimentosPaciente = async (data: SearchRequest<object>) => {
    return gridSearch<AtendimentoHistoricoResponseModel>(
      `${baseApi}/buscar-historico-atendimentos-paciente`,
      data
    );
  };

  const marcarNotificacaoProntuarioLida = async (atendimentoId: string, pacienteId: string) => {
    return put(
      `${baseApi}/${atendimentoId}/paciente/${pacienteId}/marcar-notificacao-prontuario-lida`,
      null
    );
  };

  const gerarRelatorio = async (dataInicio: string, dataFim: string) => {
    const result = await postBlobFile<AxiosResponse>(
      `${baseApi}/relatorio-intervalo-datas`,
      {
        dataInicio: dataInicio,
        dataFim: dataFim
      },
      {
        responseType: 'blob'
      }
    );
    const headerString = result.headers['content-disposition'];
    const regex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
    const matches = regex.exec(headerString);
    if (matches) {
      const filename = matches && matches[1];
      downloadBlob(result.data, `${filename}.xlsx`);
    } else {
      downloadBlob(result.data, 'atendimentos.xlsx');
    }
  };

  const gerarRelatorioAtendimentoCliente = async () => {
    const result = await getBlobFile<AxiosResponse>(`${baseApi}/relatorio-uso-por-cliente`, {
      responseType: 'blob'
    });
    const headerString = result.headers['content-disposition'];
    const regex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
    const matches = regex.exec(headerString);
    if (matches) {
      const filename = matches && matches[1];
      downloadBlob(result.data, `${filename}.xlsx`);
    } else {
      downloadBlob(result.data, 'atendimentos.xlsx');
    }
  };

  return {
    buscarGridAtendimentoPaginated,
    salvarAtendimento,
    adicionarPaciente,
    obterAtendimentoById,
    alterarAtendimento,
    alterarPaciente,
    encaminhar,
    buscarGridListaAtendimentoPaginated,
    cancelarChamado,
    encaminharParaMedicoRegulador,
    encerrarChamado,
    buscarHistoricoProntuarios,
    encaminharParaRadioOperador,
    concluirRemocao,
    encaminharVeiculo,
    gerarBoletim,
    adicionarProntuario,
    removerPaciente,
    atualizarTimeLine,
    listarAtendimentosPendentesMedicoRegulador,
    adicionarVeiculoPaciente,
    removerVeiculoPaciente,
    atualizarVeiculoPaciente,
    cancelarAmbulancia,
    assumirPacienteMedicoRegulador,
    assumirPacienteRadioOperador,
    alterarReponsavelRadioOperador,
    alterarReponsavelMedicoRegulador,
    buscarHistoricoAtendimentosPaciente,
    assumirPacienteRadioTarm,
    alterarReponsavelTarm,
    encerrarPlantaoAtendimento,
    marcarNotificacaoProntuarioLida,
    gerarRelatorio,
    gerarRelatorioAtendimentoCliente
  };
};
