export enum StatusAtendimentoEnum {
  EM_ANDAMENTO = 1,
  ENCERRADO = 2,
  PENDENTE = 3,
  CANCELADO = 4,
  CONCLUIDO = 5,
  AGENDADO = 6
}

export const StatusAtendimentoEnumLabel = new Map<number, string>([
  [StatusAtendimentoEnum.EM_ANDAMENTO, 'Em Andamento'],
  [StatusAtendimentoEnum.ENCERRADO, 'Encerrado'],
  [StatusAtendimentoEnum.PENDENTE, 'Pendente'],
  [StatusAtendimentoEnum.CANCELADO, 'Cancelado'],
  [StatusAtendimentoEnum.CONCLUIDO, 'Concluído'],
  [StatusAtendimentoEnum.AGENDADO, 'Agendado']
]);

export const ListaStatusAtendimentoEnum = [
  {
    id: StatusAtendimentoEnum.EM_ANDAMENTO,
    descricao: StatusAtendimentoEnumLabel.get(StatusAtendimentoEnum.EM_ANDAMENTO)
  },
  {
    id: StatusAtendimentoEnum.ENCERRADO,
    descricao: StatusAtendimentoEnumLabel.get(StatusAtendimentoEnum.ENCERRADO)
  },
  {
    id: StatusAtendimentoEnum.PENDENTE,
    descricao: StatusAtendimentoEnumLabel.get(StatusAtendimentoEnum.PENDENTE)
  },
  {
    id: StatusAtendimentoEnum.CANCELADO,
    descricao: StatusAtendimentoEnumLabel.get(StatusAtendimentoEnum.CANCELADO)
  },
  {
    id: StatusAtendimentoEnum.CONCLUIDO,
    descricao: StatusAtendimentoEnumLabel.get(StatusAtendimentoEnum.CONCLUIDO)
  },
  {
    id: StatusAtendimentoEnum.AGENDADO,
    descricao: StatusAtendimentoEnumLabel.get(StatusAtendimentoEnum.AGENDADO)
  }
];

export enum StatusPerfilResponsavelEnum {
  TARM = 1,
  MedicoRegulador = 2,
  RadioOperador = 3,
  EquipeMovel = 4,
  SupervisorRegulacao = 5,
  Nenhum = 10
}
