import { yupResolver } from '@hookform/resolvers/yup';
import { mdiDeleteOutline } from '@mdi/js';
import { Box, CircularProgress } from '@mui/material';
import { OptBackdrop, OptConfirmationDialog } from '@optsol/react';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { FieldErrors, FormProvider, useForm } from 'react-hook-form';

import { Colors } from '../../../../core/colors';
import {
  CriarContatoModel,
  dadosCriarContatoFormModelSchema,
  DADOS_CRIAR_CONTATO_DEFAULT
} from '../../../../models/Contato/Contato.model';
import { MenuClienteClaims } from '../../../../models/enums/AcessoClaims';
import ButtonModal from '../../../components/Button/ButtonModal';
import { ProtectedContent } from '../../../components/ProtectedContent/ProtectedContent';

import FormCriarContato from './FormCriarContato';

interface Props {
  salvarContato: (enderecoModel: CriarContatoModel) => Promise<void>;
  excluirContato: (enderecoId: string) => Promise<void>;
  contato: CriarContatoModel | undefined;
  toggleContatoModal: () => void;
}

const CriarContato = ({ salvarContato, excluirContato, toggleContatoModal, contato }: Props) => {
  const { enqueueSnackbar } = useSnackbar();
  const form = useForm<CriarContatoModel>({
    mode: 'onBlur',
    defaultValues: contato ?? DADOS_CRIAR_CONTATO_DEFAULT,
    resolver: yupResolver(dadosCriarContatoFormModelSchema)
  });

  const onValid = async (data: CriarContatoModel) => {
    try {
      setLoadingButtomSave(true);
      await salvarContato(data);
      enqueueSnackbar('Registro salvo com sucesso!', {
        variant: 'success'
      });
      toggleContatoModal();
    } finally {
      setLoadingButtomSave(false);
    }
  };

  async function excluirEnderecoHandler(contatoId: string) {
    try {
      setConfirmandoExclusao(false);
      setLoadingDelete(true);
      await excluirContato(contatoId);
    } finally {
      setLoadingDelete(false);
    }
  }

  const onInvalid = (_errors: FieldErrors<CriarContatoModel>) => {};
  const [loadingButtomSave, setLoadingButtomSave] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [confirmandoExclusao, setConfirmandoExclusao] = useState<boolean>(false);

  function cancelarExclusao() {
    setConfirmandoExclusao(false);
  }

  return (
    <>
      <OptConfirmationDialog
        open={confirmandoExclusao}
        title="Excluir contato"
        icon={{ path: mdiDeleteOutline, color: Colors.red }}
        onCancel={cancelarExclusao}
        onClose={cancelarExclusao}
        onConfirm={() => {
          excluirEnderecoHandler(form.getValues('contatoId') ?? '');
        }}
      >
        Deseja confirmar a exclusão do contato?
      </OptConfirmationDialog>
      <FormProvider {...form}>
        <FormCriarContato />
        <ProtectedContent mode="disable" access={MenuClienteClaims.ATUALIZAR}>
          <Box display="flex" gap={10}>
            <ButtonModal
              disabled={!form.getValues('contatoId')}
              size="small"
              $backgroundColor={Colors.red}
              onClick={() => {
                setConfirmandoExclusao(true);
              }}
              loadingIndicator={<CircularProgress color={'primary'} />}
              loading={loadingButtomSave}
              tipo="confirm"
            >
              Excluir
            </ButtonModal>

            <ButtonModal
              size="small"
              tipo="confirm"
              $backgroundColor={Colors.primary}
              onClick={form.handleSubmit(onValid, onInvalid)}
              loadingIndicator={<CircularProgress color={'primary'} />}
              loading={loadingButtomSave}
            >
              Salvar
            </ButtonModal>
          </Box>
        </ProtectedContent>
        <OptBackdrop open={loadingDelete} />
      </FormProvider>
    </>
  );
};

export default CriarContato;
