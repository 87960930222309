import * as yup from 'yup';

export interface ServicoCobertoModel {
  id: string;
  nome: string;
  descricao: string;
  situacao: boolean;
  temAreaAbrangencia: boolean;
}

export const DADOS_COBERTURA_DEFAULT: ServicoCobertoModel = {
  id: '',
  nome: '',
  descricao: '',
  situacao: false,
  temAreaAbrangencia: false
};

const MSG_REQUIRED = 'Campo obrigatório';

export const dadosCoberturaFormModelSchema: yup.SchemaOf<ServicoCobertoModel> = yup.object().shape({
  nome: yup.string().required(MSG_REQUIRED),
  descricao: yup.string().required(MSG_REQUIRED),
  situacao: yup.boolean().required(MSG_REQUIRED),
  temAreaAbrangencia: yup.boolean().required(MSG_REQUIRED),
  id: yup.string().required(MSG_REQUIRED)
});

export interface CriarServicoCobertoModel {
  nome: string;
  descricao: string;
}

export const DADOS_CRIAR_COBERTURA_DEFAULT: CriarServicoCobertoModel = {
  nome: '',
  descricao: ''
};

export const dadosCriarCoberturaFormModelSchema: yup.SchemaOf<CriarServicoCobertoModel> = yup
  .object()
  .shape({
    nome: yup.string().required(MSG_REQUIRED),
    descricao: yup.string().required(MSG_REQUIRED)
  });
