import { Grid, MenuItem, SelectChangeEvent } from '@mui/material';
import { useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { GrupoAcessoResponse } from '../../../models/dtos/Usuario/usuarioSearchResponse.model';
import { PerfilAcessoEnum } from '../../../models/enums/PerfilAcesso';
import { ListaTipoUsuarioEnum, TipoUsuarioEnum } from '../../../models/enums/TipoUsuario';
import { CriarUsuarioModel } from '../../../models/Usuario/Usuario.model';
import { useUsuarioService } from '../../../service/usuario.service';
import { FormSelect, FormTextField } from '../../components/Form';
import { FormNumberFormat } from '../../components/Form/FormNumberFormat';
import { ListaPerfisAcesso } from '../atendimento/ListaPerfisAcesso/ListaPerfisAcesso';

export const FormCriarUsuario = () => {
  const { control, getValues, setValue, reset, watch } = useFormContext<CriarUsuarioModel>();
  const { obterGrupoAcesso } = useUsuarioService();

  const [gruposAcesso, setGruposAcesso] = useState<GrupoAcessoResponse[]>([]);
  async function obterPerfisAcesso() {
    const result = await obterGrupoAcesso();
    setGruposAcesso(result.data);
  }
  useEffect(() => {
    obterPerfisAcesso();
    setValue('tipo', TipoUsuarioEnum.INTERNO);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function adicionarPerfilHandler(id: PerfilAcessoEnum) {
    getValues().perfisAcesso?.push(id);
    setValue('perfisAcesso', [...(getValues().perfisAcesso ?? [])]);
    reset(getValues());
  }

  async function removerPerfilHandler(id: PerfilAcessoEnum) {
    setValue('perfisAcesso', [...(getValues().perfisAcesso?.filter((x) => x !== id) ?? [])]);
    reset(getValues());
  }

  return (
    <Grid container item rowSpacing={3} columnSpacing={3} px={2.5} pt={2.5}>
      <Grid item xs={12}>
        <FormNumberFormat
          name="cpf"
          label="CPF"
          control={control}
          sx={{ mt: 0.5 }}
          format="###.###.###-##"
          placeholder="CPF"
        />
      </Grid>
      <Grid item xs={12}>
        <FormTextField name="email" sx={{ mt: 0.5 }} control={control} label="E-mail" />
      </Grid>
      <Grid item xs={12}>
        <FormTextField name="nome" sx={{ mt: 0.5 }} control={control} label="Nome" />
      </Grid>
      <Grid item xs={12} pb={2}>
        <Controller
          name={`tipo`}
          control={control}
          render={({ field: { value, onChange } }) => (
            <>
              <FormSelect
                onChange={(event) => {
                  onChange(event);
                  removerPerfilHandler(PerfilAcessoEnum.AtendenteTARMExterno);
                  if (watch('tipo') === TipoUsuarioEnum.EXTERNO) {
                    adicionarPerfilHandler(PerfilAcessoEnum.AtendenteTARMExterno);
                  }
                }}
                value={value ?? ''}
                name="tipo"
                size="small"
                label="Tipo usuário"
              >
                {ListaTipoUsuarioEnum.map((x) => (
                  <MenuItem key={x.id} value={x.id}>
                    <div style={{ display: 'flex' }}>
                      <span>{x.descricao}</span>
                    </div>
                  </MenuItem>
                ))}
              </FormSelect>
            </>
          )}
        />
      </Grid>
      {watch('tipo') === TipoUsuarioEnum.INTERNO && (
        <Grid item xs={12} pb={2}>
          <Controller
            name={`perfilAcesso`}
            control={control}
            render={({ field: { value } }) => (
              <>
                <FormSelect
                  onChange={(event: SelectChangeEvent<unknown>) => {
                    adicionarPerfilHandler(event.target.value as PerfilAcessoEnum);
                  }}
                  value={value ?? ''}
                  name="perfisAcesso"
                  size="small"
                  label="Perfil"
                >
                  {gruposAcesso
                    .filter((p) => p.codigo !== 'TAREX')
                    .map((x) => (
                      <MenuItem key={x.codigo} value={x.codigo}>
                        <div style={{ display: 'flex' }}>
                          <span>{x.nome}</span>
                        </div>
                      </MenuItem>
                    ))}
                </FormSelect>
                <ListaPerfisAcesso
                  lista={getValues().perfisAcesso ?? []}
                  listaGrupoAcesso={gruposAcesso}
                  onRemove={(id: PerfilAcessoEnum) => {
                    removerPerfilHandler(id);
                  }}
                />
              </>
            )}
          />
        </Grid>
      )}
    </Grid>
  );
};

export default FormCriarUsuario;
