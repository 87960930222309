import { HttpTransportType, HubConnection } from '@microsoft/signalr';
import { HubConnectionBuilder } from '@microsoft/signalr/dist/esm/HubConnectionBuilder';
import { LogLevel } from '@microsoft/signalr/dist/esm/ILogger';
import { IconButton } from '@mui/material';
import { useSnackbar } from 'notistack';
import { createContext, PropsWithChildren, useCallback, useEffect, useRef, useState } from 'react';

import {
  NotificacaoNaoLidaSignalRHubModel,
  TimelineSignalRHubModel,
  UserSignalRHubModel
} from '../../../models/SignalRHubModel';
import { BaseConfig } from '../../../shared/utils/baseConfig';
import { useAuthenticationContext } from '../authentication/authenticationContext';

const HUB_URL_TIMELINE = `${BaseConfig.baseApiUrlSignalR}/api/timeLineHub`;
const HUB_URL_USUARIO = `${BaseConfig.baseApiUrlSignalR}/api/usuarioHub`;

interface SignalRContextData {
  getConnection: () => {
    hubConnectionTimeline: HubConnection | undefined;
    hubConnectionUsuario: HubConnection | undefined;
  };
  signalRTimelineData: TimelineSignalRHubModel | null;
  quantidadeNotificacaoNaoLida: NotificacaoNaoLidaSignalRHubModel | null;
}

const SignalRContext = createContext<SignalRContextData>({} as SignalRContextData);

const SignalRProvider = ({ children }: PropsWithChildren) => {
  const [timelineData, setTimelineData] = useState<TimelineSignalRHubModel | null>(null);
  const [notificacaoNaoLida, setNotificacaoNaoLida] =
    useState<NotificacaoNaoLidaSignalRHubModel | null>(null);
  const hubConnectionUsuarioRef = useRef<HubConnection>();
  const hubConnectionTimelineRef = useRef<HubConnection>();

  const {
    state: { userInfo }
  } = useAuthenticationContext();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const getConnection = useCallback(() => {
    return {
      hubConnectionTimeline: hubConnectionTimelineRef.current,
      hubConnectionUsuario: hubConnectionUsuarioRef.current
    };
  }, []);

  const alertarUsuarioResponsavel = useCallback(
    (data: UserSignalRHubModel[]) => {
      data.forEach((user) => {
        if (user.usuarioId === userInfo.data?.usuarioId) {
          setNotificacaoNaoLida({
            pacienteId: user.pacienteId,
            quantidadeNotificacaoNaoLida: user.quantidadeNotificacaoNaoLida,
            usuarioId: user.usuarioId
          });
        }
        user.usuarioId === userInfo.data?.usuarioId &&
          enqueueSnackbar(
            <>
              {user.mensagem}
              <IconButton
                aria-label="fechar"
                sx={{
                  height: 'fit-content',
                  color: 'lightBlue',
                  fontSize: '1em',
                  marginLeft: '5px'
                }}
                onClick={() => closeSnackbar()}
              >
                X
              </IconButton>
            </>,
            {
              variant: 'info',
              preventDuplicate: true,
              autoHideDuration: 30000
            }
          );
      });
    },
    [closeSnackbar, enqueueSnackbar, userInfo.data?.usuarioId]
  );

  // HUB START
  useEffect(() => {
    hubConnectionTimelineRef.current = new HubConnectionBuilder()
      .configureLogging(LogLevel.Debug)
      .withUrl(HUB_URL_TIMELINE, {
        skipNegotiation: true,
        transport: HttpTransportType.WebSockets
      })
      .withAutomaticReconnect()
      .build();

    hubConnectionUsuarioRef.current = new HubConnectionBuilder()
      .configureLogging(LogLevel.Debug)
      .withUrl(HUB_URL_USUARIO, {
        skipNegotiation: true,
        transport: HttpTransportType.WebSockets
      })
      .withAutomaticReconnect()
      .build();
  }, []);

  // USUARIO HUB
  useEffect(() => {
    if (!hubConnectionUsuarioRef.current) return;
    if (!userInfo.data?.usuarioId) return;

    hubConnectionUsuarioRef.current
      .start()
      .then(function () {
        console.log('SignalR Connected: Notify');

        hubConnectionUsuarioRef.current?.on('NotificarUsuario', (data: UserSignalRHubModel[]) => {
          console.log(data);
          data && alertarUsuarioResponsavel(data);
          !data && console.log('No Notify data.');
        });
      })
      .catch(function (err: any) {
        return console.error(err.toString());
      });

    return () => {
      console.log('STOP: Notify');
      hubConnectionUsuarioRef.current?.stop();
    };
  }, [alertarUsuarioResponsavel, userInfo.data?.usuarioId]);

  // TIMELINE HUB
  useEffect(() => {
    if (!hubConnectionTimelineRef.current) return;

    hubConnectionTimelineRef.current
      .start()
      .then(function () {
        console.log('SignalR Connected: Timeline');

        hubConnectionTimelineRef.current?.on(
          'AtualizarTimeline',
          (data: TimelineSignalRHubModel) => {
            if (!data) return console.log('No Timeline data.');

            const copy = data;
            copy.timeLine.sort((a, b) => (a.ordem > b.ordem ? 1 : -1));
            setTimelineData(copy);
          }
        );
      })
      .catch(function (err: any) {
        return console.error(err.toString());
      });

    return () => {
      console.log('STOP: Timeline');
      hubConnectionTimelineRef.current?.stop();
    };
  }, []);

  return (
    <SignalRContext.Provider
      value={{
        getConnection,
        signalRTimelineData: timelineData,
        quantidadeNotificacaoNaoLida: notificacaoNaoLida
      }}
    >
      {children}
    </SignalRContext.Provider>
  );
};

export { SignalRContext, SignalRProvider };
