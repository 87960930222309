import { OptTheme } from '@optsol/react';

import { UserInfo } from '../../../shared/msal/UserInfo';
import { LoadableData } from '../../../shared/types/LoadableData';

export interface AuthenticationState {
  userInfo: LoadableData<UserInfo | undefined>;
  tema: OptTheme;
  temaSecundario: OptTheme;
  tenantId?: string;
  token?: string;
  historyToken?: string;
  localId?: string;
}

export const AUTHENTICATION_INITIAL_STATE: AuthenticationState = {
  userInfo: { data: undefined, loading: false },
  tema: {},
  temaSecundario: {}
};

export interface DecodedTokenModel {
  aud: string;
  azp: string;
  emails: string[];
  exp: number;
  extension_SecurityClaim?: string;
  extension_TenantClaim?: string;
  family_name: string;
  given_name: string;
  iat: number;
  isForgotPassword?: boolean;
  iss: string;
  name: string;
  nbf: number;
  nonce: string;
  oid: string;
  scp: string;
  sub: string;
  tfp: string;
  ver: string;
}
