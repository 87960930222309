import { ButtonHTMLAttributes, DetailedHTMLProps } from 'react';

import { Colors } from '../../../core/colors';

import * as B from './Button.styles';

export interface ButtonProps
  extends DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {
  variant: 'danger' | 'text' | 'primary' | 'success';
  label?: string;
  span?: boolean;
}

export default function Button({ label, variant, span, ref: _ref, ...props }: ButtonProps) {
  return (
    <>
      {!span ? (
        <B.Wrapper variant={variant} {...props}>
          {label}
        </B.Wrapper>
      ) : (
        <B.SpanWrapper variant={variant} color={Colors.primary} {...props}>
          {label}
        </B.SpanWrapper>
      )}
    </>
  );
}
