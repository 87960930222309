import {
  OptGrid,
  OptGridColumn,
  OptGridOptions,
  OptGridRef,
  OptGridRequest,
  OptGridResponse,
  OptSideLayoutContent
} from '@optsol/react';
import { forwardRef } from 'react';

import { Colors } from '../../../core/colors';
import { VeiculoSearchResponse } from '../../../models/dtos/Veiculo/veiculoSearchResponse.model';
import { getHeaderTheme, obterFiltroDaRota } from '../../../shared/utils/functions';
import { ChipStatus } from '../../components/StatusText/ChipStatus';
import { TipoVeiculoText } from '../../components/TipoVeiculoText/TipoVeiculoText';
import { useAuthenticationContext } from '../../contexts/authentication/authenticationContext';
interface GridProps {
  carregar: (data: OptGridRequest) => Promise<OptGridResponse<VeiculoSearchResponse>>;
  editarVeiculo(veiculoId: string): Promise<void>;
}

const options: OptGridOptions = {
  search: true,
  pageSize: 10
};

const GridVeiculos = forwardRef<OptGridRef | undefined, GridProps>(
  ({ carregar, editarVeiculo }, ref) => {
    const {
      state: { tema }
    } = useAuthenticationContext();

    const defaultPageSize = obterFiltroDaRota('pageSize');
    const defaultPage = obterFiltroDaRota('page');
    const columns: OptGridColumn<VeiculoSearchResponse>[] = [
      {
        title: 'Id',
        field: 'id',
        hidden: true
      },
      {
        title: 'Tipo',
        field: 'tipo',
        align: 'start',
        render: (props: VeiculoSearchResponse) => {
          return <TipoVeiculoText tipo={props.tipo}></TipoVeiculoText>;
        }
      },
      {
        title: 'Prefixo',
        field: 'prefixo',
        align: 'start',
        render: (props: VeiculoSearchResponse) => {
          return <>{props.prefixo ? props.prefixo : '-'}</>;
        }
      },
      {
        title: 'Placa',
        field: 'placa',
        align: 'start',
        render: (props: VeiculoSearchResponse) => {
          return <>{props.placa ? props.placa : '-'}</>;
        }
      },
      {
        title: 'Chassi',
        field: 'chassi',
        align: 'start',
        render: (props: VeiculoSearchResponse) => {
          return <>{props.chassi ? props.chassi : '-'}</>;
        }
      },
      {
        title: 'Renavam',
        field: 'renavam',
        align: 'start',
        render: (props: VeiculoSearchResponse) => {
          return <>{props.renavam ? props.renavam : '-'}</>;
        }
      },
      {
        title: 'Rastreável',
        field: 'rastreavel',
        align: 'start',
        render: (props: VeiculoSearchResponse) => {
          return <>{props.rastreavel ? 'Sim' : 'Não'}</>;
        }
      },
      {
        title: 'Situação  ',
        field: 'situacao',
        align: 'start',
        render: (props: VeiculoSearchResponse) => {
          return <ChipStatus status={props.situacao} />;
        }
      }
    ];
    return (
      <OptSideLayoutContent>
        <OptGrid
          ref={ref as any}
          headerStyle={getHeaderTheme(tema.light?.primary ? tema.light.primary : Colors.gray10)}
          data={carregar}
          options={{
            ...options,
            pageIndex: defaultPage !== null ? parseInt(defaultPage) : 0,
            pageSize: defaultPageSize !== null ? parseInt(defaultPageSize) : 10
          }}
          columns={columns}
          onRowClick={(rowData) => {
            editarVeiculo(rowData.id);
          }}
        />
      </OptSideLayoutContent>
    );
  }
);

GridVeiculos.displayName = 'GridVeiculos';
export default GridVeiculos;
