import { ResponsivePie } from '@nivo/pie';

import { Colors } from '../../../core/colors';
import { PieChartDataModel, PieChartSemDados } from '../../../models/dashboards/DashboardsModel';

import { chartTheme } from './config';
import * as S from './styles';

interface Props {
  chartData: PieChartDataModel[];
}

export const GraficoDonuts = ({ chartData }: Props) => {
  const semValoresZerados = chartData.some((obj) => obj.value !== 0);
  const valorTotal = chartData.reduce((p, c) => p + c.value, 0);

  return (
    <S.ChartContainer>
      {chartData.length && semValoresZerados ? (
        <>
          <ResponsivePie
            data={chartData ?? []}
            margin={{
              top: 20,
              right: -5,
              bottom: 10,
              left: 155
            }}
            innerRadius={0}
            padAngle={1}
            activeOuterRadiusOffset={4}
            activeInnerRadiusOffset={0}
            colors={chartData.map((value) => value.color)}
            enableArcLinkLabels={false}
            arcLabelsTextColor={'white'}
            arcLabelsSkipAngle={25}
            arcLabel={(d) => (d.value === 0 ? '' : `${((d.value * 100) / valorTotal).toFixed(0)}%`)}
            startAngle={-180}
            theme={chartTheme}
            legends={[
              {
                anchor: 'left',
                itemTextColor: '#A3AED0',
                direction: 'column',
                itemHeight: 0,
                itemWidth: 0,
                itemsSpacing: 34,
                translateX: -126.5,
                translateY: -6,
                symbolShape: 'circle',
                symbolSize: 10.5,
                symbolSpacing: 7
              }
            ]}
          />
          <S.LegendShadows />
        </>
      ) : (
        <ResponsivePie
          data={PieChartSemDados}
          margin={{
            top: 10,
            right: 0,
            bottom: 75,
            left: 0
          }}
          innerRadius={0.52}
          activeOuterRadiusOffset={4}
          activeInnerRadiusOffset={0}
          colors={Colors.gray7}
          arcLabel={() => 'Sem dados'}
          enableArcLinkLabels={false}
          arcLabelsTextColor={Colors.gray3}
          startAngle={-180}
          theme={chartTheme}
        />
      )}
    </S.ChartContainer>
  );
};
