import { Box, InputLabel, StandardTextFieldProps, TextField, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useEffect, useMemo, useRef, useState } from 'react';

import { useAuthenticationContext } from '../../contexts/authentication/authenticationContext';

import { MapModal } from './MapModal';
import './MapsCustom.css';
import { FullLocationModel } from './model/FullLocation.model';

interface Props extends Omit<StandardTextFieldProps, 'label'> {
  onPlaceChange: (
    place: google.maps.places.PlaceResult,
    placeId?: string,
    fullLocation?: FullLocationModel
  ) => void;
  defalutPlace?: string;
  label?: string;
  disabled?: boolean;
}

export const AutoCompleteMaps = ({
  onPlaceChange,
  defalutPlace,
  label,
  disabled,
  ...inputProps
}: Props) => {
  const [open, setOpen] = useState(false);
  const [placeSearched, setPlaceSearched] = useState('');
  const [place, setPlace] = useState<google.maps.places.PlaceResult>();
  const [center, setCenter] = useState<google.maps.LatLngLiteral>({
    lat: -20.3426338,
    lng: -40.2894163
  });
  const {
    state: { tema }
  } = useAuthenticationContext();
  useEffect(() => {
    setPlaceSearched(defalutPlace ?? '');
  }, [defalutPlace]);

  const inputRef = useRef<HTMLInputElement>(null);
  const autocompleteRef = useRef<google.maps.places.Autocomplete>();
  const options = useMemo(
    () => ({
      componentRestrictions: { country: 'br' },
      fields: ['address_components', 'formatted_address', 'geometry', 'icon', 'name', 'place_id']
    }),
    []
  );

  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (inputRef.current) {
      autocompleteRef.current = new google.maps.places.Autocomplete(inputRef.current, options);

      autocompleteRef.current.addListener('place_changed', function () {
        if (autocompleteRef.current) {
          const placeToAdd = autocompleteRef.current.getPlace();

          if (placeToAdd) {
            setOpen(true);
            setPlace(placeToAdd);
            setPlaceSearched(placeToAdd.formatted_address ?? '');
            if (placeToAdd.geometry?.location) setCenter(placeToAdd.geometry.location.toJSON());
          }
        }
      });
    }
  }, [enqueueSnackbar, options]);

  const savePlace = (
    placeToSave?: google.maps.GeocoderResult,
    fullLocation?: FullLocationModel
  ) => {
    setOpen(false);
    // setPlaceSearched("");

    if (placeToSave) onPlaceChange(placeToSave, undefined, fullLocation);
    if (!placeToSave && place) onPlaceChange(place, undefined, fullLocation);
  };

  return (
    <Box display="flex" flexDirection="column" flex={1}>
      <InputLabel>
        <Typography textAlign="left" color={tema.light?.primary} lineHeight={1}>
          {label}
        </Typography>
      </InputLabel>
      <TextField
        {...inputProps}
        required
        type="text"
        fullWidth
        size="small"
        inputRef={inputRef}
        value={placeSearched}
        onChange={(e) => setPlaceSearched(e.target.value)}
        placeholder={label ?? 'Pesquise por um lugar'}
        disabled={disabled}
      />
      <MapModal position={center} open={open} onClose={savePlace} />
    </Box>
  );
};
