import { mdiPlusCircle } from '@mdi/js';
import Icon from '@mdi/react';

import { Colors } from '../../../core/colors';

import * as S from './Button.styles';

interface Props {
  onClick: () => void;
  color?: boolean;
  icon?: string;
  p?: string;
}
export const IconButton = (props: Props) => {
  return (
    <S.StyledPlusButton onClick={props.onClick} p={props.p ? props.p : ''}>
      <Icon
        path={props.icon ? props.icon : mdiPlusCircle}
        size={1}
        color={props.color ? Colors.primary : '#757985'}
      />
    </S.StyledPlusButton>
  );
};
