import { mdiCarEmergency } from '@mdi/js';
import Icon from '@mdi/react';
import Box from '@mui/material/Box';
import { OptSideLayoutContent } from '@optsol/react';
import { useCallback, useEffect, useState } from 'react';

import { Colors } from '../../../core/colors';
import { StatusVeiculoEnum } from '../../../models/enums/StatusVeiculo';
import { TipoLocalEnum } from '../../../models/enums/TipoLocal';
import { TipoVeiculo } from '../../../models/enums/TipoVeiculo';
import { LocalizacoesModel } from '../../../models/Mapa/Localizacoes.model';
import { useVeiculoService } from '../../../service/veiculo.service';
import { updateFiltroUrl, obterFiltroDaRota } from '../../../shared/utils/functions';
import { FullLocationModel } from '../../components/Google/model/FullLocation.model';
import MapaComponent from '../../components/mapa-google/Mapa';
import { PainelVeiculo } from '../painelVeiculo/PainelVeiculo';

import { ToolbarMapaAmbulancia } from './ToolbarMapaAmbulancia';

const MapaAmbulancia = () => {
  const [procurarAmbulanciaSearch, setProcurarAmbulanciaSearch] = useState(
    obterFiltroDaRota('termo') ?? ''
  );
  const [statusAtendimentoSearch, setStatusAtendimentoSearch] = useState(
    obterFiltroStatusDaRota() ?? ''
  );
  const [exibirPainel, setExibirPainel] = useState<boolean>(false);
  const [enderecoOptsol] = useState<FullLocationModel>({ lat: -20.3426338, lng: -40.2894163 });
  const { obterGeolocalizacoes } = useVeiculoService();

  const [posicoesMapa, setPosicoesMapa] = useState<LocalizacoesModel>({
    geolocalizacoesEmpresa: [],
    geolocalizacoesVeiculos: []
  });
  function obterFiltroStatusDaRota() {
    const search = new URLSearchParams(location.search);
    return search.get('status') === '-1' ? '' : search.get('status');
  }

  const buscarLocalizacao = useCallback(async () => {
    // setPosicoesMapa({
    //   geolocalizacoesEmpresa: [],
    //   geolocalizacoesVeiculos: []
    // });
    const result = await obterGeolocalizacoes({
      termo: procurarAmbulanciaSearch,
      status: statusAtendimentoSearch
    });

    if (result.data?.geolocalizacoesEmpresa?.length > 0) {
      setCenter({
        lat: +result.data?.geolocalizacoesEmpresa[0]?.lat,
        lng: +result.data?.geolocalizacoesEmpresa[0]?.lng
      });
      setCenter({
        lat: +result.data?.geolocalizacoesEmpresa[0]?.lat,
        lng: +result.data?.geolocalizacoesEmpresa[0]?.lng
      });
    } else {
      result.data.geolocalizacoesEmpresa.push({
        lat: enderecoOptsol.lat,
        lng: enderecoOptsol.lng,
        nomeLocal: 'OPTSOL',
        tipo: TipoLocalEnum.SEDE,
        localizacaoId: ''
      });
      setCenter({
        lat: enderecoOptsol.lat,
        lng: enderecoOptsol.lng
      });
    }

    result.data.geolocalizacoesVeiculos?.map((x) => {
      x.tipoVeiculoDesc = x.tipoVeiculo === TipoVeiculo.USA ? ' USA ' : ' USB ';
      x.possuiCondutor = x.condutor ? true : false;
      x.condutor = x.condutor ? x.condutor : 'Não informado';
      x.placa = x.placa ? x.placa : 'Não informado';
      x.descricaoOrigem = x.descricaoOrigem ? x.descricaoOrigem : 'Não informado';
      x.descricaoDestino = x.descricaoDestino ? x.descricaoDestino : 'Não informado';
      x.numeroChamado = x.numeroChamado ? x.numeroChamado : 'Não informado';

      if (x.veiculoStatus === StatusVeiculoEnum.DISPONIVEL) {
        x.colorVeiculo = x.possuiCondutor === true ? Colors.green1 : Colors.blue1;

        x.infoTooltip =
          '<div id="content">' +
          '<div id="siteNotice">' +
          '</div>' +
          '<h1 id="firstHeading" class="firstHeading">' +
          x.prefixo +
          ' - ' +
          x.tipoVeiculoDesc +
          '</h1>' +
          '<div id="bodyContent">' +
          '<p><b>Ultima atualização a: </b> ' +
          x.dataHoraUltimaAtualizacao +
          '</p>' +
          '<p><b>Status: </b> DISPONÍVEL </p>' +
          '<p><b>Condutor: </b> ' +
          x.condutor +
          ' </p>' +
          '</div>' +
          '</div>';
      } else {
        x.colorVeiculo = x.possuiCondutor === true ? Colors.red1 : Colors.blue2;
        x.infoTooltip =
          '<div id="content">' +
          '<div id="siteNotice">' +
          '</div>' +
          '<h1 id="firstHeading" class="firstHeading">' +
          x.prefixo +
          '-' +
          x.tipoVeiculoDesc +
          '</h1>' +
          '<div id="bodyContent">' +
          '<p><b>Chamado: </b>' +
          x.numeroChamado +
          '</p>' +
          '<p><b>Ultima atualização à:  </b> ' +
          x.dataHoraUltimaAtualizacao +
          '</p>' +
          '<p><b>Status: </b> EM ATENDIMENTO </p>' +
          '<p><b>Condutor: </b> ' +
          x.condutor +
          ' </p>' +
          '<p><b>Origem: </b>' +
          x.descricaoOrigem +
          '</p>' +
          '<p><b>Destino: </b>' +
          x.descricaoDestino +
          '</p>' +
          '</div>' +
          '</div>';
      }
    });
    result.data.geolocalizacoesVeiculos = result.data.geolocalizacoesVeiculos.filter(
      (v) => v.lat !== 0 && v.lng !== 0
    );
    setPosicoesMapa(result.data);
  }, [obterGeolocalizacoes, statusAtendimentoSearch, procurarAmbulanciaSearch]);

  const [center, setCenter] = useState<google.maps.LatLngLiteral>({
    lat: enderecoOptsol.lat,
    lng: enderecoOptsol.lng
  });

  function procurarAtendimentoByStatus(data?: string) {
    updateFiltroUrl('status', data ?? '');
    setStatusAtendimentoSearch(data ?? '');
  }

  function procurarAmbulancia(data?: string) {
    updateFiltroUrl('termo', data ?? '');
    setProcurarAmbulanciaSearch(data ?? '');
  }

  function exibirEmPainelSearch(value: boolean) {
    setExibirPainel(value);
  }

  useEffect(() => {
    buscarLocalizacao();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statusAtendimentoSearch, procurarAmbulanciaSearch]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      buscarLocalizacao();
    }, 15000);
    return () => {
      clearInterval(intervalId);
    };
  }, [buscarLocalizacao]);

  return (
    <OptSideLayoutContent>
      <ToolbarMapaAmbulancia
        filtrarGridBySearch={procurarAmbulancia}
        filtrarGridByStatus={procurarAtendimentoByStatus}
        exibirEmPainelSearch={exibirEmPainelSearch}
      />
      {!exibirPainel && (
        <Box px={3.2} pb={2} display="flex" gap={2} fontSize={'0.9rem'} alignItems="center">
          <Box display="flex" gap={0.5}>
            <Icon path={mdiCarEmergency} size={0.7} color={Colors.green1} />
            Disponível
          </Box>

          <Box display="flex" gap={0.5}>
            <Icon path={mdiCarEmergency} size={0.7} color={Colors.red1} />
            Em atendimento
          </Box>
        </Box>
      )}
      {!exibirPainel && (
        <Box px={2}>
          <MapaComponent posicoesMapa={posicoesMapa} position={center}></MapaComponent>
        </Box>
      )}
      {exibirPainel && (
        <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
          <>
            <PainelVeiculo veiculos={posicoesMapa.geolocalizacoesVeiculos} />
          </>
        </Box>
      )}
    </OptSideLayoutContent>
  );
};

export default MapaAmbulancia;
