import { Grid } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';

import { CriarContatoModel } from '../../../../models/Contato/Contato.model';
import { MenuClienteClaims } from '../../../../models/enums/AcessoClaims';
import { FormTextField } from '../../../components/Form';
import { FormNumberFormat } from '../../../components/Form/FormNumberFormat';
import { ProtectedContent } from '../../../components/ProtectedContent/ProtectedContent';

export const FormCriarContato = () => {
  const { control, watch } = useFormContext<CriarContatoModel>();

  const monitorarTelefone = watch('telefone') ?? '';

  return (
    <>
      <Grid container item rowSpacing={2} columnSpacing={1} pt={2.5} pb={2.5}>
        <Grid item xs={12}>
          <Controller
            name="cpf"
            control={control}
            render={() => (
              <>
                <ProtectedContent mode="disable" access={MenuClienteClaims.ATUALIZAR}>
                  <FormNumberFormat
                    control={control}
                    format={'###.###.###-##'}
                    name="cpf"
                    label="CPF"
                    placeholder="CPF"
                  />
                </ProtectedContent>
              </>
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            name="nomeContato"
            control={control}
            render={() => (
              <>
                <ProtectedContent mode="disable" access={MenuClienteClaims.ATUALIZAR}>
                  <FormTextField name="nomeContato" control={control} label="Nome" />
                </ProtectedContent>
              </>
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            name="email"
            control={control}
            render={() => (
              <>
                <ProtectedContent mode="disable" access={MenuClienteClaims.ATUALIZAR}>
                  <FormTextField name="email" control={control} label="E-mail" />
                </ProtectedContent>
              </>
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            name="telefone"
            control={control}
            render={() => (
              <ProtectedContent mode="disable" access={MenuClienteClaims.ATUALIZAR}>
                <FormNumberFormat
                  control={control}
                  format={
                    monitorarTelefone?.replace(/[^\w\s]/gi, '')?.trim().length >= 12
                      ? '(##) #####-####'
                      : '(##) ####-#####'
                  }
                  name="telefone"
                  label="Telefone"
                  size="small"
                />
              </ProtectedContent>
            )}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default FormCriarContato;
