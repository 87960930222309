import { Box } from '@mui/material';
import { OptBackdrop } from '@optsol/react';
import { format } from 'date-fns';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';

import { AtendimentoFormModel } from '../../../models/Atendimento/Atendimento.model';
import { LISTA_PACIENTE_DEFAULT } from '../../../models/Atendimento/Paciente/InformacaoPaciente.model';
import { CriarAtendimentoRequest } from '../../../models/dtos/Atendimento/criarAtendimentoRequest.model';
import { EncaminharRequest } from '../../../models/dtos/Atendimento/encaminharRequest.mode';
import { ClienteSearchResponse } from '../../../models/dtos/Cliente/clienteSearchResponse.model';
import { MenuAtendimentoClaims } from '../../../models/enums/AcessoClaims';
import { StatusAtendimentoEnum } from '../../../models/enums/StatusAtendimento';
import { MotivoAtendimentoModel } from '../../../models/MotivoAtendimento/MotivoAtendimento.model';
import { DADOS_PLANO_DEFAULT } from '../../../models/Plano/Plano.model';
import { useAtendimentoService } from '../../../service/atendimento.service';
import { useMotivoAtendimentoService } from '../../../service/motivoAtendimento.service';
import { validarCPF } from '../../../shared/utils/functions';
import { PaginatedSearchRequest, SearchRequest } from '../../../shared/utils/searchRequest';
import { Modal } from '../../components/Modal/Modal';
import { useAuthenticationContext } from '../../contexts/authentication/authenticationContext';

import FormAtendimento from './FormAtendimento';
import FormEncaminhar from './FormEncaminhar';

interface Props {
  disableForm?: boolean;
  possuiId?: (id: string) => void;
  disableFormAgendamento?: boolean;
}

const EditarAtendimento = ({ disableForm, disableFormAgendamento, possuiId }: Props) => {
  const { getValues, reset } = useFormContext<AtendimentoFormModel>();
  const { hasAccess } = useAuthenticationContext();
  const { salvarAtendimento, obterAtendimentoById, alterarAtendimento, encaminhar } =
    useAtendimentoService();

  const { buscarGridMotivoAtendimentoPaginated } = useMotivoAtendimentoService();

  const navigate = useNavigate();

  const [atendimentoIsLoading, setAtendimentoIsLoading] = useState<boolean>(false);
  const [salvarAtendimentoIsLoading, setSalvarAtendimentoIsLoading] = useState<boolean>(false);
  const [loadingEncaminhamento, setLoadingEncaminhamento] = useState<boolean>(false);
  const { enqueueSnackbar } = useSnackbar();

  const [defaultCliente, setDefaultCliente] = useState<ClienteSearchResponse | null>(null);

  const [encaminharIsOpen, setEncaminharIsOpen] = useState<boolean>(false);

  const params = useParams();
  const { id } = params;

  async function obterAtendimento() {
    try {
      if (!params.id) return;
      setAtendimentoIsLoading(true);
      const result = await obterAtendimentoById(id ?? '');
      if (result.data.pacientes.length === 0) {
        result.data.pacientes = LISTA_PACIENTE_DEFAULT;
      }
      const atendimento = result.data;
      reset({ ...atendimento });
      if (atendimento.cliente) {
        setDefaultCliente({ ...atendimento.cliente });
      }
    } finally {
      setAtendimentoIsLoading(false);
    }
  }

  const editarAtendimento = async () => {
    let atendimentoModel = getValues();
    const telefone = atendimentoModel?.telefoneSolicitante
      ?.replace(/[^\w\s]/gi, '')
      .replace(' ', '')
      .trim();
    const documento = atendimentoModel.cpfSolicitante;
    if (!hasAccess(MenuAtendimentoClaims.INSERIR_ATENDIMENTO_USUARIO_EXTERNO))
      if (telefone.trim().length < 10 && telefone.trim().length < 11) return;
    if (documento) {
      if (!validarCPF(documento)) return;
    }
    const motivoAtendimentoParsed =
      typeof atendimentoModel.motivoAtendimento === 'string'
        ? JSON.parse(atendimentoModel.motivoAtendimento)
        : atendimentoModel.motivoAtendimento;

    atendimentoModel = { ...formatarData(atendimentoModel) };

    const request: CriarAtendimentoRequest = {
      ...atendimentoModel,
      cpfSolicitante: documento
        ?.replace(/[^\w\s]/gi, '')
        .replace(' ', '')
        .trim(),
      motivoAtendimento: motivoAtendimentoParsed,
      telefoneSolicitante: atendimentoModel.telefoneSolicitante
        ?.replace(/[^\w\s]/gi, '')
        .replace(' ', '')
        .trim()
    };

    try {
      setSalvarAtendimentoIsLoading(true);
      let result;
      const isInsert = !atendimentoModel?.atendimentoId;

      if (!atendimentoModel?.atendimentoId) {
        result = await salvarAtendimento({
          ...request
        });
        const atendimentoresult = result.data;
        if (result.data.pacientes.length == 0) {
          atendimentoresult.pacientes = [...LISTA_PACIENTE_DEFAULT];
        }
        reset({ ...atendimentoresult });
        if (atendimentoresult.cliente) {
          setDefaultCliente({
            clienteId: atendimentoresult.cliente.clienteId ?? '',
            cnpj: atendimentoresult.cliente.cnpj ?? '',
            contatos: atendimentoresult.cliente.contatos ?? [],
            cpf: atendimentoresult.cliente.cpf ?? '',
            enderecos: atendimentoresult.cliente.enderecos ?? [],
            nome: atendimentoresult.cliente.nome,
            plano: atendimentoresult.cliente.plano ?? DADOS_PLANO_DEFAULT,
            planoId: atendimentoresult.cliente.planoId,
            situacao: atendimentoresult.cliente.situacao ?? false,
            clientesFilhos: []
          });
        }
      } else {
        result = await alterarAtendimento(
          {
            ...request
          },
          atendimentoModel.atendimentoId
        );
        reset(atendimentoModel);
        if (!getValues().cliente) {
          setDefaultCliente(null);
        }
      }
      if (atendimentoModel.agendamento) {
        definirStatsusListaDePaciente(StatusAtendimentoEnum.AGENDADO);
      }
      if (isInsert) {
        navigate(result.data.atendimentoId);
      }
    } finally {
      setSalvarAtendimentoIsLoading(false);
    }
  };

  function definirStatsusListaDePaciente(status: StatusAtendimentoEnum) {
    const listaAtualizada = getValues().pacientes.map((obj) => {
      obj.statusPaciente = status;
      return obj;
    });

    reset({ ...getValues(), pacientes: listaAtualizada });
  }

  function formatarData(atendimentoModel: AtendimentoFormModel): AtendimentoFormModel {
    if (atendimentoModel.agendamento) {
      if (atendimentoModel.inicioAgendamento) {
        const dataInicioFormatada = format(
          new Date(atendimentoModel.inicioAgendamento ?? ''),
          'MM/dd/yyyy HH:mm:ss'
        );
        atendimentoModel.inicioAgendamento = dataInicioFormatada;
      }
      if (atendimentoModel.fimAgendamento) {
        const dataFimFormatada = format(
          new Date(atendimentoModel.fimAgendamento ?? ''),
          'MM/dd/yyyy HH:mm:ss'
        );
        atendimentoModel.fimAgendamento = dataFimFormatada;
      }
    }

    return atendimentoModel;
  }

  const toggleEncaminharModal = () => {
    setEncaminharIsOpen((prevState) => !prevState);
  };

  async function encaminharAtendimento(medicoId: string): Promise<void> {
    const formValues = getValues();
    const request: EncaminharRequest = {
      atendimentoId: formValues.atendimentoId ?? '',
      usuarioId: medicoId
    };
    try {
      setLoadingEncaminhamento(true);
      await encaminhar(request);
      toggleEncaminharModal();
      enqueueSnackbar('Encaminhamento realizado com sucesso!', {
        variant: 'success'
      });
    } finally {
      setLoadingEncaminhamento(false);
    }
  }

  async function obterMotivos(): Promise<MotivoAtendimentoModel[]> {
    const request: SearchRequest<PaginatedSearchRequest> = {
      page: 0,
      pageSize: 50,
      search: { apenasAtivo: true, termoBuscado: '' }
    };
    const result = await buscarGridMotivoAtendimentoPaginated(request);
    const motivos: MotivoAtendimentoModel[] = [];
    result.data.forEach((x) => {
      motivos.push({
        descricao: x.descricao,
        nome: x.nome,
        servicosCobertos: x.servicosCobertos,
        enderecosAtendimentos: x.enderecosAtendimentos,
        situacao: x.situacao,
        motivoAtendimentoId: x.motivoAtendimentoId
      });
    });

    return motivos;
  }

  useEffect(() => {
    obterAtendimento();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box>
      {!atendimentoIsLoading && (
        <>
          <FormAtendimento
            editarAtendimento={editarAtendimento}
            obterAtendimentoById={obterAtendimento}
            obterMotivos={obterMotivos}
            defaultCliente={defaultCliente}
            disableForm={disableForm}
            disableFormAgendamento={disableFormAgendamento}
            possuiId={possuiId}
          />
          <Modal
            open={encaminharIsOpen}
            onClose={toggleEncaminharModal}
            title="Encaminhamento"
            width="600px"
          >
            <FormEncaminhar
              loadingButtomSave={loadingEncaminhamento}
              encaminharAtendimento={encaminharAtendimento}
            />
          </Modal>
        </>
      )}
      <OptBackdrop open={salvarAtendimentoIsLoading} />
      <OptBackdrop open={atendimentoIsLoading} />
    </Box>
  );
};

export default EditarAtendimento;
