import * as yup from 'yup';

export interface RelatorioFormModel {
  dataInicio?: string | null;
  dataFim?: string | null;
}

export const DADOS_RELATORIO_DEFAULT: RelatorioFormModel = {
  dataInicio: '',
  dataFim: ''
};

export const dadosRelatorioFormModelSchema: yup.SchemaOf<RelatorioFormModel> = yup.object().shape({
  dataInicio: yup.string().notRequired().nullable(),
  dataFim: yup.string().notRequired().nullable()
});
