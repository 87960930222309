export enum TipoUsuarioEnum {
  INTERNO = 1,
  EXTERNO = 2
}

export const TipoUsuarioEnumLabel = new Map<number, string>([
  [TipoUsuarioEnum.INTERNO, 'Interno'],
  [TipoUsuarioEnum.EXTERNO, 'Externo']
]);

export const ListaTipoUsuarioEnum = [
  {
    id: TipoUsuarioEnum.INTERNO,
    descricao: TipoUsuarioEnumLabel.get(TipoUsuarioEnum.INTERNO)
  },
  {
    id: TipoUsuarioEnum.EXTERNO,
    descricao: TipoUsuarioEnumLabel.get(TipoUsuarioEnum.EXTERNO)
  }
];
