import { mdiCarEmergency } from '@mdi/js';
import Icon from '@mdi/react';
import { Box } from '@mui/material';

import { Colors } from '../../../core/colors';
import { LocalizacoesPorPacienteModel } from '../../../models/Mapa/Localizacoes.model';
import MapaComponent from '../mapa-google/Mapa';

import { Modal } from './Modal';

interface Props {
  openMapsModal: boolean;
  handleCloseMapsModal: () => void;
  posicoesMapa?: LocalizacoesPorPacienteModel;
  center: google.maps.LatLngLiteral;
}

export const ModalMaps = ({ openMapsModal, handleCloseMapsModal, posicoesMapa, center }: Props) => {
  return (
    <Modal
      open={openMapsModal}
      onClose={handleCloseMapsModal}
      width="60vw"
      title="Acompanhar ambulância"
    >
      <Box height={'70vh'} display="flex" flexDirection="column" pb={0.5} pt={1}>
        <Box height="100%" margin={'0 -0.4em'}>
          <MapaComponent posicoesPorPaciente={posicoesMapa} position={center} $minHeight={'100%'} />
        </Box>

        <Box display="flex" gap={2} fontSize={'0.9rem'} alignItems="center" mt={2}>
          <Box display="flex" gap={0.5}>
            <Icon path={mdiCarEmergency} size={0.7} color={Colors.green1} />
            Disponível
          </Box>
          <Box display="flex" gap={0.5}>
            <Icon path={mdiCarEmergency} size={0.7} color={Colors.red1} />
            Em atendimento
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};
