import { Box, responsiveFontSizes, ThemeProvider } from '@mui/material';
import { OptLayoutProvider, OptTheme } from '@optsol/react';

import { muiTheme } from '../../shared/theme';

export const withInternalTheme = (Component: () => JSX.Element, insideTheme: OptTheme) => {
  return (
    <OptLayoutProvider theme={insideTheme} noRouter>
      <ThemeProvider
        theme={(currentTheme) => {
          return responsiveFontSizes({ ...currentTheme, ...muiTheme });
        }}
      >
        <Box
          display="flex"
          flexDirection="column"
          flex={1}
          sx={{ overflowY: 'scroll', maxHeight: '100vh' }}
        >
          <Component />
        </Box>
      </ThemeProvider>
    </OptLayoutProvider>
  );
};
