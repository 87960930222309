import {
  Autocomplete,
  Box,
  debounce,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  SelectChangeEvent,
  Switch,
  TextField,
  Typography
} from '@mui/material';
import { OptBackdrop } from '@optsol/react';
import { useCallback, useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import {
  ClienteSearchResponse,
  GridCliente
} from '../../../models/dtos/Cliente/clienteSearchResponse.model';
import { GrupoAcessoResponse } from '../../../models/dtos/Usuario/usuarioSearchResponse.model';
import {
  MenuAtendimentoClaims,
  MenuClienteClaims,
  MenuUsuarioClaims
} from '../../../models/enums/AcessoClaims';
import { PerfilAcessoEnum } from '../../../models/enums/PerfilAcesso';
import { ListaTipoUsuarioEnum, TipoUsuarioEnum } from '../../../models/enums/TipoUsuario';
import { DADOS_PLANO_DEFAULT } from '../../../models/Plano/Plano.model';
import { UsuarioModel } from '../../../models/Usuario/Usuario.model';
import { useClienteService } from '../../../service/cliente.service';
import { useUsuarioService } from '../../../service/usuario.service';
import { validarCPF } from '../../../shared/utils/functions';
import { PaginatedSearchRequest, SearchRequest } from '../../../shared/utils/searchRequest';
import { ErrorMessage, FormSelect, FormTextField } from '../../components/Form';
import { FormNumberFormat } from '../../components/Form/FormNumberFormat';
import { TituloForm } from '../../components/Form/TituloForm';
import { ProtectedContent } from '../../components/ProtectedContent/ProtectedContent';
import { useAuthenticationContext } from '../../contexts/authentication/authenticationContext';
import GridClientes from '../../pages/Clientes/GridClientes';
import { ListaPerfisAcesso } from '../atendimento/ListaPerfisAcesso/ListaPerfisAcesso';
interface Props {
  editarUsuario: () => void;
}

export const FormEditarUsuario = ({ editarUsuario }: Props) => {
  const {
    control,
    setError,
    watch,
    setValue,
    clearErrors,
    getValues,
    reset,
    formState: { errors, isDirty }
  } = useFormContext<UsuarioModel>();

  const { obterGrupoAcesso, vincularCliente, removerClienteVinculado } = useUsuarioService();
  const {
    state: { tema }
  } = useAuthenticationContext();
  const { buscarGridClientesPaginated } = useClienteService();
  const [gruposAcesso, setGruposAcesso] = useState<GrupoAcessoResponse[]>([]);
  const [clientes, setClientes] = useState<ClienteSearchResponse[] | null>();
  const [loading, setLoading] = useState<boolean>(false);

  function editarCpf(value: string) {
    const valido = validarCPF(value);
    if (valido) {
      clearErrors('cpf');
      editarUsuario();
    } else {
      setError('cpf', { message: 'Cpf inválido' });
    }
  }

  const monitorarTelefone = watch('telefone') ?? '';

  function editarTelefone(value: string) {
    if (value) {
      const valido = !(value.trim().length < 14 && value.trim().length < 15);
      if (valido) {
        clearErrors('telefone');
        editarUsuario();
      } else {
        setError('telefone', { message: 'Telefone inválido' });
      }
    } else {
      editarUsuario();
    }
  }

  async function obterPerfisAcesso() {
    const result = await obterGrupoAcesso();
    setGruposAcesso(result.data);
  }

  useEffect(() => {
    obterPerfisAcesso();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  async function adicionarPerfilHandler(id: PerfilAcessoEnum) {
    if (!getValues().perfisAcesso?.find((x) => x === id)) {
      getValues().perfisAcesso?.push(id);
      setValue('perfisAcesso', [...(getValues().perfisAcesso ?? [])]);
      reset(getValues());
      editarUsuario();
    }
  }

  async function removerPerfilHandler(id: PerfilAcessoEnum) {
    setValue('perfisAcesso', [...(getValues().perfisAcesso?.filter((x) => x !== id) ?? [])]);
    reset(getValues());
    editarUsuario();
  }

  function filtrarClientesHandler(newValue: string) {
    filtrarClientes(newValue);
  }

  const changeHandler = useCallback((value: string) => {
    filtrarClientesHandler(value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedClienteChangeHandler = useCallback(debounce(changeHandler, 500), []);

  async function filtrarClientes(termo: string) {
    if (termo) {
      const request: SearchRequest<PaginatedSearchRequest> = {
        page: 0,
        pageSize: 10,
        search: {
          termoBuscado: termo,
          apenasAtivo: true
        }
      };
      const result = await buscarGridClientesPaginated(request);
      setClientes(result.data);
    }
  }
  async function vincularClienteHandler(cliente: ClienteSearchResponse) {
    if (cliente) {
      try {
        setLoading(true);
        const result = await vincularCliente(getValues().usuarioId ?? '', cliente.clienteId);
        if (result.success) {
          getValues().clientesPermitidosCriacaoAtendimento?.push(cliente);
          if (getValues().clientesPermitidosCriacaoAtendimento) {
            setValue(
              'clientesPermitidosCriacaoAtendimento',
              getValues().clientesPermitidosCriacaoAtendimento
            );
            reset(getValues());
          }
        }
      } finally {
        setLoading(false);
      }
    } else {
      console.log('limpar');
    }
  }

  async function removerVlienteVinculado(clienteId: string) {
    try {
      setLoading(true);
      const result = await removerClienteVinculado(getValues().usuarioId ?? '', clienteId);
      if (result.success) {
        if (getValues().clientesPermitidosCriacaoAtendimento) {
          setValue(
            'clientesPermitidosCriacaoAtendimento',
            getValues().clientesPermitidosCriacaoAtendimento?.filter(
              (x) => x.clienteId !== clienteId
            )
          );
          reset(getValues());
        }
      }
    } finally {
      setLoading(false);
    }
  }

  function definirListaDeClientesVinculados() {
    return (
      getValues().clientesPermitidosCriacaoAtendimento?.map((cliente) => {
        let documento = cliente.cpf ? cliente.cpf : '';
        if (cliente.cnpj) documento = cliente.cnpj ? cliente.cnpj : '';

        const gidCliente: GridCliente = {
          ...cliente,
          documento: documento,
          clientesFilhos: [],
          clienteId: cliente.clienteId ?? '',
          plano: cliente.plano ? cliente.plano : DADOS_PLANO_DEFAULT,
          enderecos: cliente.enderecos ?? [],
          contatos: cliente.contatos ?? [],
          situacao: cliente.situacao ?? false
        };
        return gidCliente;
      }) ?? []
    );
  }
  return (
    <Box display="flex" alignItems="center" justifyContent="center">
      <Grid container item rowSpacing={3} columnSpacing={3} px={2.5} pt={2.5}>
        <Grid item xs={12}>
          <TituloForm texto="Dados do usuário" />
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <ProtectedContent mode="disable" access={MenuUsuarioClaims.ATUALIZAR}>
            <FormTextField
              name="nome"
              control={control}
              label="Nome Completo"
              onBlur={() => {
                if (isDirty) editarUsuario();
              }}
            />
          </ProtectedContent>
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <Controller
            name="cpf"
            control={control}
            render={({ field: { value, onBlur } }) => (
              <>
                <ProtectedContent mode="disable" access={MenuUsuarioClaims.ATUALIZAR}>
                  <FormNumberFormat
                    control={control}
                    format={'###.###.###-##'}
                    name="cpf"
                    label="CPF"
                    size="small"
                    placeholder="CPF"
                    onBlur={() => {
                      isDirty ? editarCpf(value) : onBlur();
                    }}
                  />
                </ProtectedContent>
              </>
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <Controller
            name="telefone"
            control={control}
            render={({ field: { onChange, value } }) => (
              <ProtectedContent mode="disable" access={MenuUsuarioClaims.ATUALIZAR}>
                <FormNumberFormat
                  onChange={onChange}
                  control={control}
                  format={
                    monitorarTelefone
                      .replace(/[^\w\s]/gi, '')
                      .replace(' ', '')
                      .trim().length >= 11
                      ? '(##) #####-####'
                      : '(##) ####-#####'
                  }
                  name="telefone"
                  label="Telefone"
                  size="small"
                  placeholder="Telefone"
                  onBlur={() => {
                    isDirty && editarTelefone(value ?? '');
                  }}
                />
              </ProtectedContent>
            )}
          />
        </Grid>

        <Grid item xs={12} sm={6} md={6}>
          <ProtectedContent mode="disable" access={MenuUsuarioClaims.ATUALIZAR}>
            <FormTextField
              name="email"
              control={control}
              label="E-mail"
              onBlur={() => {
                if (isDirty) editarUsuario();
              }}
              disabled={getValues().usuarioId ? true : false}
            />
          </ProtectedContent>
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <Controller
            name={`tipo`}
            control={control}
            render={({ field: { value, onChange } }) => (
              <>
                <FormSelect
                  onChange={onChange}
                  value={value ?? ''}
                  name="tipo"
                  size="small"
                  label="Tipo usuário"
                  disabled
                >
                  {ListaTipoUsuarioEnum.map((x) => (
                    <MenuItem key={x.id} value={x.id}>
                      <div style={{ display: 'flex' }}>
                        <span>{x.descricao}</span>
                      </div>
                    </MenuItem>
                  ))}
                </FormSelect>
              </>
            )}
          />
          <Controller
            name="situacao"
            control={control}
            render={({ field: { onChange, value } }) => (
              <FormControlLabel
                label={'Ativo'}
                control={
                  <ProtectedContent mode="disable" access={MenuUsuarioClaims.ATUALIZAR}>
                    <Switch onChange={onChange} checked={value} onBlur={editarUsuario} />
                  </ProtectedContent>
                }
              />
            )}
          />
        </Grid>
        {(getValues()?.tipo === TipoUsuarioEnum.INTERNO || !getValues().tipo) && (
          <Grid item xs={12} sm={6} md={6}>
            <Controller
              name={`perfilAcesso`}
              control={control}
              render={({ field: { value } }) => (
                <>
                  <FormSelect
                    onChange={(event: SelectChangeEvent<unknown>) => {
                      adicionarPerfilHandler(event.target.value as PerfilAcessoEnum);
                    }}
                    value={value ?? ''}
                    name="perfisAcesso"
                    size="small"
                    label="Perfil"
                  >
                    {gruposAcesso
                      .filter((p) => p.codigo !== 'TAREX')
                      .map((x) => (
                        <MenuItem key={x.codigo} value={x.codigo}>
                          <div style={{ display: 'flex' }}>
                            <span>{x.nome}</span>
                          </div>
                        </MenuItem>
                      ))}
                  </FormSelect>

                  <ListaPerfisAcesso
                    lista={getValues().perfisAcesso ?? []}
                    listaGrupoAcesso={gruposAcesso}
                    onRemove={(id: PerfilAcessoEnum) => {
                      removerPerfilHandler(id);
                    }}
                  />
                </>
              )}
            />
          </Grid>
        )}

        {getValues().perfisAcesso?.some(
          (x) =>
            x === PerfilAcessoEnum.MedicoAmbulancia ||
            x === PerfilAcessoEnum.MedicoRegulador ||
            x === PerfilAcessoEnum.TecnicoEnfermagem ||
            x === PerfilAcessoEnum.Enfermeiro
        ) && (
          <Grid item xs={6}>
            <Controller
              name="documentoOrgaoRegulador"
              control={control}
              render={({ field: { onChange, onBlur } }) => (
                <>
                  <ProtectedContent mode="disable" access={MenuUsuarioClaims.ATUALIZAR}>
                    <FormNumberFormat
                      onChange={onChange}
                      control={control}
                      name="documentoOrgaoRegulador"
                      label="Nº doc. órgão regulador"
                      size="small"
                      placeholder="Nº doc. órgão regulador"
                      onBlur={() => {
                        isDirty ? editarUsuario() : onBlur();
                      }}
                    />
                  </ProtectedContent>
                </>
              )}
            />
            <ErrorMessage error={errors.documentoOrgaoRegulador} />
          </Grid>
        )}
        {getValues().perfisAcesso?.some(
          (x) =>
            x === PerfilAcessoEnum.MedicoAmbulancia ||
            x === PerfilAcessoEnum.MedicoRegulador ||
            x === PerfilAcessoEnum.TecnicoEnfermagem ||
            x === PerfilAcessoEnum.Enfermeiro
        ) && (
          <Grid item xs={6}>
            <Controller
              name="ufDocumento"
              control={control}
              render={({ field: { onBlur } }) => (
                <>
                  <ProtectedContent mode="disable" access={MenuUsuarioClaims.ATUALIZAR}>
                    <FormTextField
                      name="ufDocumento"
                      control={control}
                      label="Uf documento"
                      onBlur={() => {
                        isDirty ? editarUsuario() : onBlur();
                      }}
                    />
                  </ProtectedContent>
                </>
              )}
            />
          </Grid>
        )}
        {getValues().perfisAcesso?.some((x) => x === PerfilAcessoEnum.CondutorAmbulancias) && (
          <Grid item xs={12} sm={6} md={6}>
            <Controller
              name="cnh"
              control={control}
              render={({ field: { onChange, onBlur } }) => (
                <>
                  <ProtectedContent mode="disable" access={MenuUsuarioClaims.ATUALIZAR}>
                    <FormNumberFormat
                      onChange={onChange}
                      control={control}
                      name="cnh"
                      label="Nº cnh"
                      size="small"
                      placeholder="Nº cnh"
                      onBlur={() => {
                        isDirty ? editarUsuario() : onBlur();
                      }}
                    />
                  </ProtectedContent>
                </>
              )}
            />
            <ErrorMessage error={errors.cnh} />
          </Grid>
        )}
        {getValues().perfisAcesso?.some((x) => x === PerfilAcessoEnum.CondutorAmbulancias) && (
          <Grid item xs={6} sm={6} md={6}>
            <Controller
              name="categoriaCnh"
              control={control}
              render={({ field: { onChange, value } }) => (
                <>
                  <ProtectedContent mode="disable" access={MenuUsuarioClaims.ATUALIZAR}>
                    <FormSelect
                      onChange={(event: SelectChangeEvent<unknown>) => {
                        onChange(event);
                        editarUsuario();
                      }}
                      value={value ?? ''}
                      name="categoriaCnh"
                      label="Categoria Cnh"
                      size="small"
                    >
                      <MenuItem key={0} value={0}>
                        A
                      </MenuItem>
                      <MenuItem key={1} value={1}>
                        B
                      </MenuItem>
                      <MenuItem key={2} value={2}>
                        AB
                      </MenuItem>
                      <MenuItem key={3} value={3}>
                        C
                      </MenuItem>
                      <MenuItem key={4} value={4}>
                        AC
                      </MenuItem>
                      <MenuItem key={5} value={5}>
                        D
                      </MenuItem>
                      <MenuItem key={6} value={6}>
                        AD
                      </MenuItem>
                      <MenuItem key={7} value={7}>
                        E
                      </MenuItem>
                      <MenuItem key={8} value={8}>
                        AE
                      </MenuItem>
                    </FormSelect>
                  </ProtectedContent>
                </>
              )}
            />
            <ErrorMessage error={errors.categoriaCnh} />
          </Grid>
        )}
        {getValues().tipo == TipoUsuarioEnum.EXTERNO && (
          <Grid item xs={6}>
            <Box px={2.5} pt={2}>
              <Grid item xs={12}>
                <Controller
                  name="cliente"
                  render={({ field: { onChange } }) => (
                    <ProtectedContent mode="disable" access={MenuClienteClaims.ATUALIZAR}>
                      <InputLabel>
                        <Typography color={tema.light?.primary} lineHeight={1}>
                          Cliente
                        </Typography>
                      </InputLabel>
                      <Autocomplete
                        fullWidth
                        options={clientes ? clientes : []}
                        getOptionLabel={(option) =>
                          typeof option === 'object'
                            ? `${option.cpf ? option.cpf : option.cnpj} - ${option.nome}`
                            : ''
                        }
                        onChange={(_event, newValue: any) => {
                          onChange(newValue ? newValue : null);
                          vincularClienteHandler(newValue);
                        }}
                        onInputChange={(_e, value) => debouncedClienteChangeHandler(value)}
                        renderInput={(params) => (
                          <ProtectedContent mode="disable" access={MenuAtendimentoClaims.ATUALIZAR}>
                            <TextField
                              {...params}
                              size="small"
                              variant="outlined"
                              placeholder="Selecione um cliente"
                            />
                          </ProtectedContent>
                        )}
                        freeSolo
                      />
                    </ProtectedContent>
                  )}
                />
              </Grid>
            </Box>
            <Box px={2.5} pt={2}>
              <GridClientes
                data={definirListaDeClientesVinculados()}
                editarCliente={() => {}}
                onRemove={removerVlienteVinculado}
              ></GridClientes>
            </Box>
          </Grid>
        )}
      </Grid>
      <OptBackdrop open={loading} />
    </Box>
  );
};

export default FormEditarUsuario;
