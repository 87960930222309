import { mdiPlus } from '@mdi/js';
import { Box } from '@mui/material';
import { OptActionToolbar, OptSearchField } from '@optsol/react';
import { useState } from 'react';

import { Colors } from '../../../core/colors';
import { MenuVeiculoClaims } from '../../../models/enums/AcessoClaims';
import { RoutesObj } from '../../../routes/index.routes';
import { obterFiltroDaRota } from '../../../shared/utils/functions';
import { BotaoDeAcaoToolbar } from '../../components/Button/BotaoDeAcaoToolbar';
import { Modal } from '../../components/Modal/Modal';
import { ProtectedContent } from '../../components/ProtectedContent/ProtectedContent';
import { ToolbarContainer } from '../../components/ToolbarContainer';
import { useAuthenticationContext } from '../../contexts/authentication/authenticationContext';
import CriarVeiculo from '../../features/veiculo/CriarVeiculo';
import * as S from '../../style/toolbar-index';

interface Props {
  filtrarGridBySearch: (data?: string) => void;
}

export const ToolbarVeiculos = ({ filtrarGridBySearch }: Props) => {
  const { state } = useAuthenticationContext();
  const [isOpen, setIsOpen] = useState(false);
  const toggleModal = () => {
    setIsOpen((prevState) => !prevState);
  };
  return (
    <ToolbarContainer>
      <OptActionToolbar
        goBackRoute={RoutesObj.Home}
        title="Veículos"
        background={Colors.white}
        color={state.tema.light?.primary}
        clearMargin
      >
        <Box
          sx={{
            display: 'flex',
            flex: 1,
            marginLeft: '30px',
            marginBottom: '-11px'
          }}
        >
          <S.SearchContainer>
            <OptSearchField
              onSearch={filtrarGridBySearch}
              placeholder="Pesquisar"
              defaultValue={obterFiltroDaRota('termo') ?? ''}
            />
          </S.SearchContainer>
        </Box>
        <ProtectedContent access={MenuVeiculoClaims.INSERIR}>
          <BotaoDeAcaoToolbar onClick={toggleModal} texto="Novo" startIcon={mdiPlus} />
        </ProtectedContent>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end'
          }}
        >
          <Modal
            open={isOpen}
            onClose={toggleModal}
            title="Cadastro de veículo"
            width="500px"
            overflow="hidden"
          >
            <CriarVeiculo />
          </Modal>
        </Box>
      </OptActionToolbar>
    </ToolbarContainer>
  );
};
