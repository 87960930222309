import * as yup from 'yup';

import { UsuarioSearchResponseModel } from '../dtos/Usuario/usuarioSearchResponse.model';
import { TipoRastreamentoEnum } from '../enums/TipoRastreamento';
import { TipoVeiculo } from '../enums/TipoVeiculo';
export interface VeiculoModel {
  id?: string;
  tipo: TipoVeiculo;
  condutorAtual?: UsuarioSearchResponseModel;
  situacao: boolean;
  prefixo?: string;
  placa: string;
  chassi?: string | null;
  renavam?: string | null;
  rastreavel?: boolean;
  tipoRastreamento?: TipoRastreamentoEnum | null;
  idRastreio: number;
}

export const DADOS_VEICULO_DEFAULT: VeiculoModel = {
  id: '',
  tipo: 0,
  situacao: false,
  prefixo: '',
  placa: '',
  chassi: '',
  renavam: '',
  rastreavel: false,
  tipoRastreamento: null,
  idRastreio: 0
};

const MSG_REQUIRED = 'Campo obrigatório';

export const dadosVeiculoFormModelSchema: yup.SchemaOf<VeiculoModel> = yup.object().shape({
  id: yup.string().required(MSG_REQUIRED),
  tipo: yup.number().required(MSG_REQUIRED),
  prefixo: yup.string().notRequired(),
  placa: yup.string().required(MSG_REQUIRED),
  condutorAtual: yup.mixed().notRequired(),
  chassi: yup.string().notRequired().nullable(),
  renavam: yup.string().notRequired().nullable(),
  situacao: yup.boolean().required(MSG_REQUIRED),
  rastreavel: yup.boolean().notRequired(),
  tipoRastreamento: yup.number().notRequired().nullable(),
  idRastreio: yup.number().required(MSG_REQUIRED)
});

export interface CriarVeiculoModel {
  tipo: TipoVeiculo;
  placa: string;
}

export const DADOS_CRIAR_VEICULO_DEFAULT: CriarVeiculoModel = {
  tipo: 0,
  placa: ''
};

export const dadosCriarVeiculoFormModelSchema: yup.SchemaOf<CriarVeiculoModel> = yup
  .object()
  .shape({
    tipo: yup.number().required(MSG_REQUIRED),
    placa: yup.string().required(MSG_REQUIRED)
  });

export interface IdsRastreioViewModel {
  id: number;
}
