import { useCallback } from 'react';

import { UsuarioResponseModel } from '../models/dtos/Usuario/UsuarioResponse.model';
import {
  GrupoAcessoResponse,
  UsuarioSearchResponseModel
} from '../models/dtos/Usuario/usuarioSearchResponse.model';
import {
  CriarUsuarioModel,
  UsuarioModel,
  UsuarioResponse,
  UsuarioStatusResponse
} from '../models/Usuario/Usuario.model';
import { useApi } from '../shared/hooks/api';
import { PaginatedSearchRequest, SearchRequest } from '../shared/utils/searchRequest';

export const useUsuarioService = () => {
  const { gridSearch, post, get, put, remove } = useApi();

  const baseApi = `api/usuario`;

  const buscarGridUsuarioPaginated = useCallback(
    async (data: SearchRequest<object>) => {
      return gridSearch<UsuarioSearchResponseModel>(`${baseApi}/paginated`, data);
    },
    [baseApi, gridSearch]
  );

  const buscarUsuarioEncaminhar = useCallback(
    async (data: SearchRequest<PaginatedSearchRequest>) => {
      return gridSearch<UsuarioSearchResponseModel>(
        `${baseApi}/${data.search.pacienteId}/buscar-usuario-encaminhar`,
        data
      );
    },
    [baseApi, gridSearch]
  );

  const salvarUsuario = async (data: CriarUsuarioModel) => {
    return post<UsuarioResponse>(`${baseApi}`, data);
  };

  const obterUsuarioData = async (id: string) => {
    return get<UsuarioModel>(`${baseApi}/${id}`);
  };

  const editarUsuario = async (id: string, data: UsuarioModel) => {
    return put(`${baseApi}/${id}`, data);
  };

  const obterMedicosReguladores = useCallback(async () => {
    return post<UsuarioResponseModel[]>(`${baseApi}/obter-medicos-reguladores`, null);
  }, [baseApi, post]);

  const obterRadioOperador = useCallback(async () => {
    return post<UsuarioResponseModel[]>(`${baseApi}/obter-radio-operadores`, null);
  }, [baseApi, post]);

  const obterGrupoAcesso = async () => {
    return get<GrupoAcessoResponse[]>(`${baseApi}/grupo-acessos`);
  };

  const buscarListaUsuariosStatus = async () => {
    return get<UsuarioStatusResponse[]>(`${baseApi}/usuario-status`);
  };

  const obterPorPerfilAcesso = async () => {
    return post<UsuarioResponseModel[]>(`${baseApi}/obter-por-perfil/`);
  };
  const verificarSeTemAtendimentoPendente = async () => {
    return get<any>(`${baseApi}/tem-atendimento-pendente`);
  };

  const logoutTrigger = async () => {
    return put(`${baseApi}/logout`, {});
  };

  const vincularCliente = async (usuarioId: string, clienteId: string) => {
    return post(`${baseApi}/${usuarioId}/adicionar-cliente/${clienteId}`, null);
  };

  const removerClienteVinculado = async (usuarioId: string, clienteId: string) => {
    return remove(`${baseApi}/${usuarioId}/remover-cliente/${clienteId}`);
  };

  return {
    buscarGridUsuarioPaginated,
    salvarUsuario,
    obterUsuarioData,
    editarUsuario,
    obterMedicosReguladores,
    obterRadioOperador,
    buscarUsuarioEncaminhar,
    obterGrupoAcesso,
    buscarListaUsuariosStatus,
    obterPorPerfilAcesso,
    logoutTrigger,
    verificarSeTemAtendimentoPendente,
    vincularCliente,
    removerClienteVinculado
  };
};
