import { keyframes, styled, Typography } from '@mui/material';
import Box from '@mui/material/Box/Box';
import { useLocation } from 'react-router-dom';

import { Colors } from '../../core/colors';
import { useMsalService } from '../../service/authentication/msal.service';
import { useUsuarioService } from '../../service/usuario.service';
import { useAuthenticationContext } from '../contexts/authentication/authenticationContext';

// import { BotaoDeAcaoToolbar } from './Button/BotaoDeAcaoToolbar';
import ButtonMui from './Button/ButtonMui';

export const FalhaUsuario = () => {
  const { logoutTrigger } = useUsuarioService();
  const { resetarLocalId } = useAuthenticationContext();
  const { logout } = useMsalService();
  async function logoutHandler() {
    await logoutTrigger();
    resetarLocalId();
    logout();
  }
  const { state } = useLocation();
  return (
    <Box
      flex={1}
      height="90vh"
      display="flex"
      alignItems="center"
      flexDirection="column"
      justifyContent="center"
    >
      <PushReleaseFromLeftAnimation>
        <Box display="flex" flexDirection="column" paddingBottom={4} gap={1} maxWidth="md">
          <Typography fontSize={24}>Oops!</Typography>

          <Typography fontSize={16}>
            {state ? state : 'Falha ao recuperar as informações do usuário.'}
            <br />
            {state ? '' : 'Favor realizar o login novamente.'}
          </Typography>
        </Box>
        <ButtonMui
          onClick={logoutHandler}
          $backgroundColor={Colors.blue}
          style={{ width: '130px' }}
        >
          Retornar
        </ButtonMui>
      </PushReleaseFromLeftAnimation>
    </Box>
  );
};

const keyframesConfig = keyframes`
  from { transform: translateX(-100%) };
  30% { transform: translateX(100px) };
`;

const PushReleaseFromLeftAnimation = styled('div')({
  animationDelay: '1000',
  animation: `${keyframesConfig} 1s both`
});
