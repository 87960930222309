import { useEffect, useState } from 'react';

import logoPadrao from '../../../assets/img/logo_optsave.png';
import { useAuthenticationContext } from '../../contexts/authentication/authenticationContext';

export default function LogoEmpresa() {
  const [logo, setLogo] = useState<string>();

  // const { obterArquivo } = useEmpresaService();

  const {
    state: { userInfo }
  } = useAuthenticationContext();

  async function HandleLogo() {
    // if (userInfo.data) {
    //   if (userInfo.data && userInfo.data.imagem) {
    //     try {
    //       const logoAPI = await obterArquivo(userInfo.data.tenantId);
    //       const myImage = URL.createObjectURL(logoAPI);
    //       setLogo(myImage);
    //     } catch {
    //       setLogo(logoPadrao);
    //     }
    //   } else setLogo(logoPadrao);
    // }
    setLogo(logoPadrao);
  }

  useEffect(() => {
    HandleLogo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInfo.data]);

  return (
    <>
      {logo && (
        // <div style={{ width: "auto", position: "fixed", top: 15 }}>
        <img style={{ borderRadius: '15%' }} src={logo} alt="logo" width="40" height="40" />
        // </div>
      )}
    </>
  );
}
