import { mdiDelete, mdiDeleteOutline } from '@mdi/js';
import Icon from '@mdi/react';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { OptBackdrop, OptConfirmationDialog } from '@optsol/react';
import { useState } from 'react';

import { Colors } from '../../../core/colors';
import { MenuAtendimentoClaims } from '../../../models/enums/AcessoClaims';
import { ProtectedContent } from '../../components/ProtectedContent/ProtectedContent';

interface Props {
  pacienteId: string;
  atendimentoId: string;
  disableForm: boolean;
  removerPaciente: (pacienteId: string, atendimentoId: string) => Promise<void>;
}

export const ModalRemoverPaciente = ({
  pacienteId,
  atendimentoId,
  disableForm,
  removerPaciente
}: Props) => {
  const [removerPacienteModa, setRemoverPacienteModa] = useState(false);
  const [modalPacienteId, setModalPacienteId] = useState<string>('');
  const [salvarAtendimentoIsLoading, setSalvarAtendimentoIsLoading] = useState(false);

  const toggleRemoverPacienteModal = (pacienteId: string) => {
    setModalPacienteId(pacienteId);
    setRemoverPacienteModa((prevState) => !prevState);
  };

  const toggleLoading = () => {
    setSalvarAtendimentoIsLoading((prevState) => !prevState);
  };

  async function removerPacienteHandler() {
    try {
      toggleLoading();
      toggleRemoverPacienteModal(pacienteId);
      await removerPaciente(pacienteId, atendimentoId);
    } finally {
      toggleLoading();
    }
  }
  return (
    <>
      <ProtectedContent mode="disable" access={MenuAtendimentoClaims.REMOVER_PACIENTE}>
        <Tooltip title={'Remover paciente'} placement="top">
          <IconButton
            disabled={disableForm}
            color="error"
            onClick={(e) => {
              e.stopPropagation();
              toggleRemoverPacienteModal(pacienteId ?? '');
            }}
          >
            <Icon path={mdiDelete} size={1} color={Colors.red} />
          </IconButton>
        </Tooltip>
      </ProtectedContent>
      <OptConfirmationDialog
        open={removerPacienteModa && modalPacienteId === pacienteId}
        title="Excluir paciente"
        icon={{ path: mdiDeleteOutline, color: Colors.red }}
        onCancel={() => {
          toggleRemoverPacienteModal(pacienteId ?? '');
        }}
        onClose={(e: any) => {
          e.stopPropagation();
          toggleRemoverPacienteModal(pacienteId ?? '');
        }}
        onConfirm={() => {
          removerPacienteHandler();
        }}
      >
        Deseja confirmar a exclusão do paciente?
      </OptConfirmationDialog>
      <OptBackdrop open={salvarAtendimentoIsLoading} />
    </>
  );
};
