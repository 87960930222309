import { Box } from '@mui/material';
import { OptGridRef, OptGridRequest, OptSideLayoutContent } from '@optsol/react';
import { useCallback, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { RoutesObj } from '../../../routes/index.routes';
import { usePlanoService } from '../../../service/plano.service';
import { obterFiltroDaRota, updateFiltroUrl } from '../../../shared/utils/functions';
import { PaginatedSearchRequest, SearchRequest } from '../../../shared/utils/searchRequest';

import GridPlanos from './GridPlanos';
import { ToolbarPlanos } from './ToolbarPlanos';

const Planos = () => {
  const navegate = useNavigate();
  const ref = useRef<OptGridRef>();
  const [planoSearch, setPlanoSearch] = useState(obterFiltroDaRota('termo') ?? '');

  const { buscarGridPlanoPaginated } = usePlanoService();

  function ProcurarPlano(data?: string) {
    updateFiltroUrl('termo', data ?? '');
    setPlanoSearch(data ?? '');
  }

  const carregar = useCallback(
    async (
      query: OptGridRequest,
      planoSearchRequest: PaginatedSearchRequest = {
        termoBuscado: planoSearch,
        apenasAtivo: false
      }
    ) => {
      const request: SearchRequest<PaginatedSearchRequest> = {
        page: query.page,
        pageSize: query.pageSize,
        search: planoSearchRequest
      };
      updateFiltroUrl('page', query.page);
      updateFiltroUrl('pageSize', query.pageSize);
      return buscarGridPlanoPaginated(request);
    },
    [buscarGridPlanoPaginated, planoSearch]
  );

  async function editarPlano(planoId: string) {
    navegate(RoutesObj.Plano.Detalhes(planoId), {
      state: { prevUrl: window.location.href }
    });
  }

  return (
    <OptSideLayoutContent>
      <ToolbarPlanos filtrarGridBySearch={ProcurarPlano} />
      <Box px={1} pt={0.5}>
        <GridPlanos ref={ref} editarPlano={editarPlano} carregar={carregar}></GridPlanos>
      </Box>
    </OptSideLayoutContent>
  );
};

export default Planos;
