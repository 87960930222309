import { mdiDeleteOutline, mdiMapMarkerPath } from '@mdi/js';
import Icon from '@mdi/react';
import {
  Autocomplete,
  Box,
  debounce,
  Divider,
  FormControl,
  Grid,
  IconButton,
  List,
  ListItemButton,
  ListItemText,
  ListSubheader,
  Typography
} from '@mui/material';
import { OptBackdrop, OptConfirmationDialog } from '@optsol/react';
import { useSnackbar } from 'notistack';
import React, { useCallback, useEffect, useState } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';

import { Colors } from '../../../../core/colors';
import { ServicoCobertoSearchResponse } from '../../../../models/dtos/servicoCobertoSearchResponse.model';
import { MenuPlanoClaims } from '../../../../models/enums/AcessoClaims';
import { PlanoModel } from '../../../../models/Plano/Plano.model';
import { DADOS_SERVICO_COBERTO_PLANO_DEFAULT } from '../../../../models/Plano/ServicoCobertoPlano.model';
import { ServicoCobertoModel } from '../../../../models/ServicoCoberto/ServicoCoberto.model';
import { usePlanoService } from '../../../../service/plano.service';
import { useServicoCobertoService } from '../../../../service/servicoCoberto.service';
import { PaginatedSearchRequest, SearchRequest } from '../../../../shared/utils/searchRequest';
import { FormTextField } from '../../../components/Form';
import { ProtectedContent } from '../../../components/ProtectedContent/ProtectedContent';
import { useAuthenticationContext } from '../../../contexts/authentication/authenticationContext';

interface Props {
  selecionarServicoCobertoPlano: (index: number) => void;
}

const ServicoCobertoPlano = ({ selecionarServicoCobertoPlano }: Props) => {
  const { control, getValues } = useFormContext<PlanoModel>();

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'servicosCobertosPlanos'
  });

  const { enqueueSnackbar } = useSnackbar();
  const [loadingPlano, setLoadingPlano] = useState<boolean>(false);
  const [servicoCobertos, setServicoCobertos] = useState<ServicoCobertoSearchResponse[]>([]);
  const [selectedIndex, setSelectedIndex] = React.useState('');
  const [confirmandoExclusao, setConfirmandoExclusao] = useState<boolean>(false);

  const [indexExclusao, setIndexExclusao] = useState<number>(-1);

  function cancelarExclusao() {
    setConfirmandoExclusao(false);
  }

  const { buscarGridServicoCobertoPaginated } = useServicoCobertoService();
  const { salvarServicoCobertoPlano, removerServicoCobertoPlano } = usePlanoService();
  const {
    state: { tema }
  } = useAuthenticationContext();

  const filtrarServicoCobertos = useCallback(
    async (termo = '') => {
      const request: SearchRequest<PaginatedSearchRequest> = {
        page: 0,
        pageSize: 20,
        search: {
          termoBuscado: termo,
          apenasAtivo: true
        }
      };
      const result = await buscarGridServicoCobertoPaginated(request);
      setServicoCobertos(result.data);
    },
    [buscarGridServicoCobertoPaginated]
  );

  function filtrarServicoCobertosHandler(newValue: string) {
    filtrarServicoCobertos(newValue);
  }

  const changeHandler = useCallback((value: string) => {
    filtrarServicoCobertosHandler(value);
  }, []);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedChangeHandler = useCallback(debounce(changeHandler, 500), []);

  function adicionarServicoCoberto(servico: ServicoCobertoModel) {
    if (servico) {
      setLoadingPlano(true);
      const listaServicoCobertoPlanoOriginal = getValues('servicosCobertosPlanos');

      const jaAdicionado = listaServicoCobertoPlanoOriginal.filter(
        (x) => x.servicoCobertoId === servico.id
      );

      if (jaAdicionado?.length === 0) {
        const servicoCobertoPlanoSelecionado = DADOS_SERVICO_COBERTO_PLANO_DEFAULT;
        servicoCobertoPlanoSelecionado.servicoCobertoId = servico.id;
        servicoCobertoPlanoSelecionado.planoId = getValues('id');
        servicoCobertoPlanoSelecionado.servicoCobertoNome = servico.nome;
        servicoCobertoPlanoSelecionado.temAreaAbrangencia = servico.temAreaAbrangencia;

        salvarServicoCobertoPlano(servicoCobertoPlanoSelecionado).then((x) => {
          servicoCobertoPlanoSelecionado.servicoCobertoPlanoId = x.data.servicoCobertoPlanoId;
          append(servicoCobertoPlanoSelecionado);
          setLoadingPlano(false);
        });

        filtrarServicoCobertosHandler('');
      } else {
        setLoadingPlano(false);
        enqueueSnackbar('Serviço coberto já existe no plano!', {
          variant: 'warning'
        });
      }
    }
  }

  const handleListItemClick = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    id: string,
    index: number,
    possuiAbrangencia: boolean
  ) => {
    setSelectedIndex(id);
    if (possuiAbrangencia) {
      selecionarServicoCobertoPlano(index);
    } else {
      selecionarServicoCobertoPlano(-1);
    }
  };

  function removerServicoPlano(index: number) {
    setLoadingPlano(true);
    const listaServicoCobertoPlanoRemocao = getValues('servicosCobertosPlanos');
    const servicoCobertoPlanoRemover = listaServicoCobertoPlanoRemocao[index];

    removerServicoCobertoPlano(servicoCobertoPlanoRemover).then(() => {
      remove(index);
      selecionarServicoCobertoPlano(-1);
      setIndexExclusao(-1);
      cancelarExclusao();
      setLoadingPlano(false);
    });
  }

  function confirmarRemocaoServicoCoberto(index: number) {
    setConfirmandoExclusao(true);
    setIndexExclusao(index);
  }

  useEffect(() => {
    filtrarServicoCobertos('');
  }, []);

  return (
    <>
      <OptConfirmationDialog
        open={confirmandoExclusao}
        title="Excluir serviço coberto"
        icon={{ path: mdiDeleteOutline, color: Colors.red }}
        onCancel={cancelarExclusao}
        onClose={cancelarExclusao}
        onConfirm={() => {
          removerServicoPlano(indexExclusao);
        }}
      >
        Deseja confirmar a exclusão do serviço coberto do plano?
      </OptConfirmationDialog>
      <Grid item xs={12}>
        <FormControl fullWidth>
          <ProtectedContent mode="disable" access={MenuPlanoClaims.ATUALIZAR}>
            <Autocomplete
              fullWidth
              noOptionsText="Sem dados a exibir"
              options={servicoCobertos ? servicoCobertos : []}
              getOptionLabel={(option) => (typeof option === 'object' ? option.nome : '')}
              // value={servicoCoberto}
              onChange={(_event, newValue: ServicoCobertoModel | any) => {
                adicionarServicoCoberto(newValue);
              }}
              onInputChange={(_e, value) => debouncedChangeHandler(value)}
              freeSolo
              renderInput={(params) => (
                <Box display="flex" flexDirection="column" gap={1}>
                  <Typography textAlign="left" lineHeight={1} color={tema.light?.primary}>
                    Serviço coberto abrangente
                  </Typography>
                  <FormTextField
                    {...params}
                    name="descricaoServicoCobertoSearch"
                    control={control}
                    placeholder="Selecione o serviço coberto"
                    fullWidth
                  />
                </Box>
              )}
            />
          </ProtectedContent>
        </FormControl>
      </Grid>
      {fields?.length > 0 && (
        <Grid
          item
          xs={12}
          sx={{
            border: `1px solid ${tema.light?.primary}`,
            borderColor: tema.light?.primary ? tema.light.primary : Colors.primary,
            borderRadius: '3px',
            marginTop: '10px',
            borderWidth: '2px'
          }}
        >
          <Box
            sx={{
              width: '100%',
              bgcolor: 'background.paper',
              maxHeight: '400px',
              overflow: 'auto'
            }}
          >
            <List
              component="nav"
              aria-label="secondary mailbox folder"
              aria-labelledby="nested-list-subheader"
              subheader={
                <ListSubheader
                  sx={{ backgroundColor: tema.light?.primary, color: '#fff' }}
                  component="div"
                  id="nested-list-subheader"
                >
                  Serviço coberto
                </ListSubheader>
              }
            >
              <Divider />
              {fields.map((value, index) => (
                <ProtectedContent key={value.id} mode="disable" access={MenuPlanoClaims.ATUALIZAR}>
                  <ListItemButton
                    key={value.id}
                    selected={selectedIndex === value.id}
                    onClick={(event) =>
                      handleListItemClick(
                        event,
                        value.id,
                        index,
                        value?.temAreaAbrangencia ? true : false
                      )
                    }
                  >
                    <ListItemText primary={value.servicoCobertoNome} />
                    {value.temAreaAbrangencia && (
                      <Icon
                        path={mdiMapMarkerPath}
                        size={1}
                        color="blue"
                        title={'Possui abrangência'}
                      />
                    )}

                    <IconButton
                      onClick={() => confirmarRemocaoServicoCoberto(index)}
                      title="Excluir serviço coberto abrangente"
                    >
                      <Icon path={mdiDeleteOutline} size={1} color="red" />
                    </IconButton>
                  </ListItemButton>
                </ProtectedContent>
              ))}
            </List>
          </Box>
          <OptBackdrop open={loadingPlano} />
        </Grid>
      )}
    </>
  );
};

export default ServicoCobertoPlano;
