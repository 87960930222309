import { mdiDeleteOutline } from '@mdi/js';
import Icon from '@mdi/react';
import {
  Autocomplete,
  Box,
  debounce,
  Divider,
  FormControl,
  Grid,
  IconButton,
  List,
  ListItemButton,
  ListItemText,
  ListSubheader,
  Typography
} from '@mui/material';
import { OptBackdrop, OptConfirmationDialog } from '@optsol/react';
import { useSnackbar } from 'notistack';
import { useCallback, useEffect, useState } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';

import { Colors } from '../../../../core/colors';
import { ServicoCobertoSearchResponse } from '../../../../models/dtos/servicoCobertoSearchResponse.model';
import { MenuMotivoAtendimentoClaims } from '../../../../models/enums/AcessoClaims';
import {
  DADOS_SERVICO_MOTIVO_ATENDIMENTO_DEFAULT,
  MotivoAtendimentoModel
} from '../../../../models/MotivoAtendimento/MotivoAtendimento.model';
import { ServicoCobertoModel } from '../../../../models/ServicoCoberto/ServicoCoberto.model';
import { useMotivoAtendimentoService } from '../../../../service/motivoAtendimento.service';
import { useServicoCobertoService } from '../../../../service/servicoCoberto.service';
import { PaginatedSearchRequest, SearchRequest } from '../../../../shared/utils/searchRequest';
import { FormTextField } from '../../../components/Form';
import { ProtectedContent } from '../../../components/ProtectedContent/ProtectedContent';
import { useAuthenticationContext } from '../../../contexts/authentication/authenticationContext';

const ServicoCobertoMotivoAtendimento = () => {
  const { control, getValues } = useFormContext<MotivoAtendimentoModel>();

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'servicosCobertos'
  });

  const { enqueueSnackbar } = useSnackbar();
  const [loadingServicoMotivo, setLoadingServicoMotivo] = useState<boolean>(false);
  const [servicoCobertos, setServicoCobertos] = useState<ServicoCobertoSearchResponse[]>([]);

  const [confirmandoExclusao, setConfirmandoExclusao] = useState<boolean>(false);

  const [posicaoExclusao, setPosicaoExclusao] = useState<number>(-1);

  function cancelarExclusao() {
    setConfirmandoExclusao(false);
  }

  const {
    state: { tema }
  } = useAuthenticationContext();

  const { buscarGridServicoCobertoPaginated } = useServicoCobertoService();
  const { salvarServicoCobertoMotivoAtendimento, removerServicoCobertoMotivoAtendimento } =
    useMotivoAtendimentoService();

  async function filtrarServicoCobertos(termo = '') {
    const request: SearchRequest<PaginatedSearchRequest> = {
      page: 0,
      pageSize: 10,
      search: {
        termoBuscado: termo,
        apenasAtivo: true
      }
    };
    const result = await buscarGridServicoCobertoPaginated(request);
    setServicoCobertos(result.data);
  }

  function filtrarServicoCobertosHandler(newValue: string) {
    filtrarServicoCobertos(newValue);
  }

  const changeHandler = useCallback((value: string) => {
    filtrarServicoCobertosHandler(value);
  }, []);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedChangeHandler = useCallback(debounce(changeHandler, 500), []);

  function adicionarServicoCobertoMotivoAtendimento(servico: ServicoCobertoModel) {
    if (servico) {
      const listaServicoCobertoMotivoOriginal = getValues('servicosCobertos');

      const jaAdicionado = listaServicoCobertoMotivoOriginal?.filter((x) => x.id === servico.id);

      if (jaAdicionado?.length === 0) {
        const servicoCobertoMotivoRequest = DADOS_SERVICO_MOTIVO_ATENDIMENTO_DEFAULT;
        servicoCobertoMotivoRequest.servicoCobertoId = servico.id;
        servicoCobertoMotivoRequest.motivoAtendimentoId = getValues('motivoAtendimentoId');

        setLoadingServicoMotivo(true);

        salvarServicoCobertoMotivoAtendimento(servicoCobertoMotivoRequest).then(() => {
          append(servico);
          setLoadingServicoMotivo(false);
        });

        filtrarServicoCobertosHandler('');
      } else {
        enqueueSnackbar('Serviço coberto já adicionado!', {
          variant: 'warning'
        });
      }
    }
  }

  function removerServicoMotivoAtendimento(index: number) {
    setLoadingServicoMotivo(true);
    const listaServicoCobertoMotivoRemocao = getValues('servicosCobertos');

    const servicoCobertoMotivoRemover = listaServicoCobertoMotivoRemocao[index];
    const servicoCobertoMotivoRequest = DADOS_SERVICO_MOTIVO_ATENDIMENTO_DEFAULT;
    servicoCobertoMotivoRequest.servicoCobertoId = servicoCobertoMotivoRemover.id;
    servicoCobertoMotivoRequest.motivoAtendimentoId = getValues('motivoAtendimentoId');

    removerServicoCobertoMotivoAtendimento(servicoCobertoMotivoRequest).then(() => {
      remove(index);
      setPosicaoExclusao(-1);
      cancelarExclusao();
      setLoadingServicoMotivo(false);
    });
  }

  function confirmarRemocaoServicoCobertoMotivo(index: number) {
    setConfirmandoExclusao(true);
    setPosicaoExclusao(index);
  }

  useEffect(() => {
    filtrarServicoCobertos('');
  }, []);

  return (
    <>
      <OptConfirmationDialog
        open={confirmandoExclusao}
        title="Excluir serviço coberto"
        icon={{ path: mdiDeleteOutline, color: Colors.red }}
        onCancel={cancelarExclusao}
        onClose={cancelarExclusao}
        onConfirm={() => {
          removerServicoMotivoAtendimento(posicaoExclusao);
        }}
      >
        Deseja confirmar a exclusão do serviço coberto?
      </OptConfirmationDialog>
      <Grid item xs={12}>
        <FormControl fullWidth>
          <ProtectedContent mode="disable" access={MenuMotivoAtendimentoClaims.ATUALIZAR}>
            <Autocomplete
              fullWidth
              noOptionsText="Sem dados a exibir"
              options={servicoCobertos ? servicoCobertos : []}
              getOptionLabel={(option) => (typeof option === 'object' ? option.nome : '')}
              // value={servicoCoberto}
              onChange={(_event, newValue: ServicoCobertoModel | any) => {
                adicionarServicoCobertoMotivoAtendimento(newValue);
              }}
              onInputChange={(_e, value) => debouncedChangeHandler(value)}
              renderInput={(params) => (
                // <TextField
                //   {...params}
                //   size="small"
                //   label="Serviço coberto"
                //   placeholder="Selecione o serviço coberto"
                //   variant="outlined"
                // />
                <Box display="flex" flexDirection="column" gap={1}>
                  <Typography textAlign="left" lineHeight={1} color={tema.light?.primary}>
                    Serviço coberto abrangente
                  </Typography>
                  <FormTextField
                    {...params}
                    name="descricao"
                    control={control}
                    // label="Serviço coberto abrangente"
                    placeholder="Selecione o serviço coberto"
                    fullWidth
                  />
                </Box>
              )}
              freeSolo
            />
          </ProtectedContent>
        </FormControl>
      </Grid>
      {fields?.length > 0 && (
        <Grid
          item
          xs={12}
          sx={{
            border: `1px solid ${tema.light?.primary}`,
            borderColor: tema.light?.primary ? tema.light.primary : Colors.primary,
            borderRadius: '3px',
            marginTop: '10px',
            borderWidth: '2px'
          }}
        >
          <Box
            sx={{
              width: '100%',
              bgcolor: 'background.paper',
              maxHeight: '400px',
              overflow: 'auto'
            }}
          >
            <List
              component="nav"
              sx={{ borderColor: tema.light?.primary }}
              aria-label="secondary mailbox folder"
              aria-labelledby="nested-list-subheader"
              subheader={
                <ListSubheader
                  sx={{ backgroundColor: tema.light?.primary, color: '#fff' }}
                  component="div"
                  id="nested-list-subheader"
                >
                  Serviço coberto
                </ListSubheader>
              }
            >
              <Divider />
              {fields.map((value, index) => (
                <ProtectedContent
                  key={value.id}
                  mode="disable"
                  access={MenuMotivoAtendimentoClaims.ATUALIZAR}
                >
                  <ListItemButton key={value.id}>
                    <ListItemText primary={value.nome} />
                    <IconButton
                      onClick={() => confirmarRemocaoServicoCobertoMotivo(index)}
                      title="Excluir serviço coberto"
                    >
                      <Icon path={mdiDeleteOutline} size={1} color="red" />
                    </IconButton>
                  </ListItemButton>
                </ProtectedContent>
              ))}
            </List>
          </Box>
          <OptBackdrop open={loadingServicoMotivo} />
        </Grid>
      )}
    </>
  );
};

export default ServicoCobertoMotivoAtendimento;
