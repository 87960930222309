import { useSnackbar } from 'notistack';

import { IApiError } from '../types/ApiError';

interface ErrorSnackbarOptions {
  autoThrowError?: boolean;
}

const initOptions: ErrorSnackbarOptions = { autoThrowError: false };

export function useErrorSnackbar({ autoThrowError }: ErrorSnackbarOptions = initOptions) {
  const { enqueueSnackbar } = useSnackbar();

  function tratarErro(err?: IApiError) {
    if (!err || !err?.messages || err?.messages?.length === 0) {
      enqueueSnackbar('Algo deu errado. Tente novamente.', {
        variant: 'error'
      });
    } else {
      console.log(err);
      err?.messages.forEach((message) => {
        enqueueSnackbar(message, {
          variant: 'error',
          preventDuplicate: true,
          autoHideDuration: 5000
        });
      });
    }

    if (autoThrowError) {
      throw err;
    }
  }
  return { tratarErro };
}
