import { CidadeIbgeResponseModel, CidadeModel } from '../models/Cidade/Cidade.model';
import { PlanoSearchResponse } from '../models/dtos/Plano/planoSearchResponse.model';
import { EstadoModel } from '../models/Estado/Estado.model';
import { CriarPlanoModel, PlanoModel } from '../models/Plano/Plano.model';
import { ServicoCobertoPlanoModel } from '../models/Plano/ServicoCobertoPlano.model';
import { useApi } from '../shared/hooks/api';
import { SearchRequest } from '../shared/utils/searchRequest';

export const usePlanoService = () => {
  const { gridSearch, post, get, put, removeWithBody } = useApi();

  const baseApi = `api/plano`;

  const buscarGridPlanoPaginated = async (data: SearchRequest<object>) => {
    return gridSearch<PlanoSearchResponse>(`${baseApi}/paginated`, data);
  };

  const salvarPlano = async (data: CriarPlanoModel) => {
    return post<PlanoModel>(`${baseApi}`, data);
  };

  const obterPlanoData = async (id: string) => {
    return get<PlanoModel>(`${baseApi}/${id}`);
  };

  const editarPlano = async (id: string, data: PlanoModel) => {
    return put(`${baseApi}/${id}`, data);
  };

  const obterPlanos = async () => {
    return get<PlanoModel[]>(`${baseApi}`);
  };

  const salvarServicoCobertoPlano = async (data: ServicoCobertoPlanoModel) => {
    return post<ServicoCobertoPlanoModel>(`${baseApi}/adicionar-servico-coberto-plano`, data);
  };

  const removerServicoCobertoPlano = async (data: ServicoCobertoPlanoModel) => {
    return removeWithBody<ServicoCobertoPlanoModel>(
      `${baseApi}/remover-servico-coberto-plano`,
      data
    );
  };

  const salvarEstadoPlano = async (data: EstadoModel) => {
    return post<EstadoModel>(`${baseApi}/adicionar-estado`, data);
  };

  const removerEstadoPlano = async (data: EstadoModel) => {
    return removeWithBody<EstadoModel>(`${baseApi}/remover-estado`, data);
  };

  const salvarCidadePlano = async (data: CidadeModel) => {
    return post<CidadeModel>(`${baseApi}/adicionar-cidade`, data);
  };

  const removerCidadePlano = async (data: CidadeModel) => {
    return removeWithBody<CidadeModel>(`${baseApi}/remover-cidade`, data);
  };

  const obterDadosMunicipiosIBGE = async (termo: string): Promise<CidadeIbgeResponseModel[]> => {
    return await fetch(
      `https://servicodados.ibge.gov.br/api/v1/localidades/estados/${termo}/municipios`
    )
      .then((response) => {
        return response.json();
      })
      .catch((_error) => {
        return null;
      });
  };

  const obterAreaAbrangenciaServicoCoberto = async (id: string, servicoCobertoId: string) => {
    return get<ServicoCobertoPlanoModel>(
      `${baseApi}/${id}/servico-coberto/${servicoCobertoId}/area-abrangencia`
    );
  };

  return {
    buscarGridPlanoPaginated,
    salvarPlano,
    obterPlanoData,
    editarPlano,
    obterPlanos,
    salvarServicoCobertoPlano,
    removerServicoCobertoPlano,
    salvarEstadoPlano,
    removerEstadoPlano,
    salvarCidadePlano,
    removerCidadePlano,
    obterDadosMunicipiosIBGE,
    obterAreaAbrangenciaServicoCoberto
  };
};
