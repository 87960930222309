import { Box } from '@mui/material';
import { OptGridRequest, OptSearchField } from '@optsol/react';
import { useCallback, useState } from 'react';

import { Colors } from '../../../../../core/colors';
import { ClienteModel } from '../../../../../models/Cliente/Cliente.model';
import { MenuAtendimentoClaims } from '../../../../../models/enums/AcessoClaims';
import { useClienteService } from '../../../../../service/cliente.service';
import {
  PaginatedSearcClientesVinculadoshRequest,
  SearchRequest
} from '../../../../../shared/utils/searchRequest';
import ButtonMui from '../../../../components/Button/ButtonMui';
import { Modal } from '../../../../components/Modal/Modal';
import { useAuthenticationContext } from '../../../../contexts/authentication/authenticationContext';

import GridSelecaoClientes from './GridSelecaoClientes';
import * as S from './styles/index';

interface Props {
  clientes: ClienteModel[];
  isOpen: boolean;
  selecionarCliente: (cliente: ClienteModel | null) => Promise<void>;
}

export const ModalSelecaoClientes = ({ clientes, isOpen, selecionarCliente }: Props) => {
  const { hasAccess } = useAuthenticationContext();
  const { obterClientesVinculado } = useClienteService();
  const [clientePaiId, setClientePaiId] = useState('');
  const [clienteSearch, setClienteSearch] = useState('');

  const carregar = useCallback(
    async (
      query: OptGridRequest,
      clienteSearchRequest: PaginatedSearcClientesVinculadoshRequest = {
        termoBuscado: clienteSearch,
        clientePaiId: clientePaiId,
        apenasAtivo: true
      }
    ) => {
      const request: SearchRequest<PaginatedSearcClientesVinculadoshRequest> = {
        page: query.page,
        pageSize: query.pageSize,
        search: clienteSearchRequest
      };
      return obterClientesVinculado(request).then((result) => {
        return {
          ...result,
          data: result.data.map((cliente) => ({
            documento: cliente.cpf ?? cliente.cnpj,
            ...cliente
          }))
        };
      });
    },
    [obterClientesVinculado, clientePaiId, clienteSearch]
  );
  async function selecionarLinhaCliente(cliente: ClienteModel) {
    setClientePaiId(cliente.clienteId ?? '');
  }
  function filtrarGridBySearch(data?: string) {
    setClienteSearch(data ?? '');
  }
  return (
    <Modal
      open={isOpen}
      onClose={
        hasAccess(MenuAtendimentoClaims.INSERIR_ATENDIMENTO_USUARIO_EXTERNO)
          ? () => {}
          : () => {
              selecionarCliente(null);
            }
      }
      title=""
      // width="800px"
    >
      <div style={{ display: 'flex' }}>
        <Box display="flex" flexDirection="column" alignItems={'start'}>
          <strong style={{ marginLeft: '0.6em' }}>Clientes</strong>
          <GridSelecaoClientes
            data={clientes}
            selecionarCliente={selecionarCliente}
            selecionarLinhaCliente={selecionarLinhaCliente}
          />
        </Box>
        {clientePaiId && (
          <Box display="flex" flexDirection="column" alignItems={'start'}>
            <Box display="flex" flexDirection="column" justifyContent={'space-between'}>
              {/* <strong style={{ marginLeft: '0.6em' }}>Clientes vinculados</strong> */}
              <S.SearchContainer>
                <OptSearchField onSearch={filtrarGridBySearch} placeholder="Clientes vinculados" />
              </S.SearchContainer>
            </Box>
            <GridSelecaoClientes
              titulo=""
              carregar={carregar}
              data={[]}
              selecionarCliente={selecionarCliente}
            />
          </Box>
        )}
      </div>
      <Box display="flex" justifyContent="center" alignItems="center" mt={2}>
        {!hasAccess(MenuAtendimentoClaims.INSERIR_ATENDIMENTO_USUARIO_EXTERNO) && (
          <ButtonMui
            $backgroundColor={Colors.red1}
            onClick={() => {
              selecionarCliente(null);
            }}
          >
            Cancelar seleção
          </ButtonMui>
        )}
      </Box>
    </Modal>
  );
};
