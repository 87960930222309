import { mdiPen } from '@mdi/js';
import Icon from '@mdi/react';
import { ChangeEvent, useEffect, useState } from 'react';

import { Colors } from '../../../core/colors';

import * as S from './styles/index';
interface Props {
  image: string | null;
  labelColor: string;
  labelText: string;
  salvarImagem: (file: File) => Promise<void>;
}

export const ImagePicker = ({ image, labelColor, labelText, salvarImagem }: Props) => {
  const [filePreview, setFilePreview] = useState<string | undefined>(undefined);
  function handleChange(e: ChangeEvent<HTMLInputElement>) {
    if (!e.target.files) return;

    const file = e.target.files[0];

    if (file) {
      const reader = new FileReader();
      salvarImagem(file);

      reader.addEventListener('load', async (e) => {
        setFilePreview(String(e.target?.result));
      });

      reader.readAsDataURL(file);
    }
  }
  useEffect(() => {
    if (image) {
      setFilePreview(image);
    }
  }, [image]);
  return (
    <>
      {filePreview ? (
        <S.ImageWrapper color={labelColor}>
          <S.ImagePreviewWrapper>
            <S.ImagePreview preview={filePreview}></S.ImagePreview>
          </S.ImagePreviewWrapper>
          <S.LabelWrapper color={labelColor}>
            <S.Label>
              {labelText}
              <S.Input
                type="file"
                accept="image/png, image/gif, image/jpeg, image/webp, image/jpg"
                onChange={handleChange}
              />
              <Icon size={'20px'} path={mdiPen} />
            </S.Label>
          </S.LabelWrapper>
        </S.ImageWrapper>
      ) : (
        <>
          <div>
            <S.Label color={labelColor}>
              <Icon size={'20px'} path={mdiPen} color={Colors.white} />
              {labelText}
              <S.Input
                type="file"
                accept="image/png, image/gif, image/jpeg, image/webp, image/jpg"
                onChange={handleChange}
              />
            </S.Label>
          </div>
        </>
      )}
    </>
  );
};
