import * as yup from 'yup';

import { EnderecoModel } from '../Endereco/Endereco.model';
import {
  dadosCoberturaFormModelSchema,
  ServicoCobertoModel
} from '../ServicoCoberto/ServicoCoberto.model';
export interface MotivoAtendimentoModel {
  motivoAtendimentoId?: string;
  nome: string;
  situacao: boolean;
  descricao: string;
  servicosCobertos: ServicoCobertoModel[];
  servicoCobertoId?: string;
  enderecosAtendimentos?: EnderecoModel[];
}

// export interface EnderecoDestinoSearch {
//   complemento: string;
//   nome: string;
//   enderecoGeolocalizacao: EnderecoDestinoMotivo;
//   motivoAtendimentoId: string;
//   enderecoAtendimentoId: string;
// }

export const DADOS_MOTIVO_ATENDIMENTO_DEFAULT: MotivoAtendimentoModel = {
  motivoAtendimentoId: '',
  nome: '',
  situacao: false,
  descricao: '',
  servicosCobertos: [],
  servicoCobertoId: '',
  enderecosAtendimentos: []
};

const MSG_REQUIRED = 'Campo obrigatório';

export const dadosMotivoAtendimentoFormModelSchema: yup.SchemaOf<MotivoAtendimentoModel> = yup
  .object()
  .shape({
    motivoAtendimentoId: yup.string().required(MSG_REQUIRED),
    nome: yup.string().required(MSG_REQUIRED),
    descricao: yup.string().required(MSG_REQUIRED),
    situacao: yup.boolean().required(MSG_REQUIRED),
    servicosCobertos: yup.array().of(dadosCoberturaFormModelSchema).required(),
    servicoCobertoId: yup.string().notRequired(),
    enderecosAtendimentos: yup.array().notRequired(),
    enderecosDestinos: yup.array().notRequired()
  });

export interface CriarMotivoAtendimentoModel {
  nome: string;
  descricao: string;
}

export const DADOS_CRIAR_MOTIVO_ATENDIMENTO_DEFAULT: CriarMotivoAtendimentoModel = {
  nome: '',
  descricao: ''
};

export const dadosCriarMotivoAtendimentoFormModelSchema: yup.SchemaOf<CriarMotivoAtendimentoModel> =
  yup.object().shape({
    nome: yup.string().required(MSG_REQUIRED),
    descricao: yup.string().required(MSG_REQUIRED)
  });

export interface ServicoCobertoMotivoAtendimentoRequest {
  servicoCobertoId: string;
  motivoAtendimentoId?: string;
}

export interface ServicoCobertoMotivoAtendimentoResponse {
  servicoCobertoId: string;
  motivoAtendimentoId?: string;
  servicoCobertoMotivoAtendimentoId: string;
}

export const DADOS_SERVICO_MOTIVO_ATENDIMENTO_DEFAULT: ServicoCobertoMotivoAtendimentoRequest = {
  servicoCobertoId: '',
  motivoAtendimentoId: ''
};
