import * as yup from 'yup';

import { TipoVeiculo } from '../../enums/TipoVeiculo';

import { TimelineModel } from './TimeLine.model';

export interface EquipeModel {
  id?: string | null;
  tipoVeiculo?: TipoVeiculo | null;
  timelineVeiculo?: TimelineModel[] | null;
  veiculoId?: string | null;
  notificacaoEnviada?: boolean;
}

export const EQUIPE_DEFAULT: EquipeModel = {
  id: '',
  tipoVeiculo: null,
  timelineVeiculo: null,
  veiculoId: null,
  notificacaoEnviada: false
};

export const dadosEquipeModelSchema: yup.SchemaOf<EquipeModel> = yup.object().shape({
  id: yup.string().notRequired().nullable(),
  tipoVeiculo: yup.number().notRequired().nullable(),
  timelineVeiculo: yup.array().notRequired().nullable(),
  veiculoId: yup.string().notRequired().nullable(),
  notificacaoEnviada: yup.boolean().notRequired()
});
