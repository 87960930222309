import { useCallback } from 'react';

import { ChaveModel } from '../app/features/empresa/ListaChaves/ChaveModel/ChaveModel';
import { CriarEmpresaResponse } from '../models/dtos/Empresa/criarEmpresaResponse.model';
import { EmpresaSearchResponse } from '../models/dtos/Empresa/empresaSearchResponse.model';
import { ExcluirEnderecoEmpresaRequest } from '../models/dtos/Empresa/Endereco/excluirEnderecoEmpresaRequest.model';
import { ValidarLoginRequestModel } from '../models/dtos/Empresa/ValidarLogin/validarLoginRequest.model';
import { ValidarLoginResponseModel } from '../models/dtos/Empresa/ValidarLogin/validarLoginResponse.model';
import { ResponsavelFaseAtendimentoRequestModel } from '../models/dtos/ResponsavelFaseAtendimentoModel/ResponsavelFaseAtendimentoRequest.model';
import { CriarEmpresaModel, EmpresaModel } from '../models/Empresa/Empresa.model';
import { EnderecoModel } from '../models/Endereco/Endereco.model';
import { useApi } from '../shared/hooks/api';
import { objectToFormData } from '../shared/utils/functions';
import { SearchRequest } from '../shared/utils/searchRequest';

export const useEmpresaService = () => {
  const { gridSearch, post, get, put, getFile, remove, removeWithBody } = useApi();

  const baseApi = `api/empresa`;

  const buscarGridEmpresasPaginated = useCallback(
    async (data: SearchRequest<object>, signal?: AbortSignal) => {
      return gridSearch<EmpresaSearchResponse>(`${baseApi}/paginated`, data, {
        signal
      });
    },
    [baseApi, gridSearch]
  );

  const salvarEmpresa = async (data: CriarEmpresaModel) => {
    return post<CriarEmpresaResponse>(`${baseApi}/adicionar-empresa`, data);
  };

  const obterEmpresaById = async (empresaId: string) => {
    return get<any>(`${baseApi}/${empresaId}`);
  };

  const obterEmpresaByIdIqf = async (empresaId: string) => {
    return get<any>(`${baseApi}/${empresaId}/getbyid-iqf`);
  };

  const atualizarEmpresa = async (empresaId: string, data: EmpresaModel) => {
    return put<any>(`${baseApi}/${empresaId}`, data);
  };

  const salvarEndereco = async (id: string, data: EnderecoModel) => {
    return post<EnderecoModel>(`${baseApi}/${id}/adicionar-endereco`, data);
  };

  const excluirEndereco = async (data: ExcluirEnderecoEmpresaRequest) => {
    return remove(`${baseApi}/${data.empresaId}/endereco/${data.id}`);
  };

  const adicionarArquivo = async (id: string, file: File) => {
    const formData = objectToFormData({
      file
    });

    return post(`${baseApi}/${id}/adicionar-imagem`, formData);
  };

  const alterarArquivo = async (id: string, file: File) => {
    const formData = objectToFormData({
      file
    });

    return put(`${baseApi}/${id}/alterar-imagem`, formData);
  };

  const obterArquivo = async (id: string) => {
    return getFile<Blob>(`${baseApi}/${id}/download-imagem`, {
      responseType: 'blob'
    });
  };

  const obterArquivoImagemHistorico = async (fileName: string) => {
    return getFile<Blob>(`${baseApi}/download-imagem-historico?nomeArquivo=${fileName}`, {
      responseType: 'blob'
    });
  };

  const adicionarChaveIntegracao = async (id: string, nome: string) => {
    return post<ChaveModel>(`${baseApi}/${id}/chave-integracao/${nome}/`);
  };
  const removerChaveIntegracao = async (id: string, chaveId: string) => {
    return remove(`${baseApi}/${id}/chave-integracao/${chaveId}/`);
  };

  const adicionarFaseAtendimento = async (request: ResponsavelFaseAtendimentoRequestModel) => {
    return post(`${baseApi}/adicionar-fase-atendimento/`, request);
  };

  const removerFaseAtendimento = async (request: ResponsavelFaseAtendimentoRequestModel) => {
    return removeWithBody(`${baseApi}/remover-fase-atendimento/`, request);
  };

  const validarLoginSenhaIntegracao = async (request: ValidarLoginRequestModel) => {
    return post<ValidarLoginResponseModel>(`${baseApi}/validar-login-rastreamento/`, request);
  };

  return {
    buscarGridEmpresasPaginated,
    salvarEmpresa,
    obterEmpresaById,
    atualizarEmpresa,
    salvarEndereco,
    excluirEndereco,
    obterArquivo,
    adicionarArquivo,
    alterarArquivo,
    obterEmpresaByIdIqf,
    obterArquivoImagemHistorico,
    adicionarChaveIntegracao,
    removerChaveIntegracao,
    adicionarFaseAtendimento,
    removerFaseAtendimento,
    validarLoginSenhaIntegracao
  };
};
