import {
  OptGrid,
  OptGridColumn,
  OptGridOptions,
  OptGridRef,
  OptGridRequest,
  OptGridResponse,
  OptSideLayoutContent
} from '@optsol/react';
import { forwardRef } from 'react';

import { Colors } from '../../../core/colors';
import { PlanoSearchResponse } from '../../../models/dtos/Plano/planoSearchResponse.model';
import { getHeaderTheme, obterFiltroDaRota } from '../../../shared/utils/functions';
import { ChipStatus } from '../../components/StatusText/ChipStatus';
import { useAuthenticationContext } from '../../contexts/authentication/authenticationContext';

interface GridProps {
  carregar: (data: OptGridRequest) => Promise<OptGridResponse<PlanoSearchResponse>>;
  editarPlano(planoId: string): Promise<void>;
}

const options: OptGridOptions = {
  search: true,
  pageSize: 10
};
const GridPlanos = forwardRef<OptGridRef | undefined, GridProps>(
  ({ carregar, editarPlano }, ref) => {
    const {
      state: { tema }
    } = useAuthenticationContext();

    const defaultPageSize = obterFiltroDaRota('pageSize');
    const defaultPage = obterFiltroDaRota('page');
    const columns: OptGridColumn<PlanoSearchResponse>[] = [
      {
        title: 'Id',
        field: 'id',
        hidden: true
      },
      {
        title: 'Nome  ',
        field: 'nome',
        align: 'start',
        render: (props: PlanoSearchResponse) => {
          return <>{props.nome ? props.nome : '-'}</>;
        }
      },
      {
        title: 'Descrição  ',
        field: 'descricao',
        align: 'start',
        render: (props: PlanoSearchResponse) => {
          return <>{props.descricao ? props.descricao : '-'}</>;
        }
      },
      {
        title: 'Número contrato',
        field: 'numeroContrato',
        align: 'start',
        render: (props: PlanoSearchResponse) => {
          return <>{props.numeroContrato ? props.numeroContrato : '-'}</>;
        }
      },
      {
        title: 'Situação  ',
        field: 'situacao',
        align: 'start',
        render: (props: PlanoSearchResponse) => {
          return <ChipStatus status={props.situacao} />;
        }
      }
    ];
    return (
      <OptSideLayoutContent>
        <OptGrid
          ref={ref as any}
          headerStyle={getHeaderTheme(tema.light?.primary ? tema.light?.primary : Colors.gray10)}
          data={carregar}
          options={{
            ...options,
            pageIndex: defaultPage !== null ? parseInt(defaultPage) : 0,
            pageSize: defaultPageSize !== null ? parseInt(defaultPageSize) : 10
          }}
          columns={columns}
          onRowClick={(rowData) => {
            editarPlano(rowData.id);
          }}
        />
      </OptSideLayoutContent>
    );
  }
);

GridPlanos.displayName = 'GridPlanos';
export default GridPlanos;
