import { Route, Routes as ReactRouterRoutes } from 'react-router-dom';

import { useAuthenticationContext } from '../app/contexts/authentication/authenticationContext';
import { Atendimento } from '../app/features/atendimento';
import Home from '../app/pages/home/Home';
import ListarAtendimentos from '../app/pages/listarAtendimentos/ListarAtendimentos';
import { MenuAtendimentoClaims } from '../models/enums/AcessoClaims';

export const RadioOperadorRoutes = () => {
  const { hasAccess } = useAuthenticationContext();
  const enableRoute = hasAccess(MenuAtendimentoClaims.MENUS__MENU_ATENDIMENTO_OPERADOR);
  return (
    <ReactRouterRoutes>
      <Route path="/" element={enableRoute ? <ListarAtendimentos /> : <Home />} />
      <Route path="/radio-operador/:id" element={enableRoute ? <Atendimento /> : <Home />} />
    </ReactRouterRoutes>
  );
};
