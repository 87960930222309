import { yupResolver } from '@hookform/resolvers/yup';
import { useSnackbar } from 'notistack';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';

import { Colors } from '../../../core/colors';
import {
  AtendimentoFormModel,
  DADOS_ATENDIMENTO_DEFAULT,
  dadosAtendimentoFormModelSchema
} from '../../../models/Atendimento/Atendimento.model';
import { EquipeModel } from '../../../models/Atendimento/Equipe/Equipe.model';
import { TimelineModel } from '../../../models/Atendimento/Equipe/TimeLine.model';
import {
  InformacaoPacienteFormModel,
  LISTA_PACIENTE_DEFAULT
} from '../../../models/Atendimento/Paciente/InformacaoPaciente.model';
import { AdicionarProntuarioRequest } from '../../../models/dtos/Atendimento/adicionarProntuarioRequest';
import {
  EncaminharRequest,
  EncaminharVeiculoRequest
} from '../../../models/dtos/Atendimento/encaminharRequest.mode';
import {
  FasesAtendimentoEnum,
  FasesAtendimentoEnumLabel
} from '../../../models/enums/FasesAtendimento';
import { StatusAtendimentoEnum } from '../../../models/enums/StatusAtendimento';
import { StatusVeiculoEnum } from '../../../models/enums/StatusVeiculo';
import { TipoVeiculo } from '../../../models/enums/TipoVeiculo';
import { LocalizacoesPorPacienteModel } from '../../../models/Mapa/Localizacoes.model';
import { useAtendimentoService } from '../../../service/atendimento.service';
import { useVeiculoService } from '../../../service/veiculo.service';
import { validarCPF } from '../../../shared/utils/functions';
import { useAuthenticationContext } from '../../contexts/authentication/authenticationContext';
import { SignalRContext } from '../../contexts/SignalRContext/SignalRContext';

export const useAtendimentoController = () => {
  const [timeLineId, setTimeLineId] = useState<string>('');
  const [stepSelecionado, setStepSelecionado] = useState<string>('');
  const [pacienteSelecionado, setPacienteSelecionado] = useState<string>('');
  const [equipeSelecionado, setEquipeSelecionado] = useState<string>('');
  const [confirmandoMarcarStep, setConfirmandoMarcarStep] = useState<boolean>(false);
  const [expanded, setExpanded] = useState<number | false>(0);
  const [salvarAtendimentoIsLoading, setSalvarAtendimentoIsLoading] = useState<boolean>(false);
  const [openMapsModal, setOpenMapsModal] = useState(false);
  const [posicoesMapa, setPosicoesMapa] = useState<LocalizacoesPorPacienteModel>();

  const form = useForm<AtendimentoFormModel>({
    mode: 'onBlur',
    defaultValues: DADOS_ATENDIMENTO_DEFAULT,
    resolver: yupResolver(dadosAtendimentoFormModelSchema)
  });
  const { control } = form;
  const { fields } = useFieldArray({
    control,
    name: 'pacientes'
  });
  const formValues = form.getValues();
  const {
    alterarPaciente,
    adicionarPaciente,
    concluirRemocao,
    encaminharVeiculo,
    atualizarTimeLine,
    atualizarVeiculoPaciente,
    cancelarAmbulancia,
    assumirPacienteRadioOperador,
    assumirPacienteRadioTarm,
    assumirPacienteMedicoRegulador,
    alterarReponsavelRadioOperador,
    alterarReponsavelMedicoRegulador,
    encerrarChamado,
    cancelarChamado,
    removerPaciente,
    encaminharParaRadioOperador,
    encaminhar,
    adicionarProntuario,
    alterarReponsavelTarm
  } = useAtendimentoService();

  const {
    state: { userInfo },
    hasAccess
  } = useAuthenticationContext();

  const { obterGeolocalizacoesPaciente } = useVeiculoService();

  const { enqueueSnackbar } = useSnackbar();

  const { signalRTimelineData, quantidadeNotificacaoNaoLida } = useContext(SignalRContext);

  useEffect(() => {
    atualizarNotificacaoPaciente(
      quantidadeNotificacaoNaoLida?.pacienteId,
      quantidadeNotificacaoNaoLida?.quantidadeNotificacaoNaoLida,
      quantidadeNotificacaoNaoLida?.usuarioId
    );
  }, [quantidadeNotificacaoNaoLida]);

  function atualizarNotificacaoPaciente(
    pacienteIdP: string | undefined,
    quantidadeNaoLida: number | undefined,
    usuarioId: string | undefined
  ) {
    const paciente = form.getValues().pacientes.find((x) => x.pacienteId === pacienteIdP);
    if (paciente && userInfo.data?.usuarioId === usuarioId) {
      form.getValues().pacientes.forEach((x) => {
        if (x.pacienteId === paciente.pacienteId) {
          x.quantidadeNotificacaoNaoLida = quantidadeNaoLida;
        }
      });

      form.reset({ ...form.getValues() });
    }
  }

  useEffect(() => {
    const paciente = form
      .getValues()
      .pacientes.find(
        (x) =>
          x.atendimentoId === signalRTimelineData?.atendimentoId &&
          x.pacienteId === signalRTimelineData?.pacienteId &&
          x.equipes.some((equipe) => equipe.id === signalRTimelineData?.equipeId)
      );
    if (signalRTimelineData?.timeLine && signalRTimelineData?.timeLine.length > 0 && paciente) {
      const timeLineAtual =
        paciente?.equipes.find((eq) => eq.id == signalRTimelineData?.equipeId)?.timelineVeiculo ??
        [];

      renderTimeline(paciente, timeLineAtual);
      if (
        signalRTimelineData?.timeLine.some(
          (tm) => tm.nome === 'AtendimentoConcluido' && tm.confirmado === true
        )
      ) {
        let podeConcluir = false;
        if (paciente.equipes.length > 1) {
          const equipesPaciente =
            form.getValues().pacientes.find((p) => p.pacienteId === signalRTimelineData?.pacienteId)
              ?.equipes ?? [];
          const equipes = equipesPaciente.filter((tm) => tm.id !== signalRTimelineData?.equipeId);
          podeConcluir = equipes.some((x) => {
            if (x.timelineVeiculo) {
              return x.timelineVeiculo.some(
                (tv) => tv.nome === 'AtendimentoConcluido' && tv.confirmado == true
              );
            }
          });
        } else {
          podeConcluir = true;
        }

        if (podeConcluir) paciente.concluirRemocao = podeConcluir;

        form.getValues().pacientes.forEach((x) => {
          if (x.pacienteId === paciente.pacienteId) {
            x.faseAtendimento = FasesAtendimentoEnum.FASEEMPENHO;
            x.faseAtendimentoDescricao = FasesAtendimentoEnumLabel.get(
              FasesAtendimentoEnum.FASEEMPENHO
            );
            x.faseEmpenhoResponsavelId === userInfo.data?.usuarioId;
            x.concluirRemocao = podeConcluir;
          }
        });

        form.reset({ ...form.getValues() });
      }
    }
  }, [signalRTimelineData]);

  const renderTimeline = (
    paciente: InformacaoPacienteFormModel,
    timelineForm: TimelineModel[] | null | undefined
  ) => {
    const userIdMatch = signalRTimelineData?.usuariosId.some(
      (id) => id === userInfo.data?.usuarioId
    );
    if (!userIdMatch) return timelineForm?.sort((a, b) => (a.ordem > b.ordem ? 1 : -1));

    const atendimentoIdMatch = paciente.atendimentoId === signalRTimelineData?.atendimentoId;
    const pacienteIdMatch = paciente.pacienteId === signalRTimelineData?.pacienteId;
    const equipeIdMatch = paciente.equipes.some(
      (equipe) => equipe.id === signalRTimelineData?.equipeId
    );

    if (!atendimentoIdMatch || !pacienteIdMatch || !equipeIdMatch)
      return timelineForm?.sort((a, b) => (a.ordem > b.ordem ? 1 : -1));

    form.getValues().pacientes.forEach((x) => {
      if (x.pacienteId === paciente.pacienteId) {
        x.equipes.forEach((eq) => {
          if (eq.id === signalRTimelineData?.equipeId) {
            eq.timelineVeiculo = signalRTimelineData?.timeLine;
          }
        });
      }
    });

    form.reset({ ...form.getValues() });
  };

  async function editarPacienteHandler(value: InformacaoPacienteFormModel) {
    await editarPaciente(value);
  }

  const handleChange = (panel: number) => (event: React.SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleOpenMapsModal = (atendimentoId: string, pacienteId: string) => {
    setOpenMapsModal(true);
    buscarLocalizacao(atendimentoId, pacienteId);
  };

  const handleCloseMapsModal = () => {
    setOpenMapsModal(false);
  };

  const buscarLocalizacao = useCallback(
    async (atendimentoId: string, pacienteId: string) => {
      setPosicoesMapa(undefined);
      const result = await obterGeolocalizacoesPaciente(atendimentoId, pacienteId);

      if (result.data?.geolocalizacoesVeiculos?.length > 0) {
        setCenter({
          lat: +result.data?.geolocalizacoesVeiculos[0]?.lat,
          lng: +result.data?.geolocalizacoesVeiculos[0]?.lng
        });
      }
      if (result.data?.enderecoOrigem?.enderecoGeolocalizacao) {
        setCenter({
          lat: +result.data?.enderecoOrigem?.enderecoGeolocalizacao?.latitude,
          lng: +result.data?.enderecoOrigem?.enderecoGeolocalizacao?.longitude
        });
      }

      result.data.geolocalizacoesVeiculos?.map((x) => {
        x.tipoVeiculoDesc = x.tipoVeiculo === TipoVeiculo.USA ? ' USA ' : ' USB ';
        x.possuiCondutor = x.condutor ? true : false;
        x.condutor = x.condutor ? x.condutor : 'Não informado';
        x.placa = x.placa ? x.placa : 'Não informado';
        x.descricaoOrigem = x.descricaoOrigem ? x.descricaoOrigem : 'Não informado';
        x.descricaoDestino = x.descricaoDestino ? x.descricaoDestino : 'Não informado';
        x.numeroChamado = x.numeroChamado ? x.numeroChamado : 'Não informado';

        if (x.veiculoStatus === StatusVeiculoEnum.DISPONIVEL) {
          x.colorVeiculo = x.possuiCondutor === true ? Colors.green1 : Colors.blue2;

          x.infoTooltip =
            '<div id="content">' +
            '<div id="siteNotice">' +
            '</div>' +
            '<h1 id="firstHeading" class="firstHeading">' +
            x.prefixo +
            ' - ' +
            x.tipoVeiculoDesc +
            '</h1>' +
            '<div id="bodyContent">' +
            '<p><b>Ultima atualização a: </b> ' +
            x.dataHoraUltimaAtualizacao +
            '</p>' +
            '<p><b>Status: </b> DISPONÍVEL </p>' +
            '<p><b>Condutor: </b> ' +
            x.condutor +
            ' </p>' +
            '</div>' +
            '</div>';
        } else {
          x.colorVeiculo = x.possuiCondutor === true ? Colors.red1 : Colors.blue2;
          x.infoTooltip =
            '<div id="content">' +
            '<div id="siteNotice">' +
            '</div>' +
            '<h1 id="firstHeading" class="firstHeading">' +
            x.prefixo +
            '-' +
            x.tipoVeiculoDesc +
            '</h1>' +
            '<div id="bodyContent">' +
            '<p><b>Chamado: </b>' +
            x.numeroChamado +
            '</p>' +
            '<p><b>Ultima atualização à:  </b> ' +
            x.dataHoraUltimaAtualizacao +
            '</p>' +
            '<p><b>Status: </b> EM ATENDIMENTO </p>' +
            '<p><b>Condutor: </b> ' +
            x.condutor +
            ' </p>' +
            '<p><b>Origem: </b>' +
            x.descricaoOrigem +
            '</p>' +
            '<p><b>Destino: </b>' +
            x.descricaoDestino +
            '</p>' +
            '</div>' +
            '</div>';
        }
      });

      setPosicoesMapa(result.data);
    },
    [obterGeolocalizacoesPaciente]
  );

  const [center, setCenter] = useState<google.maps.LatLngLiteral>({
    lat: -20.3105583,
    lng: -40.2960533
  });

  const editarPaciente = async (paciente: InformacaoPacienteFormModel) => {
    const informacoesPacienteModel = paciente;
    if (!informacoesPacienteModel.nome?.trim()) return;
    const documento = informacoesPacienteModel.cpf;
    if (documento) {
      if (!validarCPF(documento)) return;
    }
    setSalvarAtendimentoIsLoading(true);
    try {
      if (!informacoesPacienteModel.pacienteId) {
        const formValues = form.getValues();
        const result = await adicionarPaciente({
          ...informacoesPacienteModel,
          atendimentoId: formValues.atendimentoId
        });
        formValues.pacientes = [...result.data];
        form.reset(formValues);
      } else {
        const formValues = form.getValues();
        await alterarPaciente({
          ...informacoesPacienteModel
        });
        form.reset(formValues);
      }
    } finally {
      setSalvarAtendimentoIsLoading(false);
    }
  };

  async function encaminharVeiculoHandler(
    paciente: InformacaoPacienteFormModel,
    equipeId: string,
    indexPaciente: number,
    indexEquipe: number
  ) {
    try {
      setSalvarAtendimentoIsLoading(true);
      const request: EncaminharVeiculoRequest = {
        atendimentoId: form.getValues().atendimentoId ?? '',
        equipeId: equipeId,
        pacienteId: paciente.pacienteId ?? ''
      };
      await encaminharVeiculo(request);
      atualizarPaciente(
        paciente.pacienteId ?? '',
        StatusAtendimentoEnum.EM_ANDAMENTO,
        FasesAtendimentoEnum.FASEATENDIMENTO
      );
      enqueueSnackbar('Veículo encaminhada com sucesso!', {
        variant: 'success'
      });

      // todo pedir retorno time line api
      const { pacientes } = form.getValues();
      const timeline = pacientes[indexPaciente].equipes[indexEquipe].timelineVeiculo;

      timeline?.map((t) => {
        if (t.ordem === 0) {
          t.confirmado = true;
        }
      });

      form.setValue(`pacientes.${indexPaciente}.equipes.${indexEquipe}.notificacaoEnviada`, true);

      form.setValue(`pacientes.${indexPaciente}.equipes.${indexEquipe}.timelineVeiculo`, timeline);

      form.reset(form.getValues());
    } finally {
      setSalvarAtendimentoIsLoading(false);
    }
  }

  function atualizarPaciente(
    pacienteId: string,
    status: StatusAtendimentoEnum,
    faseAtendimento: FasesAtendimentoEnum
  ) {
    const listaAtualizada = form.getValues().pacientes.map((obj) => {
      if (obj.pacienteId === pacienteId) {
        obj.faseAtendimento = faseAtendimento;
        obj.faseAtendimentoSigla = FasesAtendimentoEnumLabel.get(faseAtendimento);
        obj.faseAtendimentoDescricao = FasesAtendimentoEnumLabel.get(faseAtendimento);
        obj.statusPaciente = status;
      }
      return obj;
    });
    form.getValues().pacientes = [...listaAtualizada];
    form.reset(form.getValues());
  }

  async function cancelarAmbulanciaHandler(indexPaciente: number, indexEquipe: number) {
    try {
      setSalvarAtendimentoIsLoading(true);
      const { atendimentoId } = form.getValues();
      const pacienteId = form.getValues().pacientes[indexPaciente].pacienteId;
      const equipeId = form.getValues().pacientes[indexPaciente].equipes[indexEquipe].id;
      const veiculoId = form.getValues().pacientes[indexPaciente].equipes[indexEquipe].veiculoId;
      await cancelarAmbulancia(
        atendimentoId ?? '',
        pacienteId ?? '',
        equipeId ?? '',
        veiculoId ?? ''
      );
      enqueueSnackbar('Veículo cancelado com sucesso!', {
        variant: 'success'
      });

      form.setValue(`pacientes.${indexPaciente}.equipes.${indexEquipe}.notificacaoEnviada`, false);
      form.setValue(`pacientes.${indexPaciente}.equipes.${indexEquipe}.veiculoId`, null);
      form.setValue(`pacientes.${indexPaciente}.equipes.${indexEquipe}.timelineVeiculo`, []);

      const listaTimeLine =
        form.getValues(`pacientes.${indexPaciente}.equipes.${indexEquipe}.timelineVeiculo`) ?? [];

      let podeConcluir = false;
      if (listaTimeLine.length > 0) {
        podeConcluir = listaTimeLine.every(
          (x) => x.nome === 'AtendimentoConcluido' && x.confirmado === true
        );
      }

      if (podeConcluir) form.setValue(`pacientes.${indexPaciente}.concluirRemocao`, podeConcluir);
      else form.setValue(`pacientes.${indexPaciente}.concluirRemocao`, false);
      form.reset(form.getValues());
    } finally {
      setSalvarAtendimentoIsLoading(false);
    }
  }

  async function concluirRemocaoHandler(pacienteId: string) {
    try {
      const { atendimentoId } = form.getValues();
      setSalvarAtendimentoIsLoading(true);
      await concluirRemocao(atendimentoId ?? '', pacienteId);
      atualizarListaPacientecConcluido(pacienteId);
      enqueueSnackbar('Remoção concluída com sucesso!', {
        variant: 'success'
      });
    } finally {
      setSalvarAtendimentoIsLoading(false);
    }
  }

  function atualizarListaPacientecConcluido(pacienteId: string) {
    const listaAtualizada = form.getValues().pacientes.map((obj) => {
      if (obj.pacienteId === pacienteId) {
        obj.faseAtendimento = FasesAtendimentoEnum.FASEREGULACAO;
        obj.faseAtendimentoSigla = FasesAtendimentoEnumLabel.get(
          FasesAtendimentoEnum.FASEREGULACAO
        );
        obj.statusPaciente = StatusAtendimentoEnum.PENDENTE;
      }
      return obj;
    });
    form.getValues().pacientes = [...listaAtualizada];
    form.reset(form.getValues());
  }

  async function confirmarMarcarcaoStep() {
    try {
      setSalvarAtendimentoIsLoading(true);
      await atualizarTimeLineHandler();
      cancelarMarcarStep();
    } finally {
      setSalvarAtendimentoIsLoading(false);
    }
  }

  async function atualizarTimeLineHandler() {
    const { atendimentoId } = form.getValues();

    const response = await atualizarTimeLine(
      atendimentoId ?? '',
      pacienteSelecionado,
      equipeSelecionado,
      timeLineId
    );

    if (response) {
      const { pacientes } = form.getValues();

      const indexPaciente = pacientes.findIndex((p) => p.pacienteId === pacienteSelecionado);
      const indexEquipe = pacientes[indexPaciente].equipes.findIndex(
        (e) => e.id === equipeSelecionado
      );

      const timeline = pacientes[indexPaciente].equipes[indexEquipe].timelineVeiculo;

      timeline?.map((t) => {
        if (t.id === timeLineId) {
          t.confirmado = true;
          if (t.nome === 'AtendimentoConcluido') {
            pacientes[indexPaciente].faseAtendimento = FasesAtendimentoEnum.FASEEMPENHO;
            pacientes[indexPaciente].faseAtendimentoSigla = FasesAtendimentoEnumLabel.get(
              FasesAtendimentoEnum.FASEEMPENHO
            );
            const podeConcluir = pacientes[indexPaciente].equipes.some((x) => {
              if (x.timelineVeiculo) {
                return x.timelineVeiculo.some(
                  (tv) => tv.nome === 'AtendimentoConcluido' && tv.confirmado == true
                );
              }
            });
            pacientes[indexPaciente].concluirRemocao = podeConcluir;
          }
        }
      });

      form.setValue(`pacientes.${indexPaciente}.equipes.${indexEquipe}.timelineVeiculo`, timeline);

      form.reset(form.getValues());
    }
  }

  function cancelarMarcarStep() {
    setConfirmandoMarcarStep(false);
    setStepSelecionado('');
    setEquipeSelecionado('');
    setPacienteSelecionado('');
  }

  async function marcarStep(
    step: string,
    equipeId: string,
    pacienteId: string,
    timeLineId: string
  ) {
    setStepSelecionado(step);
    setEquipeSelecionado(equipeId);
    setPacienteSelecionado(pacienteId);
    setTimeLineId(timeLineId);
    setConfirmandoMarcarStep(true);
  }

  async function editarVeiculo(indexPaciente: number, indexEquipe: number) {
    const { atendimentoId } = form.getValues();
    const pacienteId = form.getValues().pacientes[indexPaciente].pacienteId;
    const equipeId = form.getValues().pacientes[indexPaciente].equipes[indexEquipe].id;
    const veiculoId = form.getValues().pacientes[indexPaciente].equipes[indexEquipe].veiculoId;

    const response = await atualizarVeiculoPaciente(
      atendimentoId ?? '',
      pacienteId ?? '',
      equipeId ?? '',
      veiculoId ?? ''
    );

    if (response) {
      form.setValue(
        `pacientes.${indexPaciente}.equipes.${indexEquipe}.timelineVeiculo`,
        response.data
      );

      form.setValue(`pacientes.${indexPaciente}.equipes.${indexEquipe}.notificacaoEnviada`, false);
      form.setValue(`pacientes.${indexPaciente}.equipes.${indexEquipe}.veiculoId`, veiculoId);

      form.reset(form.getValues());
    }
  }

  async function assumirPacienteHandler(
    atendimentoId: string,
    pacienteId: string,
    faseAtendimento: FasesAtendimentoEnum | null
  ) {
    try {
      setSalvarAtendimentoIsLoading(true);
      await assumirPacientePorPerfil(atendimentoId, pacienteId, faseAtendimento);
      form.getValues().pacientes = form.getValues().pacientes.map((obj) => {
        if (obj.pacienteId === pacienteId) {
          switch (faseAtendimento) {
            case FasesAtendimentoEnum.FASECRIACAO:
              obj.faseCriacaoResponsavelId = userInfo.data?.usuarioId;
              break;
            case FasesAtendimentoEnum.FASEREGULACAO:
              obj.faseRegulacaoResponsavelId = userInfo.data?.usuarioId;
              break;
            case FasesAtendimentoEnum.FASEEMPENHO:
              obj.faseEmpenhoResponsavelId = userInfo.data?.usuarioId;
              break;
            default:
              console.error('Not implemented');
              break;
          }
        }
        return obj;
      });
      form.reset(form.getValues());
      enqueueSnackbar('Atendimento assumido com sucesso!', {
        variant: 'success'
      });
    } finally {
      setSalvarAtendimentoIsLoading(false);
    }
  }

  async function assumirPacientePorPerfil(
    atendimentoId: string,
    pacienteId: string,
    faseAtendimento: FasesAtendimentoEnum | null
  ) {
    switch (faseAtendimento) {
      case FasesAtendimentoEnum.FASECRIACAO:
        await assumirPacienteRadioTarm(atendimentoId, pacienteId);
        break;
      case FasesAtendimentoEnum.FASEREGULACAO:
        await assumirPacienteMedicoRegulador(atendimentoId, pacienteId);
        break;
      case FasesAtendimentoEnum.FASEEMPENHO:
        await assumirPacienteRadioOperador(atendimentoId, pacienteId);
        break;
      default:
        console.error('Not implemented');
        break;
    }
  }

  function desabilitarSelectVeiculo(paciente: InformacaoPacienteFormModel, equipe: EquipeModel) {
    if (paciente.faseEmpenhoResponsavelId !== userInfo.data?.usuarioId) {
      return true;
    }
    return (
      (paciente.faseAtendimento !== FasesAtendimentoEnum.FASEEMPENHO &&
        paciente.faseAtendimento !== FasesAtendimentoEnum.ADMIN &&
        paciente.faseAtendimento !== FasesAtendimentoEnum.FASEATENDIMENTO &&
        paciente.faseAtendimento !== null &&
        (paciente.veiculoId !== null ||
          paciente.veiculoId != undefined ||
          paciente.veiculoId !== '')) ||
      equipe.notificacaoEnviada
    );
  }

  function podeEditarEndereco(paciente: InformacaoPacienteFormModel) {
    const usuarioResponsavelPeloPaciente =
      paciente.faseRegulacaoResponsavelId === userInfo.data?.usuarioId ||
      paciente.faseCriacaoResponsavelId === userInfo.data?.usuarioId ||
      paciente.faseEmpenhoResponsavelId === userInfo.data?.usuarioId;

    const processoDeAtendimentoEmAndamentoValido =
      paciente.statusPaciente === StatusAtendimentoEnum.EM_ANDAMENTO ||
      paciente.statusPaciente === StatusAtendimentoEnum.PENDENTE ||
      paciente.statusPaciente === StatusAtendimentoEnum.AGENDADO;

    if (!usuarioResponsavelPeloPaciente) return usuarioResponsavelPeloPaciente;

    return processoDeAtendimentoEmAndamentoValido;
  }

  async function alterarResponsavelHandler(
    atendimentoId: string,
    pacienteId: string,
    usuarioId: string,
    faseAtendimento: FasesAtendimentoEnum | null
  ) {
    switch (faseAtendimento) {
      case FasesAtendimentoEnum.FASEEMPENHO:
        await alterarReponsavelRadioOperador(atendimentoId, pacienteId, usuarioId);
        enqueueSnackbar('Responsável alterado com sucesso!', {
          variant: 'success'
        });
        break;
      case FasesAtendimentoEnum.FASEREGULACAO:
        await alterarReponsavelMedicoRegulador(atendimentoId, pacienteId, usuarioId);
        enqueueSnackbar('Responsável alterado com sucesso!', {
          variant: 'success'
        });
        break;
      case FasesAtendimentoEnum.FASECRIACAO:
        await alterarReponsavelTarm(atendimentoId, pacienteId, usuarioId);
        enqueueSnackbar('Responsável alterado com sucesso!', {
          variant: 'success'
        });
        break;
      default:
        enqueueSnackbar('Não foi possível alterar o Responsável!', {
          variant: 'error'
        });
        break;
    }
  }
  async function encerrarChamadoHandler(
    atendiemntoId: string,
    pacienteId: string,
    justificativa: string
  ) {
    await encerrarChamado(atendiemntoId, pacienteId, justificativa);
    definirStatsusListaDePaciente(pacienteId, StatusAtendimentoEnum.ENCERRADO);
    definirFaseAtendimento(pacienteId, FasesAtendimentoEnum.Nenhum);
    enqueueSnackbar('Chamado encerrado com sucesso!', {
      variant: 'success'
    });
  }

  async function cancelarChamadoHandler(
    atendimentoId: string,
    pacienteId: string,
    justificativa: string
  ) {
    await cancelarChamado(atendimentoId, pacienteId, justificativa);
    definirStatsusListaDePaciente(pacienteId, StatusAtendimentoEnum.CANCELADO);
    definirFaseAtendimento(pacienteId, FasesAtendimentoEnum.Nenhum);
    enqueueSnackbar('Chamado cancelado com sucesso!', {
      variant: 'success'
    });
  }

  function definirStatsusListaDePaciente(pacienteId: string, status: StatusAtendimentoEnum) {
    const listaAtualizada = form.getValues().pacientes.map((obj) => {
      if (obj.pacienteId === pacienteId) obj.statusPaciente = status;
      return obj;
    });
    form.getValues().pacientes = [...listaAtualizada];
    form.reset(form.getValues());
  }

  function definirFaseAtendimento(pacienteId: string, faseAtendimento: FasesAtendimentoEnum) {
    const listaAtualizada = form.getValues().pacientes.map((obj) => {
      if (pacienteId) {
        if (pacienteId === obj.pacienteId) {
          obj.faseAtendimento = faseAtendimento;
          obj.faseAtendimentoSigla = FasesAtendimentoEnumLabel.get(faseAtendimento);
          obj.faseAtendimentoDescricao = FasesAtendimentoEnumLabel.get(faseAtendimento);
        }
      } else {
        obj.faseAtendimento = faseAtendimento;
        obj.faseAtendimentoSigla = FasesAtendimentoEnumLabel.get(faseAtendimento);
        obj.faseAtendimentoDescricao = FasesAtendimentoEnumLabel.get(faseAtendimento);
      }
      return obj;
    });
    form.getValues().pacientes = [...listaAtualizada];
    form.reset(form.getValues());
  }
  function definirResponsavelRegulacao(pacienteId: string, usuarioId: string) {
    const listaAtualizada = form.getValues().pacientes.map((obj) => {
      if (pacienteId) {
        if (pacienteId === obj.pacienteId) {
          obj.faseRegulacaoResponsavelId = usuarioId;
        }
      }
      return obj;
    });
    form.getValues().pacientes = [...listaAtualizada];
    form.reset(form.getValues());
  }

  function definirResponsavelEmpenho(pacienteId: string, usuarioId: string) {
    const listaAtualizada = form.getValues().pacientes.map((obj) => {
      if (pacienteId) {
        if (pacienteId === obj.pacienteId) {
          obj.faseEmpenhoResponsavelId = usuarioId;
        }
      }
      return obj;
    });
    form.getValues().pacientes = [...listaAtualizada];
    form.reset(form.getValues());
  }

  async function removerPacienteHandler(pacienteId: string, atendimentoId: string) {
    await removerPaciente(atendimentoId, pacienteId);
    const formValues = form.getValues();

    formValues.pacientes = [...formValues.pacientes.filter((x) => x.pacienteId !== pacienteId)];
    if (formValues.pacientes.length === 0) formValues.pacientes = [...LISTA_PACIENTE_DEFAULT];
    form.reset(formValues);
  }

  const adicionarPacienteHandler = () => {
    const atendimentoValue = form.getValues();
    const novoPaciente: InformacaoPacienteFormModel = {
      ...LISTA_PACIENTE_DEFAULT[0],
      atendimentoId: atendimentoValue.atendimentoId
    };
    atendimentoValue.pacientes.push(novoPaciente);
    form.reset(atendimentoValue);
    if (atendimentoValue.pacientes.length > 0) {
      setExpanded(atendimentoValue.pacientes.length - 1);
    } else {
      setExpanded(0);
    }
  };

  const usuarioNaoPertenceAoAtendimento = formValues.pacientes.every(
    (x) =>
      (x.faseRegulacaoResponsavelId || x.faseCriacaoResponsavelId || x.faseEmpenhoResponsavelId) &&
      x.faseRegulacaoResponsavelId !== userInfo.data?.usuarioId &&
      x.faseCriacaoResponsavelId !== userInfo.data?.usuarioId &&
      x.faseEmpenhoResponsavelId !== userInfo.data?.usuarioId
  );

  function desabilitarFormularioAtendimento() {
    if (!formValues.atendimentoId) {
      return false;
    }
    const atendimentoPodeSerEditado = formValues.pacientes?.some(
      (x) =>
        x.statusPaciente === StatusAtendimentoEnum.EM_ANDAMENTO ||
        x.statusPaciente === StatusAtendimentoEnum.PENDENTE ||
        x.statusPaciente === StatusAtendimentoEnum.AGENDADO
    );

    if (usuarioNaoPertenceAoAtendimento) return usuarioNaoPertenceAoAtendimento;

    if (atendimentoPodeSerEditado) return !atendimentoPodeSerEditado;
    else return !atendimentoPodeSerEditado;
  }

  function desabilitarFormPaciente(paciente: InformacaoPacienteFormModel) {
    const naoPossuiResponsavel =
      !paciente.faseRegulacaoResponsavelId &&
      !paciente.faseCriacaoResponsavelId &&
      !paciente.faseEmpenhoResponsavelId;

    const usuarioResponsavelPeloPaciente =
      paciente.faseRegulacaoResponsavelId === userInfo.data?.usuarioId ||
      paciente.faseCriacaoResponsavelId === userInfo.data?.usuarioId ||
      paciente.faseEmpenhoResponsavelId === userInfo.data?.usuarioId;

    const processoDeAtendimentoEmAndamentoValido =
      paciente.statusPaciente === StatusAtendimentoEnum.EM_ANDAMENTO ||
      paciente.statusPaciente === StatusAtendimentoEnum.AGENDADO;

    if (naoPossuiResponsavel) return !naoPossuiResponsavel;

    if (!usuarioResponsavelPeloPaciente) return !usuarioResponsavelPeloPaciente;

    if (
      userInfo.data?.faseAtendimento.find((x) => x === FasesAtendimentoEnum.FASECRIACAO) &&
      paciente.faseAtendimento === FasesAtendimentoEnum.FASECRIACAO &&
      paciente.faseCriacaoResponsavelId === userInfo.data?.usuarioId
    ) {
      const faseAtendimentoEhCriacao =
        paciente.faseAtendimento === FasesAtendimentoEnum.FASECRIACAO;
      if (!faseAtendimentoEhCriacao) return !faseAtendimentoEhCriacao;
      else return !processoDeAtendimentoEmAndamentoValido;
    }

    if (
      userInfo.data?.faseAtendimento.find((x) => x === FasesAtendimentoEnum.FASEREGULACAO) &&
      paciente.faseAtendimento === FasesAtendimentoEnum.FASEREGULACAO &&
      paciente.faseRegulacaoResponsavelId === userInfo.data?.usuarioId
    ) {
      const faseAtendimentoEhRegulacao =
        paciente.faseAtendimento === FasesAtendimentoEnum.FASEREGULACAO;
      if (!faseAtendimentoEhRegulacao) return !faseAtendimentoEhRegulacao;
      else return !processoDeAtendimentoEmAndamentoValido;
    }

    return true;
  }

  function desabilitarTipoVeiculo(paciente: InformacaoPacienteFormModel) {
    const naoPossuiResponsavel =
      !paciente.faseRegulacaoResponsavelId &&
      !paciente.faseCriacaoResponsavelId &&
      !paciente.faseEmpenhoResponsavelId;

    const usuarioResponsavelPeloPaciente =
      paciente.faseRegulacaoResponsavelId === userInfo.data?.usuarioId ||
      paciente.faseCriacaoResponsavelId === userInfo.data?.usuarioId ||
      paciente.faseEmpenhoResponsavelId === userInfo.data?.usuarioId;

    const processoDeAtendimentoEmAndamentoValido =
      paciente.statusPaciente === StatusAtendimentoEnum.EM_ANDAMENTO ||
      paciente.statusPaciente === StatusAtendimentoEnum.AGENDADO;

    if (naoPossuiResponsavel) return !naoPossuiResponsavel;

    if (!usuarioResponsavelPeloPaciente) return !usuarioResponsavelPeloPaciente;

    if (
      userInfo.data?.faseAtendimento.find((x) => x === FasesAtendimentoEnum.FASECRIACAO) &&
      paciente.faseAtendimento === FasesAtendimentoEnum.FASECRIACAO &&
      paciente.faseCriacaoResponsavelId === userInfo.data?.usuarioId
    ) {
      const faseAtendimentoEhCriacao =
        paciente.faseAtendimento === FasesAtendimentoEnum.FASECRIACAO;
      if (!faseAtendimentoEhCriacao) return !faseAtendimentoEhCriacao;
      else return !processoDeAtendimentoEmAndamentoValido;
    }

    if (
      userInfo.data?.faseAtendimento.find((x) => x === FasesAtendimentoEnum.FASEREGULACAO) &&
      (paciente.faseAtendimento === FasesAtendimentoEnum.FASEREGULACAO ||
        paciente.faseAtendimento === FasesAtendimentoEnum.FASEEMPENHO ||
        paciente.faseAtendimento === FasesAtendimentoEnum.FASEATENDIMENTO) &&
      paciente.faseRegulacaoResponsavelId === userInfo.data?.usuarioId
    ) {
      return !processoDeAtendimentoEmAndamentoValido;
    }

    return true;
  }

  function desabilitarFormAgendamento() {
    const naopPossuiPacienteComTarm = formValues.pacientes.every(
      (x) => x.faseAtendimento !== null && x.faseAtendimento !== FasesAtendimentoEnum.FASECRIACAO
    );

    if (usuarioNaoPertenceAoAtendimento) return usuarioNaoPertenceAoAtendimento;

    return naopPossuiPacienteComTarm;
  }

  function podeRemoverPaciente(paciente: InformacaoPacienteFormModel) {
    if (!paciente.pacienteId) return false;
    const usuarioResponsavelPeloPaciente =
      paciente.faseRegulacaoResponsavelId === userInfo.data?.usuarioId ||
      paciente.faseCriacaoResponsavelId === userInfo.data?.usuarioId ||
      paciente.faseEmpenhoResponsavelId === userInfo.data?.usuarioId;

    const processoDeAtendimentoEmAndamentoValido =
      paciente.statusPaciente === StatusAtendimentoEnum.EM_ANDAMENTO ||
      paciente.statusPaciente === StatusAtendimentoEnum.AGENDADO;

    if (!usuarioResponsavelPeloPaciente) return usuarioResponsavelPeloPaciente;

    if (userInfo.data?.faseAtendimento.find((x) => x === FasesAtendimentoEnum.FASECRIACAO)) {
      const faseAtendimentoEhCriacao =
        paciente.faseAtendimento === FasesAtendimentoEnum.FASECRIACAO;
      if (faseAtendimentoEhCriacao) return processoDeAtendimentoEmAndamentoValido;
      else return faseAtendimentoEhCriacao;
    }

    if (userInfo.data?.faseAtendimento.find((x) => x === FasesAtendimentoEnum.FASEREGULACAO)) {
      const faseAtendimentoEhRegulacao =
        paciente.faseAtendimento === FasesAtendimentoEnum.FASEREGULACAO;
      if (faseAtendimentoEhRegulacao) return processoDeAtendimentoEmAndamentoValido;
      else return faseAtendimentoEhRegulacao;
    }

    return processoDeAtendimentoEmAndamentoValido;
  }

  function podeAlterarResponsavel(paciente: InformacaoPacienteFormModel) {
    const processoDeAtendimentoEmAndamentoValido =
      paciente.statusPaciente === StatusAtendimentoEnum.EM_ANDAMENTO ||
      paciente.statusPaciente === StatusAtendimentoEnum.PENDENTE ||
      paciente.statusPaciente === StatusAtendimentoEnum.AGENDADO;
    const pacienteDesiginadoParaMedicoOuRadio =
      paciente.faseAtendimento === FasesAtendimentoEnum.FASEREGULACAO ||
      paciente.faseAtendimento === FasesAtendimentoEnum.FASEEMPENHO;

    if (!processoDeAtendimentoEmAndamentoValido) return processoDeAtendimentoEmAndamentoValido;

    return pacienteDesiginadoParaMedicoOuRadio;
  }

  function podeEncerrarChamado(paciente: InformacaoPacienteFormModel) {
    const processoDeAtendimentoEmAndamentoValido =
      paciente.statusPaciente === StatusAtendimentoEnum.EM_ANDAMENTO ||
      paciente.statusPaciente === StatusAtendimentoEnum.PENDENTE ||
      paciente.statusPaciente === StatusAtendimentoEnum.AGENDADO;
    const usuarioResponsavelPeloPaciente =
      paciente.faseRegulacaoResponsavelId === userInfo.data?.usuarioId ||
      paciente.faseCriacaoResponsavelId === userInfo.data?.usuarioId ||
      paciente.faseEmpenhoResponsavelId === userInfo.data?.usuarioId;

    if (!usuarioResponsavelPeloPaciente) return usuarioResponsavelPeloPaciente;

    if (
      userInfo.data?.faseAtendimento.find((x) => x === FasesAtendimentoEnum.FASEREGULACAO) &&
      paciente.faseRegulacaoResponsavelId === userInfo.data?.usuarioId
    ) {
      const faseAtendimentoEhRegulacao =
        paciente.faseAtendimento === FasesAtendimentoEnum.FASEREGULACAO;
      if (faseAtendimentoEhRegulacao) return processoDeAtendimentoEmAndamentoValido;
      else return faseAtendimentoEhRegulacao;
    } else {
      return false;
    }
  }

  function podeAssumirPaciente(paciente: InformacaoPacienteFormModel): AssumirPacienteValidadtion {
    const resultValidation: AssumirPacienteValidadtion = {
      processoDeAtendimentoEmAndamentoValido: false,
      podeAssumirCriacao: false,
      podeAssumirEmpenho: false,
      podeAssumirRegulacao: false
    };
    if (!paciente.pacienteId) return resultValidation;

    resultValidation.processoDeAtendimentoEmAndamentoValido =
      paciente.statusPaciente === StatusAtendimentoEnum.EM_ANDAMENTO ||
      paciente.statusPaciente === StatusAtendimentoEnum.PENDENTE ||
      paciente.statusPaciente === StatusAtendimentoEnum.AGENDADO;

    if (
      userInfo.data?.faseAtendimento.some((x) => x == FasesAtendimentoEnum.FASECRIACAO) &&
      paciente.faseCriacaoResponsavelId !== userInfo.data?.usuarioId
    ) {
      resultValidation.podeAssumirCriacao = true;
    }

    if (
      (paciente.faseAtendimento === FasesAtendimentoEnum.FASEATENDIMENTO ||
        paciente.faseAtendimento === FasesAtendimentoEnum.FASEEMPENHO ||
        paciente.faseAtendimento === FasesAtendimentoEnum.FASEREGULACAO) &&
      paciente.faseRegulacaoResponsavelId !== userInfo.data?.usuarioId &&
      userInfo.data?.faseAtendimento.some((x) => x == FasesAtendimentoEnum.FASEREGULACAO)
    ) {
      resultValidation.podeAssumirRegulacao = true;
    }

    if (
      (paciente.faseAtendimento === FasesAtendimentoEnum.FASEATENDIMENTO ||
        paciente.faseAtendimento === FasesAtendimentoEnum.FASEEMPENHO) &&
      paciente.faseEmpenhoResponsavelId !== userInfo.data?.usuarioId &&
      userInfo.data?.faseAtendimento.some((x) => x == FasesAtendimentoEnum.FASEEMPENHO)
    ) {
      resultValidation.podeAssumirEmpenho = true;
    }
    // return false;
    // console.log(userInfo.data?.usuarioId);
    // console.log(paciente.faseCriacaoResponsavelId);
    return resultValidation;
  }

  function podeCancelarChamado(paciente: InformacaoPacienteFormModel) {
    if (!paciente.pacienteId) return false;

    const usuarioNaoPertenceAoAtendimento =
      paciente.faseRegulacaoResponsavelId !== userInfo.data?.usuarioId &&
      paciente.faseCriacaoResponsavelId !== userInfo.data?.usuarioId &&
      paciente.faseEmpenhoResponsavelId !== userInfo.data?.usuarioId;

    const processoDeAtendimentoEmAndamentoValido =
      paciente.statusPaciente === StatusAtendimentoEnum.EM_ANDAMENTO ||
      paciente.statusPaciente === StatusAtendimentoEnum.AGENDADO;

    if (usuarioNaoPertenceAoAtendimento) return !usuarioNaoPertenceAoAtendimento;

    if (
      userInfo.data?.faseAtendimento.find((x) => x === FasesAtendimentoEnum.FASECRIACAO) &&
      paciente.faseAtendimento == FasesAtendimentoEnum.FASECRIACAO &&
      paciente.faseCriacaoResponsavelId === userInfo.data?.usuarioId
    ) {
      const faseAtendimentoEhCriacao =
        paciente.faseAtendimento === FasesAtendimentoEnum.FASECRIACAO;
      if (faseAtendimentoEhCriacao) return processoDeAtendimentoEmAndamentoValido;
      else return faseAtendimentoEhCriacao;
    }

    if (
      userInfo.data?.faseAtendimento.find((x) => x === FasesAtendimentoEnum.FASEREGULACAO) &&
      paciente.faseAtendimento == FasesAtendimentoEnum.FASEREGULACAO &&
      paciente.faseRegulacaoResponsavelId === userInfo.data?.usuarioId
    ) {
      const faseAtendimentoEhRegulacao =
        paciente.faseAtendimento === FasesAtendimentoEnum.FASEREGULACAO;
      if (faseAtendimentoEhRegulacao) return processoDeAtendimentoEmAndamentoValido;
      else return faseAtendimentoEhRegulacao;
    }

    return processoDeAtendimentoEmAndamentoValido;
  }

  function podeEncaminharPaciente(paciente: InformacaoPacienteFormModel) {
    const usuarioNaoPertenceAoAtendimento =
      paciente.faseRegulacaoResponsavelId !== userInfo.data?.usuarioId &&
      paciente.faseCriacaoResponsavelId !== userInfo.data?.usuarioId &&
      paciente.faseEmpenhoResponsavelId !== userInfo.data?.usuarioId;
    const processoDeAtendimentoEmAndamentoValido =
      paciente.statusPaciente === StatusAtendimentoEnum.EM_ANDAMENTO ||
      paciente.statusPaciente === StatusAtendimentoEnum.AGENDADO;

    if (usuarioNaoPertenceAoAtendimento) return !usuarioNaoPertenceAoAtendimento;

    if (
      userInfo.data?.faseAtendimento.find((x) => x === FasesAtendimentoEnum.FASECRIACAO) &&
      paciente.faseAtendimento == FasesAtendimentoEnum.FASECRIACAO &&
      paciente.faseCriacaoResponsavelId === userInfo.data?.usuarioId
    ) {
      return processoDeAtendimentoEmAndamentoValido;
    }

    if (
      userInfo.data?.faseAtendimento.find((x) => x === FasesAtendimentoEnum.FASEREGULACAO) &&
      paciente.faseAtendimento == FasesAtendimentoEnum.FASEREGULACAO &&
      paciente.faseRegulacaoResponsavelId === userInfo.data?.usuarioId
    ) {
      if (paciente.equipes.length > 0) return processoDeAtendimentoEmAndamentoValido;
      else return false;
    }

    return false;
  }

  function podeIncluirNovoPaciente() {
    if (usuarioNaoPertenceAoAtendimento) return !usuarioNaoPertenceAoAtendimento;

    if (
      userInfo.data?.faseAtendimento.some(
        (x) => x === FasesAtendimentoEnum.FASECRIACAO || x === FasesAtendimentoEnum.FASEREGULACAO
      )
    ) {
      if (
        formValues.pacientes.some(
          (x) =>
            (x.faseAtendimento === FasesAtendimentoEnum.FASECRIACAO ||
              x.faseAtendimento === FasesAtendimentoEnum.FASEREGULACAO) &&
            (x.statusPaciente === StatusAtendimentoEnum.EM_ANDAMENTO ||
              x.statusPaciente === StatusAtendimentoEnum.AGENDADO)
        )
      ) {
        return true;
      } else {
        return false;
      }
    }
  }

  function podeEditarProntuario(paciente: InformacaoPacienteFormModel) {
    const usuarioPossuiFaseAtendimento = userInfo.data?.faseAtendimento.some(
      (x) => x === FasesAtendimentoEnum.FASEREGULACAO || x === FasesAtendimentoEnum.FASEEMPENHO
    );
    if (!usuarioPossuiFaseAtendimento) {
      return usuarioPossuiFaseAtendimento;
    }
    // Somente médico regulador para fazer esse tipo de alteração
    if (paciente.faseRegulacaoResponsavelId === userInfo.data?.usuarioId) {
      return (
        paciente.statusPaciente !== StatusAtendimentoEnum.CANCELADO &&
        paciente.statusPaciente !== StatusAtendimentoEnum.ENCERRADO
      );
    } else {
      return false;
    }
  }

  function podeCancelarVeiculo(equipe: EquipeModel, paciente: InformacaoPacienteFormModel) {
    if (paciente.faseEmpenhoResponsavelId !== userInfo.data?.usuarioId) return false;
    const podeCancelarVeiculo =
      equipe.veiculoId &&
      equipe.timelineVeiculo &&
      equipe.timelineVeiculo.length > 0 &&
      (paciente.faseAtendimento === FasesAtendimentoEnum.FASEEMPENHO ||
        paciente.faseAtendimento === FasesAtendimentoEnum.FASEATENDIMENTO ||
        paciente.faseAtendimento === FasesAtendimentoEnum.ADMIN);

    return podeCancelarVeiculo;
  }

  async function encaminharPaciente(
    request: EncaminharRequest,
    faseAtendimento: FasesAtendimentoEnum | null
  ) {
    try {
      setSalvarAtendimentoIsLoading(true);
      switch (faseAtendimento) {
        case FasesAtendimentoEnum.FASECRIACAO:
          await encaminhar(request);
          definirFaseAtendimento(request.pacienteId ?? '', FasesAtendimentoEnum.FASEREGULACAO);
          definirResponsavelRegulacao(request.pacienteId ?? '', request.usuarioId ?? '');
          break;
        case FasesAtendimentoEnum.FASEREGULACAO:
          await encaminharParaRadioOperador(request);
          definirFaseAtendimento(request.pacienteId ?? '', FasesAtendimentoEnum.FASEEMPENHO);
          definirResponsavelEmpenho(request.pacienteId ?? '', request.usuarioId ?? '');
          break;

        default:
          enqueueSnackbar('Perfil Acesso inválido!', {
            variant: 'error'
          });
          break;
      }
      enqueueSnackbar('Encaminhamento realizado com sucesso!', {
        variant: 'success'
      });
    } finally {
      setSalvarAtendimentoIsLoading(false);
    }
  }

  async function salvarProntuario(value: AdicionarProntuarioRequest) {
    try {
      setSalvarAtendimentoIsLoading(true);
      await adicionarProntuario(value);
    } finally {
      setSalvarAtendimentoIsLoading(false);
    }
  }

  function podeAlterarTarm(paciente: InformacaoPacienteFormModel) {
    return (
      paciente.statusPaciente === StatusAtendimentoEnum.EM_ANDAMENTO ||
      paciente.statusPaciente === StatusAtendimentoEnum.AGENDADO ||
      paciente.statusPaciente === StatusAtendimentoEnum.PENDENTE
    );
  }

  function podeAlterarMedico(paciente: InformacaoPacienteFormModel) {
    return (
      (paciente.faseAtendimento === FasesAtendimentoEnum.FASEREGULACAO ||
        paciente.faseAtendimento === FasesAtendimentoEnum.FASEATENDIMENTO ||
        paciente.faseAtendimento === FasesAtendimentoEnum.FASEEMPENHO) &&
      (paciente.statusPaciente === StatusAtendimentoEnum.EM_ANDAMENTO ||
        paciente.statusPaciente === StatusAtendimentoEnum.AGENDADO ||
        paciente.statusPaciente === StatusAtendimentoEnum.PENDENTE)
    );
  }

  function podeAlterarRadio(paciente: InformacaoPacienteFormModel) {
    return (
      (paciente.faseAtendimento === FasesAtendimentoEnum.FASEEMPENHO ||
        paciente.faseAtendimento === FasesAtendimentoEnum.FASEATENDIMENTO) &&
      (paciente.statusPaciente === StatusAtendimentoEnum.EM_ANDAMENTO ||
        paciente.statusPaciente === StatusAtendimentoEnum.AGENDADO ||
        paciente.statusPaciente === StatusAtendimentoEnum.PENDENTE)
    );
  }

  function podeConcluirRemocao(paciente: InformacaoPacienteFormModel) {
    return (
      paciente.faseEmpenhoResponsavelId === userInfo.data?.usuarioId &&
      paciente.faseAtendimento === FasesAtendimentoEnum.FASEEMPENHO
    );
  }

  return {
    adicionarPacienteHandler,
    removerPacienteHandler,
    cancelarChamadoHandler,
    encerrarChamadoHandler,
    alterarResponsavelHandler,
    podeEditarEndereco,
    assumirPacienteHandler,
    desabilitarSelectVeiculo,
    editarVeiculo,
    marcarStep,
    confirmarMarcarcaoStep,
    concluirRemocaoHandler,
    cancelarAmbulanciaHandler,
    encaminharVeiculoHandler,
    handleCloseMapsModal,
    handleOpenMapsModal,
    handleChange,
    editarPacienteHandler,
    cancelarMarcarStep,
    encaminharPaciente,
    salvarProntuario,
    state: {
      userInfo,
      form,
      formValues,
      control,
      center,
      fields,
      stepSelecionado,
      confirmandoMarcarStep,
      expanded,
      salvarAtendimentoIsLoading,
      openMapsModal,
      posicoesMapa
    },
    desabilitarFormularioAtendimento,
    desabilitarFormAgendamento,
    desabilitarFormPaciente,
    desabilitarTipoVeiculo,
    podeRemoverPaciente,
    podeAlterarResponsavel,
    podeEncerrarChamado,
    podeAssumirPaciente,
    podeCancelarChamado,
    podeEncaminharPaciente,
    podeIncluirNovoPaciente,
    podeEditarProntuario,
    podeCancelarVeiculo,
    podeAlterarRadio,
    podeAlterarMedico,
    podeAlterarTarm,
    podeConcluirRemocao,
    hasAccess,
    renderTimeline,
    atualizarNotificacaoPaciente
  };
};

export interface AssumirPacienteValidadtion {
  processoDeAtendimentoEmAndamentoValido: boolean;
  podeAssumirCriacao: boolean;
  podeAssumirRegulacao: boolean;
  podeAssumirEmpenho: boolean;
}
