export enum FasesAtendimentoEnum {
  ADMIN = 0,
  FASECRIACAO = 1,
  FASEREGULACAO = 2,
  FASEEMPENHO = 3,
  FASEATENDIMENTO = 4,
  Nenhum = 10
}

export const FasesAtendimentoEnumLabel = new Map<number, string>([
  [FasesAtendimentoEnum.ADMIN, 'ADMIN'],
  [FasesAtendimentoEnum.FASECRIACAO, 'Fase Criação'],
  [FasesAtendimentoEnum.FASEREGULACAO, 'Fase Regulação'],
  [FasesAtendimentoEnum.FASEEMPENHO, 'Fase Empenho'],
  [FasesAtendimentoEnum.FASEATENDIMENTO, 'Fase Atendimento'],
  [FasesAtendimentoEnum.Nenhum, 'Nenhum']
]);

export const ListaFasesAtendimentoEnumLabel = [
  {
    id: FasesAtendimentoEnum.ADMIN,
    descricao: FasesAtendimentoEnumLabel.get(FasesAtendimentoEnum.ADMIN)
  },
  {
    id: FasesAtendimentoEnum.FASECRIACAO,
    descricao: FasesAtendimentoEnumLabel.get(FasesAtendimentoEnum.FASECRIACAO)
  },
  {
    id: FasesAtendimentoEnum.FASEREGULACAO,
    descricao: FasesAtendimentoEnumLabel.get(FasesAtendimentoEnum.FASEREGULACAO)
  },
  {
    id: FasesAtendimentoEnum.FASEEMPENHO,
    descricao: FasesAtendimentoEnumLabel.get(FasesAtendimentoEnum.FASEEMPENHO)
  },
  {
    id: FasesAtendimentoEnum.FASEATENDIMENTO,
    descricao: FasesAtendimentoEnumLabel.get(FasesAtendimentoEnum.FASEATENDIMENTO)
  },
  {
    id: FasesAtendimentoEnum.Nenhum,
    descricao: FasesAtendimentoEnumLabel.get(FasesAtendimentoEnum.Nenhum)
  }
];
