import api, { AxiosRequestConfig } from 'axios';

import { UserInfo } from '../../shared/msal/UserInfo';
import { ApiResponse } from '../../shared/types/ApiResponse';
import { BaseConfig } from '../../shared/utils/baseConfig';

export function useAuthenticationService() {
  const baseApi = BaseConfig.baseApiUrl;

  async function getUserInfo(token?: string, localId?: string) {
    const config: AxiosRequestConfig = {};
    config.headers = {};

    config.headers.Authorization = `Bearer ${token}`;
    config.headers['Local-Id'] = localId;

    const result = await api.get<ApiResponse<UserInfo>>(
      `${baseApi}/api/usuario/token/info`,
      config
    );
    return result.data.data;
  }

  return { getUserInfo };
}
