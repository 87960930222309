import { mdiHistory } from '@mdi/js';
import Icon from '@mdi/react';
import { Badge, BadgeProps, IconButton, Tooltip, styled } from '@mui/material';
import { OptLoading, OptTimeline, OptTimelineAction } from '@optsol/react';
import { useEffect, useState } from 'react';

import { Colors } from '../../../../core/colors';
import { InformacaoPacienteFormModel } from '../../../../models/Atendimento/Paciente/InformacaoPaciente.model';
import { useAtendimentoService } from '../../../../service/atendimento.service';
import { Modal } from '../../../components/Modal/Modal';
import { useAuthenticationContext } from '../../../contexts/authentication/authenticationContext';

interface Props {
  atendimentoId: string;
  paciente: InformacaoPacienteFormModel;
  notificacoesNaoLidas: number;
  atualizarNotificacaoPaciente: (
    pacienteIdP: string | undefined,
    quantidadeNaoLida: number | undefined,
    userId: string | undefined
  ) => void;
}

export const HistoricoProntuarioButton = ({
  atendimentoId,
  paciente,
  notificacoesNaoLidas,
  atualizarNotificacaoPaciente
}: Props) => {
  const { buscarHistoricoProntuarios, marcarNotificacaoProntuarioLida } = useAtendimentoService();
  const toggleHistoricoProntuarioModal = (pacienteId: string) => {
    setModalPacienteId(pacienteId);
    setHistoricoProntuarioIsOpen((prevState) => !prevState);
  };

  const [modalPacienteId, setModalPacienteId] = useState<string>('');
  const [historicoProntuarioIsOpen, setHistoricoProntuarioIsOpen] = useState<boolean>(false);
  const [timeLineData, setTimeLineData] = useState<OptTimelineAction[]>();

  const {
    state: { userInfo }
  } = useAuthenticationContext();

  useEffect(() => {
    if (historicoProntuarioIsOpen && !timeLineData) {
      const obter = async () => {
        const historyData = await buscarHistoricoProntuarios(modalPacienteId);
        setTimeLineData(historyData.data);
        if (paciente?.quantidadeNotificacaoNaoLida && paciente?.quantidadeNotificacaoNaoLida > 0) {
          marcarNotificacaoProntuarioLida(atendimentoId, paciente.pacienteId ?? '');
          atualizarNotificacaoPaciente(paciente.pacienteId ?? '', 0, userInfo.data?.usuarioId);
        }
      };
      obter();
    } else {
      setTimeout(() => {
        setTimeLineData(undefined);
      }, 300);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [historicoProntuarioIsOpen]);
  const StyledBadge = styled(Badge)<BadgeProps>(() => ({
    '& .MuiBadge-badge': {
      right: -3,
      top: 4,
      border: `none`,
      padding: '0 4px',
      backgroundColor: Colors.red1,
      color: 'white'
    }
  }));
  return (
    <>
      <Tooltip title={'Histórico de prontuários'} placement="top">
        <IconButton
          color="info"
          onClick={(e) => {
            e.stopPropagation();
            toggleHistoricoProntuarioModal(paciente.pacienteId ?? '');
          }}
        >
          <StyledBadge badgeContent={notificacoesNaoLidas} color="secondary">
            <Icon path={mdiHistory} size={1} color={Colors.black} />
          </StyledBadge>
        </IconButton>
      </Tooltip>
      <Modal
        open={historicoProntuarioIsOpen && modalPacienteId === paciente.pacienteId}
        onClose={() => {
          toggleHistoricoProntuarioModal(paciente.pacienteId ?? '');
        }}
        title="Histórico de prontuários"
        width="750px"
      >
        <div style={{ marginTop: '1em' }}>
          {timeLineData && (
            <OptTimeline
              data={timeLineData ?? []}
              valuesTableOptions={{
                nameHeader: 'Name',
                valueHeader: 'Value'
              }}
            />
          )}

          {!timeLineData && (
            <div
              style={{
                height: '100px',
                overflow: 'none'
              }}
            >
              <OptLoading size={50} />
            </div>
          )}
        </div>
      </Modal>
    </>
  );
};
