export enum TipoVeiculo {
  USB = 0,
  USA = 1,
  VR = 2,
  CarroAdministrativo = 3
}

export const TipoVeiculoEnumLabel = new Map<number, string>([
  [TipoVeiculo.USB, 'Unidade Suporte Básica - USB'],
  [TipoVeiculo.USA, 'Unidade Suporte Avançada - USA'],
  [TipoVeiculo.VR, ' Veículo rápido - VR'],
  [TipoVeiculo.CarroAdministrativo, 'Carro administrativo - CA']
]);
export const TipoVeiculoSiglaEnumLabel = new Map<number, string>([
  [TipoVeiculo.USB, 'USB'],
  [TipoVeiculo.USA, 'USA'],
  [TipoVeiculo.VR, 'VR'],
  [TipoVeiculo.CarroAdministrativo, 'CA']
]);

export const ListaTipoVeiculoEnum = [
  {
    id: TipoVeiculo.USB,
    descricao: TipoVeiculoEnumLabel.get(TipoVeiculo.USB)
  },
  {
    id: TipoVeiculo.USA,
    descricao: TipoVeiculoEnumLabel.get(TipoVeiculo.USA)
  },
  {
    id: TipoVeiculo.VR,
    descricao: TipoVeiculoEnumLabel.get(TipoVeiculo.VR)
  },
  {
    id: TipoVeiculo.CarroAdministrativo,
    descricao: TipoVeiculoEnumLabel.get(TipoVeiculo.CarroAdministrativo)
  }
];
