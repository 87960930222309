import { yupResolver } from '@hookform/resolvers/yup';
import { mdiClipboardTextClockOutline } from '@mdi/js';
import {
  OptActionToolbar,
  OptBackdrop,
  OptLoading,
  OptSideLayoutContent,
  OptTimeline,
  OptTimelineAction
} from '@optsol/react';
import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';

import {
  ClienteFormModel,
  DADOS_CLIENTE_DEFAULT,
  dadosClienteFormModelSchema
} from '../../../models/Cliente/Cliente.model';
import { ContatoModel, CriarContatoModel } from '../../../models/Contato/Contato.model';
import { AtualizarClienteRequest } from '../../../models/dtos/Cliente/atualizarClienteRequest.model';
import { ContatoResponse } from '../../../models/dtos/Cliente/Contato/contatoResponse.model';
import { CriarContatoRequest } from '../../../models/dtos/Cliente/Contato/criarContatoRequest.model';
import { ExcluirContatoRequest } from '../../../models/dtos/Cliente/Contato/excluirContatoRequest.model';
import { EnderecoResponse } from '../../../models/dtos/Cliente/Endereco/enderecoResponse.model';
import { ExcluirEnderecoRequest } from '../../../models/dtos/Cliente/Endereco/excluirEnderecoRequest.model';
import { PlanoSearchResponse } from '../../../models/dtos/Plano/planoSearchResponse.model';
import { EnderecoModel } from '../../../models/Endereco/Endereco.model';
import { MenuClienteClaims } from '../../../models/enums/AcessoClaims';
import { InformacoesPlanoModel } from '../../../models/Plano/Plano.model';
import { RoutesObj } from '../../../routes/index.routes';
import { useClienteService } from '../../../service/cliente.service';
import { useHistoricoService } from '../../../service/historico.service';
import { ApiResponse } from '../../../shared/types/ApiResponse';
import { validarCNPJ, validarCPF } from '../../../shared/utils/functions';
import { BotaoDeAcaoToolbar } from '../../components/Button/BotaoDeAcaoToolbar';
import { Modal } from '../../components/Modal/Modal';
import { ProtectedContent } from '../../components/ProtectedContent/ProtectedContent';
import { ToolbarContainer } from '../../components/ToolbarContainer';
import { useAuthenticationContext } from '../../contexts/authentication/authenticationContext';

import FormEditarCliente from './FormEditarCliente';

const EditarCliente = () => {
  const form = useForm<ClienteFormModel>({
    mode: 'onBlur',
    defaultValues: DADOS_CLIENTE_DEFAULT,
    resolver: yupResolver(dadosClienteFormModelSchema)
  });

  const {
    obterClienteById,
    atualizarCliente,
    salvarEndereco,
    alterarEndereco,
    excluirEndereco,
    salvarContato,
    alterarContato,
    excluirContato
  } = useClienteService();
  const [enderecos, setEnderecos] = useState<EnderecoModel[]>([]);
  const [defaultPlano, setDefaultPlano] = useState<PlanoSearchResponse | null>(null);
  const [defaultInformacoesPlano, setDefaultInformacoesPlano] =
    useState<InformacoesPlanoModel | null>(null);
  const [contatos, setContatos] = useState<ContatoModel[]>([]);
  const [clienteIsLoading, setClienteIsLoading] = useState<boolean>(false);
  const [contatoIsloading, setContatoIsloading] = useState<boolean>(false);
  const [enderecoIsloading, setEnderecoIsloading] = useState<boolean>(false);
  const [salvarClienteIsLoading, setSalvarClienteIsLoading] = useState<boolean>(false);
  const [modalHistorico, setModalHistorico] = useState<boolean>(false);
  const [timeLineData, setTimeLineData] = useState<OptTimelineAction[]>();
  const { obterHistoryEntidade } = useHistoricoService();

  const { id } = useParams();
  const { state } = useAuthenticationContext();

  async function obterCliente() {
    try {
      if (!id) return;
      setClienteIsLoading(true);
      const result = await obterClienteById(id ?? '');
      form.reset({
        ...result.data,
        cpfCnpj: result.data.cpf ? result.data.cpf : result.data.cnpj,
        situacao: result.data.situacao
      });
      setEnderecos(result.data?.enderecos ?? []);
      setContatos(tranformContato(result.data?.contatos ?? []) ?? []);

      if (result.data?.plano) {
        setDefaultPlano({ ...result.data.plano });
      } else {
        setDefaultPlano(null);
      }

      if (result.data.informacoesPlano) {
        setDefaultInformacoesPlano({ ...result.data?.informacoesPlano });
      } else {
        setDefaultInformacoesPlano(null);
      }
    } finally {
      setClienteIsLoading(false);
    }
  }

  function tranformContato(contatos: ContatoModel[]) {
    return contatos.map((contato) => ({
      telefone: contato.telefoneFixo ? contato.telefoneFixo : contato.telefoneCelular,
      ...contato
    }));
  }

  const editarCliente = async () => {
    try {
      setSalvarClienteIsLoading(true);
      const guidRegex = new RegExp(
        '^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$',
        'i'
      );
      if (!id) return;

      const clienteModel = form.getValues();
      const documento = clienteModel.cpfCnpj.trim();

      if (!validarCPF(documento) && !validarCNPJ(documento)) return;

      const request: AtualizarClienteRequest = {
        cpf: documento.length === 14 ? documento : null,
        cnpj: documento.length === 18 ? documento : null,
        nome: clienteModel.nome,
        situacao: clienteModel.situacao,
        planoId: guidRegex.test(clienteModel.planoId ?? '') ? clienteModel.planoId : null,
        dataNascimento: clienteModel.dataNascimento,
        sexo: clienteModel.sexo,
        codigoCliente: clienteModel.codigoCliente,
        informacaoImportante: clienteModel.informacaoImportante
      };

      await atualizarCliente(id ?? '', request);

      form.reset(form.getValues());
    } finally {
      setSalvarClienteIsLoading(false);
    }
  };

  const salvarEnderecoHandler = async (enderecoModel: EnderecoModel) => {
    try {
      setEnderecoIsloading(true);
      if (!id) return;

      let result: ApiResponse<EnderecoResponse>;
      const estaEditando = !enderecoModel?.enderecoId ? false : true;
      if (!estaEditando) {
        result = await salvarEndereco(id, enderecoModel);
      } else {
        result = await alterarEndereco(id, enderecoModel);
      }

      const novaLista = atualizarlistaEndereco(result, estaEditando);
      setEnderecos(novaLista);
    } finally {
      setEnderecoIsloading(false);
    }
  };

  function atualizarlistaEndereco(
    { data }: ApiResponse<EnderecoResponse>,
    estaEditando: boolean
  ): EnderecoModel[] {
    const novosEndereco = [...enderecos];
    const novoEndereco: EnderecoModel = {
      ...data
    };
    if (!estaEditando) {
      novosEndereco.unshift(novoEndereco);
      return novosEndereco;
    } else {
      return novosEndereco.map((x) => {
        if (x.enderecoId === novoEndereco.enderecoId) {
          x = novoEndereco;
          return x;
        }
        return x;
      });
    }
  }

  const excluirEnderecoHandler = async (enderecoId: string) => {
    try {
      setEnderecoIsloading(true);
      if (!id) return;
      const request: ExcluirEnderecoRequest = {
        clienteId: id ?? '',
        enderecoId: enderecoId
      };
      await excluirEndereco(request);
      setEnderecos(enderecos.filter((x) => x.enderecoId !== enderecoId));
    } finally {
      setEnderecoIsloading(false);
    }
  };

  const salvarContatoHandler = async (contatoModel: CriarContatoModel) => {
    try {
      setContatoIsloading(true);
      if (!id) return;

      const telefone = contatoModel?.telefone
        ?.replace(/[^\w\s]/gi, '')
        .replace(' ', '')
        .trim();
      const request: CriarContatoRequest = {
        clienteId: id ?? '',
        email: contatoModel.email,
        telefoneCelular: telefone?.length === 11 ? telefone : null,
        telefoneFixo: telefone?.length === 10 ? telefone : null,
        contatoId: contatoModel.contatoId,
        nomeContato: contatoModel.nomeContato,
        cpf: contatoModel.cpf ?? ''
      };
      let result: ApiResponse<ContatoResponse>;
      const estaEditando = !contatoModel?.contatoId ? false : true;
      if (!estaEditando) {
        result = await salvarContato(request);
      } else {
        result = await alterarContato(request);
      }

      const novaLista = atualizarlistaContato(result, estaEditando);
      setContatos(tranformContato(novaLista));
    } finally {
      setContatoIsloading(false);
    }
  };

  function atualizarlistaContato(
    { data }: ApiResponse<ContatoResponse>,
    estaEditando: boolean
  ): ContatoResponse[] {
    const novosContatos = [...contatos];
    const novoContato: ContatoResponse = {
      ...data
    };
    if (!estaEditando) {
      novosContatos.unshift(novoContato);
      return novosContatos;
    } else {
      return novosContatos.map((x) => {
        if (x.contatoId === novoContato.contatoId) {
          x = novoContato;
          return x;
        }
        return x;
      });
    }
  }

  const excluirContatoHandler = async (contatoId: string) => {
    try {
      setContatoIsloading(true);
      if (!id) return;
      const request: ExcluirContatoRequest = {
        clienteId: id ?? '',
        contatoId: contatoId
      };
      await excluirContato(request);
      setContatos(tranformContato(contatos.filter((x) => x.contatoId !== contatoId)));
    } finally {
      setContatoIsloading(false);
    }
  };

  useEffect(() => {
    if (modalHistorico && !timeLineData) {
      const obter = async () => {
        try {
          const historyData = await obterHistoryEntidade(
            'Cliente',
            state.userInfo.data?.tenantId ?? '', //"712A7D07-A57C-4F78-8F9F-66DDF61A44C0",
            id as string
          );

          const data = historyData.versions.sort((a, b) => (a.order > b.order ? -1 : 1));
          data.map((history: { dateTimeAction: string | number | Date }) => {
            const newDate = new Date(history.dateTimeAction);
            const dataFormatada = new Intl.DateTimeFormat('pt-BR', {
              year: 'numeric',
              month: '2-digit',
              day: '2-digit',
              hour: '2-digit',
              hourCycle: 'h23',
              minute: '2-digit'
            }).format(newDate);
            return (history.dateTimeAction = dataFormatada);
          });
          setTimeLineData(data);
        } catch {
          console.log('Erro ao tentar carregar timeline values');
          toggleModal();
        }
      };
      obter();
    } else {
      setTimeout(() => {
        setTimeLineData(undefined);
      }, 300);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalHistorico]);

  const toggleModal = async () => {
    setModalHistorico((prevState) => !prevState);
  };

  useEffect(() => {
    obterCliente();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <OptSideLayoutContent>
        <ToolbarContainer>
          <OptActionToolbar
            goBackRoute={window.history.state.usr?.prevUrl ?? RoutesObj.Cliente.Clientes}
            title="Editar cliente"
            color={state.tema.light?.primary}
            clearMargin
          >
            <ProtectedContent access={MenuClienteClaims.VISUALIZAR}>
              <BotaoDeAcaoToolbar
                onClick={toggleModal}
                texto="Histórico"
                startIcon={mdiClipboardTextClockOutline}
              />
            </ProtectedContent>
          </OptActionToolbar>
        </ToolbarContainer>
        <Modal open={modalHistorico} onClose={toggleModal} title="Histórico" width="800px">
          <div style={{ marginTop: '1em' }}>
            {timeLineData && (
              <OptTimeline
                data={timeLineData ?? []}
                valuesTableOptions={{
                  nameHeader: 'Name',
                  valueHeader: 'Value'
                }}
              />
            )}
            {!timeLineData && (
              <div style={{ height: '100px', overflow: 'none' }}>
                <OptLoading size={50} />
              </div>
            )}
          </div>
        </Modal>

        {!clienteIsLoading && (
          <FormProvider {...form}>
            <FormEditarCliente
              defaultPlano={defaultPlano}
              defaultInformacoesPlano={defaultInformacoesPlano}
              enderecos={enderecos}
              contatos={contatos}
              editarCliente={editarCliente}
              salvarEndereco={salvarEnderecoHandler}
              salvarContato={salvarContatoHandler}
              excluirEndereco={excluirEnderecoHandler}
              excluirContato={excluirContatoHandler}
              clienteIsLoading={clienteIsLoading}
              enderecoIsLoading={enderecoIsloading}
              contatoIsLoading={contatoIsloading}
            />
          </FormProvider>
        )}
        <OptBackdrop open={salvarClienteIsLoading} />
      </OptSideLayoutContent>
    </>
  );
};

export default EditarCliente;
