import { Route, Routes as ReactRouterRoutes } from 'react-router-dom';

import { useAuthenticationContext } from '../app/contexts/authentication/authenticationContext';
import EditarPlano from '../app/features/plano/EditarPlano';
import Home from '../app/pages/home/Home';
import Planos from '../app/pages/planos/Planos';
import { MenuPlanoClaims } from '../models/enums/AcessoClaims';

export const PlanoRoutes = () => {
  const { hasAccess } = useAuthenticationContext();
  const enableRoute = hasAccess(MenuPlanoClaims.VISUALIZAR);
  return (
    <ReactRouterRoutes>
      <Route path="/" element={enableRoute ? <Planos /> : <Home />} />
      <Route path="/plano/:id" element={enableRoute ? <EditarPlano /> : <Home />} />
    </ReactRouterRoutes>
  );
};
