import { mdiNavigationVariant } from '@mdi/js';
import {
  OptBackdrop,
  OptGrid,
  OptGridColumn,
  OptGridOptions,
  OptGridRef,
  OptLoading,
  OptSideLayoutContent
} from '@optsol/react';
import { forwardRef, useCallback, useEffect, useState } from 'react';

import { UsuarioSearchResponseModel } from '../../../models/dtos/Usuario/usuarioSearchResponse.model';
import { FasesAtendimentoEnum } from '../../../models/enums/FasesAtendimento';
import { PerfilAcessoEnum } from '../../../models/enums/PerfilAcesso';
import { useUsuarioService } from '../../../service/usuario.service';
import { PaginatedSearchRequest, SearchRequest } from '../../../shared/utils/searchRequest';
import * as S from '../UsuarioStatusList/styles/index';

interface Props {
  encaminhar: (usuarioId: string) => Promise<void>;
  perfil?: PerfilAcessoEnum;
  supervisorAlterando?: boolean;
  usuarioResponsavelId?: string;
  pacienteId: string;
  faseAtendimento?: FasesAtendimentoEnum | null;
}

const options: OptGridOptions = {
  search: true,
  pageSize: 10
};

const GridEncaminharUsuario = forwardRef<OptGridRef | undefined, Props>(
  (
    { encaminhar, perfil, supervisorAlterando, usuarioResponsavelId, pacienteId, faseAtendimento },
    ref
  ) => {
    const [usuarios, setUsuarios] = useState<UsuarioSearchResponseModel[]>([]);
    const [dataLoading, setDataLoading] = useState(false);
    const [encaminharLoading, setEncaminharLoading] = useState(false);

    const { buscarUsuarioEncaminhar } = useUsuarioService();
    const obterUsuariosHandler = useCallback(
      async (termo = '') => {
        const request: SearchRequest<PaginatedSearchRequest> = {
          page: 0,
          pageSize: 20,
          search: {
            termoBuscado: termo,
            perfilAcesso: perfil,
            usuarioResponsavelId: usuarioResponsavelId,
            pacienteId: pacienteId,
            supervisorAlterando: supervisorAlterando,
            faseAtendimentoParaAlteracao: faseAtendimento
          }
        };

        const result = await buscarUsuarioEncaminhar(request);
        setUsuarios(result.data);
      },
      [buscarUsuarioEncaminhar]
    );

    async function encaminharHandler(usuarioId: string) {
      try {
        setEncaminharLoading(true);
        await encaminhar(usuarioId);
      } finally {
        setEncaminharLoading(false);
      }
    }

    async function obterUsuariosHandlerAync() {
      try {
        setDataLoading(true);
        await obterUsuariosHandler();
      } finally {
        setDataLoading(false);
      }
    }

    useEffect(() => {
      obterUsuariosHandlerAync();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const columns: OptGridColumn<UsuarioSearchResponseModel>[] = [
      {
        title: 'Id',
        field: 'usuarioId',
        hidden: true
      },
      {
        title: 'Status',
        field: 'status',
        hidden: true
      },
      {
        title: 'Última Atualização',
        field: 'ultimaAtualizacao',
        hidden: true
      },
      {
        title: 'Nome',
        field: 'nome',
        render: (props: UsuarioSearchResponseModel) => {
          return (
            <div>
              <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                {props.status && <S.CircleDotRed status={props.status} />}
                <span>{props.nome}</span>
              </div>
              {props.ultimaAtualizacao && (
                <span style={{ fontSize: '12px', color: 'gray' }}>{props.ultimaAtualizacao}</span>
              )}
            </div>
          );
        }
      },
      {
        title: 'Em andamento',
        field: 'totalChamadosEmAndamento',
        align: 'left'
      }
    ];
    return (
      <OptSideLayoutContent>
        {dataLoading ? (
          <OptLoading color="primary" size={50} />
        ) : (
          <OptGrid
            ref={ref as any}
            data={usuarios}
            options={options}
            columns={columns}
            actionsPosition={'end'}
            actions={[
              (rowData) => ({
                icon: { path: mdiNavigationVariant },
                tooltip: 'Encaminhar',
                onClick: () => encaminharHandler(rowData.usuarioId),
                disabled: false
              })
            ]}
          />
        )}
        <OptBackdrop open={encaminharLoading} />
      </OptSideLayoutContent>
    );
  }
);

GridEncaminharUsuario.displayName = 'GridEncaminharUsuario';
export default GridEncaminharUsuario;
