import * as yup from 'yup';

import { validarCPF } from '../../../shared/utils/functions';
import { CidModel } from '../../Cid/Cid.model';
import { EnderecoModel } from '../../Endereco/Endereco.model';
import { dadosEnderecoGeoLocalizacaoFormModelSchema } from '../../Endereco/EnderecoGeoLocalizacao.mode';
import { ClassificacaoUrgenciaEnum } from '../../enums/ClassificacaoUrgencia';
import { FasesAtendimentoEnum } from '../../enums/FasesAtendimento';
import { StatusAtendimentoEnum } from '../../enums/StatusAtendimento';
import { dadosEquipeModelSchema, EquipeModel } from '../Equipe/Equipe.model';

import { Sexo } from './../../enums/Sexo';
import { TipoVeiculo } from './../../enums/TipoVeiculo';

export interface InformacaoPacienteFormModel {
  pacienteId?: string | null;
  atendimentoId?: string | null;
  concluirRemocao?: boolean;
  nome?: string | null;
  cpf?: string | null;
  idade?: number | null;
  sexo?: Sexo | null;
  enderecoOrigem: EnderecoModel | null;
  enderecoDestino: EnderecoModel | null;
  informacaoProntuario?: string | null;
  statusPaciente?: StatusAtendimentoEnum | null;
  tipoVeiculo?: TipoVeiculo | null;
  urgencia?: ClassificacaoUrgenciaEnum | null;
  obito?: boolean | null;
  dataHoraObito?: string | null;
  somenteIda?: boolean | null;
  veiculoId?: string | null;
  equipes: EquipeModel[];
  faseAtendimento: FasesAtendimentoEnum | null;
  faseAtendimentoDescricao?: string | null;
  faseAtendimentoSigla?: string | null;
  prontuarios?: any[];
  faseCriacaoResponsavelId?: string;
  faseEmpenhoResponsavelId?: string;
  faseRegulacaoResponsavelId?: string;
  informarJustificativa?: boolean | null;
  cid?: CidModel | null;
  quantidadeNotificacaoNaoLida?: number | null;
}

export interface PacienteModel {
  pacienteId?: string | null;
  atendimentoId?: string | null;
  nome?: string | null;
  cpf?: string | null;
  idade?: number | null;
  sexo?: Sexo | null;
  enderecoOrigem: EnderecoModel | null;
  enderecoDestino: EnderecoModel | null;
}

export const LISTA_PACIENTE_DEFAULT: InformacaoPacienteFormModel[] = [
  {
    nome: '',
    enderecoDestino: null,
    enderecoOrigem: null,
    atendimentoId: '',
    concluirRemocao: false,
    cpf: '',
    idade: null,
    pacienteId: '',
    sexo: null,
    informacaoProntuario: '',
    statusPaciente: StatusAtendimentoEnum.EM_ANDAMENTO,
    tipoVeiculo: null,
    urgencia: ClassificacaoUrgenciaEnum.Emergencia,
    obito: false,
    dataHoraObito: '',
    somenteIda: false,
    veiculoId: '',
    equipes: [],
    faseAtendimento: null,
    faseAtendimentoDescricao: null,
    faseAtendimentoSigla: null,
    prontuarios: [],
    faseCriacaoResponsavelId: '',
    faseEmpenhoResponsavelId: '',
    faseRegulacaoResponsavelId: '',
    informarJustificativa: null,
    cid: null,
    quantidadeNotificacaoNaoLida: 0
  }
];

export const DADOS_INFORMACAO_PACIENTE_DEFAULT: InformacaoPacienteFormModel = {
  pacienteId: '',
  atendimentoId: '',
  cpf: '',
  sexo: null,
  concluirRemocao: false,
  idade: null,
  nome: '',
  enderecoDestino: null,
  enderecoOrigem: null,
  informacaoProntuario: '',
  statusPaciente: null,
  tipoVeiculo: null,
  urgencia: ClassificacaoUrgenciaEnum.Emergencia,
  obito: false,
  dataHoraObito: '',
  somenteIda: false,
  veiculoId: '',
  equipes: [],
  faseAtendimento: null,
  faseAtendimentoDescricao: null,
  faseAtendimentoSigla: null,
  prontuarios: [],
  cid: null,
  quantidadeNotificacaoNaoLida: 0
};
const MSG_REQUIRED = 'Campo obrigatório';

export const dadosInformacaoPacienteFormModelSchema: yup.SchemaOf<InformacaoPacienteFormModel> = yup
  .object()
  .shape({
    pacienteId: yup.string().notRequired().nullable(),
    atendimentoId: yup.string().notRequired().nullable(),
    nome: yup.string().notRequired().nullable(),
    cpf: yup
      .string()
      .notRequired()
      .test('Teste documento', 'Documento inválido', (value) => {
        if (!value) return false;
        return validarCPF(value);
      }),
    sexo: yup.number().notRequired().nullable(),
    idade: yup.number().notRequired().nullable(),
    concluirRemocao: yup.boolean().notRequired(),
    enderecoDestino: dadosEnderecoGeoLocalizacaoFormModelSchema.required(MSG_REQUIRED),
    enderecoOrigem: dadosEnderecoGeoLocalizacaoFormModelSchema.required(MSG_REQUIRED),
    informacaoProntuario: yup.string().notRequired().nullable(),
    statusPaciente: yup.number().notRequired().nullable(),
    tipoVeiculo: yup.number().notRequired().nullable(),
    urgencia: yup.number().notRequired().nullable(),
    obito: yup.boolean().notRequired().nullable(),
    dataHoraObito: yup.string().notRequired().nullable(),
    somenteIda: yup.boolean().notRequired().nullable(),
    veiculoId: yup.string().notRequired().nullable(),
    equipes: yup.array().of(dadosEquipeModelSchema).required(MSG_REQUIRED),
    faseAtendimento: yup.mixed().notRequired(),
    faseAtendimentoDescricao: yup.string().notRequired().nullable(),
    faseAtendimentoSigla: yup.string().notRequired().nullable(),
    prontuarios: yup.mixed().notRequired(),
    faseCriacaoResponsavelId: yup.string().notRequired(),
    faseEmpenhoResponsavelId: yup.string().notRequired(),
    faseRegulacaoResponsavelId: yup.string().notRequired(),
    informarJustificativa: yup.boolean().notRequired(),
    cid: yup.mixed().notRequired(),
    quantidadeNotificacaoNaoLida: yup.number().notRequired()
  });
