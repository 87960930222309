import { mdiNavigationVariant } from '@mdi/js';
import {
  OptBackdrop,
  OptGrid,
  OptGridColumn,
  OptGridOptions,
  OptSideLayoutContent
} from '@optsol/react';
import { useState } from 'react';

import { EncerrarPlantaoRequest } from '../../../../models/dtos/Atendimento/encaminharRequest.mode';
import { UsuarioResponseModel } from '../../../../models/dtos/Usuario/UsuarioResponse.model';
import {
  FasesAtendimentoEnum,
  FasesAtendimentoEnumLabel
} from '../../../../models/enums/FasesAtendimento';
import { useAtendimentoService } from '../../../../service/atendimento.service';
import * as S from '../../../components/UsuarioStatusList/styles/index';

interface Props {
  logout: () => void;
  perfisAcesso?: string[];
  usuarios: UsuarioResponseModel[];
}
const options: OptGridOptions = {
  search: true,
  pageSize: 10
};
export const FormEncerrarPlantao = ({ logout, usuarios }: Props) => {
  const { encerrarPlantaoAtendimento } = useAtendimentoService();

  const [usuariosList, setUsuarios] = useState<UsuarioResponseModel[]>(usuarios);
  const [loadingButtomSave, setLoadingButtomSave] = useState(false);
  function encaminharHandler(usuarioId: string, fasesAtendimento: FasesAtendimentoEnum[]) {
    encerrarPlantao(usuarioId, fasesAtendimento);
  }

  async function encerrarPlantao(
    medicoId: string,
    fasesAtendimento: FasesAtendimentoEnum[]
  ): Promise<void> {
    try {
      setLoadingButtomSave(true);
      const faseAtendimento = fasesAtendimento[0];
      const request: EncerrarPlantaoRequest = {
        usuarioId: medicoId,
        faseAtendimento: faseAtendimento
      };
      await encerrarPlantaoAtendimento(request);

      const newList = usuariosList.filter(
        (x) => !x.faseAtendimento.some((x) => x === faseAtendimento)
      );

      if (newList.length === 0) {
        logout();
      } else {
        setUsuarios(newList);
      }
    } finally {
      setLoadingButtomSave(false);
    }
  }
  const columns: OptGridColumn<UsuarioResponseModel>[] = [
    {
      title: 'Id',
      field: 'usuarioId',
      hidden: true
    },
    {
      title: 'Status',
      field: 'status',
      hidden: true
    },
    {
      title: 'Última Atualização',
      field: 'ultimaAtualizacao',
      hidden: true
    },
    {
      title: 'Perfis',
      field: 'perfisAcesso',
      hidden: true
    },
    {
      title: 'FaseAtendimento',
      field: 'faseAtendimento',
      hidden: true
    },
    {
      title: 'Nome',
      field: 'nome',
      render: (props: UsuarioResponseModel) => {
        return (
          <div>
            <div style={{ display: 'flex', alignItems: 'center', gap: '3px' }}>
              <span>{props.nome}</span>
              {props.status ? <S.CircleDotRed status={props.status} /> : <span></span>}
            </div>
            {props.ultimaAtualizacao && (
              <span style={{ fontSize: '12px', color: 'gray' }}>{props.ultimaAtualizacao}</span>
            )}
            <span style={{ fontSize: '12px', color: 'gray' }}>
              &ensp;
              {FasesAtendimentoEnumLabel.get(props.faseAtendimento[0])}
            </span>
          </div>
        );
      }
    },
    {
      title: 'Em andamento',
      field: 'totalChamadosEmAndamento',
      align: 'left'
    }
  ];
  return (
    <>
      <OptSideLayoutContent>
        <OptGrid
          data={usuariosList}
          options={options}
          columns={columns}
          actionsPosition={'end'}
          actions={[
            (rowData) => ({
              icon: { path: mdiNavigationVariant },
              tooltip: 'Encerrar',
              onClick: () => encaminharHandler(rowData.usuarioId, rowData.faseAtendimento),
              disabled: false
            })
          ]}
        />

        <OptBackdrop open={loadingButtomSave} />
      </OptSideLayoutContent>
    </>
  );
};
