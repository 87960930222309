import { FasesAtendimentoEnum } from '../../enums/FasesAtendimento';
import { PerfilAcessoEnum } from '../../enums/PerfilAcesso';
import { StatusUsuario } from '../../enums/StatusUsuario';

export interface UsuarioResponseModel {
  usuarioId: string;
  nome: string;
  situacao: boolean;
  telefone: string;
  cpf: string;
  email: string;
  totalChamadosEmAndamento: number;
  totalChamadosPendentes: number;
  perfisAcesso: PerfilAcessoEnum[];
  faseAtendimento: FasesAtendimentoEnum[];
  documentoOrgaoRegulador: string;
  // public CategoriaCnhEnum CategoriaCnh { get; set; }
  // List<AndamentoAtendimentoResponseModel> AndamentoAtendimentos { get; set; }
  cnh: string;
  ultimaAtualizacao: string | null;
  status: StatusUsuario;
}

export const DADOS_USUARIO_RESPONSE_DEFAULT: UsuarioResponseModel = {
  usuarioId: '',
  nome: '',
  situacao: false,
  telefone: '',
  cpf: '',
  email: '',
  totalChamadosEmAndamento: 0,
  totalChamadosPendentes: 0,
  // public PerfilAcessoEnum PerfilAcesso { get; set; }
  documentoOrgaoRegulador: '',
  // public CategoriaCnhEnum CategoriaCnh { get; set; }
  // List<AndamentoAtendimentoResponseModel> AndamentoAtendimentos { get; set; }
  cnh: '',
  ultimaAtualizacao: null,
  status: StatusUsuario.OFFLINE,
  perfisAcesso: [],
  faseAtendimento: []
};
