import { Routes as ReactRouterRoutes, Route } from 'react-router-dom';

import { useAuthenticationContext } from '../app/contexts/authentication/authenticationContext';
import { Atendimento } from '../app/features/atendimento';
import Home from '../app/pages/home/Home';
import ListarAtendimentos from '../app/pages/listarAtendimentos/ListarAtendimentos';
import { MenuAtendimentoClaims } from '../models/enums/AcessoClaims';

import { RoutesObj } from './index.routes';

export const AtendimentoRoutes = () => {
  const { hasAccess } = useAuthenticationContext();
  const enableRoute = hasAccess(MenuAtendimentoClaims.MENU_ATENDIMENTO);
  return (
    <ReactRouterRoutes>
      <Route path="/" element={enableRoute ? <ListarAtendimentos /> : <Home />} />
      <Route path="/atendimento" element={enableRoute ? <Atendimento /> : <Home />} />
      <Route path="/*" element={enableRoute ? <ListarAtendimentos /> : <Home />} />
      <Route path="/atendimento/:id" element={enableRoute ? <Atendimento /> : <Home />} />
      <Route
        path={RoutesObj.Atendimento.AtendimentoTelefone(':telefone')}
        element={enableRoute ? <Atendimento /> : <Home />}
      />
    </ReactRouterRoutes>
  );
};
