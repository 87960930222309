import { LoadingButton, LoadingButtonProps } from '@mui/lab';
import { PropsWithChildren } from 'react';

import { Colors } from '../../../core/colors';
import { useAuthenticationContext } from '../../contexts/authentication/authenticationContext';

interface ButtonMuiProps extends LoadingButtonProps {
  tipo: 'cancel' | 'confirm';
  $backgroundColor: string | undefined;
}

export default function ButtonModal({
  children,
  tipo,
  $backgroundColor,
  ...props
}: PropsWithChildren<ButtonMuiProps>) {
  const {
    state: { tema }
  } = useAuthenticationContext();

  const THEME = {
    confirm: {
      bg: tema.light?.primary ? tema.light?.primary : $backgroundColor,
      color: '#FFFFFF',
      border: tema.light?.primary ? tema.light?.primary : $backgroundColor,
      onHover: `
          box-shadow: 0 3px 6px rgba(0,0,0,.2);
          `
    },
    cancel: {
      bg: 'transparent',
      color: '#757985',
      border: Colors.gray7,
      onHover: `
         color: ${tema.light?.primary ? tema.light?.primary : $backgroundColor};
          `
    }
  };

  return (
    <LoadingButton
      sx={{
        color: THEME[tipo].color,
        backgroundColor: THEME[tipo].bg,
        textTransform: 'capitalize',
        fontSize: '15px',
        width: '50%',
        border: `1px solid ${THEME[tipo].border}`,
        '&:hover': {
          backgroundColor: $backgroundColor
        },
        '&:disabled': {
          backgroundColor: Colors.gray8
        }
      }}
      {...props}
    >
      {children}
    </LoadingButton>
  );
}
