import { LoadingButton, LoadingButtonProps } from '@mui/lab';
import { styled } from '@mui/material';
import { PropsWithChildren } from 'react';

import { Colors } from '../../../core/colors';

interface ButtonMuiProps extends LoadingButtonProps {
  $backgroundColor: string | '';
}

export default function ButtonMui({ children, ...props }: PropsWithChildren<ButtonMuiProps>) {
  return <Button {...props}>{children}</Button>;
}

const Button = styled(LoadingButton)<ButtonMuiProps>`
  color: white;
  background-color: ${({ $backgroundColor }) => $backgroundColor};
  text-transform: capitalize;
  font-size: 15px;
  &:hover {
    background-color: ${({ $backgroundColor }) => $backgroundColor};
  }
  &:disabled {
    background-color: ${Colors.gray8};
  }
`;
