import { OptLoading } from '@optsol/react';
import { useEffect, useState } from 'react';

interface Props {
  obterImageBlobURL: () => Promise<Blob | undefined>;
}
export const ImageLoadingContainer = ({ obterImageBlobURL }: Props) => {
  const [imagemBlobUrl, setImagemBlobUrl] = useState<string>('');
  async function obterImageBlobURLHandler() {
    const imagemBlob = await obterImageBlobURL();
    if (imagemBlob) setImagemBlobUrl(URL.createObjectURL(imagemBlob));
  }
  useEffect(() => {
    obterImageBlobURLHandler();
  }, []);
  return (
    <>
      {imagemBlobUrl ? (
        <img
          style={{ maxWidth: '500px', maxHeight: '500px' }}
          src={imagemBlobUrl}
          alt="Imagem histórico"
        />
      ) : (
        <OptLoading color="primary" size={50} />
      )}
    </>
  );
};
