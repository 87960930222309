import { Backdrop } from '@mui/material';
import { PropsWithChildren } from 'react';

interface Props {
  open: boolean;
  handleClose: () => void;
}

export const BackdropContainer = ({ children, open, handleClose }: PropsWithChildren<Props>) => {
  return (
    <Backdrop
      sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={open}
      onClick={handleClose}
    >
      {children}
    </Backdrop>
  );
};
