import { Colors } from '../../core/colors';
import {
  BarChartDataModel,
  DashboardsData,
  PieChartDataModel
} from '../../models/dashboards/DashboardsModel';

export const validarCPF = (cpf?: string) => {
  if (!cpf) return false;
  // Remove tudo o que nao for numero
  cpf = cpf.replace(/[^\d]+/g, '');
  // Valida tamanho e todos os numeros iguais
  if (cpf.length !== 11 || !!cpf.match(/(\d)\1{10}/)) return false;
  // Retorna um array com todos os numeros
  const numeros = cpf.split('').map((el) => parseInt(el));
  // Calcular Soma dos digitos e multiplicar por 10
  const soma = (count: number) =>
    numeros.slice(0, count - 12).reduce((soma, el, index) => soma + el * (count - index), 0) * 10;
  // Pegar o resto por 11 e transforma 10 para 0
  const resto = (count: number) => (soma(count) % 11) % 10;
  return resto(10) === numeros[9] && resto(11) === numeros[10];
};

export function validarCNPJ(cnpj?: string): boolean {
  if (!cnpj) return false;

  cnpj = cnpj.replace(/[^\d]+/g, '').padStart(14, '0');

  if (cnpj === '' || cnpj.length !== 14) {
    return false;
  }

  // Elimina CNPJs invalidos conhecidos
  if (
    cnpj === '00000000000000' ||
    cnpj === '11111111111111' ||
    cnpj === '22222222222222' ||
    cnpj === '33333333333333' ||
    cnpj === '44444444444444' ||
    cnpj === '55555555555555' ||
    cnpj === '66666666666666' ||
    cnpj === '77777777777777' ||
    cnpj === '88888888888888' ||
    cnpj === '99999999999999'
  ) {
    return false;
  }

  // Valida DVs
  let tamanho = cnpj.length - 2;
  let numeros = cnpj.substring(0, tamanho);
  const digitos = cnpj.substring(tamanho);
  let soma = 0;
  let pos = tamanho - 7;
  for (let i = tamanho; i >= 1; i--) {
    soma += parseInt(numeros.charAt(tamanho - i)) * pos--;
    if (pos < 2) {
      pos = 9;
    }
  }
  let resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
  if (resultado !== parseInt(digitos.charAt(0))) {
    return false;
  }

  tamanho = tamanho + 1;
  numeros = cnpj.substring(0, tamanho);
  soma = 0;
  pos = tamanho - 7;
  for (let i = tamanho; i >= 1; i--) {
    soma += parseInt(numeros.charAt(tamanho - i)) * pos--;
    if (pos < 2) {
      pos = 9;
    }
  }
  resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
  if (resultado !== parseInt(digitos.charAt(1))) {
    return false;
  }

  return true;
}

export function downloadBlob(blob: Blob, fileName: string) {
  if (!blob) return;

  // Create blob link to download
  const url = window.URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = url;
  link.target = '_blank';

  link.setAttribute('download', fileName);

  // Append to html link element page
  document.body.appendChild(link);

  // Start download
  link.click();

  // Clean up and remove the link
  link.parentNode && link.parentNode.removeChild(link);
}
function buildFormData(formData: FormData, data: any, parentKey?: any) {
  if (data && typeof data === 'object' && !(data instanceof Date) && !(data instanceof File)) {
    Object.keys(data).forEach((key) => {
      buildFormData(formData, data[key], parentKey ? `${parentKey}[${key}]` : key);
    });
  } else {
    const value = data == null ? '' : data;
    if (value instanceof Date) {
      formData.append(parentKey, tolocalISOString(value));
    } else {
      formData.append(parentKey, value);
    }
  }
}

export function objectToFormData<T>(data: T) {
  const formData = new FormData();
  buildFormData(formData, data);
  return formData;
}

export const tolocalISOString = (date: Date) => {
  const tzOffset = date.getTimezoneOffset() * 60000; //offset in milliseconds
  return new Date(date.getTime() - tzOffset).toISOString();
};

export function converterHexToRgbA(hex: string, opacity = '1'): string {
  const r = parseInt(hex.slice(1, 3), 16),
    g = parseInt(hex.slice(3, 5), 16),
    b = parseInt(hex.slice(5, 7), 16);

  return 'rgba(' + r + ', ' + g + ', ' + b + ', ' + opacity + ')';
}

function padTo2Digits(num: number) {
  return num.toString().padStart(2, '0');
}

export function formatDate(date: Date) {
  return (
    [padTo2Digits(date.getDate()), padTo2Digits(date.getMonth() + 1), date.getFullYear()].join(
      '/'
    ) +
    ' ' +
    [
      padTo2Digits(date.getHours()),
      padTo2Digits(date.getMinutes()),
      padTo2Digits(date.getSeconds())
    ].join(':')
  );
}

export function dataParaBase64() {
  const dataAtual = formatDate(new Date());
  const dataBase64 = window.btoa(dataAtual);
  return dataBase64;
}

export function generateColorHex() {
  const letters = '0123456789ABCDEF';
  let color = '#';
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
}

export function generateLightColorHex() {
  let color = '#';
  for (let i = 0; i < 3; i++)
    color += ('0' + Math.floor(((1 + Math.random()) * Math.pow(16, 2)) / 2).toString(16)).slice(-2);
  return color;
}

export function limitarLabel(limite: number, data: PieChartDataModel[] | BarChartDataModel[]) {
  const newData = [...data];
  newData.map((obj) => {
    if (obj.id && obj.id.length > limite) {
      obj.id = `${obj.id.substring(0, limite)}...`;
    }
    // if (obj.label && obj.label.length > limite) {
    //   obj.label = `${obj.label.substring(0, limite)}...`;
    // }
  });
  return newData;
}

export function obterEnderecoGoole(
  place: google.maps.places.PlaceResult,
  type: string
): string[] | undefined {
  return place.address_components
    ?.filter(function (it) {
      return it.types.indexOf(type) !== -1;
    })
    .map(function (it) {
      return it.long_name;
    });
}

export function capitalizeCase(str: string) {
  const newStr = str
    .trim()
    .toLowerCase()
    .split(' ')
    .filter((x) => x.trim() != '')
    .reduce((sentence, word) => `${sentence} ${word[0].toUpperCase()}${word.substring(1)}`, '')
    .trim();

  return newStr;
}

export function injetarCoresDashboardData(data: DashboardsData) {
  const newData: DashboardsData = {
    atedimentosMrData: [],
    chamadosPorPrioridadeData: [],
    maiorIncidenciaData: [],
    motivoAtendimentoData: [],
    statusAmbulanciaData: [],
    indicadores: []
  };

  Object.assign(newData, { indicadores: data.indicadores });

  const newAtendimentosMrData = data.atedimentosMrData.map((obj) => {
    return (obj = {
      ...obj,
      id: capitalizeCase(obj.id),
      label: capitalizeCase(obj.label),
      valueColor: ''
    });
  });

  Object.assign(newData, { atedimentosMrData: newAtendimentosMrData });

  const newChamadosColors = [
    Colors.prioridade.emergencia,
    Colors.prioridade.muitoUrgente,
    Colors.prioridade.urgente,
    Colors.prioridade.poucourgente,
    Colors.prioridade.naoUrgente,
    Colors.gray5
  ];

  const newChamadosPorPrioridadeData = data.chamadosPorPrioridadeData.map((obj, index) => {
    return (obj = { ...obj, color: newChamadosColors[index] });
  });

  Object.assign(newData, {
    chamadosPorPrioridadeData: newChamadosPorPrioridadeData
  });

  const newMaiorIncidenciaData = data.maiorIncidenciaData.map((obj) => {
    return (obj = { ...obj, color: generateColorHex() });
  });

  Object.assign(newData, {
    maiorIncidenciaData: newMaiorIncidenciaData
  });

  const newMotivoAtendimentoData = data.motivoAtendimentoData.map((obj) => {
    return (obj = {
      ...obj,
      id: capitalizeCase(obj.id),
      label: capitalizeCase(obj.label),
      valueColor: ''
    });
  });

  Object.assign(newData, {
    motivoAtendimentoData: newMotivoAtendimentoData
  });

  const newStatusColors = [Colors.ambulancia.disponivel, Colors.ambulancia.emAtendimento];
  const newStatusAmbulanciaData = data.statusAmbulanciaData.map((obj, index) => {
    return (obj = { ...obj, color: newStatusColors[index] });
  });

  Object.assign(newData, {
    statusAmbulanciaData: newStatusAmbulanciaData
  });

  return newData;
}

export function obterFiltroDaRota(fieldName: string) {
  const search = new URLSearchParams(location.search);
  return search.get(fieldName);
}

export function updateFiltroUrl(fieldName: string, value: any) {
  const query = window.location.href.split('?');
  const search = new URLSearchParams(query.length > 0 ? query[1] : location.search);
  search.set(fieldName, `${value}`);
  history.pushState(null, '', `?${search.toString()}`);
}

export function getHeaderTheme(bg: string) {
  const headerStyle = {
    backgroundColor: bg,
    color: Colors.white
  };

  return headerStyle;
}
