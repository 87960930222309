import { Grid } from '@mui/material';
import { useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { CriarClienteModel } from '../../../models/Cliente/Cliente.model';
import { FormTextField } from '../../components/Form';
import { FormNumberFormat } from '../../components/Form/FormNumberFormat';

export const FormCriarCliente = () => {
  const { control } = useFormContext<CriarClienteModel>();
  const [mask, setMask] = useState('###.###.###-###');

  return (
    <>
      <Grid container item rowSpacing={3} columnSpacing={3} pt={2.5} pb={2.5}>
        <Grid item xs={12}>
          <FormNumberFormat
            control={control}
            format={mask}
            name="cpfCnpj"
            label="CPF/CNPJ"
            size="small"
            placeholder="CPF/CNPJ"
            onPaste={(e: any) => {
              if (e.clipboardData.getData('text/plain').replace(/\D/g, '').trim().length >= 12) {
                setMask('##.###.###/####-##');
              } else {
                setMask('###.###.###-###');
              }
            }}
            onChange={(event) => {
              if (event.target.value.replace(/[^\w\s]/gi, '').trim().length >= 12) {
                setMask('##.###.###/####-##');
              } else {
                setMask('###.###.###-###');
              }
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            name="nome"
            control={control}
            render={() => (
              <>
                <FormTextField name="nome" control={control} label="Nome" />
              </>
            )}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default FormCriarCliente;
