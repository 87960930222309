import { MotivoAtendimentoSearchResponse } from '../models/dtos/MotivoAtendimento/MotivoAtendimentoSearchResponse.model';
import { EnderecoModel } from '../models/Endereco/Endereco.model';
import { ExcluirEnderecoDestinoMotivoRequest } from '../models/MotivoAtendimento/EnderecoDestinoMotivo.mode';
import {
  CriarMotivoAtendimentoModel,
  MotivoAtendimentoModel,
  ServicoCobertoMotivoAtendimentoRequest,
  ServicoCobertoMotivoAtendimentoResponse
} from '../models/MotivoAtendimento/MotivoAtendimento.model';
import { useApi } from '../shared/hooks/api';
import { SearchRequest } from '../shared/utils/searchRequest';

export const useMotivoAtendimentoService = () => {
  const { gridSearch, post, get, put, removeWithBody, remove } = useApi();

  const baseApi = `api/motivo-atendimento`;

  const buscarGridMotivoAtendimentoPaginated = async (data: SearchRequest<object>) => {
    return gridSearch<MotivoAtendimentoSearchResponse>(`${baseApi}/paginated`, data);
  };

  const salvarMotivoAtendimento = async (data: CriarMotivoAtendimentoModel) => {
    return post<MotivoAtendimentoModel>(`${baseApi}`, data);
  };

  const obterMotivoAtendimentoData = async (id: string) => {
    return get<MotivoAtendimentoModel>(`${baseApi}/${id}`);
  };

  const obterMotivosAtendimento = async () => {
    return get<MotivoAtendimentoModel[]>(`${baseApi}`);
  };

  const salvarServicoCobertoMotivoAtendimento = async (
    data: ServicoCobertoMotivoAtendimentoRequest
  ) => {
    return post<ServicoCobertoMotivoAtendimentoResponse>(
      `${baseApi}/adicionar-servico-coberto-motivo-atendimento`,
      data
    );
  };

  const removerServicoCobertoMotivoAtendimento = async (
    data: ServicoCobertoMotivoAtendimentoRequest
  ) => {
    return removeWithBody(`${baseApi}/remover-servico-coberto-motivo-atendimento`, data);
  };

  const editarMotivoAtendimento = async (id: string, data: MotivoAtendimentoModel) => {
    return put(`${baseApi}/${id}`, data);
  };

  const salvarEndereco = async (id: string, data: EnderecoModel) => {
    return post<EnderecoModel>(`${baseApi}/${id}/adicionar-endereco`, data);
  };

  const excluirEndereco = async (data: ExcluirEnderecoDestinoMotivoRequest) => {
    return remove(`${baseApi}/${data.motivoAtendimentoId}/endereco/${data.enderecoDestinoId}`);
  };

  return {
    buscarGridMotivoAtendimentoPaginated,
    salvarMotivoAtendimento,
    obterMotivoAtendimentoData,
    editarMotivoAtendimento,
    salvarServicoCobertoMotivoAtendimento,
    removerServicoCobertoMotivoAtendimento,
    salvarEndereco,
    excluirEndereco,
    obterMotivosAtendimento
  };
};
