import { createTheme } from '@mui/material';

import { Colors } from '../core/colors';

export const muiTheme = createTheme({
  palette: {
    primary: {
      main: Colors.primary
    },
    secondary: {
      main: Colors.secondary
    },
    error: {
      main: Colors.red
    }
  },
  components: {
    MuiInputLabel: {
      styleOverrides: {
        root: { color: Colors.primary }
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          height: '40px'
          // padding: '12px 24px'
        }
      }
    },
    MuiSelect: {
      styleOverrides: {
        select: {
          padding: '0.5rem 1rem',
          '&::placeholder': {
            color: Colors.primary
          }
        }
      }
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          /*           '&:hover': {
            backgroundColor: Colors.primary
          },
          '& .MuiOutlinedInput-root': {
            color: Colors.secondary,

            '& fieldset': {
              borderColor: Colors.primary
            },
            '&:hover fieldset': {
              borderColor: Colors.primary
            },
            '&.Mui-focused': {
              borderColor: Colors.secondary,
              backgroundColor: Colors.primary
            }
          }, */
          input: {
            // height: 7,
            size: 'small',
            '&::placeholder': {
              color: Colors.gray6
            }
          }
        }
      },
      variants: [
        {
          props: { color: 'error' },
          style: {
            '&:hover': {
              backgroundColor: Colors.white
            },
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                borderColor: Colors.red
              },
              '&:hover fieldset': {
                borderColor: Colors.red
              }
            },
            input: {
              '&::placeholder': {
                color: Colors.red
              }
            }
          }
        }
      ]
    }
  },

  typography: {
    fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
    fontSize: 14,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    h1: { fontSize: 24, fontWeight: 700 },
    h2: { fontSize: 20, fontWeight: 700 },
    h3: { fontSize: 18, fontWeight: 500 },
    h4: { fontSize: 16, fontWeight: 400 },
    subtitle1: { fontSize: 18, fontWeight: 400 }
  },
  shape: { borderRadius: 8 }
});
