import { Box } from '@mui/system';
import { OptGrid, OptSideLayoutContent } from '@optsol/react';
import {
  OptGridColumn,
  OptGridOptions,
  OptGridRef,
  OptGridRequest,
  OptGridResponse
} from '@optsol/react/lib/esm/components/OptGrid/@types';
import { forwardRef } from 'react';

import { Colors } from '../../../core/colors';
import { UsuarioSearchResponseModel } from '../../../models/dtos/Usuario/usuarioSearchResponse.model';
import { TipoUsuarioEnum, TipoUsuarioEnumLabel } from '../../../models/enums/TipoUsuario';
import { getHeaderTheme, obterFiltroDaRota } from '../../../shared/utils/functions';
import { CategoriaCnhText } from '../../components/CategoriaCnhText/CategoriaCnhText';
import { ChipStatus } from '../../components/StatusText/ChipStatus';
import { useAuthenticationContext } from '../../contexts/authentication/authenticationContext';

interface GridProps {
  carregar: (data: OptGridRequest) => Promise<OptGridResponse<UsuarioSearchResponseModel>>;
  editarUsuario(usuarioId: string): Promise<void>;
}

const options: OptGridOptions = {
  search: true,
  pageSize: 10
};

const GridUsuarios = forwardRef<OptGridRef | undefined, GridProps>(
  ({ carregar, editarUsuario }, ref) => {
    const {
      state: { tema }
    } = useAuthenticationContext();

    const defaultPageSize = obterFiltroDaRota('pageSize');
    const defaultPage = obterFiltroDaRota('page');
    const columns: OptGridColumn<UsuarioSearchResponseModel>[] = [
      {
        title: 'Id',
        field: 'usuarioId',
        hidden: true
      },
      {
        title: 'Nome',
        field: 'nome',
        align: 'start',
        render: (props: UsuarioSearchResponseModel) => {
          return <>{props.nome ? props.nome : '-'}</>;
        }
      },
      {
        title: 'CPF',
        field: 'cpf',
        align: 'start',
        render: (props: UsuarioSearchResponseModel) => {
          return <>{props.cpf ? props.cpf : '-'}</>;
        }
      },
      {
        title: 'Telefone',
        field: 'telefone',
        align: 'start',
        render: (props: UsuarioSearchResponseModel) => {
          return <>{props.telefone ? props.telefone : '-'}</>;
        }
      },
      {
        title: 'Email',
        field: 'email',
        align: 'start',
        render: (props: UsuarioSearchResponseModel) => {
          return <>{props.email ? props.email : '-'}</>;
        }
      },
      {
        title: 'Tipo',
        field: 'tipo',
        align: 'start',
        render: (props: UsuarioSearchResponseModel) => {
          return (
            <>
              <span>{TipoUsuarioEnumLabel.get(props.tipo ?? TipoUsuarioEnum.INTERNO)}</span>
            </>
          );
        }
      },
      {
        title: 'Perfil',
        field: 'perfisAcesso',
        align: 'start',
        render: (props: UsuarioSearchResponseModel) => {
          return (
            <>
              {props.tipo === TipoUsuarioEnum.INTERNO ? (
                props.perfisAcesso.map((x) => (
                  <>
                    <Box sx={{ fontWeight: '600' }}>{x}</Box>
                  </>
                ))
              ) : (
                <span> - </span>
              )}
            </>
          );
        }
      },
      {
        title: 'Doc. orgão regulador',
        field: 'documentoOrgaoRegulador',
        align: 'start',
        render: (props: UsuarioSearchResponseModel) => {
          return <>{props.documentoOrgaoRegulador ? props.documentoOrgaoRegulador : '-'}</>;
        }
      },
      {
        title: 'Nº CNH',
        field: 'cnh',
        align: 'start',
        render: (props: UsuarioSearchResponseModel) => {
          return <>{props.cnh ? props.cnh : '-'}</>;
        }
      },
      {
        title: 'Categoria',
        field: 'categoriaCnh',
        align: 'start',
        render: (props: UsuarioSearchResponseModel) => {
          return <CategoriaCnhText tipo={props.categoriaCnh}></CategoriaCnhText>;
        }
      },
      {
        title: 'Situação  ',
        field: 'situacao',
        align: 'start',
        render: (props: UsuarioSearchResponseModel) => {
          return <ChipStatus status={props.situacao} />;
        }
      }
    ];
    return (
      <OptSideLayoutContent>
        <OptGrid
          ref={ref as any}
          data={carregar}
          headerStyle={getHeaderTheme(tema.light?.primary ? tema.light.primary : Colors.gray10)}
          options={{
            ...options,
            pageIndex: defaultPage !== null ? parseInt(defaultPage) : 0,
            pageSize: defaultPageSize !== null ? parseInt(defaultPageSize) : 10
          }}
          columns={columns}
          onRowClick={(rowData) => {
            editarUsuario(rowData.usuarioId);
          }}
        />
      </OptSideLayoutContent>
    );
  }
);

GridUsuarios.displayName = 'GridUsuarios';
export default GridUsuarios;
