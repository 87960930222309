import { mdiFile } from '@mdi/js';
import Icon from '@mdi/react';
import { Box, Button, Grid, TextField, Tooltip, Typography } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { InputLabel, OptBackdrop } from '@optsol/react';
import { addDays, format } from 'date-fns';
import { useState } from 'react';
import { Controller, FieldErrors, useFormContext } from 'react-hook-form';

import { Colors } from '../../../../core/colors';
import { RelatorioFormModel } from '../../../../models/Relatorio/Relatorio.model';
import { useAtendimentoService } from '../../../../service/atendimento.service';
import { useAuthenticationContext } from '../../../contexts/authentication/authenticationContext';

export const RelatorioForm = () => {
  const { control, setValue, watch, getValues, handleSubmit } =
    useFormContext<RelatorioFormModel>();
  const {
    state: { tema }
  } = useAuthenticationContext();
  const [loading, setLoading] = useState(false);
  const { gerarRelatorio } = useAtendimentoService();
  const onValid = async (data: RelatorioFormModel) => {
    try {
      setLoading(true);
      data = { ...formatarData(data) };
      await gerarRelatorio(data.dataInicio ?? '', data.dataFim ?? '');
    } finally {
      setLoading(false);
    }
  };
  const onInvalid = (_errors: FieldErrors<RelatorioFormModel>) => {
    console.log(_errors);
  };
  function formatarData(atendimentoModel: RelatorioFormModel): RelatorioFormModel {
    if (atendimentoModel.dataInicio) {
      const dataInicioFormatada = format(new Date(atendimentoModel.dataInicio ?? ''), 'dd/MM/yyyy');
      atendimentoModel.dataInicio = dataInicioFormatada;
    }
    if (atendimentoModel.dataFim) {
      const dataFimFormatada = format(new Date(atendimentoModel.dataFim ?? ''), 'dd/MM/yyyy');
      atendimentoModel.dataFim = dataFimFormatada;
    }

    return atendimentoModel;
  }
  return (
    <Box
      display="flex"
      flexDirection="row"
      gap={2}
      justifyContent={'start'}
      alignItems={'center'}
      pt={2}
      pl={2}
    >
      <Grid item>
        <Controller
          name="dataInicio"
          control={control}
          render={({ field: { onChange, value } }) => {
            return (
              <DatePicker
                value={value ?? (null as any)}
                onChange={(e) => {
                  onChange(e);
                  setValue('dataInicio', e);
                }}
                renderInput={(params) => (
                  <>
                    <InputLabel>
                      <Typography textAlign="left" color={tema.light?.primary} lineHeight={1}>
                        Início
                      </Typography>
                    </InputLabel>
                    <TextField fullWidth {...params} size="small" />
                  </>
                )}
              />
            );
          }}
        />
      </Grid>
      <Grid item>
        <Controller
          name="dataFim"
          control={control}
          render={({ field: { onChange, value } }) => {
            return (
              <DatePicker
                disabled={!watch('dataInicio')}
                value={value ?? (null as any)}
                onChange={onChange}
                minDate={new Date(getValues('dataInicio') ?? '')}
                maxDate={addDays(new Date(getValues('dataInicio') ?? ''), 40)}
                renderInput={(params) => (
                  <>
                    <InputLabel>
                      <Typography textAlign="left" color={tema.light?.primary} lineHeight={1}>
                        Fim
                      </Typography>
                    </InputLabel>
                    <TextField size="small" fullWidth {...params} />
                  </>
                )}
              />
            );
          }}
        />
      </Grid>
      <Grid item>
        <Tooltip title={'Gerar relatório'} placement="top">
          <Button
            variant="outlined"
            onClick={handleSubmit(onValid, onInvalid)}
            disabled={false}
            sx={{ marginTop: '1em' }}
          >
            <Icon path={mdiFile} size={1} color={Colors.blue} />
          </Button>
        </Tooltip>
      </Grid>
      <OptBackdrop open={loading} />
    </Box>
  );
};
