export enum MenuAtendimentoClaims {
  MENU_ATENDIMENTO = 'A1',
  GRID_ATENDIMENTO = 'B1',
  GRID_ATENDIMENTO__AMBULANCIA = 'C1',
  GRID_ATENDIMENTO__CLASSIFICACAO = 'C2',
  GRID_ATENDIMENTO__DATA_AGENDAMENTO = 'C3',
  GRID_ATENDIMENTO__DATA_CHAMADO = 'C4',
  GRID_ATENDIMENTO__ENDERECO_DESTINO = 'C5',
  GRID_ATENDIMENTO__ENDERECO_ORGIEM = 'C6',
  GRID_ATENDIMENTO__INCIDENTE = 'C7',
  GRID_ATENDIMENTO__MEDICO_REGULADOR = 'C8',
  GRID_ATENDIMENTO__NUMERO_CHAMADO = 'C9',
  GRID_ATENDIMENTO__PACIENTE = 'C10',
  GRID_ATENDIMENTO__RADIO_OPERADOR = 'C11',
  GRID_ATENDIMENTO__SOLICITANTE = 'C12',
  GRID_ATENDIMENTO__STATUS = 'C13',
  GRID_ATENDIMENTO__TARM = 'C14',
  GRID_ATENDIMENTO__TELEFONE = 'C15',
  GRID_ATENDIMENTO__TIPO_AMBULANCIA = 'C16',
  MENUS = 'B2',
  MENUS__MENU_ATENDIMENTO_OPERADOR = 'C17',
  MENUS__MENU_ATENDIMENTO_REGULADOR = 'C18',
  MENUS__MENU_ATENDIMENTO_SUPERVISOR = 'C19',
  MENUS__MENU_ATENDIMENTO_TARM = 'C20',
  AGENDAR_ATENDIMENTO = 'B3',
  ALTERAR_RESPONSAVEL = 'B4',
  ASSUMIR_PACIENTE = 'B5',
  ATUALIZAR = 'B6',
  ATUALIZAR_STEP_TIMELINE = 'B7',
  CANCELAR_CHAMADO = 'B8',
  CONCLUIR_REMOCAO = 'B9',
  ENCAMINHAR_AMBULANCIA = 'B10',
  ENCAMINHAR_ATENDIMENTO_MEDICO_REGULADOR = 'B11',
  ENCAMINHAR_PACIENTE_RADIO_OPERADOR = 'B12',
  ENCERRAR_CHAMADO = 'B13',
  FILTRO_APENAS_ENCERRADOS = 'B14',
  FILTRO_MEUS_ATENDIMENTOS = 'B15',
  GERAR_BOLETIM = 'B16',
  INSERIR = 'B17',
  INSERIR_PACIENTE = 'B18',
  LISTAR_CLIENTE = 'B19',
  LISTAR_MOTIVO_ATENDIMENTO = 'B20',
  REMOVER_AMBULANCIA = 'B21',
  REMOVER_PACIENTE = 'B22',
  VISUALIZAR = 'B23',
  VISUALIZAR_HISTORICO_ATEDIMENTOS_PACIENTE = 'B24',
  VISUALIZAR_HISTORICO_ATENDIMENTO = 'B25',
  VISUALIZAR_HISTORICO_PRONTUARIO = 'B26',
  ATUALIZAR_VEICULO_PACIENTE = 'B57',
  OBTER_POR_PERFIL = 'B58',
  VISUALIZAR_TIMELINE = 'B59',
  ENCERRAR_PLANTAO = 'B60',
  INSERIR_ATENDIMENTO_USUARIO_EXTERNO = 'B61',
  GERAR_RELATORIO = 'B62'
}
export enum MobileAtendimentoClaims {
  MOBILE_ATENDIMENTO = 'A2',
  TAB_DETALHE = 'B27',
  TAB_DETALHE__CONCLUIR = 'C21',
  TAB_DETALHE__CONFIRMAR_STEPS = 'C22',
  TAB_DETALHE__VISUALIZAR = 'C23',
  TAB_OBITO = 'B28',
  TAB_OBITO__ATUALIZAR = 'C24',
  TAB_OBITO__VISUALIZAR = 'C25',
  TAB_PRONTUARIO = 'B29',
  TAB_PRONTUARIO__INSERIR = 'C26',
  TAB_PRONTUARIO__VISUALIZAR = 'C27',
  TAB_SINAIS_VITAIS = 'B30',
  TAB_SINAIS_VITAIS__INSERIR = 'C28',
  TAB_SINAIS_VITAIS__VISUALIZAR = 'C29',
  ACEITAR = 'B31',
  ATUALIZAR_EQUIPE_VEICULO = 'B32',
  RECUSAR = 'B33'
}
export enum MenuUsuarioClaims {
  MENU_USUARIO = 'A3',
  ATUALIZAR = 'B34',
  INSERIR = 'B35',
  VISUALIZAR = 'B36'
}
export enum MenuVeiculoClaims {
  MENU_VEICULO = 'A4',
  ATUALIZAR = 'B37',
  INSERIR = 'B38',
  VISUALIZAR = 'B39'
}
export enum MenuServicoCobertoClaims {
  MENU_SERVICO_COBERTO = 'A5',
  ATUALIZAR = 'B40',
  INSERIR = 'B41',
  VISUALIZAR = 'B42'
}
export enum MenuPlanoClaims {
  MENU_PLANO = 'A6',
  ATUALIZAR = 'B43',
  INSERIR = 'B44',
  VISUALIZAR = 'B45'
}
export enum MenuMotivoAtendimentoClaims {
  MENU_MOTIVO_ATENDIMENTO = 'A7',
  ATUALIZAR = 'B46',
  INSERIR = 'B47',
  VISUALIZAR = 'B48'
}
export enum MenuHomeClaims {
  MENU_HOME = 'A8',
  VISUALIZAR_GRAFICOS = 'B49',
  VISUALIZAR_STATUS_USUARIOS = 'B50'
}
export enum MenuClienteClaims {
  MENU_CLIENTE = 'A9',
  ATUALIZAR = 'B51',
  INSERIR = 'B52',
  VISUALIZAR = 'B53'
}
export enum MenuEmpresaClaims {
  MENU_EMPRESA = 'A10',
  ATUALIZAR = 'B54',
  INSERIR = 'B55',
  MENUS = 'B56',
  MENUS__MENU_EMPRESA_CLIENTE = 'C30',
  MENUS__MENU_EMPRESA_OPTSOL = 'C31'
}
export enum MenuAcompanhamentoMapaClaims {
  MENU_ACOMPANHAMENTO_MAPA = 'A11'
}
