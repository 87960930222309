import { mdiMapMarkerAccount, mdiMapSearch } from '@mdi/js';
import Icon from '@mdi/react';
import {
  Box,
  FormControlLabel,
  Grid,
  IconButton,
  MenuItem,
  SelectChangeEvent,
  Switch,
  ToggleButton,
  ToggleButtonGroup
} from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { Colors } from '../../../../core/colors';
import { AtendimentoFormModel } from '../../../../models/Atendimento/Atendimento.model';
import { InformacaoPacienteFormModel } from '../../../../models/Atendimento/Paciente/InformacaoPaciente.model';
import { EnderecoGeoLocalizacao } from '../../../../models/Endereco/EnderecoGeoLocalizacao.mode';
import { MenuAtendimentoClaims } from '../../../../models/enums/AcessoClaims';
import {
  ClassificacaoUrgenciaEnum,
  ClassificacaoUrgenciaEnumLabel
} from '../../../../models/enums/ClassificacaoUrgencia';
import { FasesAtendimentoEnum } from '../../../../models/enums/FasesAtendimento';
import { StatusAtendimentoEnum } from '../../../../models/enums/StatusAtendimento';
import { validarCPF } from '../../../../shared/utils/functions';
import { ErrorMessage, FormSelect, FormTextField } from '../../../components/Form';
import { FormNumberFormat } from '../../../components/Form/FormNumberFormat';
import { AutoCompleteMaps } from '../../../components/Google/Autocomplete';
import { FullLocationModel } from '../../../components/Google/model/FullLocation.model';
import { ProtectedContent } from '../../../components/ProtectedContent/ProtectedContent';
import { useAuthenticationContext } from '../../../contexts/authentication/authenticationContext';

interface Props {
  editarPaciente: (value: InformacaoPacienteFormModel) => Promise<void>;
  index: number;
  disableForm?: boolean;
  podeEditarEndereco?: boolean;
  medicoRegulador?: boolean;
  permiteEditarEmAndamento?: boolean;
  accordionSummary?: boolean;
}

export const FormInformacoesPaciente = ({
  editarPaciente,
  index,
  disableForm,
  podeEditarEndereco,
  medicoRegulador,
  accordionSummary
}: Props) => {
  const {
    control,
    getValues,
    watch,
    setValue,
    formState: { isDirty }
  } = useFormContext<AtendimentoFormModel>();

  const paciente = getValues().pacientes[index];
  const [placeOrigemToEdit] = useState(
    paciente.enderecoOrigem?.enderecoGeolocalizacao?.descricaoLocal ?? ''
  );
  const [placeDestinoToEdit] = useState(
    paciente.enderecoDestino?.enderecoGeolocalizacao?.descricaoLocal ?? ''
  );

  const [exibirEnderecoOrigemMaps, setExibirEnderecoOrigemMaps] = useState(false);
  const [exibirEnderecoDestinoMaps, setExibirEnderecoDestinoMaps] = useState(false);
  const [cpfMessage, setCpfMessage] = useState<string>();

  const {
    state: { tema }
  } = useAuthenticationContext();

  const watchEnderecosOrigemCliente = watch('cliente.enderecos');
  const wacthEnderecosDestinosMotivoAtendimento = watch('motivoAtendimento.enderecosAtendimentos');

  const adicionarEnderecoOrigem = useCallback(
    (place?: google.maps.places.PlaceResult, fullLocation?: FullLocationModel | undefined) => {
      if (!place) return;
      let cep: string[] | undefined;
      let rua: string[] | undefined;
      let bairro: string[] | undefined;
      let cidade: string[] | undefined;
      let estado: string[] | undefined;
      let numero: string[] | undefined;
      let nome: string | undefined;

      if (place.address_components) {
        cep = obterEnderecoGoole(place, 'postal_code');
        rua = obterEnderecoGoole(place, 'route');
        bairro = obterEnderecoGoole(place, 'sublocality_level_1');
        cidade = obterEnderecoGoole(place, 'administrative_area_level_2');
        numero = obterEnderecoGoole(place, 'street_number');
        estado = place.address_components
          .filter(function (it) {
            return it.types.indexOf('administrative_area_level_1') !== -1;
          })
          .map(function (it) {
            return it.short_name;
          });
      }

      if (numero && numero.length > 0) {
        if (isNaN(+numero[0])) {
          numero = [];
        }
      }

      const enderecoGeoLocalizacao: EnderecoGeoLocalizacao = {
        descricaoLocal: place?.formatted_address ?? '',
        latitude: fullLocation?.lat ?? place.geometry?.location?.lat() ?? 0,
        longitude: fullLocation?.lng ?? place.geometry?.location?.lng() ?? 0,
        placeId: place?.place_id ?? '',
        bairro: bairro ? bairro[0] : '',
        cidade: cidade ? cidade[0] : '',
        estado: estado ? estado[0] : '',
        cep: cep ? cep[0] : '',
        numero: numero && numero.length > 0 ? +numero[0] : 0,
        rua: rua ? rua[0] : ''
      };

      const paciente = getValues().pacientes[index];

      paciente.enderecoOrigem = {
        ...paciente.enderecoOrigem,
        nome: nome ?? '',
        enderecoGeolocalizacao: enderecoGeoLocalizacao ?? null
      };
      editarPaciente(paciente);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [editarPaciente, paciente, placeOrigemToEdit]
  );

  const adicionarEnderecoDestino = useCallback(
    (place?: google.maps.places.PlaceResult, fullLocation?: FullLocationModel | undefined) => {
      if (!place) return;
      let cep: string[] | undefined;
      let rua: string[] | undefined;
      let bairro: string[] | undefined;
      let cidade: string[] | undefined;
      let estado: string[] | undefined;
      let numero: string[] | undefined;
      let nome: string | undefined;

      if (place.address_components) {
        cep = obterEnderecoGoole(place, 'postal_code');
        rua = obterEnderecoGoole(place, 'route');
        bairro = obterEnderecoGoole(place, 'sublocality_level_1');
        cidade = obterEnderecoGoole(place, 'administrative_area_level_2');
        numero = obterEnderecoGoole(place, 'street_number');
        estado = place.address_components
          .filter(function (it) {
            return it.types.indexOf('administrative_area_level_1') !== -1;
          })
          .map(function (it) {
            return it.short_name;
          });
        nome = place.name;
      }

      if (numero && numero.length > 0) {
        if (isNaN(+numero[0])) {
          numero = [];
        }
      }

      const enderecoGeoLocalizacao: EnderecoGeoLocalizacao = {
        descricaoLocal: place?.formatted_address ?? '',
        latitude: fullLocation?.lat ?? place.geometry?.location?.lat() ?? 0,
        longitude: fullLocation?.lng ?? place.geometry?.location?.lng() ?? 0,
        placeId: place?.place_id ?? '',
        bairro: bairro ? bairro[0] : '',
        cidade: cidade ? cidade[0] : '',
        estado: estado ? estado[0] : '',
        cep: cep ? cep[0] : '',
        numero: numero && numero.length > 0 ? +numero[0] : 0,
        rua: rua ? rua[0] : ''
      };

      paciente.enderecoDestino = {
        ...paciente.enderecoDestino,
        nome: nome ?? '',
        enderecoGeolocalizacao: enderecoGeoLocalizacao ?? null
      };

      editarPaciente(paciente);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [editarPaciente, paciente, placeDestinoToEdit]
  );

  function obterEnderecoGoole(
    place: google.maps.places.PlaceResult,
    type: string
  ): string[] | undefined {
    return place.address_components
      ?.filter(function (it) {
        return it.types.indexOf(type) !== -1;
      })
      .map(function (it) {
        return it.long_name;
      });
  }

  function validarCpfOnBlur() {
    if (paciente.cpf) {
      if (!(paciente.cpf?.replace(/[^\w\s]/gi, '')?.trim().length >= 12)) {
        if (!validarCPF(paciente.cpf)) {
          setCpfMessage('Documento inválido');
          return;
        } else {
          setCpfMessage('');
        }
      }
    }
  }

  async function setarExibirIconCampoEnderecoDestino(value: boolean) {
    setExibirEnderecoDestinoMaps(value);
  }

  async function setarExibirIconCampoEnderecoOrigem(value: boolean) {
    setExibirEnderecoOrigemMaps(value);
  }

  async function adicionarEnderecoDestinoMotivo(value: string) {
    const enderecoSelecionado = wacthEnderecosDestinosMotivoAtendimento?.find(
      (x) => x.enderecoId === value
    );
    setValue(
      `pacientes.${index}.enderecoDestino.complemento`,
      enderecoSelecionado?.complemento ?? ''
    );
    if (enderecoSelecionado) paciente.enderecoDestino = enderecoSelecionado;
    await editarPaciente(paciente);
  }

  async function adicionarEnderecoOrigemCliente(value: string) {
    const enderecoSelecionado = watchEnderecosOrigemCliente?.find((x) => x.enderecoId === value);

    setValue(
      `pacientes.${index}.enderecoOrigem.complemento`,
      enderecoSelecionado?.complemento ?? ''
    );
    if (enderecoSelecionado) paciente.enderecoOrigem = enderecoSelecionado;
    await editarPaciente(paciente);
  }

  useEffect(() => {
    if (paciente.enderecoOrigem && paciente.enderecoOrigem.enderecoGeolocalizacao) {
      setExibirEnderecoOrigemMaps(true);
    } else {
      if (watchEnderecosOrigemCliente && watchEnderecosOrigemCliente?.length > 0) {
        setExibirEnderecoOrigemMaps(false);
      } else {
        setExibirEnderecoOrigemMaps(true);
      }
    }
  }, [paciente.enderecoOrigem, watchEnderecosOrigemCliente]);

  useEffect(() => {
    if (paciente.enderecoDestino && paciente.enderecoDestino.enderecoGeolocalizacao) {
      setExibirEnderecoDestinoMaps(true);
    } else {
      if (
        wacthEnderecosDestinosMotivoAtendimento &&
        wacthEnderecosDestinosMotivoAtendimento?.length > 0
      ) {
        setExibirEnderecoDestinoMaps(false);
      } else {
        setExibirEnderecoDestinoMaps(true);
      }
    }
  }, [paciente.enderecoDestino, wacthEnderecosDestinosMotivoAtendimento]);

  const possuiPacientes = getValues('pacientes').length;

  const permiteEditarEmAndamento = paciente.statusPaciente === StatusAtendimentoEnum.EM_ANDAMENTO;

  const permiteFaseRegulacao = paciente.faseAtendimento === FasesAtendimentoEnum.FASEREGULACAO;

  const liberarEditarMedicoRegulador = () => {
    if (!possuiPacientes) return { liberar: true, medico: true };

    if (permiteEditarEmAndamento && permiteFaseRegulacao) return { liberar: true, medico: true };

    if (permiteEditarEmAndamento && !permiteFaseRegulacao) return { liberar: true, medico: false };

    return { liberar: false, medico: false };
  };

  return (
    <>
      <div>
        <Grid container item rowSpacing={3} columnSpacing={2} px={1}>
          <Grid item xs={6} pb={!paciente.pacienteId ? 2 : 0}>
            <ProtectedContent mode="disable" access={MenuAtendimentoClaims.ATUALIZAR}>
              <FormTextField
                label="Nome"
                name={`pacientes.${index}.nome`}
                onBlur={() => {
                  isDirty && editarPaciente(paciente);
                }}
                disabled={
                  medicoRegulador
                    ? !liberarEditarMedicoRegulador().liberar ||
                      !liberarEditarMedicoRegulador().medico
                    : disableForm
                }
              />
            </ProtectedContent>
          </Grid>
          {paciente.pacienteId && (
            <>
              <Grid item xs={6}>
                <ProtectedContent mode="disable" access={MenuAtendimentoClaims.ATUALIZAR}>
                  <FormNumberFormat
                    control={control}
                    format={'###.###.###-##'}
                    name={`pacientes.${index}.cpf`}
                    label="CPF"
                    placeholder="CPF"
                    onBlur={() => {
                      validarCpfOnBlur();
                      isDirty && editarPaciente(paciente);
                    }}
                    disabled={
                      medicoRegulador
                        ? !liberarEditarMedicoRegulador().liberar ||
                          !liberarEditarMedicoRegulador().medico
                        : disableForm
                    }
                  />
                </ProtectedContent>
                <ErrorMessage error={cpfMessage} />
              </Grid>
              <Grid item xs={6}>
                <ProtectedContent mode="disable" access={MenuAtendimentoClaims.ATUALIZAR}>
                  <FormTextField
                    name={`pacientes.${index}.idade`}
                    label="Idade"
                    type={'number'}
                    onBlur={() => {
                      paciente.idade = paciente.idade ? paciente.idade : 0;
                      isDirty && editarPaciente(paciente);
                    }}
                    disabled={
                      medicoRegulador
                        ? !liberarEditarMedicoRegulador().liberar ||
                          !liberarEditarMedicoRegulador().medico
                        : disableForm
                    }
                  />
                </ProtectedContent>
              </Grid>
              <Grid item xs={6}>
                <ProtectedContent mode="disable" access={MenuAtendimentoClaims.ATUALIZAR}>
                  <FormSelect
                    control={control}
                    name={`pacientes.${index}.sexo`}
                    onBlur={() => {
                      editarPaciente(paciente);
                    }}
                    label="Sexo"
                    disabled={
                      medicoRegulador
                        ? !liberarEditarMedicoRegulador().liberar ||
                          !liberarEditarMedicoRegulador().medico
                        : disableForm
                    }
                  >
                    <MenuItem key={0} value={0}>
                      Masculino
                    </MenuItem>
                    <MenuItem key={1} value={1}>
                      Feminino
                    </MenuItem>
                  </FormSelect>
                </ProtectedContent>
              </Grid>
            </>
          )}
        </Grid>
        {paciente.pacienteId && (
          <>
            <Grid
              container
              item
              rowSpacing={3}
              columnSpacing={3}
              px={accordionSummary ? 1 : 2.5}
              pt={2.5}
            >
              <Grid item container xs={12} spacing={2}>
                <Grid item xs={6} mt={1}>
                  {exibirEnderecoOrigemMaps ? (
                    <Controller
                      name={`pacientes.${index}.enderecoOrigem`}
                      control={control}
                      render={() => (
                        <>
                          <AutoCompleteMaps
                            InputProps={{
                              startAdornment: (
                                <>
                                  {exibirEnderecoOrigemMaps ? (
                                    <IconButton
                                      title="Selecionar endereços do cliente"
                                      onClick={() => {
                                        setarExibirIconCampoEnderecoOrigem(false);
                                      }}
                                    >
                                      <Icon path={mdiMapMarkerAccount} size={1} color={'#FA8072'} />
                                    </IconButton>
                                  ) : (
                                    <IconButton
                                      title="Selecionar endereço pelo google maps"
                                      onClick={() => {
                                        setarExibirIconCampoEnderecoOrigem(true);
                                      }}
                                    >
                                      <Icon path={mdiMapSearch} size={1} color={'#FA8072'} />
                                    </IconButton>
                                  )}
                                </>
                              )
                            }}
                            onPlaceChange={(
                              place: google.maps.places.PlaceResult,
                              placeId?: string | undefined,
                              fullLocation?: FullLocationModel | undefined
                            ) => {
                              adicionarEnderecoOrigem(place, fullLocation);
                            }}
                            defalutPlace={
                              paciente?.enderecoOrigem?.enderecoGeolocalizacao?.descricaoLocal ?? ''
                            }
                            label="Endereço origem"
                            disabled={!podeEditarEndereco}
                          />
                        </>
                      )}
                    />
                  ) : (
                    <Controller
                      name={`pacientes.${index}.enderecoOrigem`}
                      control={control}
                      render={({ field: { onChange, value } }) => {
                        return (
                          <>
                            <FormSelect
                              onChange={(event: SelectChangeEvent<unknown>) => {
                                onChange(event.target.value);
                                adicionarEnderecoOrigemCliente(event.target.value as string);
                              }}
                              startAdornment={
                                <>
                                  {exibirEnderecoOrigemMaps ? (
                                    <IconButton
                                      title="Selecionar endereços do cliente"
                                      onClick={() => {
                                        setarExibirIconCampoEnderecoOrigem(false);
                                      }}
                                    >
                                      <Icon path={mdiMapMarkerAccount} size={1} color={'#FA8072'} />
                                    </IconButton>
                                  ) : (
                                    <IconButton
                                      title="Selecionar endereço pelo google maps"
                                      onClick={() => {
                                        setarExibirIconCampoEnderecoOrigem(true);
                                      }}
                                    >
                                      <Icon path={mdiMapSearch} size={1} color={'#FA8072'} />
                                    </IconButton>
                                  )}
                                </>
                              }
                              value={value ?? ''}
                              name="enderecoId"
                              label="Endereço origem"
                              disabled={!podeEditarEndereco}
                            >
                              {watchEnderecosOrigemCliente?.map((x) => {
                                return (
                                  <MenuItem key={x.enderecoId!} value={x.enderecoId!}>
                                    {x.nome}
                                  </MenuItem>
                                );
                              })}
                            </FormSelect>
                          </>
                        );
                      }}
                    />
                  )}
                </Grid>
                <Grid item xs={6}>
                  <FormTextField
                    name={`pacientes.${index}.enderecoOrigem.complemento`}
                    fullWidth
                    size="small"
                    label="Complemento endereço origem"
                    onBlur={() => {
                      editarPaciente(paciente);
                    }}
                    disabled={!podeEditarEndereco}
                  />
                </Grid>
              </Grid>

              <Grid item container xs={12} spacing={2}>
                <Grid item xs={6} mt={1}>
                  {exibirEnderecoDestinoMaps ? (
                    <Controller
                      name={`pacientes.${index}.enderecoDestino`}
                      control={control}
                      render={() => (
                        <>
                          <AutoCompleteMaps
                            InputProps={{
                              startAdornment: (
                                <>
                                  {exibirEnderecoDestinoMaps ? (
                                    <IconButton
                                      title="Selecionar endereço do motivo atendimento"
                                      onClick={() => {
                                        setarExibirIconCampoEnderecoDestino(false);
                                      }}
                                    >
                                      <Icon
                                        path={mdiMapMarkerAccount}
                                        size={1}
                                        color={tema.light?.primary}
                                      />
                                    </IconButton>
                                  ) : (
                                    <IconButton
                                      title="Selecionar endereço pelo google maps"
                                      onClick={() => {
                                        setarExibirIconCampoEnderecoDestino(true);
                                      }}
                                    >
                                      <Icon
                                        path={mdiMapSearch}
                                        size={1}
                                        color={tema.light?.primary}
                                      />
                                    </IconButton>
                                  )}
                                </>
                              )
                            }}
                            onPlaceChange={(
                              place: google.maps.places.PlaceResult,
                              placeId?: string | undefined,
                              fullLocation?: FullLocationModel | undefined
                            ) => {
                              adicionarEnderecoDestino(place, fullLocation);
                            }}
                            defalutPlace={
                              paciente?.enderecoDestino?.enderecoGeolocalizacao?.descricaoLocal ??
                              ''
                            }
                            label="Endereço destino"
                            disabled={!podeEditarEndereco}
                          />
                        </>
                      )}
                    />
                  ) : (
                    <Controller
                      name={`pacientes.${index}.enderecoDestino`}
                      control={control}
                      render={({ field: { onChange, value } }) => {
                        return (
                          <>
                            <FormSelect
                              onChange={(event: SelectChangeEvent<unknown>) => {
                                onChange(event.target.value);
                                adicionarEnderecoDestinoMotivo(event.target.value as string);
                              }}
                              startAdornment={
                                <>
                                  {exibirEnderecoDestinoMaps ? (
                                    <IconButton
                                      title="Selecionar endereço do motivo atendimento"
                                      onClick={() => {
                                        setarExibirIconCampoEnderecoDestino(false);
                                      }}
                                    >
                                      <Icon
                                        path={mdiMapMarkerAccount}
                                        size={1}
                                        color={tema.light?.primary}
                                      />
                                    </IconButton>
                                  ) : (
                                    <IconButton
                                      title="Selecionar endereço pelo google maps"
                                      onClick={() => {
                                        setarExibirIconCampoEnderecoDestino(true);
                                      }}
                                    >
                                      <Icon
                                        path={mdiMapSearch}
                                        size={1}
                                        color={tema.light?.primary}
                                      />
                                    </IconButton>
                                  )}
                                </>
                              }
                              value={value ?? ''}
                              name="enderecoId"
                              label="Endereço destino"
                              disabled={!podeEditarEndereco}
                            >
                              {wacthEnderecosDestinosMotivoAtendimento?.map((x) => {
                                return (
                                  <MenuItem key={x.enderecoId ?? ''} value={x.enderecoId ?? ''}>
                                    {x.nome}
                                  </MenuItem>
                                );
                              })}
                            </FormSelect>
                          </>
                        );
                      }}
                    />
                  )}
                </Grid>

                <Grid item xs={6}>
                  <FormTextField
                    name={`pacientes.${index}.enderecoDestino.complemento`}
                    label="Complemento endereço destino"
                    size="small"
                    onBlur={() => {
                      editarPaciente(paciente);
                    }}
                    disabled={!podeEditarEndereco}
                  />
                </Grid>
              </Grid>

              <Grid item xs={6}>
                <Controller
                  name={`pacientes.${index}.somenteIda`}
                  control={control}
                  render={({ field: { onChange, value } }) => {
                    return (
                      <FormControlLabel
                        label={value ? 'Apenas ida' : 'Ida e volta'}
                        control={
                          <ProtectedContent mode="disable" access={MenuAtendimentoClaims.ATUALIZAR}>
                            <Switch
                              onChange={onChange}
                              checked={value ?? true}
                              onBlur={() => {
                                editarPaciente(paciente);
                              }}
                              disabled={
                                medicoRegulador
                                  ? !liberarEditarMedicoRegulador().liberar ||
                                    !liberarEditarMedicoRegulador().medico
                                  : disableForm
                              }
                            />
                          </ProtectedContent>
                        }
                      />
                    );
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <Box sx={{ display: 'flex' }}>
                  <Controller
                    name={`pacientes.${index}.urgencia`}
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <>
                        <ToggleButtonGroup
                          value={value ? +value : 0}
                          exclusive
                          onChange={(event) => {
                            onChange(event);
                            editarPaciente(paciente);
                          }}
                          disabled={disableForm}
                        >
                          <ToggleButton
                            value={ClassificacaoUrgenciaEnum.Emergencia}
                            sx={{
                              backgroundColor: 'transparent',
                              color: Colors.red1,
                              borderTop: `8px solid ${Colors.red1}`,
                              '&.Mui-selected': {
                                backgroundColor: Colors.red1,
                                color: Colors.white,
                                '&:hover': {
                                  backgroundColor: Colors.red1
                                }
                              },
                              '&:hover': {
                                backgroundColor: Colors.red1,
                                color: Colors.white
                              },
                              height: '40px',
                              fontSize: '11px'
                            }}
                          >
                            {ClassificacaoUrgenciaEnumLabel.get(
                              ClassificacaoUrgenciaEnum.Emergencia
                            )}
                          </ToggleButton>
                          <ToggleButton
                            value={ClassificacaoUrgenciaEnum.MuitoUrgente}
                            sx={{
                              backgroundColor: 'transparent',
                              color: Colors.orange1,
                              borderTop: `8px solid ${Colors.orange1}`,
                              '&.Mui-selected': {
                                backgroundColor: Colors.orange1,
                                color: Colors.white,
                                '&:hover': {
                                  backgroundColor: Colors.orange1
                                }
                              },
                              '&:hover': {
                                backgroundColor: Colors.orange1,
                                color: Colors.white
                              },
                              height: '40px',
                              fontSize: '11px'
                            }}
                          >
                            {ClassificacaoUrgenciaEnumLabel.get(
                              ClassificacaoUrgenciaEnum.MuitoUrgente
                            )}
                          </ToggleButton>
                          <ToggleButton
                            value={ClassificacaoUrgenciaEnum.Urgente}
                            sx={{
                              backgroundColor: 'transparent',
                              color: Colors.yellow1,
                              borderTop: `8px solid ${Colors.yellow1}`,
                              '&.Mui-selected': {
                                backgroundColor: Colors.yellow1,
                                color: Colors.white,
                                '&:hover': {
                                  backgroundColor: Colors.yellow1
                                }
                              },
                              '&:hover': {
                                backgroundColor: Colors.yellow1,
                                color: Colors.white
                              },
                              height: '40px',
                              fontSize: '11px'
                            }}
                          >
                            {ClassificacaoUrgenciaEnumLabel.get(ClassificacaoUrgenciaEnum.Urgente)}
                          </ToggleButton>
                          <ToggleButton
                            value={ClassificacaoUrgenciaEnum.PoucoUrgente}
                            sx={{
                              backgroundColor: 'transparent',
                              color: Colors.green,
                              borderTop: `8px solid ${Colors.green}`,
                              '&.Mui-selected': {
                                backgroundColor: Colors.green,
                                color: Colors.white,
                                '&:hover': {
                                  backgroundColor: Colors.green
                                }
                              },
                              '&:hover': {
                                backgroundColor: Colors.green,
                                color: Colors.white
                              },
                              height: '40px',
                              fontSize: '11px'
                            }}
                          >
                            {ClassificacaoUrgenciaEnumLabel.get(
                              ClassificacaoUrgenciaEnum.PoucoUrgente
                            )}
                          </ToggleButton>
                          <ToggleButton
                            value={ClassificacaoUrgenciaEnum.NaoUrgente}
                            sx={{
                              backgroundColor: 'transparent',
                              color: Colors.blue,
                              borderTop: `8px solid ${Colors.blue}`,
                              '&.Mui-selected': {
                                backgroundColor: Colors.blue,
                                color: Colors.white,
                                '&:hover': {
                                  backgroundColor: Colors.blue
                                }
                              },
                              '&:hover': {
                                backgroundColor: Colors.blue,
                                color: Colors.white
                              },
                              height: '40px',
                              fontSize: '11px'
                            }}
                          >
                            {ClassificacaoUrgenciaEnumLabel.get(
                              ClassificacaoUrgenciaEnum.NaoUrgente
                            )}
                          </ToggleButton>
                        </ToggleButtonGroup>
                      </>
                    )}
                  />
                </Box>
              </Grid>
            </Grid>
          </>
        )}
      </div>
    </>
  );
};

export default FormInformacoesPaciente;
