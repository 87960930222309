import { Box } from '@mui/material';
import { OptGridRequest, OptSideLayoutContent } from '@optsol/react';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { MenuEmpresaClaims } from '../../../models/enums/AcessoClaims';
import { RoutesObj } from '../../../routes/index.routes';
import { useEmpresaService } from '../../../service/empresa.service';
import { PaginatedSearchRequest, SearchRequest } from '../../../shared/utils/searchRequest';
import { ProtectedContent } from '../../components/ProtectedContent/ProtectedContent';
import { useAuthenticationContext } from '../../contexts/authentication/authenticationContext';

import GridEmpresas from './GridEmpresas';
import { ToolbarEmpresa } from './ToolbarEmpresa';

const Empresas = () => {
  const [empresaSearch, setEmpresaSearch] = useState('');
  const { buscarGridEmpresasPaginated } = useEmpresaService();
  const navigate = useNavigate();

  const { hasAccess } = useAuthenticationContext();

  function procurarCliente(data?: string) {
    setEmpresaSearch(data ?? '');
  }

  const carregar = useCallback(
    async (
      query: OptGridRequest,
      empresaSearchRequest: PaginatedSearchRequest = {
        termoBuscado: empresaSearch,
        apenasAtivo: false
      }
    ) => {
      const request: SearchRequest<PaginatedSearchRequest> = {
        page: query.page,
        pageSize: query.pageSize,
        search: empresaSearchRequest
      };
      return buscarGridEmpresasPaginated(request);
    },
    [buscarGridEmpresasPaginated, empresaSearch]
  );

  const editarEmpresaHandler = useCallback(
    (empresaId: string) => {
      navigate(RoutesObj.EmpresaAdmin.Detalhes(empresaId));
    },
    [navigate]
  );
  useEffect(() => {
    if (!hasAccess(MenuEmpresaClaims.MENUS__MENU_EMPRESA_OPTSOL)) {
      navigate(RoutesObj.Home);
    }
  }, [hasAccess, navigate]);
  return (
    <OptSideLayoutContent>
      <ProtectedContent access={MenuEmpresaClaims.MENUS__MENU_EMPRESA_OPTSOL}>
        <ToolbarEmpresa filtrarGridBySearch={procurarCliente} />
      </ProtectedContent>
      <Box px={2.5} pt={2}>
        <ProtectedContent access={MenuEmpresaClaims.MENUS__MENU_EMPRESA_OPTSOL}>
          <GridEmpresas editarEmpresa={editarEmpresaHandler} carregar={carregar}></GridEmpresas>
        </ProtectedContent>
      </Box>
    </OptSideLayoutContent>
  );
};

export default Empresas;
