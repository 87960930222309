import * as yup from 'yup';

const MSG_REQUIRED = 'Campo obrigatório';

export interface ContatoModel {
  contatoId: string;
  email: string;
  telefoneFixo: string;
  telefoneCelular: string;
  clienteId: string;
  telefone?: string;
  nomeContato: string;
  cpf: string;
}

export interface CriarContatoModel {
  email?: string | null;
  telefone?: string | null;
  contatoId?: string | null;
  nomeContato: string;
  cpf?: string;
}

export const DADOS_CRIAR_CONTATO_DEFAULT: CriarContatoModel = {
  email: '',
  telefone: '',
  contatoId: null,
  nomeContato: '',
  cpf: ''
};
const regexEmail = new RegExp('[a-z0-9]+@[a-z]+.[a-z]{2,3}');
export const dadosCriarContatoFormModelSchema: yup.SchemaOf<CriarContatoModel> = yup
  .object()
  .shape({
    email: yup
      .string()
      .notRequired()
      .nullable()
      .test('Teste email', 'E-mail inválido', (value) => {
        if (!value) return true;
        return regexEmail.test(value.trim());
      }),
    telefone: yup
      .string()
      .notRequired()
      .nullable()
      .test('Teste telefone', 'Telefone inválido', (value) => {
        if (!value) {
          return true;
        } else {
          return !(value.trim().length < 14 && value.trim().length < 15);
        }
      }),
    nomeContato: yup.string().required(MSG_REQUIRED),
    contatoId: yup.string().notRequired().nullable(),
    cpf: yup.string().notRequired()
  });
