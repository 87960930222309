export const Colors = {
  primary: '#5e7efb', //

  primaryContrast: '#5e7efb',

  primaryTints: {
    tint1: '#4158af',
    tint2: '#5e7efb',
    tint3: '#7e97fb',
    tint4: '#97abfb',
    tint5: '#abbbfb',
    tint6: '#bbc8fb'
  },

  secondary: '#7ec47e',

  gray10p: 'rgba(214, 214, 214, 0.1)',
  gray20p: 'rgb(234, 238, 244, .2)', //
  gray40p: 'rgb(234, 238, 244, .4)', //
  gray60p: 'rgba(234, 238, 244, 0.6)', //

  gray: '#25282D',
  gray1: '#333333',
  gray2: '#454854',
  gray3: '#757575',
  gray4: '#828282',
  gray5: '#b5b5b5',
  gray6: '#cccccc',
  gray7: '#d9d9d9',
  gray8: '#ebebeb',
  gray9: '#EAEEF4',
  gray10: '#F1F2F8',
  gray11: '#F5F5F5',
  gray12: '#F4F7FE',

  white: '#FFFFFF',

  blue: '#2F80ED',
  blue1: '#6dd1d1',
  blue2: '#5f9ae8',
  blue3: '#A3AED0',

  green: '#7ec47e',
  green1: 'green',
  green2: '#98d998',

  ciano: '#008B8B',
  ciano1: '#27a39e',

  red: '#E20000',
  red1: '#ed6d6d',
  red2: 'rgba(226, 0, 0, 0.2)',

  orange: '#ffa500',
  orange1: '#eba76c',
  darkOrange: '#FF8C00',

  yellow: '#f7f01b',
  yellow1: '#e6d520',

  black: '#000000',

  prioridade: {
    emergencia: '#C61620',
    muitoUrgente: '#F78222',
    urgente: '#F3C800',
    poucourgente: '#027E3F',
    naoUrgente: '#00ACEE'
  },

  ambulancia: {
    disponivel: '#008000',
    emAtendimento: '#FFD336'
  },

  timeline: {
    primary: '#b7bfef', //secondaryContrast
    secondary: '#4e4e59' //primaryContrast
  }
} as const;
