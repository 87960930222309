import {
  mdiCalendarAccountOutline,
  mdiCheckboxMarkedCircleOutline,
  mdiCloseCircleOutline,
  mdiExclamationThick,
  mdiPoll,
  mdiSwapVertical
} from '@mdi/js';
import Box from '@mui/material/Box';
import { useEffect, useState } from 'react';

import { ChartDataModel, INIT_INDICADORES } from '../../../../models/dashboards/DashboardsModel';
import { IndicadoresEnum } from '../../../../models/enums/Indicadores';
import CardIndicador from '../../../components/CardIndicador/CardIndicador';

interface Props {
  data: ChartDataModel[];
}

// enum IndicadoresEnum {
//   EmAndamento = 1,
//   Encerrado = 2,
//   Pendente = 3,
//   Cancelado = 4,
//   Agendado = 6,
//   TotalDeChamados = 99
// }

const Indicadores = ({ data }: Props) => {
  const [orderData, setOrderData] = useState(INIT_INDICADORES);

  const renderIndicador = () => {
    const totalChamadosData = data.find(
      (item) => parseInt(item.id) === IndicadoresEnum.TotalDeChamados
    );
    totalChamadosData &&
      setOrderData((prevState) => ({ ...prevState, totalChamados: totalChamadosData }));

    const emAndamentoData = data.find((item) => parseInt(item.id) === IndicadoresEnum.EmAndamento);
    emAndamentoData &&
      setOrderData((prevState) => ({ ...prevState, emAndamento: emAndamentoData }));

    const agendadosData = data.find((item) => parseInt(item.id) === IndicadoresEnum.Agendado);
    agendadosData && setOrderData((prevState) => ({ ...prevState, agendados: agendadosData }));

    const pendentesData = data.find((item) => parseInt(item.id) === IndicadoresEnum.Pendente);
    pendentesData && setOrderData((prevState) => ({ ...prevState, pendentes: pendentesData }));

    const canceladosData = data.find((item) => parseInt(item.id) === IndicadoresEnum.Cancelado);
    canceladosData && setOrderData((prevState) => ({ ...prevState, cancelados: canceladosData }));

    const finalizadosData = data.find((item) => parseInt(item.id) === IndicadoresEnum.Finalizado);
    finalizadosData &&
      setOrderData((prevState) => ({ ...prevState, finalizados: finalizadosData }));
  };

  useEffect(() => {
    renderIndicador();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box
      gap={5}
      display="grid"
      justifyContent="center"
      gridTemplateColumns="repeat(auto-fit, 205px)"
      mb={2.5}
      mt={0.5}
    >
      <CardIndicador
        titulo={'Total de chamados'}
        valor={orderData.totalChamados.value}
        icon={mdiPoll}
      />
      <CardIndicador
        titulo={'Em andamento'}
        valor={orderData.emAndamento.value}
        icon={mdiSwapVertical}
        iconSize={1.4}
      />
      <CardIndicador
        titulo={'Agendados'}
        valor={orderData.agendados.value}
        icon={mdiCalendarAccountOutline}
        iconSize={1.2}
      />
      <CardIndicador
        titulo={'Pendentes'}
        valor={orderData.pendentes.value}
        icon={mdiExclamationThick}
        iconSize={1.2}
      />
      <CardIndicador
        titulo={'Cancelados'}
        valor={orderData.cancelados.value}
        icon={mdiCloseCircleOutline}
        iconSize={1.2}
      />
      <CardIndicador
        titulo={'Finalizados'}
        valor={orderData.finalizados.value}
        icon={mdiCheckboxMarkedCircleOutline}
        iconSize={1.2}
      />
    </Box>
  );
};

export default Indicadores;
