import { Grid, TextField } from '@mui/material';
import { OptBackdrop } from '@optsol/react';
import { useState } from 'react';

import Button from '../../../components/Button/Button';

interface Props {
  onClickButton: (value: string) => Promise<void>;
  label: string;
  defaultValue?: string;
}

export const ModalJustificativa = ({ onClickButton, label, defaultValue }: Props) => {
  const [justificativa, setJustificativa] = useState<string>(defaultValue ?? '');
  const [actionLoading, setActionLoading] = useState<boolean>(false);

  async function onClickButtonHandler() {
    try {
      setActionLoading(true);
      await onClickButton(justificativa);
    } finally {
      setActionLoading(false);
    }
  }

  return (
    <Grid container item rowSpacing={3} columnSpacing={3} px={2.5} pt={2.5}>
      <Grid item xs={12}>
        <TextField
          inputProps={{ maxLength: 2000 }}
          onChange={(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
            setJustificativa(event.target.value);
          }}
          value={justificativa}
          label="Justificativa"
          variant="outlined"
          size="small"
          fullWidth
          multiline
          rows={2}
          maxRows={4}
        />
        {`${justificativa.length} / 2000`}
      </Grid>
      <Grid item xs={12}>
        <Button
          onClick={onClickButtonHandler}
          label={label}
          variant="primary"
          disabled={!justificativa}
        />
      </Grid>
      <OptBackdrop open={actionLoading} />
    </Grid>
  );
};
