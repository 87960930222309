import { Colors } from '../../../core/colors';
import {
  ClassificacaoUrgenciaEnum,
  ClassificacaoUrgenciaEnumLabel
} from '../../../models/enums/ClassificacaoUrgencia';

export const renderStatusColor = (status: number) => {
  switch (status) {
    case ClassificacaoUrgenciaEnum.Emergencia:
      return {
        color: Colors.red1,
        texto: ClassificacaoUrgenciaEnumLabel.get(ClassificacaoUrgenciaEnum.Emergencia)
      };
    case ClassificacaoUrgenciaEnum.MuitoUrgente:
      return {
        color: Colors.orange1,
        texto: ClassificacaoUrgenciaEnumLabel.get(ClassificacaoUrgenciaEnum.MuitoUrgente)
      };
    case ClassificacaoUrgenciaEnum.Urgente:
      return {
        color: Colors.yellow1,
        texto: ClassificacaoUrgenciaEnumLabel.get(ClassificacaoUrgenciaEnum.Urgente)
      };
    case ClassificacaoUrgenciaEnum.PoucoUrgente:
      return {
        color: Colors.green,
        texto: ClassificacaoUrgenciaEnumLabel.get(ClassificacaoUrgenciaEnum.PoucoUrgente)
      };
    case ClassificacaoUrgenciaEnum.NaoUrgente:
      return {
        color: Colors.blue,
        texto: ClassificacaoUrgenciaEnumLabel.get(ClassificacaoUrgenciaEnum.NaoUrgente)
      };
    case ClassificacaoUrgenciaEnum.SemClassificacao:
      return {
        color: Colors.gray3,
        texto: ClassificacaoUrgenciaEnumLabel.get(ClassificacaoUrgenciaEnum.SemClassificacao)
      };
    default:
      return { color: Colors.gray3, texto: '-' };
  }
};
