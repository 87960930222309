import { yupResolver } from '@hookform/resolvers/yup';
import { mdiClipboardTextClockOutline } from '@mdi/js';
import { Box } from '@mui/material';
import {
  OptActionToolbar,
  OptBackdrop,
  OptLoading,
  OptSideLayoutContent,
  OptTimeline,
  OptTimelineAction
} from '@optsol/react';
import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';

import { MenuPlanoClaims } from '../../../models/enums/AcessoClaims';
import {
  DADOS_PLANO_DEFAULT,
  dadosPlanoFormModelSchema,
  PlanoModel
} from '../../../models/Plano/Plano.model';
import { RoutesObj } from '../../../routes/index.routes';
import { useHistoricoService } from '../../../service/historico.service';
import { usePlanoService } from '../../../service/plano.service';
import { BotaoDeAcaoToolbar } from '../../components/Button/BotaoDeAcaoToolbar';
import { Modal } from '../../components/Modal/Modal';
import { ProtectedContent } from '../../components/ProtectedContent/ProtectedContent';
import { ToolbarContainer } from '../../components/ToolbarContainer';
import { useAuthenticationContext } from '../../contexts/authentication/authenticationContext';

import Abrangencia from './abrangencia/Abrangencia';
import FormEditarPlano from './FormEditarPlano';

const EditarPlano = () => {
  const [loadingPlano, setLoadingPlano] = useState<boolean>(false);
  const [modalHistorico, setModalHistorico] = useState<boolean>(false);
  const [timeLineData, setTimeLineData] = useState<OptTimelineAction[]>();
  const { id } = useParams<{ id: string }>();
  const { obterPlanoData, editarPlano } = usePlanoService();
  const { obterHistoryEntidade } = useHistoricoService();
  const { state } = useAuthenticationContext();
  useState<PlanoModel>(DADOS_PLANO_DEFAULT);

  const form = useForm<PlanoModel>({
    mode: 'onBlur',
    defaultValues: DADOS_PLANO_DEFAULT,
    resolver: yupResolver(dadosPlanoFormModelSchema)
  });

  const editarPlanoHandler = async () => {
    try {
      setLoadingPlano(true);
      const planoEditar = form.getValues();
      await editarPlano(id ?? '', planoEditar);
      form.reset(form.getValues());
    } finally {
      setLoadingPlano(false);
    }
  };

  const carregar = async (id: string) => {
    setLoadingPlano(true);
    let data = DADOS_PLANO_DEFAULT;
    const result = await obterPlanoData(id);
    data = result.data;
    form.reset(data);
    setLoadingPlano(false);
  };

  const toggleModal = async () => {
    setModalHistorico((prevState) => !prevState);
  };

  useEffect(() => {
    if (modalHistorico && !timeLineData) {
      const obter = async () => {
        try {
          const historyData = await obterHistoryEntidade(
            'Plano',
            state.userInfo.data?.tenantId ?? '', //"712A7D07-A57C-4F78-8F9F-66DDF61A44C0",
            id as string
          );

          const data = historyData.versions.sort((a, b) => (a.order > b.order ? -1 : 1));
          data.map((history: { dateTimeAction: string | number | Date }) => {
            const newDate = new Date(history.dateTimeAction);
            const dataFormatada = new Intl.DateTimeFormat('pt-BR', {
              year: 'numeric',
              month: '2-digit',
              day: '2-digit',
              hour: '2-digit',
              hourCycle: 'h23',
              minute: '2-digit'
            }).format(newDate);
            return (history.dateTimeAction = dataFormatada);
          });
          setTimeLineData(data);
        } catch {
          console.log('Erro ao tentar carregar timeline values');
          toggleModal();
        }
      };
      obter();
    } else {
      setTimeout(() => {
        setTimeLineData(undefined);
      }, 300);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalHistorico]);

  useEffect(() => {
    carregar(id ? id : '');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return (
    <>
      <OptSideLayoutContent>
        <ToolbarContainer>
          <OptActionToolbar
            goBackRoute={window.history.state.usr?.prevUrl ?? RoutesObj.Plano.Planos}
            title="Editar plano"
            color={state.tema.light?.primary}
            clearMargin
          >
            <ProtectedContent access={MenuPlanoClaims.VISUALIZAR}>
              <BotaoDeAcaoToolbar
                onClick={toggleModal}
                texto="Histórico"
                startIcon={mdiClipboardTextClockOutline}
              />
            </ProtectedContent>
          </OptActionToolbar>
        </ToolbarContainer>
        <Modal open={modalHistorico} onClose={toggleModal} title="Histórico" width="800px">
          <div style={{ marginTop: '1em' }}>
            {timeLineData && (
              <OptTimeline
                data={timeLineData ?? []}
                valuesTableOptions={{
                  nameHeader: 'Name',
                  valueHeader: 'Value'
                }}
              />
            )}
            {!timeLineData && (
              <div style={{ height: '100px', overflow: 'none' }}>
                <OptLoading size={50} />
              </div>
            )}
          </div>
        </Modal>
        <Box padding={2}>
          <FormProvider {...form}>
            <FormEditarPlano editarPlano={editarPlanoHandler} />
            <Abrangencia />
          </FormProvider>
        </Box>
        <OptBackdrop open={loadingPlano} />
      </OptSideLayoutContent>
    </>
  );
};

export default EditarPlano;
