import Icon from '@mdi/react';
import { Box } from '@mui/material';

import { Colors } from '../../../core/colors';
import { useAuthenticationContext } from '../../contexts/authentication/authenticationContext';

interface Props {
  titulo: string;
  valor: number;
  icon: string;
  iconSize?: number;
}

const CardIndicador = ({ titulo, valor, icon, iconSize }: Props) => {
  const {
    state: { tema }
  } = useAuthenticationContext();

  return (
    <Box
      display="flex"
      height={'75px'}
      minWidth="205px"
      borderRadius={'20px'}
      bgcolor={'white'}
      boxShadow={'rgba(0, 0, 0, 0.1) 0px 2px 2px -1px, rgba(0, 0, 0, 0.01) 0px 6px 4px -1px'}
      // boxShadow={'rgba(0, 0, 0, 0.15) 0.5px 1px 4px'}
    >
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        borderRadius={'30%'}
        width={'48px'}
        height={'48px'}
        bgcolor={Colors.gray12}
        my="auto"
        mx="10px"
        pl={0.1}
      >
        <Icon path={icon} size={iconSize ?? 1} color={tema.light?.primary ?? Colors.gray} />
      </Box>
      <Box display="flex" flexDirection="column" justifyContent="center">
        <Box fontWeight={500} fontSize={'14px'} lineHeight={'24px'} color={'#A3AED0'}>
          {titulo}
        </Box>
        <Box fontWeight={700} lineHeight={'32px'} fontSize={'24px'} color="#2B3674">
          {valor}
        </Box>
      </Box>
    </Box>
  );
};

export default CardIndicador;
