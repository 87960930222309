import { mdiClose } from '@mdi/js';
import Icon from '@mdi/react';
import { IconButton, Tooltip } from '@mui/material';
import { OptBackdrop } from '@optsol/react';
import { useState } from 'react';

import { Colors } from '../../../../core/colors';
import { Modal } from '../../../components/Modal/Modal';
import { ModalJustificativa } from '../modalJustificativa/ModalJustificativa';

interface Props {
  disableForm?: boolean;
  pacienteId: string;
  atendimentoId: string;
  cancelarChamado: (
    atendimentoId: string,
    pacienteId: string,
    justificativa: string
  ) => Promise<void>;
}

export const CancelarChamadoButton = ({
  disableForm,
  pacienteId,
  atendimentoId,
  cancelarChamado
}: Props) => {
  const [confirmacaoCancelamento, setConfirmacaoCancelamento] = useState<boolean>(false);
  const [cancelarChamadoIsLoading, setCancelarChamadoIsLoading] = useState<boolean>(false);
  const toggleConfirmacaoCancelamento = () => {
    setConfirmacaoCancelamento((prevState) => !prevState);
  };

  async function cancelarChamadoHandler(justificativa: string) {
    try {
      setCancelarChamadoIsLoading(true);
      await cancelarChamado(atendimentoId, pacienteId, justificativa);
      toggleConfirmacaoCancelamento();
    } finally {
      setCancelarChamadoIsLoading(false);
    }
  }
  return (
    <>
      <Tooltip title={'Cancelar chamado'} placement="top">
        <IconButton
          disabled={disableForm}
          color="info"
          onClick={(e) => {
            e.stopPropagation();
            toggleConfirmacaoCancelamento();
          }}
        >
          <Icon path={mdiClose} size={1} color={Colors.gray3} />
        </IconButton>
      </Tooltip>
      <Modal
        open={confirmacaoCancelamento}
        onClose={toggleConfirmacaoCancelamento}
        title="Justificativa"
        width="600px"
      >
        <ModalJustificativa onClickButton={cancelarChamadoHandler} label="Cancelar chamado" />
      </Modal>
      <OptBackdrop open={cancelarChamadoIsLoading} />
    </>
  );
};
