import { mdiPlus } from '@mdi/js';
import { Box, FormControlLabel, Switch } from '@mui/material';
import { OptActionToolbar, OptSearchField } from '@optsol/react';
import { useState } from 'react';

import { Colors } from '../../../core/colors';
import { MenuClienteClaims } from '../../../models/enums/AcessoClaims';
import { RoutesObj } from '../../../routes/index.routes';
import { obterFiltroDaRota } from '../../../shared/utils/functions';
import { BotaoDeAcaoToolbar } from '../../components/Button/BotaoDeAcaoToolbar';
import { Modal } from '../../components/Modal/Modal';
import { ProtectedContent } from '../../components/ProtectedContent/ProtectedContent';
import { ToolbarContainer } from '../../components/ToolbarContainer';
import { useAuthenticationContext } from '../../contexts/authentication/authenticationContext';
import CadastroCliente from '../../features/cliente/CadastroCliente';
import * as S from '../../style/toolbar-index';

import { ImportaClienteButton } from './ImportaClienteButton/ImportaClienteButton';

interface Props {
  filtrarGridBySearch: (data?: string) => void;
  filtrarGridByFilhos: (value?: boolean) => void;
  obterFiltroClientesAssociadosDaRota: () => boolean | undefined;
  atualizarGrid(): void;
}

export const ToolbarCliente = ({
  filtrarGridBySearch,
  filtrarGridByFilhos,
  obterFiltroClientesAssociadosDaRota,
  atualizarGrid
}: Props) => {
  const { state } = useAuthenticationContext();
  const [isOpen, setIsOpen] = useState(false);
  const toggleModal = () => {
    setIsOpen((prevState) => !prevState);
  };

  function procurarClientesVinculados(value?: boolean) {
    filtrarGridByFilhos(value);
  }

  return (
    <ToolbarContainer>
      <OptActionToolbar
        goBackRoute={RoutesObj.Home}
        title="Lista de clientes"
        background={Colors.white}
        color={state.tema.light?.primary}
        clearMargin
      >
        <Box
          sx={{
            display: 'flex',
            flex: 1,
            marginLeft: '30px',
            marginBottom: '-11px',
            alignItems: 'center'
          }}
        >
          <S.SearchContainer>
            <OptSearchField
              onSearch={filtrarGridBySearch}
              placeholder="Search"
              defaultValue={obterFiltroDaRota('termo') ?? ''}
            />
          </S.SearchContainer>
          <FormControlLabel
            sx={{ margin: 0, minWidth: 250, marginLeft: 5, marginBottom: '0.5em' }}
            label={'Incluir clientes vinculados'}
            control={
              <Switch
                onChange={(event) => {
                  procurarClientesVinculados(event.target.checked);
                }}
                defaultChecked={obterFiltroClientesAssociadosDaRota() ?? false}
              />
            }
          />
        </Box>
        <ImportaClienteButton atualizarGrid={atualizarGrid} />
        <ProtectedContent access={MenuClienteClaims.INSERIR}>
          <BotaoDeAcaoToolbar onClick={toggleModal} texto="Novo" startIcon={mdiPlus} />
        </ProtectedContent>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end'
          }}
        >
          <Modal
            open={isOpen}
            onClose={toggleModal}
            title="Cadastro de cliente"
            width="500px"
            overflow="hidden"
          >
            <CadastroCliente />
          </Modal>
        </Box>
      </OptActionToolbar>
    </ToolbarContainer>
  );
};
