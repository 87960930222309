import * as yup from 'yup';

import { validarCNPJ, validarCPF } from '../../shared/utils/functions';
import { ContatoModel } from '../Contato/Contato.model';
import { EnderecoModel } from '../Endereco/Endereco.model';
import { Sexo } from '../enums/Sexo';

import { dadosPlanoFormModelSchema, DADOS_PLANO_DEFAULT, PlanoModel } from './../Plano/Plano.model';

export interface ClienteFormModel {
  nome: string;
  cpfCnpj: string;
  planoId: string | null;
  situacao: boolean;
  dataNascimento?: string | null;
  sexo?: Sexo | null;
  clientesFilhos?: ClienteModel[];
  codigoCliente: string;
  clientePai?: ClienteModel;
  informacaoImportante?: string;
}
export const DADOS_CLLIENTE_RESPONSE_DEFAULT: ClienteModel = {
  clienteId: '',
  nome: '',
  dataNascimento: '',
  sexo: null,
  planoId: '',
  enderecos: [],
  contatos: [],
  cpf: '',
  cnpj: '',
  situacao: false,
  motivo: '',
  plano: DADOS_PLANO_DEFAULT
};
export const DADOS_CLIENTE_DEFAULT: ClienteFormModel = {
  nome: '',
  cpfCnpj: '',
  planoId: '',
  situacao: true,
  dataNascimento: '',
  sexo: null,
  clientesFilhos: [],
  codigoCliente: '',
  informacaoImportante: '',
  clientePai: DADOS_CLLIENTE_RESPONSE_DEFAULT
};
const MSG_REQUIRED = 'Campo obrigatório';
export const clienteModelSchema: yup.SchemaOf<ClienteModel> = yup.object().shape({
  clienteId: yup.string().required(MSG_REQUIRED),
  nome: yup.string().required(MSG_REQUIRED),
  dataNascimento: yup.string().required(MSG_REQUIRED),
  sexo: yup.number().required(MSG_REQUIRED),
  planoId: yup.string().required(MSG_REQUIRED),
  enderecos: yup.array().notRequired(),
  contatos: yup.array().notRequired(),
  cpf: yup
    .string()
    .notRequired()
    .test('Teste documento', 'Documento inválido', (value) => {
      if (!value) return false;
      return validarCPF(value);
    }),
  cnpj: yup
    .string()
    .notRequired()
    .test('Teste documento', 'Documento inválido', (value) => {
      if (!value) return false;
      return validarCNPJ(value);
    }),
  situacao: yup.boolean().notRequired(),
  motivo: yup.string().notRequired(),
  informacaoImportante: yup.string().notRequired(),
  plano: dadosPlanoFormModelSchema.notRequired(),
  clientePai: yup.mixed().notRequired()
});

export const dadosClienteFormModelSchema: yup.SchemaOf<ClienteFormModel> = yup.object().shape({
  nome: yup.string().required(MSG_REQUIRED),
  planoId: yup.string().required(MSG_REQUIRED),
  cpfCnpj: yup
    .string()
    .required(MSG_REQUIRED)
    .test('Teste documento', 'Documento inválido', (value) => {
      if (!value) return false;
      if (value.trim().length <= 14) return validarCPF(value);

      return validarCNPJ(value);
    }),
  situacao: yup.boolean().required(MSG_REQUIRED),
  dataNascimento: yup.string().notRequired(),
  sexo: yup.number().notRequired(),
  clientesFilhos: yup.array().notRequired(),
  informacaoImportante: yup.string().notRequired(),
  codigoCliente: yup.string().required(MSG_REQUIRED).typeError('Valor inválido'),
  clientePai: clienteModelSchema.notRequired()
});

export interface CriarClienteModel {
  nome: string;
  cpfCnpj: string;
}

export const DADOS_CRIAR_CLLIENTE_DEFAULT: CriarClienteModel = {
  nome: '',
  cpfCnpj: ''
};
export const dadosCriarClienteFormModelSchema: yup.SchemaOf<CriarClienteModel> = yup
  .object()
  .shape({
    nome: yup.string().required(MSG_REQUIRED),
    cpfCnpj: yup
      .string()
      .required(MSG_REQUIRED)
      .test('Teste documento', 'Documento inválido', (value) => {
        if (!value) return false;
        if (value.trim().length <= 14) return validarCPF(value);

        return validarCNPJ(value);
      })
  });

export interface ClienteModel {
  nome: string;
  clienteId?: string;
  dataNascimento: string;
  sexo: Sexo | null;
  planoId: string;
  plano: PlanoModel | null;
  enderecos?: EnderecoModel[];
  contatos?: ContatoModel[];
  cpf?: string;
  cnpj?: string;
  situacao?: boolean;
  motivo?: string;
  clientePai?: ClientePaiModel;
  informacaoImportante?: string;
}

export interface ClientePaiModel {
  nome: string;
  clienteId?: string;
  dataNascimento: string;
  sexo: Sexo | null;
  planoId: string;
  plano: PlanoModel | null;
  enderecos?: EnderecoModel[];
  contatos?: ContatoModel[];
  cpf?: string;
  cnpj?: string;
  situacao?: boolean;
  motivo?: string;
}
