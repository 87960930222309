import { mdiCheckboxMarkedCircleOutline } from '@mdi/js';
import Icon from '@mdi/react';
import { Button, Tooltip } from '@mui/material';
import { OptBackdrop, OptConfirmationDialog } from '@optsol/react';
import { useState } from 'react';

import { Colors } from '../../../../core/colors';
import { InformacaoPacienteFormModel } from '../../../../models/Atendimento/Paciente/InformacaoPaciente.model';
import { StatusAtendimentoEnum } from '../../../../models/enums/StatusAtendimento';

interface Props {
  paciente: InformacaoPacienteFormModel;
  concluirRemocao: (pacienteId: string) => Promise<void>;
}

export const ConcluirRemocaoButton = ({ paciente, concluirRemocao }: Props) => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  async function handleConfirm() {
    toggleModal();

    try {
      setLoading(true);
      await concluirRemocao(paciente.pacienteId ?? '');
    } finally {
      setLoading(false);
    }
  }
  const toggleModal = () => {
    setOpen((prevState) => !prevState);
  };
  return (
    <>
      <Tooltip title={'Concluir remoção'} placement="top">
        <Button
          onClick={() => {
            toggleModal();
          }}
          variant="contained"
          disabled={
            paciente.statusPaciente === StatusAtendimentoEnum.CONCLUIDO || !paciente.concluirRemocao
          }
          color={'success'}
        >
          <Icon path={mdiCheckboxMarkedCircleOutline} size={1} color={Colors.white} />
        </Button>
      </Tooltip>
      <OptConfirmationDialog
        open={open}
        title="Concluir remoção"
        icon={{ path: mdiCheckboxMarkedCircleOutline, color: Colors.green1 }}
        onCancel={toggleModal}
        onClose={toggleModal}
        onConfirm={handleConfirm}
      >
        Deseja concluir remoção?
      </OptConfirmationDialog>
      <OptBackdrop open={loading} />
    </>
  );
};
