import { mdiClipboardTextClockOutline } from '@mdi/js';
import Icon from '@mdi/react';
import { Box, Tooltip } from '@mui/material';
import { OptLoading, OptTimeline, OptTimelineAction } from '@optsol/react';
import { useEffect, useState } from 'react';

import { Colors } from '../../../../core/colors';
import { useHistoricoService } from '../../../../service/historico.service';
import { Modal } from '../../../components/Modal/Modal';
import { useAuthenticationContext } from '../../../contexts/authentication/authenticationContext';

interface Props {
  atendimentoId: string;
}

export const HistoricoPaciente = ({ atendimentoId }: Props) => {
  const [modalHistorico, setModalHistorico] = useState<boolean>(false);
  const toggleModal = async () => {
    setModalHistorico((prevState) => !prevState);
  };
  const [timeLineData, setTimeLineData] = useState<OptTimelineAction[]>();
  const { obterHistoryEntidade } = useHistoricoService();

  const { state } = useAuthenticationContext();

  useEffect(() => {
    if (modalHistorico && !timeLineData) {
      const obter = async () => {
        try {
          const historyData = await obterHistoryEntidade(
            'Atendimento',
            state.userInfo.data?.tenantId ?? '',
            atendimentoId as string
          );

          const data = historyData.versions.sort((a, b) => (a.order > b.order ? -1 : 1));
          data.map((history: { dateTimeAction: string | number | Date }) => {
            const newDate = new Date(history.dateTimeAction);
            const dataFormatada = new Intl.DateTimeFormat('pt-BR', {
              year: 'numeric',
              month: '2-digit',
              day: '2-digit',
              hour: '2-digit',
              hourCycle: 'h23',
              minute: '2-digit'
            }).format(newDate);
            return (history.dateTimeAction = dataFormatada);
          });
          setTimeLineData(data);
        } catch (error) {
          toggleModal();
        }
      };
      obter();
    } else {
      setTimeout(() => {
        setTimeLineData(undefined);
      }, 300);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalHistorico]);

  return (
    <>
      <Tooltip title="Histórico" placement="top">
        <Box
          sx={{ cursor: 'pointer' }}
          onClick={(e) => {
            e.stopPropagation();
            toggleModal();
          }}
        >
          <Icon path={mdiClipboardTextClockOutline} color={Colors.primary} size={1} />
        </Box>
      </Tooltip>
      <Modal open={modalHistorico} onClose={toggleModal} title="Histórico" width="800px">
        <div style={{ marginTop: '1em' }}>
          {timeLineData && (
            <OptTimeline
              data={timeLineData ?? []}
              valuesTableOptions={{
                nameHeader: 'Name',
                valueHeader: 'Value'
              }}
            />
          )}
          {!timeLineData && (
            <div style={{ height: '100px', overflow: 'none' }}>
              <OptLoading size={50} />
            </div>
          )}
        </div>
      </Modal>
    </>
  );
};
