import { OptChip } from '@optsol/react';
import styled from 'styled-components';

import { Colors } from '../../../core/colors';

interface Props {
  status: boolean;
}

export function ChipStatus({ status }: Props) {
  return (
    <>
      {!status ? (
        <ChipContainer bg={Colors.red}>
          <OptChip label={'Inativo'} style={{ borderRadius: '4px', height: 25 }} />
        </ChipContainer>
      ) : status ? (
        <ChipContainer bg={Colors.green}>
          <OptChip label={'Ativo'} style={{ borderRadius: '4px', height: 25 }} />
        </ChipContainer>
      ) : (
        <OptChip label={status} style={{ borderRadius: '4px', height: 25 }} />
      )}
    </>
  );
}

// Tive que criar esse styled pois as props backgroundcolor e textcolor do OptChip não estão funcionando
const ChipContainer = styled.div<{ bg: string }>`
  & > .MuiChip-root {
    background-color: ${({ bg }) => bg};
    color: ${Colors.white};
    font-size: 14px;
  }
`;
