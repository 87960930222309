import { Bar, BarDatum, ResponsiveBar } from '@nivo/bar';

import { Colors } from '../../../core/colors';
import { BarChartDataModel, BarChartSemDados } from '../../../models/dashboards/DashboardsModel';
import { useAuthenticationContext } from '../../contexts/authentication/authenticationContext';

import { AtendimentoMrTheme, barChartTheme } from './config';
import * as S from './styles';

interface Props {
  chartData: BarChartDataModel[];
}

export const GraficoBarras = ({ chartData }: Props) => {
  const {
    state: { tema }
  } = useAuthenticationContext();

  const semValoresZerados = chartData.some((obj) => obj.value !== 0);

  return (
    <S.ChartContainer>
      {chartData.length && semValoresZerados ? (
        <S.AtendimentoMrContainer style={{ overflowX: 'hidden' }}>
          <Bar
            height={chartData.length * 30}
            width={340}
            data={chartData as unknown as BarDatum[]}
            keys={['value']}
            indexBy="id"
            margin={{
              top: 0,
              right: 0,
              bottom: 0,
              left: 125
            }}
            // colors={({ id, data }) => data[`${id}Color`] as string}
            colors={[tema.light?.primary ?? Colors.gray4]}
            borderColor={tema.light?.primary ?? Colors.gray4}
            colorBy={'id'}
            layout="horizontal"
            valueScale={{ type: 'linear' }}
            indexScale={{ type: 'band', round: true }}
            theme={AtendimentoMrTheme}
            axisTop={null}
            tooltipLabel={(data) => `${data.indexValue}`}
            axisBottom={null}
            axisRight={null}
            axisLeft={{ tickPadding: 12, tickSize: 0 }}
            padding={0.25}
            borderRadius={12}
            labelTextColor={'white'}
            labelSkipWidth={25}
            labelSkipHeight={12}
          />
        </S.AtendimentoMrContainer>
      ) : (
        <ResponsiveBar
          data={BarChartSemDados as unknown as BarDatum[]}
          keys={['value']}
          indexBy="id"
          margin={{
            top: 50,
            right: 50,
            bottom: 110,
            left: 50
          }}
          layout="horizontal"
          valueScale={{ type: 'linear' }}
          indexScale={{ type: 'band', round: true }}
          tooltipLabel={(data) => `${data.indexValue}`}
          theme={barChartTheme}
          axisTop={null}
          axisBottom={null}
          colors={[Colors.gray7]}
          axisRight={null}
          axisLeft={null}
          labelSkipWidth={80}
          padding={0.25}
          labelTextColor={Colors.gray2}
          label={() => 'Sem dados'}
          labelSkipHeight={12}
          // legends={[
          //   {
          //     dataFrom: "keys",
          //     anchor: "bottom-right",
          //     direction: "column",
          //     justify: false,
          //     translateX: 120,
          //     translateY: 0,
          //     itemsSpacing: 2,
          //     itemWidth: 100,
          //     itemHeight: 20,
          //     itemDirection: "left-to-right",
          //     itemOpacity: 0.85,
          //     symbolSize: 20,
          //     effects: [
          //       {
          //         on: "hover",
          //         style: {
          //           itemOpacity: 1,
          //         },
          //       },
          //     ],
          //   },
          // ]}
        />
      )}
    </S.ChartContainer>
  );
};
