import React from 'react';

import { FullLocationModel } from './model/FullLocation.model';

// [START maps_react_map_marker_component]

type Props = google.maps.MarkerOptions & {
  onPlaceChange: (place: google.maps.GeocoderResult, fullLocation: FullLocationModel) => void;
};
export const Marker: React.FC<Props> = ({ onPlaceChange, ...options }) => {
  const [marker, setMarker] = React.useState<google.maps.Marker>();

  React.useEffect(() => {
    if (!marker) {
      setMarker(
        new google.maps.Marker({
          draggable: true
        })
      );
    } else
      marker.addListener('dragend', () => {
        new google.maps.Geocoder().geocode(
          {
            location: marker.getPosition()
          },

          (results, status) => {
            if (status === `OK` && results) {
              const [place] = results;
              const fullLocation: FullLocationModel = {
                lat: marker.getPosition()?.lat() ?? 0,
                lng: marker.getPosition()?.lng() ?? 0
              };
              onPlaceChange(place, fullLocation);
            }
          }
        );
      });

    // remove marker from map on unmount
    return () => {
      if (marker) {
        marker.setMap(null);
      }
    };
  }, [marker, onPlaceChange]);

  React.useEffect(() => {
    if (marker) {
      if (marker?.getPosition()) options.position = marker?.getPosition();
      marker.setOptions(options);
    }
  }, [marker, options]);

  return null;
};
