import {
  mdiArrowDown,
  mdiCheckCircleOutline,
  mdiMapSearchOutline,
  mdiPlus,
  mdiSend
} from '@mdi/js';
import { Icon } from '@mdi/react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Divider,
  Grid,
  IconButton,
  MenuItem,
  SelectChangeEvent,
  Tooltip,
  Typography
} from '@mui/material';
import { OptBackdrop, OptConfirmationDialog, OptSideLayoutContent } from '@optsol/react';
import { Controller, FormProvider } from 'react-hook-form';

import { Colors } from '../../../core/colors';
import { MenuAtendimentoClaims } from '../../../models/enums/AcessoClaims';
import { FasesAtendimentoEnum } from '../../../models/enums/FasesAtendimento';
import { ListaTipoVeiculoEnum } from '../../../models/enums/TipoVeiculo';
import { FormSelect } from '../../components/Form';
import { ModalMaps } from '../../components/Modal/ModalMaps';
import { ProtectedContent } from '../../components/ProtectedContent/ProtectedContent';
import { SelectVeiculo } from '../../components/SelectVeiculo/SelectVeiculo';
import { StatusPacienteChip } from '../../components/StatusPaciente/StatusPacienteChip';
import StepperCustomizado from '../../components/Stepper/Stepper';
import InformacoesPlano from '../cliente/InformacoesPlano';

import { useAtendimentoController } from './AtendimentoController';
import { CancelarAmbulanciaButton } from './CancelarAmbulanciaButton/CancelarAmbulanciaButton';
import { ConcluirRemocaoButton } from './ConcluirRemocaoButton/ConcluirRemocaoButton';
import EditarAtendimento from './EditarAtendimento';
import FormMedicoRegulador from './medicoRegulador/FormMedicoRegulador';
import FormInformacoesPaciente from './paciente/FormInformacoesPaciente';
import { PacienteActionsBar } from './PacienteActionsBar/PacienteActionsBar';
import * as S from './styles/index';
import { ToolbarTarmAtendimento } from './ToolbarTarmAtendimento/ToolbarTarmAtendimento';

type Props = ReturnType<typeof useAtendimentoController>;

export const AtendimentoView = ({
  adicionarPacienteHandler,
  removerPacienteHandler,
  cancelarChamadoHandler,
  encerrarChamadoHandler,
  alterarResponsavelHandler,
  podeEditarEndereco,
  assumirPacienteHandler,
  desabilitarSelectVeiculo,
  editarVeiculo,
  marcarStep,
  confirmarMarcarcaoStep,
  concluirRemocaoHandler,
  cancelarAmbulanciaHandler,
  encaminharVeiculoHandler,
  handleCloseMapsModal,
  handleOpenMapsModal,
  handleChange,
  editarPacienteHandler,
  cancelarMarcarStep,
  encaminharPaciente,
  salvarProntuario,
  podeEditarProntuario,
  state: {
    userInfo,
    form,
    formValues,
    center,
    fields,
    stepSelecionado,
    confirmandoMarcarStep,
    expanded,
    salvarAtendimentoIsLoading,
    openMapsModal,
    posicoesMapa
  },
  desabilitarFormularioAtendimento,
  desabilitarFormAgendamento,
  desabilitarFormPaciente,
  desabilitarTipoVeiculo,
  podeRemoverPaciente,
  podeAlterarResponsavel,
  podeEncerrarChamado,
  podeAssumirPaciente,
  podeCancelarChamado,
  podeEncaminharPaciente,
  podeIncluirNovoPaciente,
  podeCancelarVeiculo,
  podeAlterarRadio,
  podeAlterarMedico,
  podeAlterarTarm,
  podeConcluirRemocao,
  hasAccess,
  atualizarNotificacaoPaciente
}: Props) => {
  return (
    <>
      <OptSideLayoutContent>
        <OptConfirmationDialog
          open={confirmandoMarcarStep}
          title="Marcar etapa atendimento"
          icon={{ path: mdiCheckCircleOutline, color: Colors.green }}
          onCancel={cancelarMarcarStep}
          onClose={cancelarMarcarStep}
          onConfirm={() => {
            confirmarMarcarcaoStep();
          }}
        >
          Deseja confirmar a etapa de {stepSelecionado}?
        </OptConfirmationDialog>
        <ToolbarTarmAtendimento
          title="Atendimento"
          atendimentoId={formValues.atendimentoId ?? ''}
          numeroChamado={formValues.numeroChamado ?? ''}
        ></ToolbarTarmAtendimento>

        <Box>
          <FormProvider {...form}>
            <EditarAtendimento
              disableForm={desabilitarFormularioAtendimento()}
              disableFormAgendamento={desabilitarFormAgendamento()}
            />
            {formValues.atendimentoId && formValues.informacoesPlano && (
              <Box px={2.5} py={2}>
                <InformacoesPlano
                  plano={formValues.informacoesPlano}
                  motivoAtendimento={formValues.motivoAtendimento}
                />
              </Box>
            )}
            {formValues.atendimentoId && formValues.informacoesPlano && (
              <Box display="flex" flex={1} bgcolor={Colors.gray7} mb={4} mx={4}>
                <Divider />
              </Box>
            )}

            {formValues.atendimentoId && (
              <Grid container rowSpacing={3} columnSpacing={3} px={2} pt={2}>
                <Grid item xs={12} py={4} mb={11}>
                  <div style={{ margin: '1em' }}>
                    {fields.map((value, index) => {
                      if (!value.equipes) {
                        return <></>;
                      }
                      return (
                        <div key={index}>
                          <Accordion
                            expanded={expanded === index}
                            onChange={handleChange(index)}
                            style={{ marginTop: '0.2em' }}
                            key={index}
                          >
                            <AccordionSummary
                              expandIcon={
                                <Icon path={mdiArrowDown} size={1} color={Colors.gray1} />
                              }
                              id={`panel1bh-header`}
                              aria-controls={'panel1bh-content'}
                            >
                              <S.AccordionSummaryContent>
                                <Box
                                  display="flex"
                                  justifyContent="flex-start"
                                  alignItems="center"
                                  gap={1}
                                >
                                  <Typography sx={{ flexShrink: 0 }}>
                                    {value.nome ? (
                                      <span
                                        style={{
                                          margin: '0.5em',
                                          textTransform: 'uppercase',
                                          fontWeight: 'bold'
                                        }}
                                      >
                                        {value.nome}
                                      </span>
                                    ) : (
                                      <span style={{ margin: '0.5em' }}>
                                        Informações do paciente
                                      </span>
                                    )}
                                  </Typography>
                                  <Box mt={-0.4}>
                                    <StatusPacienteChip
                                      status={value.statusPaciente}
                                      faseAtendimentoSigla={value.faseAtendimentoDescricao ?? ''}
                                    />
                                  </Box>
                                </Box>
                                <PacienteActionsBar
                                  informacaoImportante={
                                    form.getValues('cliente')?.informacaoImportante ?? ''
                                  }
                                  paciente={value}
                                  podeRemoverPaciente={podeRemoverPaciente}
                                  podeEncerrarChamado={podeEncerrarChamado}
                                  podeCancelarChamado={podeCancelarChamado}
                                  podeAssumirPaciente={podeAssumirPaciente}
                                  podeAlterarResponsavel={podeAlterarResponsavel}
                                  podeEncaminharPaciente={podeEncaminharPaciente}
                                  podeAlterarRadio={podeAlterarRadio}
                                  podeAlterarMedico={podeAlterarMedico}
                                  podeAlterarTarm={podeAlterarTarm}
                                  alterarResponsavelHandler={alterarResponsavelHandler}
                                  assumirPacienteHandler={assumirPacienteHandler}
                                  cancelarChamadoHandler={cancelarChamadoHandler}
                                  encerrarChamadoHandler={encerrarChamadoHandler}
                                  removerPacienteHandler={removerPacienteHandler}
                                  encaminharPaciente={encaminharPaciente}
                                  atualizarNotificacaoPaciente={atualizarNotificacaoPaciente}
                                />
                              </S.AccordionSummaryContent>
                            </AccordionSummary>
                            <AccordionDetails>
                              <FormInformacoesPaciente
                                key={index}
                                index={index}
                                editarPaciente={editarPacienteHandler}
                                podeEditarEndereco={podeEditarEndereco(value)}
                                disableForm={
                                  desabilitarFormPaciente(value) ||
                                  !hasAccess(MenuAtendimentoClaims.ATUALIZAR)
                                }
                                accordionSummary
                              />
                              {value.faseAtendimento !== null &&
                                value.faseAtendimento !== FasesAtendimentoEnum.FASECRIACAO && (
                                  <FormMedicoRegulador
                                    key={index}
                                    index={index}
                                    editarPaciente={editarPacienteHandler}
                                    salvarProntuario={salvarProntuario}
                                    disableForm={
                                      desabilitarTipoVeiculo(value) ||
                                      !hasAccess(MenuAtendimentoClaims.ATUALIZAR)
                                    }
                                    podeEditarProntuario={podeEditarProntuario(value)}
                                  />
                                )}
                              {value.equipes.map((equipe, indexEquipe) => (
                                <ProtectedContent
                                  key={`${equipe.id}`}
                                  access={MenuAtendimentoClaims.VISUALIZAR_TIMELINE}
                                >
                                  <Box key={`${equipe.id}`} sx={{ marginTop: 5, marginBottom: 5 }}>
                                    <Typography
                                      sx={{
                                        width: '50%',
                                        flexShrink: 0
                                      }}
                                    >
                                      <Box m={'0.5em'} display="flex" gap={2}>
                                        {'Onde está a ambulância'}
                                        <Tooltip title="Acompanhar ambulância" placement="top">
                                          <IconButton
                                            style={{ marginTop: -8 }}
                                            onClick={() =>
                                              handleOpenMapsModal(
                                                value.atendimentoId ?? '',
                                                value.pacienteId ?? ''
                                              )
                                            }
                                          >
                                            <Icon
                                              path={mdiMapSearchOutline}
                                              size={1}
                                              color={Colors.blue}
                                            />
                                          </IconButton>
                                        </Tooltip>
                                      </Box>
                                    </Typography>
                                    <Grid
                                      container
                                      item
                                      rowSpacing={3}
                                      columnSpacing={3}
                                      px={1}
                                      pt={2.5}
                                      pb={2.5}
                                    >
                                      <Grid item xs={3}>
                                        <Controller
                                          name={`pacientes.${index}.equipes.${indexEquipe}.tipoVeiculo`}
                                          control={form.control}
                                          render={({ field: { onChange, value } }) => (
                                            <>
                                              <FormSelect
                                                onChange={(event: SelectChangeEvent<unknown>) => {
                                                  onChange(event);
                                                }}
                                                value={value ?? ''}
                                                name="tipo"
                                                label="Tipo veículo"
                                                size="small"
                                                disabled={true}
                                              >
                                                {ListaTipoVeiculoEnum.map((x) => {
                                                  return (
                                                    <MenuItem key={`${x.id}`} value={x.id}>
                                                      {x.descricao}
                                                    </MenuItem>
                                                  );
                                                })}
                                              </FormSelect>
                                            </>
                                          )}
                                        />
                                      </Grid>
                                      <Grid item xs={3} display="flex">
                                        <SelectVeiculo
                                          tipoVeiculo={equipe?.tipoVeiculo ?? -1}
                                          indexPaciente={index}
                                          indexEquipe={indexEquipe}
                                          editarVeiculo={editarVeiculo}
                                          disabledForm={desabilitarSelectVeiculo(value, equipe)}
                                        />
                                      </Grid>
                                      {!equipe.notificacaoEnviada &&
                                        equipe.veiculoId &&
                                        (value.faseAtendimento ===
                                          FasesAtendimentoEnum.FASEEMPENHO ||
                                          value.faseAtendimento ===
                                            FasesAtendimentoEnum.FASEATENDIMENTO) && (
                                          <Grid
                                            display="flex"
                                            item
                                            alignItems="end"
                                            marginLeft={'1em'}
                                          >
                                            <IconButton
                                              disabled={
                                                equipe.notificacaoEnviada ||
                                                value.faseEmpenhoResponsavelId !==
                                                  userInfo.data?.usuarioId
                                              }
                                              title="Enviar para ambulância selecionada"
                                              onClick={() => {
                                                encaminharVeiculoHandler(
                                                  value,
                                                  equipe.id ?? '',
                                                  index,
                                                  indexEquipe
                                                );
                                              }}
                                            >
                                              <Icon
                                                path={mdiSend}
                                                size={1}
                                                color={Colors.primary}
                                              />
                                            </IconButton>
                                          </Grid>
                                        )}
                                      <ProtectedContent
                                        access={MenuAtendimentoClaims.REMOVER_AMBULANCIA}
                                      >
                                        {podeCancelarVeiculo(equipe, value) && (
                                          <Grid
                                            display="flex"
                                            item
                                            alignItems="end"
                                            marginLeft={'0.5em'}
                                          >
                                            <CancelarAmbulanciaButton
                                              atendimentoId={value.atendimentoId ?? ''}
                                              pacienteId={value.pacienteId ?? ''}
                                              onClick={() =>
                                                cancelarAmbulanciaHandler(index, indexEquipe)
                                              }
                                            />
                                          </Grid>
                                        )}
                                      </ProtectedContent>
                                    </Grid>
                                    <StepperCustomizado
                                      notificacaoEnviada={equipe.notificacaoEnviada ?? true}
                                      marcarStep={marcarStep}
                                      equipeId={equipe.id ?? ''}
                                      pacienteId={value.pacienteId ?? ''}
                                      timeLine={equipe.timelineVeiculo ?? []}
                                      disabled={
                                        !equipe.notificacaoEnviada ||
                                        equipe.timelineVeiculo?.some(
                                          (tm) => tm.nome === 'EquipeRecusouAtendimento'
                                        )
                                      }
                                      // timeLine={
                                      //   timelineData?.timeLine ?? equipe.timelineVeiculo ?? null
                                      // }
                                    />
                                  </Box>
                                </ProtectedContent>
                              ))}

                              <S.MedicoReguladorButtonsContent>
                                {podeConcluirRemocao(value) && (
                                  <S.BotaoEncaminharContent>
                                    <ProtectedContent
                                      access={MenuAtendimentoClaims.CONCLUIR_REMOCAO}
                                    >
                                      <ConcluirRemocaoButton
                                        concluirRemocao={concluirRemocaoHandler}
                                        paciente={value}
                                      />
                                    </ProtectedContent>
                                  </S.BotaoEncaminharContent>
                                )}
                              </S.MedicoReguladorButtonsContent>
                            </AccordionDetails>
                          </Accordion>
                        </div>
                      );
                    })}
                    <ProtectedContent access={MenuAtendimentoClaims.INSERIR_PACIENTE}>
                      {podeIncluirNovoPaciente() && (
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                          <Tooltip title="Novo paciente">
                            <IconButton
                              color="info"
                              onClick={adicionarPacienteHandler}
                              size="large"
                            >
                              <Icon path={mdiPlus} size={1} color={Colors.blue} />
                            </IconButton>
                          </Tooltip>
                        </div>
                      )}
                    </ProtectedContent>
                  </div>
                </Grid>
              </Grid>
            )}
          </FormProvider>
        </Box>
        <OptBackdrop open={salvarAtendimentoIsLoading} />
        <ModalMaps
          openMapsModal={openMapsModal}
          handleCloseMapsModal={handleCloseMapsModal}
          posicoesMapa={posicoesMapa}
          center={center}
        />
      </OptSideLayoutContent>
    </>
  );
};
