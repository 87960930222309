import { Box } from '@mui/material';
import styled from 'styled-components';

export const ToolbarContainer = styled(Box)`
  & .MuiToolbar-root {
    box-shadow: 2px 6px 24px rgba(37, 40, 45, 0.1);
  }
  position: sticky;
  top: 0;
  z-index: 1200;
`;
