import { Box } from '@mui/material';
import styled from 'styled-components';

export const LabelList = styled.ul`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 30px;
  list-style-type: none;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  font-size: 14px;

  & li {
    line-height: 160%;
    background-color: rgba(215, 215, 240, 0.7);
    width: fit-content;
    margin-left: -7px;
    padding: 0 5px 1px 5px;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  }
`;

export const ChartContainer = styled(Box)`
  height: 280px;
  width: 100%;
  padding-top: 15px;
`;

export const PieContainer = styled(ChartContainer)`
  & > div > div > svg > g > g:last-child > g:nth-child(2) {
    transform: translate(5.1rem);
  }
`;

export const AtendimentoMrContainer = styled.div`
  width: 98%;
  height: 82%;
  margin: 0 10% 0 0;
  overflow: auto;

  &::-webkit-scrollbar {
    width: 4px;
    height: 4px;
  }
  &::-webkit-scrollbar-track {
    border-radius: 10px;
    background: rgba(0, 0, 0, 0.1);
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: rgba(0, 0, 0, 0.2);
  }
  &::-webkit-scrollbar-thumb:hover {
    background: rgba(0, 0, 0, 0.4);
  }
  &::-webkit-scrollbar-thumb:active {
    background: rgba(0, 0, 0, 0.9);
  }
`;

export const LegendShadows = styled.div`
  margin-top: -205px;
  margin-left: 19px;
  width: 140px;
  height: 201px;
  border-radius: 15px;
  /*  box-shadow: rgba(0, 0, 0, 0.1) 0px 5px 15px; */
  pointer-events: none;
`;

export const StatusDot = styled.span`
  --color: ${(props) => props.color};
  width: 11px;
  height: 11px;
  border-radius: 50%;
  background-color: var(--color);
`;

export const ListaIndicadoresContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0 auto;
  padding: 0 5%;
  overflow: auto;

  &::-webkit-scrollbar {
    width: 4px;
    height: 4px;
  }
  &::-webkit-scrollbar-track {
    border-radius: 10px;
    background: rgba(0, 0, 0, 0.1);
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: rgba(0, 0, 0, 0.2);
  }
  &::-webkit-scrollbar-thumb:hover {
    background: rgba(0, 0, 0, 0.4);
  }
  &::-webkit-scrollbar-thumb:active {
    background: rgba(0, 0, 0, 0.9);
  }
`;
