import { mdiCancel } from '@mdi/js';
import { Tooltip } from '@mui/material';
import { OptChip, OptConfirmationDialog } from '@optsol/react';
import { useState } from 'react';

import { Colors } from '../../../../core/colors';

import { ChaveModel } from './ChaveModel/ChaveModel';

interface Props {
  lista: ChaveModel[];
  disableForm?: boolean;
  onRemove: (chave: string) => void;
}

export const ListaChaves = ({ lista, disableForm, onRemove }: Props) => {
  const [open, setOpen] = useState(false);
  const [idChave, setIdChave] = useState<string>('');
  const toggleModal = () => {
    setOpen((prevState) => !prevState);
  };

  return (
    <>
      <OptConfirmationDialog
        open={open}
        title="Remover chave"
        icon={{ path: mdiCancel, color: Colors.red }}
        onCancel={toggleModal}
        onClose={toggleModal}
        onConfirm={() => {
          onRemove(idChave);
          toggleModal();
        }}
      >
        Deseja remover esta chave?
      </OptConfirmationDialog>
      {lista.map((x, i) => (
        <Tooltip key={x.hashSecretLabel} title={`Chave: ` + x.hashSecretLabel}>
          <OptChip
            onClick={() => {
              navigator.clipboard.writeText(x.hashSecretLabel);
            }}
            key={`${i}${x.nome}`}
            label={x.nome}
            style={{
              borderRadius: '4px',
              height: 25,
              margin: '2px 2px 2px 0px'
            }}
            disabled={disableForm}
            onDelete={() => {
              setIdChave(x.id ?? '');
              toggleModal();
            }}
          />
        </Tooltip>
      ))}
    </>
  );
};
