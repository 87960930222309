import axios from 'axios';

import { useAuthenticationContext } from '../app/contexts/authentication/authenticationContext';
import { HistoryTimeLineModel } from '../models/history/HistoryModel';
import { useApi } from '../shared/hooks/api';
import { downloadBlob } from '../shared/utils/functions';

export const useHistoricoService = () => {
  const { getFile } = useApi();
  const {
    state: { token }
  } = useAuthenticationContext();

  const baseApi = 'api/history';
  const urlApi = process.env.REACT_APP_BASE_HISTORY_API_URL;

  const obterHistoryEntidade = async (
    tipoRegistro: string,
    tenantId: string,
    entidadeId: string
  ) => {
    const response = await axios.get<HistoryTimeLineModel>(
      `${urlApi}/${baseApi}/keys?TipoRegistro=${tipoRegistro}&TenantId=${tenantId.toLowerCase()}&EntidadeId=${entidadeId.toLowerCase()}`,
      {
        headers: {
          'Content-Type': 'application/octet-stream',
          Authorization: `Bearer ${token}`
        }
      }
    );
    return response.data;
  };

  const baixarArquivoDaTimeline = async (empresaId: string, fileId: string, fileName: string) => {
    return getFile<Blob>(`${baseApi}/${empresaId}/file?fileId=${fileId}`, {
      responseType: 'blob',
      headers: {
        'Content-Type': 'application/octet-stream'
      }
    }).then((blob) => {
      downloadBlob(blob, `${fileName}`);
    });
  };

  return {
    obterHistoryEntidade,
    baixarArquivoDaTimeline
  };
};
