import { Box, InputLabel, Select, SelectProps, Typography } from '@mui/material';
import {
  Controller,
  ControllerProps,
  FieldError,
  FieldValues,
  get,
  useFormState
} from 'react-hook-form';

import { useAuthenticationContext } from '../../contexts/authentication/authenticationContext';

import { ErrorMessage } from './ErrorMessage';

interface Props<T extends FieldValues>
  extends Omit<ControllerProps<T>, 'render'>,
    Omit<SelectProps, 'defaultValue' | 'name'> {
  errors?: FieldError | string;
  itemHeight?: number;
}

const ITEM_PADDING_TOP = 8;
export const FormSelect = <T extends FieldValues>(props: Props<T>) => {
  const { control, errors: formErrors, name, label, itemHeight = 48, ...inputProps } = props;

  const { errors } = useFormState({ control });
  const error = get(errors, name);

  const MenuProps = {
    PaperProps: {
      style: { maxHeight: itemHeight * 4.5 + ITEM_PADDING_TOP }
    }
  };
  const {
    state: { tema }
  } = useAuthenticationContext();

  return (
    <Box display="flex" flexDirection="column" gap={1}>
      <InputLabel>
        <Typography textAlign="left" color={tema.light?.primary} lineHeight={1}>
          {label}
        </Typography>
      </InputLabel>
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <Select
            fullWidth
            defaultValue={field.value ?? ''}
            onChange={field.onChange}
            value={field.value ?? ''}
            variant="outlined"
            MenuProps={MenuProps}
            {...inputProps}
          />
        )}
      />
      <ErrorMessage error={error ?? formErrors} />
    </Box>
  );
};
