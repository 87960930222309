import { FormControlLabel, Grid, Switch } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';

import { MenuPlanoClaims } from '../../../models/enums/AcessoClaims';
import { PlanoModel } from '../../../models/Plano/Plano.model';
import { FormTextField } from '../../components/Form';
import { TituloForm } from '../../components/Form/TituloForm';
import { ProtectedContent } from '../../components/ProtectedContent/ProtectedContent';
interface Props {
  editarPlano: () => void;
}

export const FormEditarPlano = ({ editarPlano }: Props) => {
  const {
    control,
    watch,
    formState: { isDirty }
  } = useFormContext<PlanoModel>();

  return (
    <>
      <Grid container item rowSpacing={3} columnSpacing={3} px={2.5} pt={2.5}>
        <Grid item xs={12}>
          <TituloForm texto="Dados do plano" />
        </Grid>
        <Grid item xs={12} sm={8} md={6}>
          <Controller
            name="nome"
            control={control}
            render={({ field: { onBlur } }) => (
              <>
                <ProtectedContent mode="disable" access={MenuPlanoClaims.ATUALIZAR}>
                  <FormTextField
                    name="nome"
                    inputProps={{ maxLength: 100 }}
                    control={control}
                    label="Nome"
                    size="small"
                    fullWidth
                    onBlur={() => {
                      isDirty ? editarPlano() : onBlur();
                    }}
                  />
                </ProtectedContent>
              </>
            )}
          />
        </Grid>
        {/*    <Grid sm={4} md={6}></Grid> */}
        <Grid item xs={12} sm={8} md={6}>
          <Controller
            name="descricao"
            control={control}
            render={({ field: { onBlur } }) => (
              <>
                <ProtectedContent mode="disable" access={MenuPlanoClaims.ATUALIZAR}>
                  <FormTextField
                    inputProps={{ maxLength: 1000 }}
                    name="descricao"
                    control={control}
                    label="Descrição"
                    size="small"
                    fullWidth
                    onBlur={() => {
                      isDirty ? editarPlano() : onBlur();
                    }}
                  />
                  {`${watch('descricao').length} / 1000`}
                </ProtectedContent>
              </>
            )}
          />
        </Grid>

        {/*    <Grid sm={4} md={6}></Grid> */}
        <Grid item xs={12} sm={8} md={6}>
          <Controller
            name="numeroContrato"
            control={control}
            render={({ field: { onBlur } }) => (
              <>
                <ProtectedContent mode="disable" access={MenuPlanoClaims.ATUALIZAR}>
                  <FormTextField
                    inputProps={{ maxLength: 100 }}
                    name="numeroContrato"
                    control={control}
                    label="Número contrato"
                    size="small"
                    fullWidth
                    onBlur={() => {
                      isDirty ? editarPlano() : onBlur();
                    }}
                  />
                </ProtectedContent>
              </>
            )}
          />
        </Grid>
        {/*  <Grid sm={4} md={6}></Grid> */}
        <Grid item xs={12} sm={8} md={6}>
          <Controller
            name="codigoPlano"
            control={control}
            render={({ field: { onBlur } }) => (
              <>
                <ProtectedContent mode="disable" access={MenuPlanoClaims.ATUALIZAR}>
                  <FormTextField
                    inputProps={{ maxLength: 20 }}
                    name="codigoPlano"
                    control={control}
                    label="Código do plano"
                    size="small"
                    fullWidth
                    disabled
                    onBlur={() => {
                      isDirty ? editarPlano() : onBlur();
                    }}
                  />
                </ProtectedContent>
              </>
            )}
          />
        </Grid>

        {/*        <Grid sm={4} md={6}></Grid> */}
        <Grid item xs={6}>
          <Controller
            name="situacao"
            control={control}
            render={({ field: { onChange, value, onBlur } }) => (
              <FormControlLabel
                label={'Ativo'}
                control={
                  <ProtectedContent mode="disable" access={MenuPlanoClaims.ATUALIZAR}>
                    <Switch
                      onChange={onChange}
                      checked={value}
                      onBlur={() => {
                        isDirty ? editarPlano() : onBlur();
                      }}
                    />
                  </ProtectedContent>
                }
              />
            )}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default FormEditarPlano;
