import { Box, FormControlLabel, Switch } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { OptActionToolbar, OptSearchField } from '@optsol/react';
import { ChangeEvent, useState } from 'react';

import { RoutesObj } from '../../../routes/index.routes';
import { obterFiltroDaRota } from '../../../shared/utils/functions';
import { ToolbarContainer } from '../../components/ToolbarContainer';
import { useAuthenticationContext } from '../../contexts/authentication/authenticationContext';
import * as S from '../../style/toolbar-index';
interface Props {
  filtrarGridBySearch: (data?: string) => void;
  filtrarGridByStatus: (data?: string) => void;
  exibirEmPainelSearch: (value: boolean) => void;
}

export const ToolbarMapaAmbulancia = ({
  filtrarGridBySearch,
  filtrarGridByStatus,
  exibirEmPainelSearch
}: Props) => {
  const { state } = useAuthenticationContext();
  const [statusAtendimentoSearch, setStatusAtendimentoSearch] = useState(
    obterFiltroDaRota('status') ?? ''
  );
  const [exibirEmPainel, setExibirEmPainel] = useState<boolean>(false);
  const handleChange = (event: SelectChangeEvent) => {
    const statusSelecionado = event.target.value;
    setStatusAtendimentoSearch(statusSelecionado);
    if (statusSelecionado == '0') {
      filtrarGridByStatus('');
    } else {
      filtrarGridByStatus(statusSelecionado);
    }
  };
  const handleSwitchChange = (event: ChangeEvent<HTMLInputElement>) => {
    const valueChecked = event.target.checked;
    setExibirEmPainel(event.target.checked);
    exibirEmPainelSearch(valueChecked);
  };
  return (
    <ToolbarContainer>
      <OptActionToolbar
        goBackRoute={RoutesObj.Home}
        title="Mapa rastreamento"
        color={state.tema.light?.primary}
        clearMargin
      >
        <Box
          sx={{
            display: 'flex',
            flex: 1,
            marginLeft: '30px',
            marginBottom: '-11px',
            justifyContent: 'space-between'
          }}
        >
          <S.SearchContainer>
            <OptSearchField
              width={350}
              onSearch={filtrarGridBySearch}
              placeholder="Pesquisar por prefixo ou placa"
              defaultValue={obterFiltroDaRota('termo') ?? ''}
            />
          </S.SearchContainer>
          <Box display="flex">
            <FormControl sx={{ margin: 0, minWidth: 250, marginLeft: 5 }} size="small">
              <InputLabel id="demo-select-small">Status veículo</InputLabel>
              <Select
                labelId="demo-select-small"
                id="demo-select-small"
                onChange={handleChange}
                value={statusAtendimentoSearch}
                label="Status atendimento"
              >
                <MenuItem key={0} value={0}>
                  Todos
                </MenuItem>
                <MenuItem key={1} value={1}>
                  Disponível
                </MenuItem>
                <MenuItem key={2} value={2}>
                  Em atendimento
                </MenuItem>
              </Select>
            </FormControl>
            <div style={{ paddingLeft: '0.8em' }}>
              <FormControlLabel
                label="Exibir painel"
                control={
                  <Switch
                    onChange={(event) => {
                      handleSwitchChange(event);
                    }}
                    checked={exibirEmPainel}
                  />
                }
              />
            </div>
          </Box>
        </Box>
      </OptActionToolbar>
    </ToolbarContainer>
  );
};
