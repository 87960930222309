import { FormControlLabel, Grid, Switch } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';

import { MenuServicoCobertoClaims } from '../../../models/enums/AcessoClaims';
import { ServicoCobertoModel } from '../../../models/ServicoCoberto/ServicoCoberto.model';
import { FormTextField } from '../../components/Form';
import { TituloForm } from '../../components/Form/TituloForm';
import { ProtectedContent } from '../../components/ProtectedContent/ProtectedContent';
interface Props {
  editarServicoCoberto: () => void;
}

export const FormEditarServicoCoberto = ({ editarServicoCoberto }: Props) => {
  const {
    control,
    watch,
    formState: { isDirty }
  } = useFormContext<ServicoCobertoModel>();

  return (
    <>
      <Grid container item rowSpacing={3} columnSpacing={3} px={2.5} pt={2.5}>
        <Grid item xs={12}>
          <TituloForm texto="Dados do serviço" />
        </Grid>
        <Grid item xs={12} sm={8} md={6}>
          <Controller
            name="nome"
            control={control}
            render={({ field: { onBlur } }) => (
              <>
                <ProtectedContent mode="disable" access={MenuServicoCobertoClaims.ATUALIZAR}>
                  <FormTextField
                    name="nome"
                    control={control}
                    label="Nome"
                    size="small"
                    fullWidth
                    onBlur={() => {
                      isDirty ? editarServicoCoberto() : onBlur();
                    }}
                  />
                </ProtectedContent>
              </>
            )}
          />
        </Grid>
        <Grid item xs={12} sm={8} md={6}>
          <Controller
            name="descricao"
            control={control}
            render={({ field: { onBlur } }) => (
              <>
                <ProtectedContent mode="disable" access={MenuServicoCobertoClaims.ATUALIZAR}>
                  <FormTextField
                    inputProps={{ maxLength: 1000 }}
                    name="descricao"
                    control={control}
                    label="Descrição"
                    size="small"
                    fullWidth
                    onBlur={() => {
                      isDirty ? editarServicoCoberto() : onBlur();
                    }}
                  />
                  {`${watch('descricao').length} / 1000`}
                </ProtectedContent>
              </>
            )}
          />
        </Grid>
        <Grid item xs={12} sm={8} md={6} display="flex" gap={5}>
          <Controller
            name="temAreaAbrangencia"
            control={control}
            render={({ field: { onChange, value, onBlur } }) => (
              <ProtectedContent mode="disable" access={MenuServicoCobertoClaims.ATUALIZAR}>
                <FormControlLabel
                  label="Possui abrangência?"
                  control={
                    <Switch
                      onChange={onChange}
                      checked={value}
                      onBlur={() => {
                        isDirty ? editarServicoCoberto() : onBlur();
                      }}
                    />
                  }
                />
              </ProtectedContent>
            )}
          />
          <Controller
            name="situacao"
            control={control}
            render={({ field: { onChange, value, onBlur } }) => (
              <ProtectedContent mode="disable" access={MenuServicoCobertoClaims.ATUALIZAR}>
                <FormControlLabel
                  label={'Ativo'}
                  control={
                    <Switch
                      onChange={onChange}
                      checked={value}
                      onBlur={() => {
                        isDirty ? editarServicoCoberto() : onBlur();
                      }}
                    />
                  }
                />
              </ProtectedContent>
            )}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default FormEditarServicoCoberto;
