import { OptChip } from '@optsol/react';

import { EquipeModel } from '../../../../models/Atendimento/Equipe/Equipe.model';
import { TipoVeiculo, TipoVeiculoEnumLabel } from '../../../../models/enums/TipoVeiculo';

interface Props {
  lista: EquipeModel[];
  disableForm?: boolean;
  onRemove: (tipoVeiculo: TipoVeiculo, equipeId: string) => void;
}

export const ListaTipoVeiculos = ({ lista, disableForm, onRemove }: Props) => {
  return (
    <>
      {lista.map((x) => (
        <OptChip
          key={`${x.id}${x.tipoVeiculo}`}
          label={TipoVeiculoEnumLabel.get(x.tipoVeiculo!)}
          style={{
            borderRadius: '4px',
            height: 25,
            margin: '2px 2px 2px 0px'
          }}
          disabled={disableForm}
          onDelete={() => {
            onRemove(x.tipoVeiculo!, x.id ?? '');
          }}
        />
      ))}
    </>
  );
};
