import { mdiFileImport, mdiPen } from '@mdi/js';
import Icon from '@mdi/react';
import { Button } from '@mui/material';
import { ChangeEvent, useRef, useState } from 'react';

interface Props {
  labelColor: string;
  labelText: string;
  salvarArquivo: (file: File) => Promise<void>;
  acceptedFile: string;
}

export const FilePicker = ({ labelColor, labelText, salvarArquivo, acceptedFile }: Props) => {
  const inputRef = useRef<any>(null);
  const [filePreview, setFilePreview] = useState<File>();
  function handleChange(e: ChangeEvent<HTMLInputElement> | undefined) {
    if (!e) {
      if (inputRef.current) inputRef.current.value = null;
      return;
    }
    if (!e.target.files) return;

    const file = e.target.files[0];

    if (file) {
      const reader = new FileReader();
      salvarArquivo(file);

      reader.addEventListener('load', async () => {
        setFilePreview(file);
      });

      reader.readAsDataURL(file);
    }
  }
  return (
    <>
      {filePreview ? (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <div>
            <label htmlFor="upload-file" color={labelColor}>
              <input
                ref={inputRef}
                style={{ display: 'none' }}
                id="upload-file"
                name="upload-file"
                type="file"
                accept={acceptedFile}
                onChange={handleChange}
                onClick={() => {
                  handleChange(undefined);
                }}
              />
              <Button color="primary" variant="outlined" component="span" size="small">
                <Icon path={mdiPen} size={1} />
                {labelText}
              </Button>
            </label>
          </div>
          <span>{filePreview.name}</span>
        </div>
      ) : (
        <div>
          <label htmlFor="upload-file" color={labelColor}>
            <input
              style={{ display: 'none' }}
              id="upload-file"
              name="upload-file"
              type="file"
              accept={acceptedFile}
              onChange={handleChange}
            />
            <Button color="primary" variant="outlined" component="span" size="small">
              <Icon path={mdiFileImport} size={1} />
              {labelText}
            </Button>
          </label>
        </div>
      )}
    </>
  );
};
