import { Box } from '@mui/material';
import { OptGridRef, OptGridRequest, OptSideLayoutContent } from '@optsol/react';
import { useCallback, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { RoutesObj } from '../../../routes/index.routes';
import { useClienteService } from '../../../service/cliente.service';
import { obterFiltroDaRota, updateFiltroUrl } from '../../../shared/utils/functions';
import { PaginatedSearchRequest, SearchRequest } from '../../../shared/utils/searchRequest';

import GridClientes from './GridClientes';
import { ToolbarCliente } from './ToolbarCliente';

const Clientes = () => {
  const ref = useRef<OptGridRef>(null);
  const [clienteSearch, setClienteSearch] = useState(obterFiltroDaRota('termo') ?? '');
  const [incluirFilhosSearch, setIncluirFilhosSearch] = useState(
    obterFiltroClientesAssociadosDaRota() ?? false
  );
  const { buscarGridClientesPaginated } = useClienteService();
  const navigate = useNavigate();

  function ProcurarCliente(data?: string) {
    updateFiltroUrl('termo', data ?? '');
    setClienteSearch(data ?? '');
  }
  function ProcurarClientesAssociados(value?: boolean) {
    updateFiltroUrl('incluirClientesAssociados', value ?? false);
    setIncluirFilhosSearch(value ?? false);
  }

  function obterFiltroClientesAssociadosDaRota() {
    switch (obterFiltroDaRota('incluirClientesAssociados')) {
      case 'true':
        return true;
      case 'false':
        return false;
      default:
        return undefined;
    }
  }

  const carregar = useCallback(
    async (
      query: OptGridRequest,
      clienteSearchRequest: PaginatedSearchRequest = {
        termoBuscado: clienteSearch,
        apenasAtivo: false,
        incluirFilhos: incluirFilhosSearch
      }
    ) => {
      const request: SearchRequest<PaginatedSearchRequest> = {
        page: query.page,
        pageSize: query.pageSize,
        search: clienteSearchRequest
      };
      updateFiltroUrl('page', query.page);
      updateFiltroUrl('pageSize', query.pageSize);
      return buscarGridClientesPaginated(request).then((result) => {
        return {
          ...result,
          data: result.data.map((cliente) => ({
            documento: cliente.cpf ?? cliente.cnpj,
            ...cliente
          }))
        };
      });
    },
    [buscarGridClientesPaginated, clienteSearch, incluirFilhosSearch]
  );

  const editarClienteHandler = useCallback(
    (clienteId: string) => {
      navigate(RoutesObj.Cliente.Detalhes(clienteId), {
        state: { prevUrl: window.location.href }
      });
    },
    [navigate]
  );
  function atualizarGrid() {
    ref.current?.refresh();
  }
  return (
    <OptSideLayoutContent>
      <ToolbarCliente
        filtrarGridBySearch={ProcurarCliente}
        filtrarGridByFilhos={ProcurarClientesAssociados}
        obterFiltroClientesAssociadosDaRota={obterFiltroClientesAssociadosDaRota}
        atualizarGrid={atualizarGrid}
      />
      <Box px={2.5} pt={2}>
        <GridClientes
          ref={ref}
          editarCliente={editarClienteHandler}
          carregar={carregar}
          data={[]}
        ></GridClientes>
      </Box>
    </OptSideLayoutContent>
  );
};

export default Clientes;
