import { Grid } from '@mui/material';
import { useCallback } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { EnderecoModel } from '../../../../models/Endereco/Endereco.model';
import { EnderecoGeoLocalizacao } from '../../../../models/Endereco/EnderecoGeoLocalizacao.mode';
import { MenuClienteClaims } from '../../../../models/enums/AcessoClaims';
import { obterEnderecoGoole } from '../../../../shared/utils/functions';
import { ErrorMessage, FormTextField } from '../../../components/Form';
import { AutoCompleteMaps } from '../../../components/Google/Autocomplete';
import { FullLocationModel } from '../../../components/Google/model/FullLocation.model';
import { ProtectedContent } from '../../../components/ProtectedContent/ProtectedContent';
import { useAuthenticationContext } from '../../../contexts/authentication/authenticationContext';

export const FormCriarEndereco = () => {
  const {
    control,
    formState: { errors },
    getValues,
    setValue
  } = useFormContext<EnderecoModel>();
  const { hasAccess } = useAuthenticationContext();

  const adicionarEndereco = useCallback(
    (place?: google.maps.places.PlaceResult, fullLocation?: FullLocationModel | undefined) => {
      if (!place) return;

      console.log(fullLocation, 'fullLocation');
      console.log(place, 'fullLocation');

      let cep: string[] | undefined;
      let rua: string[] | undefined;
      let bairro: string[] | undefined;
      let cidade: string[] | undefined;
      let estado: string[] | undefined;
      let numero: string[] | undefined;

      if (place.address_components) {
        cep = obterEnderecoGoole(place, 'postal_code');
        rua = obterEnderecoGoole(place, 'route');
        bairro = obterEnderecoGoole(place, 'sublocality_level_1');
        cidade = obterEnderecoGoole(place, 'administrative_area_level_2');
        numero = obterEnderecoGoole(place, 'street_number');
        estado = place.address_components
          .filter(function (it) {
            return it.types.indexOf('administrative_area_level_1') !== -1;
          })
          .map(function (it) {
            return it.short_name;
          });
      }

      if (numero && numero.length > 0) {
        if (isNaN(+numero[0])) {
          numero = [];
        }
      }

      const enderecoGeoLocalizacao: EnderecoGeoLocalizacao = {
        descricaoLocal: place?.formatted_address ?? '',
        latitude: fullLocation?.lat ?? place.geometry?.location?.lat() ?? 0,
        longitude: fullLocation?.lng ?? place.geometry?.location?.lng() ?? 0,
        placeId: place?.place_id ?? '',
        bairro: bairro && bairro.length > 0 ? bairro[0] : '',
        cidade: cidade && cidade.length > 0 ? cidade[0] : '',
        estado: estado && estado.length > 0 ? estado[0] : '',
        cep: cep && cep.length > 0 ? cep[0] : '',
        numero: numero && numero.length > 0 ? +numero[0] : null,
        rua: rua && rua.length > 0 ? rua[0] : ''
      };

      setValue('enderecoGeolocalizacao', enderecoGeoLocalizacao);
    },
    [setValue]
  );

  return (
    <>
      <Grid container item columnSpacing={3} pt={2.5}>
        <Grid item xs={12}>
          <Controller
            name="nome"
            control={control}
            render={() => (
              <>
                <ProtectedContent mode="disable" access={MenuClienteClaims.ATUALIZAR}>
                  <FormTextField name="nome" control={control} label="Nome endereço" />
                </ProtectedContent>
              </>
            )}
          />
        </Grid>
      </Grid>
      <Grid container item columnSpacing={3} pt={2.5}>
        <Grid item xs={12}>
          <AutoCompleteMaps
            name="enderecoGeolocalizacao.descricaoLocal"
            onPlaceChange={(
              place: google.maps.places.PlaceResult,
              placeId?: string | undefined,
              fullLocation?: FullLocationModel | undefined
            ) => {
              adicionarEndereco(place, fullLocation);
            }}
            defalutPlace={getValues('enderecoGeolocalizacao.descricaoLocal') ?? ''}
            label="Endereço"
            disabled={!hasAccess(MenuClienteClaims.ATUALIZAR)}
          />
          <ErrorMessage error={errors.enderecoGeolocalizacao?.descricaoLocal} />
        </Grid>
      </Grid>
      <Grid container item rowSpacing={2} columnSpacing={1} pb={2.5} pt={2.5}>
        <Grid item xs={12}>
          <Controller
            name="complemento"
            control={control}
            render={() => (
              <>
                <ProtectedContent mode="disable" access={MenuClienteClaims.ATUALIZAR}>
                  <FormTextField name="complemento" control={control} label="Complemento" />
                </ProtectedContent>
              </>
            )}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default FormCriarEndereco;
