import styled from 'styled-components';

import { Colors } from '../../core/colors';

export const SearchContainer = styled.div`
  & fieldset {
    border-radius: 20px;
  }

  & .MuiOutlinedInput-root {
    height: 35px;
  }

  .Mui-focused .MuiOutlinedInput-notchedOutline,
  [class*='MuiInputBase-root-MuiOutlinedInput-root']:hover.Mui-focused
    .MuiOutlinedInput-notchedOutline {
    border: 2px solid ${Colors.primary};
  }
`;
