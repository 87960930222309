import { Box } from '@mui/material';
import { OptGridRef, OptGridRequest, OptSideLayoutContent } from '@optsol/react';
import { useCallback, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { RoutesObj } from '../../../routes/index.routes';
import { useMotivoAtendimentoService } from '../../../service/motivoAtendimento.service';
import { obterFiltroDaRota, updateFiltroUrl } from '../../../shared/utils/functions';
import { PaginatedSearchRequest, SearchRequest } from '../../../shared/utils/searchRequest';

import GridMotivoAtendimentos from './GridMotivoAtendimento';
import { ToolbarMotivoAtendimentos } from './ToolbarMotivoAtendimentos';

const MotivoAtendimentos = () => {
  const navegate = useNavigate();
  const ref = useRef<OptGridRef>();
  const [motivoAtendimentoSearch, setMotivoAtendimentoSearch] = useState(
    obterFiltroDaRota('termo') ?? ''
  );

  const { buscarGridMotivoAtendimentoPaginated } = useMotivoAtendimentoService();

  function ProcurarMotivoAtendimento(data?: string) {
    updateFiltroUrl('termo', data ?? '');
    setMotivoAtendimentoSearch(data ?? '');
  }

  const carregar = useCallback(
    async (
      query: OptGridRequest,
      motivoAtendimentoSearchRequest: PaginatedSearchRequest = {
        termoBuscado: motivoAtendimentoSearch,
        apenasAtivo: false
      }
    ) => {
      const request: SearchRequest<PaginatedSearchRequest> = {
        page: query.page,
        pageSize: query.pageSize,
        search: motivoAtendimentoSearchRequest
      };
      updateFiltroUrl('page', query.page);
      updateFiltroUrl('pageSize', query.pageSize);
      return buscarGridMotivoAtendimentoPaginated(request);
    },
    [buscarGridMotivoAtendimentoPaginated, motivoAtendimentoSearch]
  );

  async function editarMotivoAtendimento(motivoAtendimentoId: string) {
    navegate(RoutesObj.MotivoAtendimento.Detalhes(motivoAtendimentoId), {
      state: { prevUrl: window.location.href }
    });
  }

  return (
    <OptSideLayoutContent>
      <ToolbarMotivoAtendimentos filtrarGridBySearch={ProcurarMotivoAtendimento} />
      <Box px={1} pt={0.5}>
        <GridMotivoAtendimentos
          ref={ref}
          editarMotivoAtendimento={editarMotivoAtendimento}
          carregar={carregar}
        ></GridMotivoAtendimentos>
      </Box>
    </OptSideLayoutContent>
  );
};

export default MotivoAtendimentos;
