import { Chip } from '@mui/material';

import { useAuthenticationContext } from '../../../contexts/authentication/authenticationContext';

interface Props {
  clienteLabel: string;
}

export const ClientePai = ({ clienteLabel }: Props) => {
  const {
    state: { tema }
  } = useAuthenticationContext();
  return (
    <Chip
      label={`Cliente pai: ${clienteLabel}`}
      style={{
        justifyContent: 'flex-start',
        background: tema.light?.primary,
        color: tema.light?.secondary,
        borderRadius: '4px',
        height: 25,
        marginLeft: '0.5rem',
        width: 'max-content'
      }}
    />
  );
};
