import {
  Box,
  FormControlLabel,
  Grid,
  InputLabel,
  Switch,
  TextField,
  Typography
} from '@mui/material';
import { DateTimePicker } from '@mui/x-date-pickers';
import { isAfter } from 'date-fns';
import { useCallback, useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { AtendimentoFormModel } from '../../../../../models/Atendimento/Atendimento.model';
import { ErrorMessage } from '../../../../components/Form';
import { TituloForm } from '../../../../components/Form/TituloForm';
import { useAuthenticationContext } from '../../../../contexts/authentication/authenticationContext';

interface Props {
  editarAtendimento: () => void;
  disableForm?: boolean;
}

export const FormAgendamento = ({ editarAtendimento, disableForm }: Props) => {
  const [error, setError] = useState(false);
  const {
    state: { tema }
  } = useAuthenticationContext();
  const {
    control,
    watch,
    getValues,
    setValue,
    formState: { isDirty }
  } = useFormContext<AtendimentoFormModel>();

  const monitorarFlag = watch('agendamento');
  const fimAgendamento = watch('fimAgendamento') ?? '';
  const inicioAgendamento = watch('inicioAgendamento') ?? '';

  function isValidDate(dateString: string) {
    const date = new Date(dateString);
    return !isNaN(date.getTime());
  }

  const validarData = useCallback(
    (inicio: string, fim: string) => isAfter(new Date(fim), new Date(inicio)),
    []
  );

  useEffect(() => {
    if (!isValidDate(inicioAgendamento)) return;
    if (!isValidDate(fimAgendamento)) return;

    const dataValida = validarData(inicioAgendamento, fimAgendamento);
    setError(!dataValida);
  }, [fimAgendamento, inicioAgendamento, validarData]);

  return (
    <Box p={2} display="flex" gap={2}>
      <Grid item container columnSpacing={2}>
        <Grid item xs={12} pl={2}>
          <TituloForm texto="Informações do agendamento" />
        </Grid>
        <Grid item xs={12} style={{ marginTop: '2rem' }}>
          <Controller
            name="agendamento"
            control={control}
            render={({ field: { onChange, value } }) => {
              return (
                <FormControlLabel
                  label="Agendamento"
                  control={
                    <Switch
                      onChange={(event) => {
                        onChange(event);
                        if (monitorarFlag) {
                          setValue('inicioAgendamento', null);
                          setValue('fimAgendamento', null);
                        }
                      }}
                      checked={value ?? false}
                      onBlur={(event: any) => {
                        if (!event.target.checked && isDirty) {
                          editarAtendimento();
                        }
                      }}
                      disabled={disableForm}
                    />
                  }
                />
              );
            }}
          />
        </Grid>

        {watch('agendamento') && (
          <Grid item xs={6}>
            <Controller
              name="inicioAgendamento"
              control={control}
              render={({ field: { onChange, value } }) => {
                return (
                  <DateTimePicker
                    disabled={!monitorarFlag || disableForm}
                    value={value ?? (null as any)}
                    onChange={(e) => {
                      onChange(e);
                      setValue('inicioAgendamento', e);
                    }}
                    minDate={new Date().toISOString()}
                    renderInput={(params) => (
                      <>
                        <InputLabel>
                          <Typography textAlign="left" color={tema.light?.primary} lineHeight={1}>
                            Início agendamento
                          </Typography>
                        </InputLabel>
                        <TextField fullWidth {...params} size="small" />
                      </>
                    )}
                  />
                );
              }}
            />
          </Grid>
        )}
        <Grid item xs={6}>
          {watch('agendamento') && (
            <Box>
              <Controller
                name="fimAgendamento"
                control={control}
                render={({ field: { onChange, value } }) => {
                  return (
                    <DateTimePicker
                      disabled={!monitorarFlag || disableForm || !watch('inicioAgendamento')}
                      value={value ?? (null as any)}
                      onChange={onChange}
                      onClose={() => {
                        if (
                          validarData(
                            watch('inicioAgendamento') ?? '',
                            watch('fimAgendamento') ?? ''
                          ) &&
                          isDirty
                        )
                          editarAtendimento();
                      }}
                      minDate={getValues('inicioAgendamento')}
                      renderInput={(params) => (
                        <>
                          <InputLabel>
                            <Typography textAlign="left" color={tema.light?.primary} lineHeight={1}>
                              Fim agendamento{' '}
                            </Typography>
                          </InputLabel>
                          <TextField
                            size="small"
                            fullWidth
                            {...params}
                            onBlur={() => {
                              if (
                                validarData(
                                  watch('inicioAgendamento') ?? '',
                                  watch('fimAgendamento') ?? ''
                                ) &&
                                isDirty
                              ) {
                                editarAtendimento();
                              }
                            }}
                          />
                        </>
                      )}
                    />
                  );
                }}
              />

              {error && <ErrorMessage error={'Data inválida'} />}
            </Box>
          )}
        </Grid>
      </Grid>
    </Box>
  );
};
