import { OptBackdrop, OptLayoutProvider, OptLoading, OptSideLayout } from '@optsol/react';
import { useEffect, useState } from 'react';

import { BASE_CONFIG } from '../../core/baseConfig';
import { UsuarioResponseModel } from '../../models/dtos/Usuario/UsuarioResponse.model';
import { FasesAtendimentoEnum } from '../../models/enums/FasesAtendimento';
import { AppRoutes } from '../../routes/index.routes';
import { useMsalService } from '../../service/authentication/msal.service';
import { useUsuarioService } from '../../service/usuario.service';
import { FalhaUsuario } from '../components/FalhaUsuario';
import LogoEmpresa from '../components/LogoEmpresa/LogoEmpresa';
import { Modal } from '../components/Modal/Modal';
import SideLayoutColorContainer from '../components/SideLayoutColorContainer/SideLayoutColorContainer';
import { useAuthenticationContext } from '../contexts/authentication/authenticationContext';
import { FormEncerrarPlantao } from '../features/atendimento/formEncerrarPlantao/FormEncerrarPlantao';
import './App.css';

import { useSections } from './App.sections';

function App() {
  const [logoutBackdrop, setLogoutBackdrop] = useState(false);
  const [modalEncerrarPlantao, setModalEncerrarPlantao] = useState<boolean>(false);
  const [usuariosList, setUsuarios] = useState<UsuarioResponseModel[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const sections = useSections();
  const msal = useMsalService();
  const {
    state: { userInfo, tema, localId },
    inicializar: inicializarToken,
    resetarLocalId
  } = useAuthenticationContext();

  const { logoutTrigger, obterPorPerfilAcesso } = useUsuarioService();

  async function inicializar() {
    await inicializarToken();
  }

  useEffect(() => {
    inicializar();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const toggleEncerrarPlantao = () => {
    setModalEncerrarPlantao((prevState) => !prevState);
  };

  const possuiUserInfo = userInfo && userInfo.data;
  const carregando = userInfo?.loading;

  async function logout() {
    if (modalEncerrarPlantao) toggleEncerrarPlantao();

    setLogoutBackdrop(true);
    await logoutTrigger();
    resetarLocalId();
    msal.logout();
  }

  async function abrirModal() {
    if (
      userInfo.data?.faseAtendimento.some(
        (x) => x === FasesAtendimentoEnum.FASEREGULACAO || x === FasesAtendimentoEnum.FASEEMPENHO
      )
    ) {
      try {
        setLoading(true);
        const result = await obterPorPerfilAcesso();
        if (result.data.length > 0) {
          setUsuarios(result.data);
          toggleEncerrarPlantao();
        } else {
          logout();
        }
      } finally {
        setLoading(false);
      }
    } else {
      logout();
    }
  }
  if (!carregando && !possuiUserInfo && localId) return <FalhaUsuario />;
  return (
    <>
      {carregando && <OptLoading size={30} color={'primary'} />}

      {!carregando && possuiUserInfo && localId && (
        <OptLayoutProvider theme={tema} noRouter>
          <SideLayoutColorContainer>
            <OptSideLayout
              sections={sections}
              limitedSectionsView
              routes={AppRoutes}
              logo={{
                icon: <LogoEmpresa />,
                iconColor: 'transparent',
                path: '/'
              }}
              onLogout={() => {
                abrirModal();
              }}
              onManageProfile={() => {
                console.log('onManageProfile');
              }}
              appBarConfig={{
                sectionsAlignment: 'center',
                sideAppbarWidth: 50,
                hideLinkDescription: true
                /*    actions: [
                  {
                    icon: mdiBell,
                    qtdNotifications: 0,
                    onClick: () => {
                      console.log('Sem notificações');
                    },
                    title: 'Notificações'
                  }
                ] */
              }}
              profile={{
                email: userInfo.data?.email ?? '',
                name: userInfo.data?.nome ?? '',
                avatarSrc: undefined
              }}
              version={`Versão ${BASE_CONFIG.App.Version}`}
            />
            <Modal
              open={modalEncerrarPlantao}
              onClose={toggleEncerrarPlantao}
              title="Encerrar plantão"
              width="600px"
              overflow="hidden"
            >
              <FormEncerrarPlantao
                logout={logout}
                perfisAcesso={userInfo.data?.perfisAcesso}
                usuarios={usuariosList}
              />
            </Modal>
          </SideLayoutColorContainer>
        </OptLayoutProvider>
      )}
      <OptBackdrop open={loading} />
      <OptBackdrop open={logoutBackdrop} />
    </>
  );
}

export default App;
