import { Route, Routes as ReactRouterRoutes } from 'react-router-dom';

import { useAuthenticationContext } from '../app/contexts/authentication/authenticationContext';
import EditarUsuario from '../app/features/usuario/EditarUsuario';
import Home from '../app/pages/home/Home';
import Usuarios from '../app/pages/usuarios/Usuarios';
import { MenuUsuarioClaims } from '../models/enums/AcessoClaims';

export const UsuarioRoutes = () => {
  const { hasAccess } = useAuthenticationContext();
  const enableRoute = hasAccess(MenuUsuarioClaims.VISUALIZAR);
  return (
    <ReactRouterRoutes>
      <Route path="/" element={enableRoute ? <Usuarios /> : <Home />} />
      <Route path="/usuario/:id" element={enableRoute ? <EditarUsuario /> : <Home />} />
    </ReactRouterRoutes>
  );
};
