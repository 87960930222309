import styled from 'styled-components';

export const ButtonGridContent = styled.div`
  display: flex;
  justify-content: space-between;
  margin-right: 1em;
  margin-left: 1em;
`;

export const DeleteButtonContent = styled.div`
  display: flex;
  margin-top: 0.5em;
`;

export const GridContent = styled.div`
  display: flex;
  place-content: space-between;
`;
export const ImageWrapper = styled.div`
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border-radius: 20px;
  display: flex;
  background-color: ${(props) => props.color};
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 200px;
  overflow: hidden;
  width: 100%;
  max-width: 425px;
`;

export const Label = styled.label`
  background-color: ${(props) => props.color};
  color: #fff;
  border-radius: 20px;
  width: 100%;
  padding: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
`;

export const Input = styled.input`
  display: none;
`;

export const CustomGridImageContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ColorPickerContainer = styled.div<{ color: string }>`
  margin-top: 10px;
  & .rcp-fields-element-label {
    display: none;
  }
  & .rcp-fields-element.hex-element {
    border-radius: 6px;
    background-color: ${({ color }) => (color ? color : '')};
  }
  & .rcp-fields-element-input {
    font-size: 15px;
    text-shadow: -1px 0 #fff, 0 1px #fff, 1px 0 #fff, 0 -1px #fff;
  }
`;
