import { yupResolver } from '@hookform/resolvers/yup';
import { CircularProgress } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { FieldErrors, FormProvider, useForm } from 'react-hook-form';

import { Colors } from '../../../../core/colors';
import {
  dadosCriarEnderecoFormModelSchema,
  DADOS_CRIAR_ENDERECO_DEFAULT,
  EnderecoModel
} from '../../../../models/Endereco/Endereco.model';
import { MenuMotivoAtendimentoClaims } from '../../../../models/enums/AcessoClaims';
import ButtonModal from '../../../components/Button/ButtonModal';
import { ProtectedContent } from '../../../components/ProtectedContent/ProtectedContent';

import FormCriarEnderecoDestinoMotivo from './FormCriarEnderecoDestinoMotivo';

interface Props {
  salvarEndereco: (enderecoDestinoMotivoModel: EnderecoModel) => Promise<void>;
  enderecoDestinoMotivoModel: EnderecoModel | undefined;
  toggleEnderecoModal: () => void;
}

const CriarEnderecoDestinoMotivo = ({
  salvarEndereco,
  toggleEnderecoModal,
  enderecoDestinoMotivoModel
}: Props) => {
  const { enqueueSnackbar } = useSnackbar();
  const form = useForm<EnderecoModel>({
    mode: 'onBlur',
    defaultValues: enderecoDestinoMotivoModel ?? DADOS_CRIAR_ENDERECO_DEFAULT,
    resolver: yupResolver(dadosCriarEnderecoFormModelSchema)
  });

  const onValid = async (data: EnderecoModel) => {
    data.enderecoId = form.getValues().enderecoId;
    try {
      setLoadingButtomSave(true);
      await salvarEndereco(data);
      enqueueSnackbar('Registro salvo com sucesso!', {
        variant: 'success'
      });
      toggleEnderecoModal();
    } finally {
      setLoadingButtomSave(false);
    }
  };

  const onInvalid = (_errors: FieldErrors<EnderecoModel>) => {};
  const [loadingButtomSave, setLoadingButtomSave] = useState(false);

  return (
    <>
      <FormProvider {...form}>
        <FormCriarEnderecoDestinoMotivo />
        <ProtectedContent mode="disable" access={MenuMotivoAtendimentoClaims.ATUALIZAR}>
          <ButtonModal
            size="large"
            tipo="confirm"
            $backgroundColor={Colors.primary}
            onClick={form.handleSubmit(onValid, onInvalid)}
            loadingIndicator={<CircularProgress color={'primary'} />}
            loading={loadingButtomSave}
          >
            Salvar
          </ButtonModal>
        </ProtectedContent>
      </FormProvider>
    </>
  );
};

export default CriarEnderecoDestinoMotivo;
