import { mdiAccountOff, mdiCarEmergency, mdiCarInfo } from '@mdi/js';
import Icon from '@mdi/react';
import { Card, CardContent, Grid } from '@mui/material';
import { useEffect, useState } from 'react';

import { Colors } from '../../../core/colors';
import {
  TipoVeiculo,
  TipoVeiculoEnumLabel,
  TipoVeiculoSiglaEnumLabel
} from '../../../models/enums/TipoVeiculo';
import { VeiculoStatusModel } from '../../../models/Mapa/Localizacoes.model';
import { useAuthenticationContext } from '../../contexts/authentication/authenticationContext';
import './styles/styles.css';

interface Props {
  veiculos: VeiculoStatusModel[];
}

export const PainelVeiculo = ({ veiculos }: Props) => {
  const [listaOriginal, setListaOriginal] = useState<VeiculoStatusModel[]>([]);
  const [idsVeiculos, setIdVeiculos] = useState<string[]>([]);

  useEffect(() => {
    setListaOriginal(veiculos);
  }, []);
  const {
    state: { tema }
  } = useAuthenticationContext();

  useEffect(() => {
    if (listaOriginal.length > 0 && veiculos.length > 0) {
      const newListOriginal = listaOriginal.sort();
      const newListVeiculos = veiculos.sort();

      newListOriginal.forEach((veiculo, i) => {
        if (
          deepEqual(
            {
              ...veiculo,
              dataHoraUltimaAtualizacao: '',
              dataHoraUltimaAtualizacaoDate: '',
              infoTooltip: ''
            },
            {
              ...newListVeiculos[i],
              dataHoraUltimaAtualizacao: '',
              dataHoraUltimaAtualizacaoDate: '',
              infoTooltip: ''
            }
          ) === false
        ) {
          idsVeiculos.push(veiculo.veiculoId);
          setIdVeiculos(idsVeiculos);
        }
      });
      idsVeiculos.forEach((id) => {
        document.getElementById(id)?.classList.add('classBlink');
      });
      setIdVeiculos([]);
      setListaOriginal(veiculos);
    }
    const idTimeOut = setTimeout(() => {
      idsVeiculos.forEach((id) => {
        document.getElementById(id)?.classList.remove('classBlink');
      });
    }, 6000);
    return () => {
      clearTimeout(idTimeOut);
    };
  }, [veiculos]);

  function deepEqual(object1: any, object2: any) {
    // if (!object1 || !object2) return true;
    const keys1 = Object.keys(object1);
    const keys2 = Object.keys(object2);

    if (keys1.length !== keys2.length) {
      return false;
    }

    for (const key of keys1) {
      const val1 = object1[key];
      const val2 = object2[key];
      const areObjects = isObject(val1) && isObject(val2);
      if ((areObjects && !deepEqual(val1, val2)) || (!areObjects && val1 !== val2)) {
        return false;
      }
    }

    return true;
  }

  function isObject(object: any) {
    return object != null && typeof object === 'object';
  }

  function returnComponentListCard(
    veiculosP: VeiculoStatusModel[],
    cardWidth: string,
    margin: string
  ) {
    return veiculosP.map((gv) => {
      return (
        <Card
          id={gv.veiculoId}
          key={gv.veiculoId}
          style={{
            maxWidth: '300px',
            minWidth: '300px',
            minHeight: '6em',
            display: 'flex',
            justifyContent: 'center',
            backgroundColor: gv.colorVeiculo,
            color: Colors.white,
            margin: margin,
            boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 12px',
            flexWrap: 'wrap'
          }}
        >
          <CardContent
            style={{
              fontSize: '13px',
              display: 'flex',
              alignItems: 'center',
              placeContent: 'space-between',
              flexDirection: 'column',
              width: 'max-content',
              flexWrap: 'wrap',
              gap: 6
            }}
          >
            <div
              style={{
                width: '100%',
                justifyContent: 'start',
                display: 'flex',
                alignItems: 'center'
              }}
            >
              <Icon
                path={gv.semAtualizarMuitoTempo ? mdiCarInfo : mdiCarEmergency}
                size={0.9}
                color={Colors.white}
              />
              <span>Atualizado a {gv.dataHoraUltimaAtualizacao}</span>
            </div>
            <div
              style={{
                display: 'flex',
                placeContent: 'space-between',
                width: '100%',
                fontWeight: 'bold'
              }}
            >
              <span>{gv.prefixo}</span>
              <span>{TipoVeiculoSiglaEnumLabel.get(gv.tipoVeiculo)}</span>
              <span>{gv.placa}</span>
            </div>
            <div>
              {gv.possuiCondutor ? (
                <span>
                  {gv.condutor} {`${gv.numeroChamado ?? ''}`}
                </span>
              ) : (
                <Icon path={mdiAccountOff} size={0.9} color={Colors.white} />
              )}
            </div>
          </CardContent>
        </Card>
      );
    });
  }

  const styleGridHeaderItem = {
    backgroundColor: tema.light?.primary,
    opacity: 0.5,
    color: Colors.white,
    width: '100%',
    minHeight: '50px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    top: 1,
    padding: '0.2em'
  };

  return (
    <>
      <Grid container spacing={3}>
        <Grid
          item
          xs={12}
          sm={6}
          md={4}
          style={{
            flexFlow: 'column',
            flexDirection: 'column',
            height: '95vh',
            flexWrap: 'wrap',
            overflowY: 'hidden',
            overflowX: 'auto',
            padding: '0',
            paddingLeft: '0.7em',
            paddingTop: '0.7em'
          }}
        >
          <div style={{ ...styleGridHeaderItem, position: 'sticky' }}>
            {TipoVeiculoEnumLabel.get(TipoVeiculo.USB)}
          </div>
          <div style={{ padding: '0.1em' }}>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'column',
                flexWrap: 'wrap',
                height: '95vh',
                paddingRight: '0.2em'
              }}
            >
              {returnComponentListCard(
                veiculos.filter((x) => x.tipoVeiculo === TipoVeiculo.USB),
                veiculos.filter((v) => v.tipoVeiculo === TipoVeiculo.USB).length > 9
                  ? '50%'
                  : '80%',
                '0.2em 0em 0.2em 0.1em'
              )}
            </div>
          </div>
        </Grid>

        {[...[TipoVeiculo.USA, TipoVeiculo.VR, TipoVeiculo.CarroAdministrativo]].map((tipo, i) => (
          <Grid
            key={i}
            item
            xs={12}
            sm={6}
            md={2.66}
            style={{
              backgroundColor: i === 0 || i === 2 ? Colors.gray11 : Colors.white,
              height: '95vh',
              display: 'flex',
              overflow: 'auto',
              flexDirection: 'column',
              padding: '0',
              paddingTop: '0.7em'
            }}
          >
            <div
              style={{
                ...styleGridHeaderItem,
                position: 'sticky',
                opacity: tipo === TipoVeiculo.VR ? 0.5 : 1,
                backgroundColor: tema.light?.primary
              }}
            >
              {TipoVeiculoEnumLabel.get(tipo)}
            </div>
            <div
              style={{
                // padding: '0.1em',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center'
              }}
            >
              {returnComponentListCard(
                veiculos.filter((x) => x.tipoVeiculo === tipo),
                '80%',
                '0.2em 0em 0.2em 0em'
              )}
            </div>
          </Grid>
        ))}
      </Grid>
    </>
  );
};
