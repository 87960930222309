import { Box, Grid } from '@mui/material';
import { useState } from 'react';

import { DADOS_ESTADO_PLANO_DEFAULT, EstadoModel } from '../../../../models/Estado/Estado.model';
import { TituloForm } from '../../../components/Form/TituloForm';

import EstadoAbrangenciaPlano from './EstadoAbrangenciaPlano';
import MunicipioAbrangenciaPlano from './MunicipioAbrangenciaPlano';
import ServicoCobertoPlano from './ServicoCobertoPlano';

const Abrangencia = () => {
  const [servicoCobertoPlanoSelecionado, setServicoCobertoPlanoSelecionado] = useState<number>(-1);
  const [estadoPlanoIndex, setEstadoPlanoIndex] = useState<number>(-1);
  const [selecionarEstadoPlano, setSelecionarEstadoPlano] = useState<EstadoModel>(
    DADOS_ESTADO_PLANO_DEFAULT
  );

  function setarServicoCoberto(value: number) {
    setServicoCobertoPlanoSelecionado(value);
    setEstadoPlanoIndex(-1);
    setSelecionarEstadoPlano(DADOS_ESTADO_PLANO_DEFAULT);
  }

  return (
    <>
      <Box sx={{ width: 'auto' }}>
        <Grid container item rowSpacing={3} columnSpacing={3} px={2.5} pt={2.5}>
          <Grid item xs={12}>
            <TituloForm texto="Abrangência" />
          </Grid>
          <Grid item xs={12} sm={8} md={4}>
            <ServicoCobertoPlano selecionarServicoCobertoPlano={setarServicoCoberto} />
          </Grid>
          {servicoCobertoPlanoSelecionado >= 0 && (
            <>
              <Grid item xs={12} sm={8} md={4}>
                <EstadoAbrangenciaPlano
                  selecionarEstadoPlanoIndex={setEstadoPlanoIndex}
                  selecionarEstadoPlano={setSelecionarEstadoPlano}
                  name={`servicosCobertosPlanos.${servicoCobertoPlanoSelecionado}.estados`}
                  index={servicoCobertoPlanoSelecionado}
                ></EstadoAbrangenciaPlano>
              </Grid>
            </>
          )}

          {estadoPlanoIndex >= 0 && (
            <>
              <Grid item xs={12} sm={8} md={4}>
                <MunicipioAbrangenciaPlano
                  index={estadoPlanoIndex}
                  estadoSelecionado={selecionarEstadoPlano}
                  name={`servicosCobertosPlanos.${servicoCobertoPlanoSelecionado}.estados.${estadoPlanoIndex}.cidades`}
                ></MunicipioAbrangenciaPlano>
              </Grid>
            </>
          )}
        </Grid>
      </Box>
    </>
  );
};

export default Abrangencia;
