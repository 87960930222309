import { VeiculoSearchResponse } from '../models/dtos/Veiculo/veiculoSearchResponse.model';
import { TipoVeiculo } from '../models/enums/TipoVeiculo';
import {
  LocalizacoesModel,
  LocalizacoesPorPacienteModel,
  SearchLocalizacao
} from '../models/Mapa/Localizacoes.model';
import {
  CriarVeiculoModel,
  IdsRastreioViewModel,
  VeiculoModel
} from '../models/Veiculo/Veiculo.model';
import { useApi } from '../shared/hooks/api';
import { SearchRequest } from '../shared/utils/searchRequest';

export const useVeiculoService = () => {
  const { gridSearch, post, get, put } = useApi();

  const baseApi = `api/veiculo`;

  const buscarGridVeiculoPaginated = async (data: SearchRequest<object>) => {
    return gridSearch<VeiculoSearchResponse>(`${baseApi}/paginated`, data);
  };

  const salvarVeiculo = async (data: CriarVeiculoModel) => {
    return post<VeiculoModel>(`${baseApi}`, data);
  };

  const obterVeiculoData = async (id: string) => {
    return get<VeiculoModel>(`${baseApi}/${id}`);
  };

  const editarVeiculo = async (id: string, data: VeiculoModel) => {
    return put(`${baseApi}/${id}`, data);
  };

  const obterVeiculosByTipo = async (tipoVeiculo: TipoVeiculo) => {
    return post<VeiculoModel[]>(`${baseApi}/obter-ambulancias`, {
      tipoVeiculo: tipoVeiculo
    });
  };

  const obterVeiculos = async () => {
    return get<VeiculoModel[]>(`${baseApi}`);
  };

  const obterIdsRastreio = async () => {
    return post<IdsRastreioViewModel[]>(`${baseApi}/obter-idsRastreio`);
  };

  const obterGeolocalizacoes = async (search: SearchLocalizacao) => {
    return post<LocalizacoesModel>(`${baseApi}/obter-geolocalizacoes`, search);
  };

  const obterGeolocalizacoesPaciente = async (atendimentoId: string, pacienteId: string) => {
    return post<LocalizacoesPorPacienteModel>(
      `${baseApi}/obter-geolocalizacoes-paciente/atendimento/${atendimentoId}/paciente/${pacienteId}`
    );
  };

  return {
    buscarGridVeiculoPaginated,
    salvarVeiculo,
    obterVeiculoData,
    editarVeiculo,
    obterVeiculosByTipo,
    obterVeiculos,
    obterIdsRastreio,
    obterGeolocalizacoes,
    obterGeolocalizacoesPaciente
  };
};
