import * as yup from 'yup';

import { TipoLocalEnum } from '../enums/TipoLocal';

import {
  dadosEnderecoGeoLocalizacaoFormModelSchema,
  DADOS_ENDERECO_GEOLOCALIZACAO_DEFAULT,
  EnderecoGeoLocalizacao
} from './EnderecoGeoLocalizacao.mode';

const MSG_REQUIRED = 'Campo obrigatório';

export interface EnderecoModel {
  enderecoId?: string | null;
  nome: string;
  complemento?: string | null;
  enderecoGeolocalizacao: EnderecoGeoLocalizacao;
  tipoLocal?: TipoLocalEnum | null;
}

export const DADOS_CRIAR_ENDERECO_DEFAULT: EnderecoModel = {
  enderecoId: null,
  nome: '',
  complemento: null,
  enderecoGeolocalizacao: DADOS_ENDERECO_GEOLOCALIZACAO_DEFAULT,
  tipoLocal: null
};
export const dadosCriarEnderecoFormModelSchema: yup.SchemaOf<EnderecoModel> = yup.object().shape({
  enderecoId: yup.string().notRequired().nullable(),
  nome: yup.string().required(MSG_REQUIRED),
  complemento: yup.string().notRequired().nullable(),
  enderecoGeolocalizacao: dadosEnderecoGeoLocalizacaoFormModelSchema.required(),
  tipoLocal: yup.number().notRequired().nullable()
});
