import { mdiAccountCheck } from '@mdi/js';
import Icon from '@mdi/react';
import { IconButton, Tooltip } from '@mui/material';
import { OptBackdrop, OptConfirmationDialog } from '@optsol/react';
import { useState } from 'react';

import { Colors } from '../../../../core/colors';
import { InformacaoPacienteFormModel } from '../../../../models/Atendimento/Paciente/InformacaoPaciente.model';
import { Modal } from '../../../components/Modal/Modal';
import { ModalJustificativa } from '../modalJustificativa/ModalJustificativa';

interface Props {
  disableForm?: boolean;
  pacienteId: string;
  atendimentoId: string;
  paciente: InformacaoPacienteFormModel;
  encerrarChamado: (
    atendimentoId: string,
    pacienteId: string,
    justificativa: string
  ) => Promise<void>;
}

export const EncerrarChamadoButton = ({
  disableForm,
  pacienteId,
  atendimentoId,
  paciente,
  encerrarChamado
}: Props) => {
  const [confirmandoEncerramento, setConfirmandoEncerramento] = useState<boolean>(false);
  const [confirmacaoJustificativa, setConfirmacaoJustificativa] = useState<boolean>(false);
  const [encerrarChamadoIsLoading, setEncerrarChamadoIsLoading] = useState<boolean>(false);
  async function encerrarChamadoHandler() {
    toggleConfirmacaoEncerramento();

    try {
      setEncerrarChamadoIsLoading(true);
      await encerrarChamado(atendimentoId, pacienteId, '');
    } finally {
      setEncerrarChamadoIsLoading(false);
    }
  }
  async function encerrarChamadoComJustificativaHandler(justificativa: string) {
    try {
      setEncerrarChamadoIsLoading(true);
      await encerrarChamado(atendimentoId, pacienteId, justificativa);
    } finally {
      setEncerrarChamadoIsLoading(false);
    }
  }
  const toggleConfirmacaoEncerramento = () => {
    if (paciente.informarJustificativa) {
      toggleConfirmacaoJustificativa();
    } else {
      setConfirmandoEncerramento((prevState) => !prevState);
    }
  };

  const toggleConfirmacaoJustificativa = () => {
    setConfirmacaoJustificativa((prevState) => !prevState);
  };
  return (
    <>
      <Tooltip title={'Encerrar chamado'} placement="top">
        <IconButton
          disabled={disableForm}
          color="info"
          onClick={(e) => {
            e.stopPropagation();
            toggleConfirmacaoEncerramento();
          }}
        >
          <Icon path={mdiAccountCheck} size={1} color={Colors.green1} />
        </IconButton>
      </Tooltip>
      <OptConfirmationDialog
        open={confirmandoEncerramento}
        title="Encerrar chamado"
        icon={{ path: mdiAccountCheck, color: Colors.green1 }}
        onCancel={toggleConfirmacaoEncerramento}
        onClose={toggleConfirmacaoEncerramento}
        onConfirm={encerrarChamadoHandler}
      >
        Deseja encerrar esse chamado?
      </OptConfirmationDialog>
      <Modal
        open={confirmacaoJustificativa}
        onClose={toggleConfirmacaoJustificativa}
        title="Justificativa"
        width="600px"
      >
        <ModalJustificativa
          onClickButton={encerrarChamadoComJustificativaHandler}
          label={'Encerrar chamado'}
          defaultValue={'Orientação Médica'}
        />
      </Modal>

      <OptBackdrop open={encerrarChamadoIsLoading} />
    </>
  );
};
