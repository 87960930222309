import * as yup from 'yup';
const MSG_REQUIRED = 'Campo obrigatório';
export interface EnderecoGeoLocalizacao {
  bairro?: string | null;
  cep?: string | null;
  cidade: string;
  descricaoLocal: string;
  estado: string;
  // lat: number;
  latitude: number;
  // lng: number;
  longitude: number;
  numero?: number | null;
  placeId: string;
  rua?: string | null;
}

export const DADOS_ENDERECO_GEOLOCALIZACAO_DEFAULT: EnderecoGeoLocalizacao = {
  descricaoLocal: '',
  // lat: 0,
  latitude: 0,
  // lng: 0,
  longitude: 0,
  placeId: '',
  bairro: '',
  cidade: '',
  estado: '',
  cep: '',
  numero: 0,
  rua: ''
};
export const dadosEnderecoGeoLocalizacaoFormModelSchema: yup.SchemaOf<EnderecoGeoLocalizacao> = yup
  .object()
  .shape({
    descricaoLocal: yup.string().required(MSG_REQUIRED),
    // lat: yup.number().required(MSG_REQUIRED),
    latitude: yup.number().required(MSG_REQUIRED),
    // lng: yup.number().required(MSG_REQUIRED),
    longitude: yup.number().required(MSG_REQUIRED),
    placeId: yup.string().required(MSG_REQUIRED),
    bairro: yup.string().notRequired().nullable(),
    cidade: yup.string().required(MSG_REQUIRED),
    estado: yup.string().required(MSG_REQUIRED),
    cep: yup.string().notRequired().nullable(),
    numero: yup.number().notRequired().nullable(),
    rua: yup.string().notRequired().nullable()
  });
