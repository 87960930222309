import * as yup from 'yup';

import {
  dadosCoberturaFormModelSchema,
  ServicoCobertoModel
} from './../ServicoCoberto/ServicoCoberto.model';
import {
  dadosServicoCobertoPlanoFormModelSchema,
  ServicoCobertoPlanoModel
} from './ServicoCobertoPlano.model';
export interface PlanoModel {
  id: string;
  nome: string;
  descricao: string;
  descricaoServicoCobertoSearch?: string;
  numeroContrato: string;
  codigoPlano: string;
  situacao: boolean;
  servicosCobertosPlanos: ServicoCobertoPlanoModel[];
}

export const DADOS_PLANO_DEFAULT: PlanoModel = {
  id: '',
  nome: '',
  descricao: '',
  numeroContrato: '',
  codigoPlano: '',
  descricaoServicoCobertoSearch: '',
  situacao: false,
  servicosCobertosPlanos: []
};

const MSG_REQUIRED = 'Campo obrigatório';

export const dadosPlanoFormModelSchema: yup.SchemaOf<PlanoModel> = yup.object().shape({
  nome: yup.string().required(MSG_REQUIRED),
  descricao: yup.string().required(MSG_REQUIRED),
  situacao: yup.boolean().required(MSG_REQUIRED),
  descricaoServicoCobertoSearch: yup.string().notRequired(),
  numeroContrato: yup.string().required(MSG_REQUIRED).typeError('Valor inválido'),
  codigoPlano: yup.string().required(MSG_REQUIRED).typeError('Valor inválido'),
  servicosCobertosPlanos: yup.array().of(dadosServicoCobertoPlanoFormModelSchema).required(),
  id: yup.string().required(MSG_REQUIRED)
});
export interface CriarPlanoModel {
  nome: string;
  descricao: string;
}

export const DADOS_CRIAR_PLANO_DEFAULT: CriarPlanoModel = {
  nome: '',
  descricao: ''
};

export const dadosCriarPlanoFormModelSchema: yup.SchemaOf<CriarPlanoModel> = yup.object().shape({
  nome: yup.string().required(MSG_REQUIRED),
  descricao: yup.string().required(MSG_REQUIRED)
});

export interface InformacoesPlanoModel {
  id: string;
  abrangencia: string;
  nomePlano: string;
  numeroContrato: string;
  servicosCobertos: ServicoCobertoModel[];
}

export const dadosInformacoesPlanoModelSchema: yup.SchemaOf<InformacoesPlanoModel> = yup
  .object()
  .shape({
    abrangencia: yup.string().required(MSG_REQUIRED),
    nomePlano: yup.string().required(MSG_REQUIRED),
    numeroContrato: yup.string().required(MSG_REQUIRED),
    servicosCobertos: yup.array().of(dadosCoberturaFormModelSchema).notRequired(),
    id: yup.string().required(MSG_REQUIRED)
  });

export const DADOS_INFORMACOES_PLANO_DEFAULT: InformacoesPlanoModel = {
  id: '',
  abrangencia: '',
  nomePlano: '',
  numeroContrato: '',
  servicosCobertos: []
};
