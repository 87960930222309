import styled from 'styled-components';

export const CircleDotRed = styled.span<{ status: number }>`
  height: 13px;
  width: 13px;
  background: ${({ status }) => {
    if (status === 1) {
      return 'linear-gradient(rgba(32, 232, 9, 0.7) 0%,rgba(7, 184, 4, 0.7) 10%,rgba(16, 122, 4, 0.7) 50%,rgba(5, 77, 2, 0.7) 100%)';
    }
    if (status === 2) {
      return 'linear-gradient(rgba(252, 137, 30, 0.7) 0%,rgba(224, 141, 22, 0.7) 10%,rgba(179, 103, 23, 0.7) 50%,rgba(110, 64, 8, 0.7) 100%)';
    }
    if (status === 3) {
      return 'linear-gradient(rgba(219, 211, 211, 0.7) 0%,rgba(189, 187, 187, 0.7) 10%,rgba(143, 141, 141, 0.7) 50%,rgba(97, 97, 97, 0.7) 100%)';
    } else {
      return 'linear-gradient(rgba(219, 211, 211, 0.7) 0%,rgba(189, 187, 187, 0.7) 10%,rgba(143, 141, 141, 0.7) 50%,rgba(97, 97, 97, 0.7) 100%)';
    }
  }};
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
