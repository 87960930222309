import { yupResolver } from '@hookform/resolvers/yup';
import { CircularProgress } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { FieldErrors, FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { Colors } from '../../../core/colors';
import {
  CriarPlanoModel,
  DADOS_CRIAR_PLANO_DEFAULT,
  dadosCriarPlanoFormModelSchema
} from '../../../models/Plano/Plano.model';
import { RoutesObj } from '../../../routes/index.routes';
import { usePlanoService } from '../../../service/plano.service';
import ButtonModal from '../../components/Button/ButtonModal';

import FormCriarPlano from './FormCriarPlano';

const CriarPlano = () => {
  const [loadingButtomSave, setLoadingButtomSave] = useState(false);
  const { salvarPlano } = usePlanoService();
  const { enqueueSnackbar } = useSnackbar();
  const navegate = useNavigate();

  const form = useForm<CriarPlanoModel>({
    mode: 'onBlur',
    defaultValues: DADOS_CRIAR_PLANO_DEFAULT,
    resolver: yupResolver(dadosCriarPlanoFormModelSchema)
  });

  const onValid = (data: CriarPlanoModel) => {
    setLoadingButtomSave(true);
    salvarPlano(data).then((x) => {
      setLoadingButtomSave(false);
      enqueueSnackbar('Registro salvo com sucesso!', {
        variant: 'success'
      });
      navegate(RoutesObj.Plano.Detalhes(x.data.id));
    });
  };

  const onInvalid = (_errors: FieldErrors<CriarPlanoModel>) => {};

  return (
    <>
      <FormProvider {...form}>
        <FormCriarPlano />
        <ButtonModal
          size="large"
          tipo="confirm"
          $backgroundColor={Colors.primary}
          onClick={form.handleSubmit(onValid, onInvalid)}
          loadingIndicator={<CircularProgress color={'primary'} size={16} />}
          loading={loadingButtomSave}
        >
          Salvar
        </ButtonModal>
      </FormProvider>
    </>
  );
};

export default CriarPlano;
