import React from 'react';

// [START maps_react_map_marker_component]
type Props = google.maps.MarkerOptions & {
  onPlaceChange: (place: google.maps.GeocoderResult) => void;
  tooltipModelTexto?: string;
};

export const MarkerMapa: React.FC<Props> = ({
  onPlaceChange: _,
  tooltipModelTexto,
  ...options
}) => {
  const [marker, setMarker] = React.useState<google.maps.Marker>();
  // const [currentInfoWindow, setCurrentInfoWindow] = useState<google.maps.InfoWindow>();

  React.useEffect(() => {
    setMarker(new google.maps.Marker());

    // remove marker from map on unmount
    return () => {
      if (marker) {
        marker.setMap(null);
      }
    };
  }, []);

  React.useEffect(() => {
    if (marker) {
      marker.setOptions(options);
    }
  }, [marker, options]);

  React.useEffect(() => {
    if (marker) {
      google.maps.event.clearInstanceListeners(marker);
    }
    if (tooltipModelTexto) {
      const infowindow = new window.google.maps.InfoWindow({
        content: tooltipModelTexto
      });
      // setCurrentInfoWindow(infowindow);
      marker?.addListener('click', () => infowindow.open(null, marker));
    }
  }, [marker, tooltipModelTexto]);

  return null;
};
