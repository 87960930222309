import { Wrapper } from '@googlemaps/react-wrapper';
import React from 'react';
import ReactDOM from 'react-dom/client';

import App from './app/core/App';
import AppProviders from './app/core/App.providers';
import { BASE_CONFIG } from './core/baseConfig';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  // <React.StrictMode>
  <Wrapper apiKey={BASE_CONFIG.Google.ApiKey} libraries={['places']}>
    <AppProviders>
      <App />
    </AppProviders>
  </Wrapper>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
