import { useApi } from '../shared/hooks/api';
import { SearchRequest } from '../shared/utils/searchRequest';

export const useDashboardService = () => {
  const { gridSearch } = useApi();

  const baseApi = `api/dashboard`;

  const buscarPaginatedChamadosPorPrioridade = async (data: SearchRequest<object>) => {
    return gridSearch<any>(`${baseApi}/chamados-por-prioridade`, data);
  };

  const buscarPaginatedIncidenciaChamados = async (data: SearchRequest<object>) => {
    return gridSearch<any>(`${baseApi}/incidencia-chamados`, data);
  };

  const buscarPaginatedStatusVeiculos = async (data: SearchRequest<object>) => {
    return gridSearch<any>(`${baseApi}/status-veiculos`, data);
  };

  const buscarPaginatedMotivosAtendimento = async (data: SearchRequest<object>) => {
    return gridSearch<any>(`${baseApi}/motivos-atendimento`, data);
  };

  const buscarPaginatedAtendimentosPormedicoRegulador = async (data: SearchRequest<object>) => {
    return gridSearch<any>(`${baseApi}/atendimentos-por-medico-regulador`, data);
  };

  const buscarPaginatedIndicadores = async (data: SearchRequest<object>) => {
    return gridSearch<any>(`${baseApi}/atendimentos-por-status`, data);
  };

  return {
    buscarPaginatedChamadosPorPrioridade,
    buscarPaginatedIncidenciaChamados,
    buscarPaginatedStatusVeiculos,
    buscarPaginatedMotivosAtendimento,
    buscarPaginatedAtendimentosPormedicoRegulador,
    buscarPaginatedIndicadores
  };
};
