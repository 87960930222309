import {
  mdiAccountGroup,
  mdiAccountPlus,
  mdiAmbulance,
  mdiCardBulletedSettings,
  mdiCarEmergency,
  mdiFile,
  mdiFileDocumentEdit,
  mdiFileDocumentMultiple,
  mdiMapSearch,
  mdiOfficeBuilding
} from '@mdi/js';
import { OptMenuSection } from '@optsol/react';

import {
  MenuAcompanhamentoMapaClaims,
  MenuAtendimentoClaims,
  MenuClienteClaims,
  MenuEmpresaClaims,
  MenuMotivoAtendimentoClaims,
  MenuPlanoClaims,
  MenuServicoCobertoClaims,
  MenuUsuarioClaims,
  MenuVeiculoClaims
} from '../../models/enums/AcessoClaims';
import { useAuthenticationContext } from '../contexts/authentication/authenticationContext';

export const useSections = () => {
  const {
    state: { userInfo },
    hasAccess
  } = useAuthenticationContext();

  const sections: OptMenuSection[] = [];

  const protectedItems: OptMenuSection = {
    title: 'ProtectedRemocoes',
    items: []
  };

  if (hasAccess(MenuServicoCobertoClaims.VISUALIZAR)) {
    protectedItems.items.push({
      icon: mdiFileDocumentEdit,
      path: '/servicos-cobertos',
      title: 'Serviços cobertos'
    });
  }

  if (hasAccess(MenuPlanoClaims.VISUALIZAR)) {
    protectedItems.items.push({
      icon: mdiFileDocumentMultiple,
      path: '/planos',
      title: 'Planos',
      activeShouldBeExact: true
    });
  }

  if (hasAccess(MenuClienteClaims.VISUALIZAR)) {
    protectedItems.items.push({
      icon: mdiAccountGroup,
      path: '/clientes',
      title: 'Clientes'
    });
  }

  if (hasAccess(MenuVeiculoClaims.VISUALIZAR)) {
    protectedItems.items.push({
      icon: mdiCarEmergency,
      path: '/veiculos',
      title: 'Veículos',
      activeShouldBeExact: true
    });
  }

  if (hasAccess(MenuMotivoAtendimentoClaims.VISUALIZAR)) {
    protectedItems.items.push({
      icon: mdiCardBulletedSettings,
      path: '/motivo-atendimentos',
      title: 'Motivo atendimentos',
      activeShouldBeExact: true
    });
  }

  if (hasAccess(MenuUsuarioClaims.VISUALIZAR)) {
    protectedItems.items.push({
      icon: mdiAccountPlus,
      path: '/usuarios',
      title: 'Usuário'
    });
  }

  if (hasAccess(MenuAtendimentoClaims.MENU_ATENDIMENTO)) {
    protectedItems.items.push({
      icon: mdiAmbulance,
      path: '/atendimentos',
      title: 'Atendimento'
    });
  }

  if (hasAccess(MenuAcompanhamentoMapaClaims.MENU_ACOMPANHAMENTO_MAPA)) {
    protectedItems.items.push({
      icon: mdiMapSearch,
      path: '/mapa-ambulancias',
      title: 'Acompanhamento de ambulâncias'
    });
  }

  if (hasAccess(MenuEmpresaClaims.MENUS__MENU_EMPRESA_OPTSOL)) {
    protectedItems.items.push({
      icon: mdiOfficeBuilding,
      path: '/empresas-admin',
      title: 'Empresa OptSol'
    });
  }

  if (hasAccess(MenuEmpresaClaims.MENUS__MENU_EMPRESA_CLIENTE)) {
    protectedItems.items.push({
      icon: mdiOfficeBuilding,
      path: `/empresas-admin/empresa-admin/${userInfo.data?.tenantId}`,
      title: 'Empresa'
    });
  }
  if (hasAccess(MenuAtendimentoClaims.GERAR_RELATORIO)) {
    protectedItems.items.push({
      icon: mdiFile,
      path: `/relatorio`,
      title: 'Relatórios'
    });
  }
  if (protectedItems.items.length) {
    sections.push(protectedItems);
  }

  return sections;
};
