import { Box, Typography } from '@mui/material';

import { Colors } from '../../../core/colors';
import { PieChartDataModel } from '../../../models/dashboards/DashboardsModel';

import * as S from './styles';

interface Props {
  data: PieChartDataModel[];
}

const ListaIndicadores = ({ data }: Props) => {
  return (
    <S.ListaIndicadoresContainer>
      {data.length ? (
        data.map((item) => (
          <Box key={item.label} height="36px" display="flex" justifyContent="space-between">
            <Box display="flex" alignItems="center" justifyContent="space-between" gap={2}>
              <S.StatusDot color={item.color} />
              <Typography fontSize="14px" color={'#A3AED0'}>
                {item.label}
              </Typography>
            </Box>
            <Box fontSize="17px" fontWeight={500} pt={0.65}>
              {item.value}
            </Box>
          </Box>
        ))
      ) : (
        <Box
          width={'100%'}
          height={'200px'}
          display="flex"
          justifyContent="center"
          alignItems="center"
          color={Colors.gray2}
        >
          Sem dados
        </Box>
      )}
    </S.ListaIndicadoresContainer>
  );
};

export default ListaIndicadores;
