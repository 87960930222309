import { mdiChevronDoubleLeft, mdiChevronDoubleRight, mdiFilterVariant } from '@mdi/js';
import Icon from '@mdi/react';
import {
  Box,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { useState } from 'react';
import { Control, Controller } from 'react-hook-form';

import { Colors } from '../../../../core/colors';
import { InputsDashboardModel } from '../../../../models/dashboards/DashboardsModel';
import { MenuHomeClaims } from '../../../../models/enums/AcessoClaims';
import { ProtectedContent } from '../../../components/ProtectedContent/ProtectedContent';
import { useAuthenticationContext } from '../../../contexts/authentication/authenticationContext';
import * as S from '../styles';

interface Props {
  control: Control<InputsDashboardModel, any>;
  formWatch: InputsDashboardModel;
  userListActive: boolean;
  abrirUserList: () => void;
}

const InputsDashboard = ({ control, formWatch, userListActive, abrirUserList }: Props) => {
  const [expandirInputs, setExpandirInputs] = useState(false);

  const {
    state: { tema }
  } = useAuthenticationContext();

  const toggleInputs = () => {
    setExpandirInputs((prevState) => !prevState);
  };

  return (
    <Box
      height={'65px'}
      display="flex"
      justifyContent={!expandirInputs ? 'flex-end' : 'flex-start'}
    >
      {expandirInputs && (
        <Grid container item rowSpacing={3} columnSpacing={3} pr={3} pl={5.5} py={2}>
          <Grid item xs={4} sm={3} md={3} lg={2.5} xl={2}>
            <Controller
              name="dataInicio"
              control={control}
              render={({ field: { onChange, value } }) => {
                return (
                  <DatePicker
                    label="Data início"
                    inputFormat="dd/MM/yyyy"
                    value={value ?? null}
                    maxDate={formWatch.dataFim ? formWatch.dataFim : null}
                    onChange={onChange}
                    InputProps={{ style: { height: '30px', marginTop: '5px' } }}
                    renderInput={(params) => (
                      <TextField
                        size="small"
                        fullWidth
                        InputLabelProps={{ sx: { color: Colors.gray } }}
                        {...params}
                      />
                    )}
                  />
                );
              }}
            />
          </Grid>
          <Grid item xs={4} sm={3} md={3} lg={2.5} xl={2}>
            <Controller
              name="dataFim"
              control={control}
              render={({ field: { onChange, value } }) => {
                return (
                  <DatePicker
                    label="Data fim"
                    inputFormat="dd/MM/yyyy"
                    minDate={formWatch.dataInicio ? formWatch.dataInicio : null}
                    value={value ?? null}
                    onChange={onChange}
                    InputProps={{ style: { height: '30px', marginTop: '5px' } }}
                    renderInput={(params) => (
                      <TextField
                        size="small"
                        fullWidth
                        InputLabelProps={{ sx: { color: Colors.gray } }}
                        {...params}
                      />
                    )}
                  />
                );
              }}
            />
          </Grid>
          <Grid item xs={4} sm={3} md={3} lg={2.5} xl={2}>
            <Controller
              name="periodo"
              control={control}
              render={({ field: { onChange, value } }) => {
                return (
                  <>
                    <FormControl fullWidth size="small">
                      <InputLabel id="buscar-por-periodo" sx={{ color: Colors.gray }}>
                        Por período
                      </InputLabel>
                      <Select
                        labelId="buscar-por-periodo"
                        id="select-por-periodo"
                        onChange={onChange}
                        value={value ?? null}
                        label="Por período"
                        sx={{ height: '30px', marginTop: '5px' }}
                      >
                        <MenuItem key={1} value={1}>
                          Hoje
                        </MenuItem>
                        <MenuItem key={2} value={2}>
                          Últimos 30 dias
                        </MenuItem>
                        <MenuItem key={3} value={3}>
                          Últimos 60 dias
                        </MenuItem>
                        <MenuItem key={4} value={4}>
                          Últimos 90 dias
                        </MenuItem>
                        <MenuItem key={5} value={5}>
                          Últimos 12 meses
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </>
                );
              }}
            />
          </Grid>
        </Grid>
      )}
      <Box display="flex" justifyContent="center" alignItems="center" mr={2} ml={1}>
        <IconButton aria-label="Por período" onClick={toggleInputs}>
          <Tooltip title="Por período">
            <Icon path={mdiFilterVariant} size={1} />
          </Tooltip>
        </IconButton>
      </Box>
      <ProtectedContent access={MenuHomeClaims.VISUALIZAR_STATUS_USUARIOS}>
        <Box
          display="flex"
          // width="290px"
          justifyContent="center"
          alignItems="center"
          mr={userListActive ? 2.4 : 5.5}
        >
          <S.UserListButton
            variant="outlined"
            $active={userListActive}
            bgcolor={tema.light?.primary ?? Colors.ciano}
            onClick={abrirUserList}
          >
            Usuários
            <Icon
              path={userListActive ? mdiChevronDoubleRight : mdiChevronDoubleLeft}
              size={1}
              style={{ marginLeft: '5px' }}
            />
          </S.UserListButton>
        </Box>
      </ProtectedContent>
    </Box>
  );
};

export default InputsDashboard;
