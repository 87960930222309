import { useCallback } from 'react';

import { CidModel } from '../models/Cid/Cid.model';
import { useApi } from '../shared/hooks/api';
import { SearchRequest } from '../shared/utils/searchRequest';

export const useCidService = () => {
  const { gridSearch } = useApi();

  const baseApi = `api/cid`;

  const buscarCidPaginated = useCallback(
    async (data: SearchRequest<object>) => {
      return gridSearch<CidModel>(`${baseApi}/paginated`, data);
    },
    [baseApi, gridSearch]
  );

  return {
    buscarCidPaginated
  };
};
