import { yupResolver } from '@hookform/resolvers/yup';
import { CircularProgress } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { FieldErrors, FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { Colors } from '../../../core/colors';
import {
  CriarUsuarioModel,
  DADOS_CRIAR_USUARIO_DEFAULT,
  dadosCriarUsuarioFormModelSchema
} from '../../../models/Usuario/Usuario.model';
import { RoutesObj } from '../../../routes/index.routes';
import { useUsuarioService } from '../../../service/usuario.service';
import ButtonModal from '../../components/Button/ButtonModal';

import FormCriarUsuario from './FormCriarUsuario';

const CriarUsuario = () => {
  const [loadingButtomSave, setLoadingButtomSave] = useState(false);
  const { salvarUsuario } = useUsuarioService();
  const { enqueueSnackbar } = useSnackbar();
  const navegate = useNavigate();

  const form = useForm<CriarUsuarioModel>({
    mode: 'onBlur',
    defaultValues: DADOS_CRIAR_USUARIO_DEFAULT,
    resolver: yupResolver(dadosCriarUsuarioFormModelSchema)
  });

  const onValid = async (data: CriarUsuarioModel) => {
    setLoadingButtomSave(true);
    try {
      data.email = data.email.trim().toLowerCase().replaceAll(' ', '');
      const result = await salvarUsuario(data);
      enqueueSnackbar('Registro salvo com sucesso!', {
        variant: 'success'
      });
      navegate(RoutesObj.Usuario.Detalhes(result.data?.usuarioId));
    } finally {
      setLoadingButtomSave(false);
    }
  };

  const onInvalid = (_errors: FieldErrors<CriarUsuarioModel>) => {};

  return (
    <>
      <FormProvider {...form}>
        <FormCriarUsuario />
        <ButtonModal
          size="large"
          tipo="confirm"
          $backgroundColor={Colors.primary}
          onClick={form.handleSubmit(onValid, onInvalid)}
          loadingIndicator={<CircularProgress color={'primary'} size={16} />}
          loading={loadingButtomSave}
        >
          Salvar
        </ButtonModal>
      </FormProvider>
    </>
  );
};

export default CriarUsuario;
