import { Route, Routes as ReactRouterRoutes } from 'react-router-dom';

import { useAuthenticationContext } from '../app/contexts/authentication/authenticationContext';
import EditarMotivoAtendimento from '../app/features/motivoAtendimento/EditarMotivoAtendimento';
import Home from '../app/pages/home/Home';
import MotivoAtendimentos from '../app/pages/motivoAtendimento/MotivoAtendimentos';
import { MenuMotivoAtendimentoClaims } from '../models/enums/AcessoClaims';

export const MotivoAtendimentoRoutes = () => {
  const { hasAccess } = useAuthenticationContext();
  const enableRoute = hasAccess(MenuMotivoAtendimentoClaims.VISUALIZAR);
  return (
    <ReactRouterRoutes>
      <Route path="/" element={enableRoute ? <MotivoAtendimentos /> : <Home />} />
      <Route
        path="/motivo-atendimento/:id"
        element={enableRoute ? <EditarMotivoAtendimento /> : <Home />}
      />
    </ReactRouterRoutes>
  );
};
