import { Typography, TypographyProps } from '@mui/material';

import { Colors } from '../../core/colors';

type Props = TypographyProps;

const Chave = (props: Props) => {
  return <Typography {...props} fontWeight="bold" lineHeight={1} sx={{ color: Colors.gray2 }} />;
};

export default Chave;
