import { FormControlLabel, Grid, Switch, Typography } from '@mui/material';
import { OptGridRef } from '@optsol/react';
import { useRef, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { EnderecoModel } from '../../../models/Endereco/Endereco.model';
import { MenuMotivoAtendimentoClaims } from '../../../models/enums/AcessoClaims';
import { MotivoAtendimentoModel } from '../../../models/MotivoAtendimento/MotivoAtendimento.model';
import { IconButton } from '../../components/Button/IconButton';
import { FormTextField } from '../../components/Form';
import { TituloForm } from '../../components/Form/TituloForm';
import { Modal } from '../../components/Modal/Modal';
import { ProtectedContent } from '../../components/ProtectedContent/ProtectedContent';
import { useAuthenticationContext } from '../../contexts/authentication/authenticationContext';
import GridEnderecoDestinoMotivo from '../../pages/motivoAtendimento/GridEnderecoDestinoMotivo/GridEnderecoDestinoMotivo';

import CriarEnderecoDestinoMotivo from './enderecoDestino/CriarEnderecoDestinoMotivo';
import ServicoCobertoMotivoAtendimento from './servicoCobertoMotivoAtendimento/ServicoCobertoMotivoAtendimento';
import * as S from './styles/index';
interface Props {
  editarMotivoAtendimento: () => void;
  excluirEndereco: (enderecoDestinoId: string) => Promise<void>;
  salvarEndereco: (enderecoDestinoMotivoModel: EnderecoModel) => Promise<void>;
  enderecos: EnderecoModel[];
}

export const FormEditarMotivoAtendimento = ({
  editarMotivoAtendimento,
  salvarEndereco,
  excluirEndereco,
  enderecos
}: Props) => {
  const {
    control,
    watch,
    formState: { isDirty }
  } = useFormContext<MotivoAtendimentoModel>();
  const {
    state: { tema }
  } = useAuthenticationContext();

  const { hasAccess } = useAuthenticationContext();

  const [enderecoIsOpen, setEnderecoIsOpen] = useState(false);
  const [endereco, setEndereco] = useState<EnderecoModel>();

  const toggleEnderecoModal = () => {
    setEnderecoIsOpen((prevState) => !prevState);
  };

  function criarEndereco() {
    setEndereco(undefined);
    toggleEnderecoModal();
  }

  const refGridEndereco = useRef<OptGridRef>();

  async function excluirEnderecoHandler(enderecoDestinoId: string) {
    await excluirEndereco(enderecoDestinoId);
  }

  return (
    <>
      <Grid container item rowSpacing={3} columnSpacing={3} px={2.5} pt={2.5}>
        <Grid item xs={12}>
          <TituloForm texto="Dados do motivo" />
        </Grid>
        <Grid item xs={12} sm={8} md={6}>
          <Controller
            name="nome"
            control={control}
            render={({ field: { onBlur } }) => (
              <>
                <ProtectedContent mode="disable" access={MenuMotivoAtendimentoClaims.ATUALIZAR}>
                  <FormTextField
                    name="nome"
                    control={control}
                    label="Nome"
                    size="small"
                    fullWidth
                    onBlur={() => {
                      isDirty ? editarMotivoAtendimento() : onBlur();
                    }}
                  />
                </ProtectedContent>
              </>
            )}
          />
          <Controller
            name="situacao"
            control={control}
            render={({ field: { onChange, value, onBlur } }) => (
              <FormControlLabel
                label={'Ativo'}
                control={
                  <ProtectedContent mode="disable" access={MenuMotivoAtendimentoClaims.ATUALIZAR}>
                    <Switch
                      onChange={onChange}
                      checked={value}
                      onBlur={() => {
                        isDirty ? editarMotivoAtendimento() : onBlur();
                      }}
                    />
                  </ProtectedContent>
                }
              />
            )}
          />
        </Grid>

        <Grid item xs={12} sm={8} md={6}>
          <Controller
            name="descricao"
            control={control}
            render={({ field: { onBlur } }) => (
              <>
                <ProtectedContent mode="disable" access={MenuMotivoAtendimentoClaims.ATUALIZAR}>
                  <FormTextField
                    inputProps={{ maxLength: 1000 }}
                    name="descricao"
                    control={control}
                    label="Descrição"
                    size="small"
                    fullWidth
                    onBlur={() => {
                      isDirty ? editarMotivoAtendimento() : onBlur();
                    }}
                  />
                  {`${watch('descricao').length} / 1000`}
                </ProtectedContent>
              </>
            )}
          />
        </Grid>
        <Grid item xs={6} sm={6} md={2}></Grid>
        <Grid item xs={6}></Grid>
        <Grid item xs={6}>
          <TituloForm texto="Serviços cobertos" />
        </Grid>
        <Grid item xs={6}>
          <TituloForm texto="Endereços de destino" />
        </Grid>
        <Grid item xs={12} sm={8} md={6}>
          <ServicoCobertoMotivoAtendimento />
        </Grid>
        <Grid item xs={12} sm={8} md={6}>
          <S.ButtonGridContent>
            <Modal
              open={enderecoIsOpen}
              onClose={toggleEnderecoModal}
              title="Adicionar endereço destino"
              width="600px"
              overflow="hidden"
            >
              <CriarEnderecoDestinoMotivo
                salvarEndereco={salvarEndereco}
                toggleEnderecoModal={toggleEnderecoModal}
                enderecoDestinoMotivoModel={endereco}
              />
            </Modal>
            <Typography textAlign="left" lineHeight={1} color={tema.light?.primary}>
              Endereço destino
            </Typography>
            <ProtectedContent access={MenuMotivoAtendimentoClaims.ATUALIZAR}>
              <IconButton onClick={criarEndereco} color />
            </ProtectedContent>
          </S.ButtonGridContent>

          <S.GridContent>
            <GridEnderecoDestinoMotivo
              excluirEndereco={excluirEnderecoHandler}
              data={enderecos}
              ref={refGridEndereco}
              hasAccess={hasAccess(MenuMotivoAtendimentoClaims.ATUALIZAR)}
            />
          </S.GridContent>
        </Grid>
      </Grid>
    </>
  );
};

export default FormEditarMotivoAtendimento;
