import {
  OptGrid,
  OptGridColumn,
  OptGridOptions,
  OptGridRef,
  OptGridRequest,
  OptGridResponse,
  OptSideLayoutContent
} from '@optsol/react';
import { forwardRef } from 'react';

import { Colors } from '../../../core/colors';
import { ServicoCobertoSearchResponse } from '../../../models/dtos/servicoCobertoSearchResponse.model';
import { getHeaderTheme, obterFiltroDaRota } from '../../../shared/utils/functions';
import { ChipStatus } from '../../components/StatusText/ChipStatus';
import { useAuthenticationContext } from '../../contexts/authentication/authenticationContext';

interface GridProps {
  carregar: (data: OptGridRequest) => Promise<OptGridResponse<ServicoCobertoSearchResponse>>;
  editarServicocoberto(servicoCobertoId: string): Promise<void>;
}

const options: OptGridOptions = {
  search: true,
  pageSize: 10
};

const GridServicoCobertos = forwardRef<OptGridRef | undefined, GridProps>(
  ({ carregar, editarServicocoberto }, ref) => {
    const {
      state: { tema }
    } = useAuthenticationContext();
    const defaultPageSize = obterFiltroDaRota('pageSize');
    const defaultPage = obterFiltroDaRota('page');
    const columns: OptGridColumn<ServicoCobertoSearchResponse>[] = [
      {
        title: 'Id',
        field: 'id',
        hidden: true
      },
      {
        title: 'Nome  ',
        field: 'nome',
        align: 'start',
        render: (props: ServicoCobertoSearchResponse) => {
          return <>{props.nome ? props.nome : '-'}</>;
        }
      },
      {
        title: 'Descrição  ',
        field: 'descricao',
        align: 'start',
        render: (props: ServicoCobertoSearchResponse) => {
          return <>{props.descricao ? props.descricao : '-'}</>;
        }
      },
      {
        title: 'Possui abrangência',
        field: 'temAreaAbrangencia',
        align: 'start',
        render: (props: ServicoCobertoSearchResponse) => {
          return <>{props.temAreaAbrangencia ? 'Sim' : 'Não'}</>;
        }
      },
      {
        title: 'Situação',
        field: 'situacao',
        align: 'start',
        render: (props: ServicoCobertoSearchResponse) => {
          return <ChipStatus status={props.situacao} />;
        }
      }
    ];
    return (
      <OptSideLayoutContent>
        <OptGrid
          ref={ref as any}
          headerStyle={getHeaderTheme(tema.light?.primary ? tema.light?.primary : Colors.gray10)}
          columns={columns}
          data={carregar}
          options={{
            ...options,
            pageIndex: defaultPage !== null ? parseInt(defaultPage) : 0,
            pageSize: defaultPageSize !== null ? parseInt(defaultPageSize) : 10
          }}
          onRowClick={(rowData) => {
            editarServicocoberto(rowData.id);
          }}
        />
      </OptSideLayoutContent>
    );
  }
);

GridServicoCobertos.displayName = 'GridServicoCobertos';
export default GridServicoCobertos;
