import { mdiDeleteOutline } from '@mdi/js';
import Icon from '@mdi/react';
import {
  Autocomplete,
  Box,
  debounce,
  Divider,
  FormControl,
  Grid,
  IconButton,
  List,
  ListItemButton,
  ListItemText,
  ListSubheader,
  TextField,
  Typography
} from '@mui/material';
import { OptBackdrop, OptConfirmationDialog } from '@optsol/react';
import { useSnackbar } from 'notistack';
import { useCallback, useEffect, useState } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';

import { Colors } from '../../../../core/colors';
import {
  CidadeIbgeResponseModel,
  DADOS_CIDADE_PLANO_DEFAULT
} from '../../../../models/Cidade/Cidade.model';
import { MenuPlanoClaims } from '../../../../models/enums/AcessoClaims';
import { EstadoModel } from '../../../../models/Estado/Estado.model';
import { PlanoModel } from '../../../../models/Plano/Plano.model';
import { usePlanoService } from '../../../../service/plano.service';
import { ProtectedContent } from '../../../components/ProtectedContent/ProtectedContent';
import { useAuthenticationContext } from '../../../contexts/authentication/authenticationContext';

interface Props {
  index: number;
  estadoSelecionado: EstadoModel;
  name: `servicosCobertosPlanos.${number}.estados.${number}.cidades`;
}

const MunicipioAbrangenciaPlano = ({ estadoSelecionado, name }: Props) => {
  const {
    state: { tema }
  } = useAuthenticationContext();
  const { control, getValues } = useFormContext<PlanoModel>();

  const { fields, append, remove, replace } = useFieldArray({
    control,
    name
  });

  const [municipiosIBGE, setMunicipiosIBGE] = useState<CidadeIbgeResponseModel[]>([]);

  // const [municipiosFiltrados, setMunicipiosFiltrados] = useState<CidadeIbgeResponseModel[]>([]);

  const { enqueueSnackbar } = useSnackbar();
  const [loadingMunicipioPlano, setLoadingMunicipioPlano] = useState<boolean>(false);

  const [confirmandoExclusao, setConfirmandoExclusao] = useState<boolean>(false);

  const [posicaoExclusao, setPosicaoExclusao] = useState<number>(-1);

  function cancelarExclusao() {
    setConfirmandoExclusao(false);
  }

  const { obterDadosMunicipiosIBGE, salvarCidadePlano, removerCidadePlano } = usePlanoService();

  async function carregarMunicipios(termo: string) {
    if (termo) {
      const result = await obterDadosMunicipiosIBGE(termo);
      setMunicipiosIBGE(result);
    }
  }

  function adicionarMunicipio(municipio: CidadeIbgeResponseModel) {
    if (municipio) {
      setLoadingMunicipioPlano(true);
      const listaMunicipioPlanoOriginal = getValues(name);
      const jaAdicionado = listaMunicipioPlanoOriginal.filter((x) => x.nome === municipio.nome);
      if (jaAdicionado?.length === 0) {
        const municipioPlanoSelecionado = DADOS_CIDADE_PLANO_DEFAULT;
        municipioPlanoSelecionado.nome = municipio.nome;
        municipioPlanoSelecionado.codigoIBGE = municipio.id;
        municipioPlanoSelecionado.planoId = getValues('id');
        municipioPlanoSelecionado.servicoCobertoPlanoId = estadoSelecionado.servicoCobertoPlanoId;
        municipioPlanoSelecionado.estadoId = estadoSelecionado.estadoId;

        salvarCidadePlano(municipioPlanoSelecionado).then((x) => {
          municipioPlanoSelecionado.cidadeId = x.data.cidadeId;
          append(municipioPlanoSelecionado);
          setLoadingMunicipioPlano(false);
        });
      } else {
        setLoadingMunicipioPlano(false);
        enqueueSnackbar('Município já adicionado!', {
          variant: 'warning'
        });
      }
    }
  }

  function filtrarMunicipioHandler(newValue: string) {
    filtrarMunicipio(newValue);
  }

  const changeHandler = useCallback((value: string) => {
    filtrarMunicipioHandler(value);
  }, []);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedChangeHandler = useCallback(debounce(changeHandler, 500), []);

  async function filtrarMunicipio(_termo: string) {
    // const result = municipiosIBGE?.filter((x) => x.nome == termo || x.id == termo);
    // setMunicipiosFiltrados(result);
  }

  function removerMunicipio(posicao: number) {
    setLoadingMunicipioPlano(true);
    const listaMunicipioPlanoRemocao = getValues(name);

    const municipioPlanoRemover = listaMunicipioPlanoRemocao[posicao];

    removerCidadePlano(municipioPlanoRemover).then(() => {
      remove(posicao);
      setPosicaoExclusao(-1);
      cancelarExclusao();
      setLoadingMunicipioPlano(false);
    });
  }

  function confirmarRemocaoMunicipio(index: number) {
    setConfirmandoExclusao(true);
    setPosicaoExclusao(index);
  }

  useEffect(() => {
    carregarMunicipios(estadoSelecionado.sigla);
    const atualizarListaField = getValues(name);
    replace(atualizarListaField);
  }, [estadoSelecionado]);

  return (
    <>
      <OptConfirmationDialog
        open={confirmandoExclusao}
        title="Excluir município"
        icon={{ path: mdiDeleteOutline, color: Colors.red }}
        onCancel={cancelarExclusao}
        onClose={cancelarExclusao}
        onConfirm={() => {
          removerMunicipio(posicaoExclusao);
        }}
      >
        Deseja confirmar a exclusão do município de abrangência?
      </OptConfirmationDialog>
      <Grid item xs={12}>
        <Box display="flex" flexDirection="column" gap={1}>
          <Typography textAlign="left" lineHeight={1} color={tema.light?.primary}>
            Município abrangente
          </Typography>
          <FormControl fullWidth>
            <ProtectedContent mode="disable" access={MenuPlanoClaims.ATUALIZAR}>
              <Autocomplete
                fullWidth
                noOptionsText="Sem dados a exibir"
                options={municipiosIBGE ? municipiosIBGE : []}
                getOptionLabel={(option) => (typeof option === 'object' ? option.nome : '')}
                // value={servicoCoberto}
                onChange={(_event, newValue: any) => {
                  adicionarMunicipio(newValue);
                }}
                onInputChange={(_e, value) => debouncedChangeHandler(value)}
                renderInput={(params) => (
                  <>
                    <TextField
                      {...params}
                      size="small"
                      // label="Município abrangente"
                      placeholder="Selecione o município"
                      variant="outlined"
                    />
                  </>
                )}
                freeSolo
              />
            </ProtectedContent>
          </FormControl>
        </Box>
      </Grid>
      {fields?.length > 0 && (
        <Grid
          item
          xs={12}
          sx={{
            border: `1px solid ${tema.light?.primary}`,
            borderColor: tema.light?.primary ? tema.light.primary : Colors.primary,
            borderRadius: '3px',
            marginTop: '10px',
            borderWidth: '2px'
          }}
        >
          <Box
            sx={{
              width: '100%',
              bgcolor: 'background.paper',
              maxHeight: '400px',
              overflow: 'auto'
            }}
          >
            <List
              component="nav"
              aria-label="secondary mailbox folder"
              aria-labelledby="nested-list-subheader"
              subheader={
                <ListSubheader
                  sx={{ backgroundColor: tema.light?.primary, color: '#fff' }}
                  component="div"
                  id="nested-list-subheader"
                >
                  Município
                </ListSubheader>
              }
            >
              <Divider />
              {fields.map((value, index) => (
                <ProtectedContent key={value.id} mode="disable" access={MenuPlanoClaims.ATUALIZAR}>
                  <ListItemButton
                    key={index}
                    // selected={selectedIndex === value.id}
                    // onClick={(event) => handleListItemClick(event, value.id)}
                  >
                    <ListItemText primary={value.nome} />
                    <IconButton
                      onClick={() => confirmarRemocaoMunicipio(index)}
                      title="Excluir município abrangente"
                    >
                      <Icon path={mdiDeleteOutline} size={1} color="red" />
                    </IconButton>
                  </ListItemButton>
                </ProtectedContent>
              ))}
            </List>
          </Box>
          <OptBackdrop open={loadingMunicipioPlano} />
        </Grid>
      )}
    </>
  );
};

export default MunicipioAbrangenciaPlano;
